import { ReactNode } from 'react'

interface SummaryList {
  heading: ReactNode
  children: ReactNode
  className?: string
}

const SummaryList = ({ heading, children, className = '' }: SummaryList): JSX.Element => (
  <section className={`summary ${className}`}>
    <header className='summary-header'>{heading}</header>
    <dl>{children}</dl>
  </section>
)

export default SummaryList
