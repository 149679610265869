import { useRef } from 'react'
import { TextArea } from '../../../../../Components/Form/Inputs'
import { TextAreaProps } from '../../../../../Components/Form/Inputs/TextArea/TextArea'
import useScrollToElement from '../../../../../Hooks/useScrollToElement'
import './FlexibilityNotes.less'

interface FlexibiltyNotesProps extends GetPlaceholderArgs {
  value: TextAreaProps['value']
  onFlexibilityNotesChange: TextAreaProps['onChange']
  hide?: boolean
  className?: string
}

interface GetPlaceholderArgs {
  isFlexibleOnDate: boolean
  isFlexibleOnTime: boolean
}

const getPlaceholder = ({ isFlexibleOnDate, isFlexibleOnTime }: GetPlaceholderArgs): string => {
  const basePlaceholder = 'Let us know how flexible you are on'
  if (isFlexibleOnDate && isFlexibleOnTime) {
    return `${basePlaceholder} time and date!`
  }

  if (isFlexibleOnTime) {
    return `${basePlaceholder} timings!`
  }

  return `${basePlaceholder} the date!`
}

const FlexibiltyNotes = ({
  value,
  hide,
  className,
  onFlexibilityNotesChange,
  isFlexibleOnDate,
  isFlexibleOnTime,
}: FlexibiltyNotesProps): JSX.Element => {
  const sectionRef = useRef<HTMLElement>(null)
  const showInput = (hide === undefined || !hide) && (isFlexibleOnDate || isFlexibleOnTime)
  useScrollToElement(sectionRef, { pause: !showInput })

  return (
    <section
      ref={sectionRef}
      className={`flexibility-details${showInput ? ' show' : ''} ${className || ''}`}
      aria-live='polite'>
      {showInput && (
        <>
          <label htmlFor='flexible-date-time-notes'>
            Flexibility details
            <span className='visually-hidden'> (optional)</span>
          </label>
          <TextArea
            id='flexible-date-time-notes'
            rows={4}
            placeholder={getPlaceholder({ isFlexibleOnDate, isFlexibleOnTime })}
            value={value}
            onChange={onFlexibilityNotesChange}
          />
        </>
      )}
    </section>
  )
}

export default FlexibiltyNotes
