// AUTO-GENERATED CODE, DO NOT EDIT!
/* eslint-disable */

import * as Types from '../../../../../Types/generated-types'

import gql from 'graphql-tag'
import * as Urql from 'urql'
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export type EventTypesQueryVariables = Types.Exact<{ [key: string]: never }>

export type EventTypesQuery = {
  __typename?: 'Query'
  collections: Array<{ __typename?: 'Collection'; id: string; name: string }>
}

export const EventTypesDocument = gql`
  query EventTypes {
    collections {
      id
      name
    }
  }
`

export function useEventTypesQuery(
  options?: Omit<Urql.UseQueryArgs<EventTypesQueryVariables>, 'query'>
) {
  return Urql.useQuery<EventTypesQuery, EventTypesQueryVariables>({
    query: EventTypesDocument,
    ...options,
  })
}
