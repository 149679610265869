import {
  ChangeEventHandler,
  DetailedHTMLProps,
  FormEventHandler,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  useId,
  useState,
} from 'react'
import './Input.less'
import ValidationMessage from '../ValidationMessage/ValidationMessage'
import getValidationMessage, { MessageOverrides } from './Validation/getValidationMessage'

interface CustomProps {
  errorMessageOverrides?: MessageOverrides
  fieldName?: string // the name that will be used in validation error messages
  html5Error?: boolean
  forwardedRef?: ForwardedRef<HTMLInputElement | null>
}

export type InputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  CustomProps

const _Input = ({
  onChange: _onChange,
  onInvalid: _onInvalid,
  className,
  fieldName = 'value',
  forwardedRef,
  errorMessageOverrides,
  html5Error,
  ...props
}: InputProps): JSX.Element => {
  const [invalidMessage, setInvalidMessage] = useState<string | null>(null)
  const validationMessageId = useId()

  const onInvalid: FormEventHandler<HTMLInputElement> = (event) => {
    _onInvalid && _onInvalid(event)
    const { currentTarget } = event
    setInvalidMessage(getValidationMessage(currentTarget, fieldName, errorMessageOverrides))

    if (!html5Error) {
      event.preventDefault()
    }
  }
  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    _onChange && _onChange(event)
    setInvalidMessage(null)
  }

  const isInvalid = !!invalidMessage

  return (
    <ValidationMessage
      id={validationMessageId}
      className='hb-input'
      invalidMessage={invalidMessage}
      html5Error={html5Error}>
      <input
        {...props}
        onInvalid={onInvalid}
        onChange={onChange}
        ref={forwardedRef}
        aria-invalid={isInvalid}
        aria-errormessage={(isInvalid && validationMessageId) || undefined}
        className={`${className ? `${className} ` : ''}`}
      />
    </ValidationMessage>
  )
}

const Input = forwardRef<HTMLInputElement | null, InputProps>(function CustomInput(props, ref) {
  return <_Input forwardedRef={ref} {...props} />
})

export default Input
