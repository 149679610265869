import { Link } from 'react-router-dom'
import useBriefContext from '../../Hooks/Brief/useBriefContext'
import { BIZ_EMAIL_PATH } from '../../Router/Routes/formRoutes'
import './BizLoginBanner.less'
import isGuestAuthenticated from '../../Logic/Guest/isGuestAuthenticated'

const BizLoginBanner = (): JSX.Element | null => {
  const { brief } = useBriefContext()

  if (isGuestAuthenticated(brief)) return null

  return (
    <p className='biz-login-banner'>
      <span className='prefix'>Are you a</span> HeadBox Business customer?{' '}
      <Link to={`/${BIZ_EMAIL_PATH}`} className='sign-in-link'>
        Sign in
      </Link>
    </p>
  )
}

export default BizLoginBanner
