import { ChangeEventHandler, Dispatch, SetStateAction } from 'react'
import { Input } from '../../../../../Components/Form/Inputs'
import GuestLabel from './GuestLabel/GuestLabel'
import './NumberInput.less'

interface GuestNumberInputProps {
  numberOfGuests: number | ''
  setNumberOfGuests: Dispatch<SetStateAction<number | ''>>
}

const GuestNumberInput = ({
  numberOfGuests,
  setNumberOfGuests,
}: GuestNumberInputProps): JSX.Element => {
  const onChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setNumberOfGuests(target.value && parseInt(target.value))
  }

  return (
    <p className='contextual-guest-input'>
      I&#39;m expecting{' '}
      <label htmlFor='num-guests' className='visually-hidden'>
        Number of guests
      </label>
      <Input
        id='num-guests'
        type='number'
        min='1'
        max='1000000'
        step='1'
        value={numberOfGuests}
        onChange={onChange}
        required
      />{' '}
      <GuestLabel />
    </p>
  )
}

export default GuestNumberInput
