const JourneyIcon = (): JSX.Element => (
  <svg
    width='171'
    height='170'
    viewBox='0 0 171 170'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    aria-hidden='true'>
    <g clipPath='url(#clip0_1159_22226)'>
      <path
        d='M92.301 164.974L87.8867 166.298L87.8865 
        166.298C87.1405 166.522 86.5138 167.034 
        86.1443 167.72C85.7748 168.407 85.6927 
        169.213 85.9161 169.96L85.9435 
        170.051L93.1709 167.883L92.301 164.974Z'
        fill='#2F2E41'
      />
      <path
        d='M71.4712 167.2L67.1448 165.611L67.1447 
        165.611C66.4134 165.343 65.6057 165.376 
        64.8991 165.704C64.1925 166.032 63.6449 
        166.628 63.3768 167.36L63.3439 
        167.45L70.4274 170.051L71.4712 167.2Z'
        fill='#2F2E41'
      />
      <path
        d='M18.1122 85.8715C18.1146 81.4622 19.0962 77.1088 
        20.9859 73.1264C22.8756 69.1441 25.6261 65.6327 29.0381 
        62.8466C32.4501 60.0604 36.4382 58.0694 40.7134 
        57.0177C44.9886 55.966 49.4439 55.88 53.7563 56.7659C58.0688 
        57.6518 62.1306 59.4874 65.6473 62.1397C69.164 64.7921 72.0475 
        68.1947 74.0891 72.1012C76.1306 76.0076 77.279 80.32 77.451 
        84.7258C77.623 89.1317 76.8143 93.5206 75.0835 97.5748C75.1125 
        97.5425 75.1425 97.511 75.1714 97.4786C73.8531 100.556 72.0255 
        103.389 69.7657 105.857C69.7589 105.864 69.752 105.871 69.7452 
        105.879C69.5629 106.078 69.3795 106.276 69.1919 106.47C66.4796 109.303 
        63.2323 111.569 59.6386 113.135C56.0449 114.701 52.1764 115.537 48.2577 115.593L49.2606 
        170.154H46.1621L46.7892 134.143L42.3071 131.78L42.9908 130.479L46.8179 132.497L47.1123 
        115.59C39.3605 115.412 31.986 112.203 26.5664 106.649C21.1468 101.095 18.1124 93.6375 18.1122 85.8715Z'
        fill='#E6E6E6'
      />
      <path
        d='M103.364 74.2421C103.367 69.2245 104.484 64.2703 106.635 
        59.7385C108.785 55.2067 111.915 51.2108 115.798 48.0402C119.681 
        44.8697 124.219 42.6039 129.084 41.4071C133.949 40.2103 139.019 
        40.1124 143.927 41.1205C148.834 42.1286 153.456 44.2176 157.458 
        47.2359C161.46 50.2542 164.742 54.1263 167.065 58.5718C169.388 63.0172 170.695 
        67.9246 170.891 72.9383C171.086 77.9521 170.166 82.9467 168.197 87.5602C168.23 87.5235 168.264 
        87.4877 168.297 87.4507C166.796 90.9529 164.717 94.1763 162.145 96.9849C162.137 96.9934 162.129 
        97.0017 162.122 97.0101C161.914 97.2366 161.706 97.4619 161.492 97.6827C158.406 100.907 154.71 
        103.485 150.621 105.267C146.531 107.05 142.129 108.001 137.669 108.065L138.811 170.154H135.285L135.998 
        129.174L130.898 126.485L131.676 125.004L136.031 127.301L136.366 108.062C127.545 107.859 119.153 104.207 
        112.985 97.8867C106.818 91.5664 103.365 83.0797 103.364 74.2421Z'
        fill='#E6E6E6'
      />
      <path d='M107.79 44.4429H107.669L105.479 170.153H110.101L107.79 44.4429Z' fill='#3F3D56' />
      <path d='M40.929 166.91H41.3916V170.153H40.929V166.91Z' fill='#3F3D56' />
      <path
        d='M26.1196 170.195C26.1196 170.195 25.9599 166.843 22.6856 167.232L26.1196 170.195Z'
        fill='#3F3D56'
      />
      <path
        d='M138.297 170.195C138.297 170.195 138.138 166.843 134.863 167.232L138.297 170.195Z'
        fill='#3F3D56'
      />
      <path
        d='M1.31544 170.195C1.31544 170.195 1.47519 166.843 4.74951 167.232L1.31544 170.195Z'
        fill='#3F3D56'
      />
      <path
        d='M54.5132 170.195C54.5132 170.195 54.6729 166.843 57.9473 167.232L54.5132 170.195Z'
        fill='#3F3D56'
      />
      <path
        d='M98.6905 170.195C98.6905 170.195 98.8502 166.843 102.125 167.232L98.6905 170.195Z'
        fill='#3F3D56'
      />
      <path
        d='M145.643 170.195C145.643 170.195 145.803 166.843 149.077 167.232L145.643 170.195Z'
        fill='#3F3D56'
      />
      <path d='M0.213226 169.821H171V170.206H0.213226V169.821Z' fill='#3F3D56' />
      <path
        d='M91.9482 165.878L89.7069 166.551L86.0507 158.211L89.3588 157.219L91.9482 165.878Z'
        fill='#FFB8B8'
      />
      <path
        d='M70.6478 167.713L68.4509 166.906L70.5121 158.036L73.7544 159.226L70.6478 167.713Z'
        fill='#FFB8B8'
      />
      <path
        d='M88.1903 150.782L92.0073 163.398L87.427 164.737L83.0373 153.076L88.1903 150.782Z'
        fill='#2F2E41'
      />
      <path
        d='M76.1665 153.649L72.731 165.501L67.9598 163.207L71.395 152.12L76.1665 153.649Z'
        fill='#2F2E41'
      />
      <path
        d='M77.9546 155.787C81.0099 155.773 84.058 155.491 87.0643 154.946L87.1408 154.93V152.015L88.8853 
        151.82L87.5237 146.95C88.1583 139.446 87.2565 129.944 86.9647 127.208C86.8978 126.567 
        86.8538 126.207 86.8538 126.207L85.323 113.174L82.7614 110.806L81.5978 111.564L79.4392 113.726C76.9302 
        119.911 74.9392 125.733 74.9267 126.107L66.5123 153.114L66.569 153.154C69.4908 155.22 73.9446 
        155.787 77.9546 155.787Z'
        fill='#3F3D56'
      />
      <path
        opacity='0.2'
        d='M84.2373 118.955L84.8257 122.633L80.8427 124.279L84.2373 118.955Z'
        fill='black'
      />
      <path
        d='M76.4218 110.786H85.6307C85.8202 110.786 86.0018 110.711 86.1357 110.577C86.2697 110.442 
        86.345 110.261 86.3452 110.071V106.095C86.3424 104.684 85.7808 103.332 84.7836 102.335C83.7864 
        101.338 82.4351 100.778 81.0263 100.778C79.6174 100.778 78.2661 101.338 77.2689 102.335C76.2717 
        103.332 75.7101 104.684 75.7073 106.095V110.071C75.7076 110.261 75.7829 110.442 75.9168 
        110.577C76.0508 110.711 76.2324 110.786 76.4218 110.786Z'
        fill='#2F2E41'
      />
      <path
        d='M80.0095 110.547C77.8558 110.547 76.1099 108.798 76.1099 106.641C76.1099 
        104.484 77.8558 102.735 80.0095 102.735C82.1632 102.735 83.9092 104.484 83.9092 
        106.641C83.9092 108.798 82.1632 110.547 80.0095 110.547Z'
        fill='#FFB8B8'
      />
      <path
        d='M74.4488 106.493H80.0798L80.1376 105.683L80.4263 106.493H81.2933L81.4078 
        104.888L81.98 106.493H83.6577V106.413C83.6565 105.296 83.2128 104.225 82.424 103.435C81.6352 
        102.645 80.5657 102.2 79.4502 102.199H78.6563C77.5408 102.2 76.4713 102.645 75.6826 103.435C74.8938 
        104.225 74.4501 105.296 74.4488 106.413V106.493Z'
        fill='#2F2E41'
      />
      <path
        d='M80.1258 111.529C80.1682 111.529 80.2105 111.525 80.2522 111.518L84.3755 
        110.789V103.964H79.8365L79.9489 104.095C81.5124 105.921 80.3345 108.883 79.4932 
        110.485C79.4312 110.603 79.4035 110.735 79.4134 110.868C79.4233 111 79.4704 111.127 79.5491 
        111.234C79.6151 111.325 79.7018 111.4 79.802 111.451C79.9022 111.502 80.0132 111.529 80.1258 111.529Z'
        fill='#2F2E41'
      />
      <path
        d='M72.8507 128.026H75.1108C75.1833 128.026 75.2533 127.999 75.307 127.95C75.3607 
        127.902 75.3943 127.834 75.4011 127.762L75.8535 123.118H72.1079L72.5603 127.762C72.5671 
        127.834 72.6007 127.902 72.6544 127.95C72.7081 127.999 72.7781 128.026 72.8507 128.026Z'
        fill='#4F987B'
      />
      <path
        d='M72.1138 123.702H75.8475C75.9248 123.702 75.9989 123.672 76.0536 123.617C76.1083 
        123.562 76.1391 123.488 76.1392 123.41V122.709C76.1391 122.632 76.1083 122.557 76.0536 
        122.503C75.9989 122.448 75.9248 122.417 75.8475 122.417H72.1138C72.0365 122.417 71.9624 
        122.448 71.9077 122.503C71.853 122.557 71.8222 122.632 71.8221 122.709V123.41C71.8222 
        123.488 71.853 123.562 71.9077 123.617C71.9624 123.672 72.0365 123.702 72.1138 123.702Z'
        fill='#2F2E41'
      />
      <path
        opacity='0.2'
        d='M86.7588 126.027C85.7209 127.231 84.3131 128.055 82.7567 128.37C81.2003 128.685 
        79.5834 128.474 78.1603 127.768L77.3115 127.347L86.7588 126.027Z'
        fill='black'
      />
      <path
        d='M73.1889 125.129C73.3628 124.933 73.5775 124.778 73.8178 124.674C74.0581 124.57 74.3182 
        124.521 74.5797 124.529C74.8412 124.537 75.0978 124.602 75.3314 124.72C75.565 124.838 
        75.77 125.006 75.9318 125.212L79.8692 124.104L81.1245 126.337L75.5422 127.822C75.1789 
        128.072 74.7368 128.179 74.2998 128.124C73.8627 128.068 73.4611 127.854 73.171 127.522C72.881 
        127.19 72.7226 126.763 72.7258 126.322C72.7291 125.881 72.8939 125.456 73.1889 125.129Z'
        fill='#FFB8B8'
      />
      <path
        d='M82.5851 127.606C84.4571 127.606 86.99 126.507 89.9968 124.377C90.1644 124.261 90.3061 124.111 
        90.4128 123.937C90.5195 123.763 90.589 123.569 90.6169 123.367C90.8387 121.959 89.4681 120.057 
        89.3337 119.874L87.8929 115.912C87.8763 115.847 87.412 114.133 86.2463 113.522C86.0009 113.396 
        85.7311 113.325 85.4555 113.313C85.18 113.301 84.9051 113.35 84.6499 113.454C82.4295 114.264 
        84.1633 120.517 84.4013 121.337L81.4595 122.724L79.5914 123.917L77.0331 124.185L77.7277 127.405L81.613 
        127.492C81.9311 127.57 82.2576 127.608 82.5851 127.606Z'
        fill='#3F3D56'
      />
      <path
        d='M100.749 110.977L108.067 114.836L107.048 116.776L99.7296 112.917L100.749 110.977Z'
        fill='#3F3D56'
      />
      <path
        d='M40.1997 168.977C40.1997 168.977 40.04 165.625 36.7657 166.015L40.1997 168.977Z'
        fill='#3F3D56'
      />
      <path
        d='M41.1249 165.788C40.2198 165.788 39.4861 165.053 39.4861 164.147C39.4861 163.24 40.2198 162.505 41.1249 
        162.505C42.03 162.505 42.7637 163.24 42.7637 164.147C42.7637 165.053 42.03 165.788 41.1249 165.788Z'
        fill='#F7E9E8'
      />
      <path
        d='M153.765 168.514C153.765 168.514 153.605 165.162 150.331 165.552L153.765 168.514Z'
        fill='#3F3D56'
      />
      <path
        d='M154.69 165.324C153.785 165.324 153.052 164.589 153.052 163.683C153.052 162.776 153.785 162.042 154.69 
        162.042C155.595 162.042 156.329 162.776 156.329 163.683C156.329 164.589 155.595 165.324 154.69 165.324Z'
        fill='#F7E9E8'
      />
      <path d='M154.494 166.447H154.957V169.69H154.494V166.447Z' fill='#3F3D56' />
      <path
        d='M126.935 168.746C126.935 168.746 126.775 165.394 123.501 165.784L126.935 168.746Z'
        fill='#3F3D56'
      />
      <path
        d='M127.86 165.556C126.955 165.556 126.221 164.821 126.221 163.915C126.221 163.008 126.955 162.273 
        127.86 162.273C128.765 162.273 129.499 163.008 129.499 163.915C129.499 164.821 128.765 165.556 127.86 165.556Z'
        fill='#F7E9E8'
      />
      <path d='M127.664 166.679H128.127V169.922H127.664V166.679Z' fill='#3F3D56' />
      <path
        d='M55.5816 17.5999L52.2944 14.9666C54.8481 14.6844 55.8973 16.0793 56.3268 17.1834C58.3218 16.3537 
        60.4937 17.4411 60.4937 17.4411L53.9166 19.8326C54.2484 18.9449 54.826 18.1704 55.5816 17.5999Z'
        fill='#3F3D56'
      />
      <path
        d='M5.58988 44.4236L2.30268 41.7903C4.85637 41.5081 5.90564 42.903 6.33507 44.0071C8.33011 43.1774 
        10.502 44.2648 10.502 44.2648L3.92486 46.6563C4.25666 45.7686 4.83423 44.9941 5.58988 44.4236Z'
        fill='#3F3D56'
      />
      <path
        d='M59.5098 67.3132L56.2226 64.6799C58.7763 64.3978 59.8256 65.7927 60.255 66.8968C62.25 66.0671 
        64.4219 67.1545 64.4219 67.1545L57.8448 69.5459C58.1766 68.6583 58.7542 67.8838 59.5098 67.3132Z'
        fill='#3F3D56'
      />
      <path
        d='M107.912 88.7829C83.4623 88.7829 63.6421 68.9313 63.6421 44.4432C63.6421 19.9551 83.4623 0.103516 
        107.912 0.103516C132.361 0.103516 152.182 19.9551 152.182 
        44.4432C152.182 68.9313 132.361 88.7829 107.912 88.7829Z'
        fill='#187759'
      />
      <path
        opacity='0.2'
        d='M140.599 14.8276C144.729 24.5022 145.289 35.3323 142.18 45.3825C139.071 55.4327 
        132.495 64.0481 123.627 69.6893C114.76 75.3305 104.179 77.6298 93.7743 76.1764C83.3701 
        74.723 73.8208 69.6116 66.8328 61.7555C69.3554 67.6662 73.1308 72.9571 77.8975 77.2614C82.6643 
        81.5656 88.3084 84.7805 94.4387 86.6831C100.569 88.5858 107.039 89.1308 113.401 88.2803C119.762 87.4299 
        125.863 85.2043 131.281 81.7579C136.698 78.3114 141.303 73.7264 144.776 68.3207C148.248 62.9149 150.506 56.8175 
        151.393 50.451C152.279 44.0845 151.773 37.6011 149.909 31.4499C148.045 25.2988 144.869 19.6271 140.599 14.8276Z'
        fill='black'
      />
    </g>
    <defs>
      <clipPath id='clip0_1159_22226'>
        <rect width='171' height='170' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default JourneyIcon
