interface GetContainerClassNameArgs {
  customClassName?: string
  collapse: boolean
  hideChildren: boolean
  defaultChildAnimation: boolean
}

const getContainerClassName = ({
  customClassName,
  collapse,
  hideChildren,
  defaultChildAnimation,
}: GetContainerClassNameArgs): string =>
  `${customClassName} ${collapse ? 'collapse' : 'expand'} ${
    defaultChildAnimation ? 'default-animation' : ''
  } ${hideChildren ? 'hide-contents' : ''} animated-collapsible-container`

export default getContainerClassName
