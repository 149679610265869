import { HTMLAttributes, ReactNode, useEffect, useLayoutEffect, useRef } from 'react'
import './Dialog.less'
import './DialogAnimation.less'
import dialogPolyfill from 'dialog-polyfill'

interface DialogProps extends HTMLAttributes<HTMLDialogElement> {
  children: ReactNode
  isModal: boolean
  isOpen: boolean
}

const Dialog = ({ children, isModal, isOpen, ...htmlProps }: DialogProps): JSX.Element => {
  const dialogRef = useRef<HTMLDialogElement>(null)
  const dialog = dialogRef.current
  const dialogLoaded = dialog !== null

  useLayoutEffect(() => {
    if (!dialogLoaded) return

    dialogPolyfill.registerDialog(dialog)
  }, [dialogLoaded])

  useEffect(() => {
    if (!dialogLoaded) return

    if (isOpen && !dialog.open) {
      return isModal ? dialog.showModal() : dialog.show()
    }

    if (!isOpen && dialog.open) dialog.close()
  }, [dialogLoaded, isOpen])

  return (
    <>
      <dialog className={`hb-dialog ${htmlProps.className}`} ref={dialogRef}>
        {children}
      </dialog>
    </>
  )
}

export default Dialog
