import { findLeadFeedCity } from '.'
import { Coordinates, LocationAttributes } from '../../Types/Location'
import { optimiseAddressResult, patchAULocation } from './Address'
import fetchGeocodeLocations from './GoogleAPI/fetchGeocoderLocations'

export type GoogleLocationAttributes = Omit<LocationAttributes, 'radius' | 'currency'>

const fetchLocationByCoordinates = (coords: Coordinates): Promise<GoogleLocationAttributes> =>
  fetchGeocodeLocations({ coordinates: coords }).then((results) => {
    const leadFeedCity = findLeadFeedCity(coords)
    const { formatted_address, country } = optimiseAddressResult(results)

    const address =
      country === 'AU'
        ? patchAULocation({ location: formatted_address, ...coords })
        : formatted_address
    return {
      ...coords,
      location: address,
      country,
      leadFeedCity,
    }
  })

export default fetchLocationByCoordinates
