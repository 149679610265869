import { GUEST_EVENTS_LEAD_FEED } from '../../../Constants'

interface GetGuestEventsURLArgs {
  showSuccess: boolean
}

const getGuestEventsURL = ({ showSuccess }: GetGuestEventsURLArgs): URL => {
  const queryParams = new URLSearchParams({
    ...(showSuccess && { 'brief-success': 'true' }),
  })
  const gexpURL = new URL(GUEST_EVENTS_LEAD_FEED)
  gexpURL.search = queryParams.toString()

  return gexpURL
}

export default getGuestEventsURL
