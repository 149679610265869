import { useMemo } from 'react'
import useBriefContext from '../../../Hooks/Brief/useBriefContext'
import getCompletionStats from '../../../Logic/Form/Progress/getCompletionStats'
import './ProgressBar.less'

const ProgressBar = (): JSX.Element => {
  const { brief } = useBriefContext()
  const { totalSteps, completedSteps } = useMemo(() => getCompletionStats(brief), [brief])
  const percentage = (completedSteps / totalSteps) * 100
  const currentPath = window.location.pathname
  const isComplete = currentPath.includes('success')

  return (
    <progress
      max={100}
      value={isComplete ? 100 : percentage}
      aria-label='Form completion'
      className={`form-progress ${isComplete ? 'success' : ''}`}>
      {percentage}%
    </progress>
  )
}

export default ProgressBar
