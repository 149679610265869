import { FormSequence, SequenceStep } from '../formSequence'
import { requiredGuestAttrs, requiredLocationAttrs, requiredTimingAttrs } from '../Biz/sequence'
import isGuestAuthenticated from '../../../Guest/isGuestAuthenticated'
import isNewGuest from '../../../Guest/isNewGuest'

const hasRequiredLocationAttrs: SequenceStep['isStepComplete'] = (brief) =>
  requiredLocationAttrs.every((attr) => !!brief[attr])

const skipLocationStep: SequenceStep['skipStep'] = (brief) =>
  hasRequiredLocationAttrs(brief) &&
  typeof brief.hostId === 'string' &&
  typeof brief.spaceId === 'string'

export const LEAD_FEED_FORM_SEQUENCE: FormSequence = [
  {
    path: 'location',
    isStepComplete: hasRequiredLocationAttrs,
    skipStep: skipLocationStep,
  },
  {
    path: 'type',
    isStepComplete: ({ collection, collectionName, audience }) =>
      !!collectionName && !!collection && !!audience,
  },
  {
    path: 'guests',
    isStepComplete: (brief) => brief.capacities !== undefined && !!brief.layouts,
  },
  {
    path: 'timings',
    isStepComplete: (brief) => requiredTimingAttrs.every((attr) => !!brief[attr]),
  },
  {
    path: 'budget',
    isStepComplete: (brief) => !!brief.budget,
  },
  {
    path: 'style',
    isStepComplete: () => true,
  },
  {
    path: 'extras',
    isStepComplete: () => true,
  },
  {
    path: 'name',
    isStepComplete: (brief) => !!brief.eventName && !!brief.bookingIntent,
  },
  {
    path: 'email',
    isStepComplete: (brief) =>
      !!brief.guest?.email &&
      (brief.guest?.id !== undefined ||
        !!brief.guest?.unambiguousOrigin ||
        brief.guest?.country !== undefined),
    skipStep: isGuestAuthenticated,
  },
  {
    path: 'user',
    isStepComplete: ({ guest }) => !!guest && requiredGuestAttrs.every((attr) => !!guest[attr]),
    skipStep: (brief) => !isNewGuest(brief.guest),
  },
  {
    path: 'else',
    isStepComplete: () => true,
  },
]

export default LEAD_FEED_FORM_SEQUENCE
