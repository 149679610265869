import { Radius } from '.'

type LocationOverride = {
  locationPrefix: string
  radius: Radius
  coords?: { lng: number; lat: number }
}
const OVERRIDES: LocationOverride[] = [
  {
    locationPrefix: 'London, ',
    radius: { distance: 7.5, unit: 'km' },
  },
  {
    locationPrefix: 'New York, NY, USA',
    radius: { distance: 5, unit: 'mi' },
    coords: {lat:40.7652, lng: -73.97148},
  },
  {
    locationPrefix: 'Financial District, New York, NY, USA',
    radius: { distance: .75, unit: 'mi' },
    coords: { lat: 40.709, lng: -74.0086 },
  },
  {
    locationPrefix: 'Brooklyn, NY, USA',
    radius: { distance: 4.5, unit: 'mi' },
    coords: { lat: 40.64002, lng: -73.96287 },
  },
  {
    locationPrefix: 'Lower Manhattan, New York, NY, USA',
    radius: { distance: 1.25, unit: 'mi' },
    coords: { lat: 40.71968, lng: -73.99975 },
  },
  {
    locationPrefix: 'Manhattan, New York, NY, USA',
    radius: { distance: 1.5, unit: 'mi' },
    coords: { lat: 40.7464, lng: -73.99011 },
  },
] as const

export function getLocationRadiusOverrides(location?: string): Radius | undefined {
  return OVERRIDES.find(({ locationPrefix }) => location?.startsWith(locationPrefix))?.radius
}
export function getLocationCoordsOverrides(
  location?: string
): { lng: number; lat: number } | undefined {
  return OVERRIDES.find(
    ({ locationPrefix, coords }) => location?.startsWith(locationPrefix) && coords !== undefined
  )?.coords
}
