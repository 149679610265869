import { StylesQuery } from '../../FormSteps/Styles/StylesInput'
import { FacilitiesQuery } from '../../../Views/FormSteps/Facilities/Base/Facilities/FacilitiesInput.generated'
import ListItem from '../Base/SummaryList/Item/ListItem'
import BaseSummaryList from '../Base/SummaryList'
import useBriefContext from '../../../Hooks/Brief/useBriefContext'
import isDirectEnquiryBrief from '../../../Logic/Form/Sequences/DirectEnquiry/isDirectEnquiryBrief'
import EditLink from '../Base/SummaryList/EditLink'
import './SummaryList.less'
import Quote from '../Base/ItemComponents/Quote'
import Layout from '../Base/ItemComponents/Layout'
import Budget from '../Base/ItemComponents/Budget'
import Timings from '../Base/ItemComponents/Timings'
import EventLocation from '../Base/ItemComponents/EventLocation'

interface BizSummaryListProps {
  facilities?: FacilitiesQuery['collection']['features']
  styles?: StylesQuery['getAllStyles']
}

const BizSummaryList = ({ facilities, styles }: BizSummaryListProps): JSX.Element => {
  const { brief } = useBriefContext()
  const selectedFacilities = facilities
    ? brief.spaceFacilityIds?.map(
        (selectedID) => facilities.find(({ id }) => selectedID === parseInt(id, 10))?.name
      )
    : undefined
  const selectedStyles = styles
    ? brief.styleIds?.map((selectedID) => styles.find(({ id }) => id === selectedID)?.label)
    : undefined

  return (
    <BaseSummaryList
      className='biz-summary'
      heading={
        <>
          <h2>
            <span className='visually-hidden'>Your Event Summary: </span>
            {brief.eventName}
          </h2>
          <EditLink path={'name'} sectionLabel='event name' />
        </>
      }>
      <ListItem path='location' label='Location' editable={!isDirectEnquiryBrief(brief)}>
        <EventLocation brief={brief} />
      </ListItem>

      <ListItem path='type' label='Event type'>
        {brief.collectionName ?? ''}
      </ListItem>

      <ListItem path='budget' label='Budget'>
        <Budget brief={brief} />
      </ListItem>

      <ListItem path='guests' label='Guests'>
        {brief.capacities}
      </ListItem>

      <ListItem path='layout' label='Layout'>
        <Layout brief={brief} />
      </ListItem>

      <ListItem path='timings' label='Date & time'>
        <Timings brief={brief} />
      </ListItem>

      <ListItem path='flexible' label='Date flexibility'>
        {brief.flexibleDates ? 'Yes' : 'No'}
      </ListItem>

      <ListItem
        path='flexible'
        elementID='flexible-dates-notes'
        label='Flexibility note'
        show={brief.flexibleDates ?? undefined}>
        {brief.flexibleDatesNote ? `"${brief.flexibleDatesNote}"` : 'None'}
      </ListItem>

      <ListItem path='style' label='Venue styles'>
        {selectedStyles ? selectedStyles.join(', ') || 'None' : '...'}
      </ListItem>

      <ListItem path='extras' label='Facilities'>
        {selectedFacilities ? selectedFacilities.join(', ') || 'None' : '...'}
      </ListItem>

      <ListItem path='extras' elementID='catering-needs' label='Food/drink requests'>
        <Quote text={brief.cateringNeeds} />
      </ListItem>

      <ListItem path='name' elementID='additional-notes' label='Additional notes'>
        <Quote text={brief.additionalNotes} />
      </ListItem>
    </BaseSummaryList>
  )
}

export default BizSummaryList
