import { PartialBrief } from '../../../../Types/Brief'

interface LayoutProps {
  brief: PartialBrief
}

export const Layout = ({ brief: { layouts } }: LayoutProps): JSX.Element | null => {
  if (!layouts || layouts === 'not_sure') return null
  if (layouts === 'seating') return <> (seated)</>

  return <> ({layouts.replace(/_+/g, ' ').toLocaleLowerCase()})</>
}

export default Layout
