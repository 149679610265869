import { useSearchParams, useNavigate } from 'react-router-dom'
import useBriefContext from '../../../Hooks/Brief/useBriefContext'
import getAdjacentSteps from '../../../Logic/Form/Authorization/getAdjacentSteps'
import { FormPath } from '../../../Router/Routes/formRoutes'
import { StandardFormProps, CustomFormProps } from './FormPage'

type UseFormNavigationArgs = { path: string } & (StandardFormProps | CustomFormProps)

interface UseFormNavigationProps {
  onContinue: CustomFormProps['onContinue']
  canContinue: boolean
  afterContinue: () => void
  previousPage?: FormPath
  returnToSummary: boolean
}

const useFormNavigationProps = ({
  path,
  ...providedProps
}: UseFormNavigationArgs): UseFormNavigationProps => {
  const { brief, dispatchBrief } = useBriefContext()
  const [params] = useSearchParams()
  const navigate = useNavigate()

  const returnToSummary = params.get('return-to-summary') === 'true' && path !== 'else'
  const { isStepComplete, nextPage, previousPage } = getAdjacentSteps(path, brief)

  const continuePath = returnToSummary ? 'else' : nextPage
  const defaultNavigate = () => {
    if (continuePath === undefined) {
      throw new Error(
        'Cannot navigate to undefined `continuePath`. You should override the default"' +
          ' `afterContinue` function for this Input view."'
      )
    }
    navigate(`/${continuePath}`)
  }

  if ('inputAttributes' in providedProps) {
    const { inputAttributes } = providedProps

    const onContinue = () => dispatchBrief({ updateAttributes: inputAttributes })

    return {
      canContinue: isStepComplete(inputAttributes),
      onContinue,
      afterContinue: providedProps.afterContinue ?? defaultNavigate,
      previousPage,
      returnToSummary,
    }
  }

  return {
    ...providedProps,
    afterContinue: providedProps.afterContinue ?? defaultNavigate,
    previousPage,
    returnToSummary,
  }
}

export default useFormNavigationProps
