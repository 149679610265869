import useBriefContext from '../../../Hooks/Brief/useBriefContext'
import isBizBrief from '../../../Logic/Form/Sequences/Biz/isBizBrief'
import InputView from '../InputView'
import BizFacilitiesCateringInput from './Biz/FacilitiesInput'
import LFFacilitiesCateringInput from './LF/FacilitiesInput'

const FacilitiesAndCateringInput: InputView = (args): JSX.Element => {
  const { brief } = useBriefContext()
  if (isBizBrief(brief)) return <BizFacilitiesCateringInput {...args} />

  return <LFFacilitiesCateringInput {...args} />
}

export default FacilitiesAndCateringInput
