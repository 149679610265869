import { useTranslation } from 'react-i18next'
import { PartialBrief } from '../../../../Types/Brief'
import { getCurrencySymbol } from '../../../../Utilities/Location'

interface BudgetProps {
  brief: PartialBrief
}

export const Budget = ({ brief }: BudgetProps): JSX.Element => {
  const { t } = useTranslation('summaryPage')

  return (
    <>
      {t('summaryItems.budget', {
        currencySymbol: getCurrencySymbol(brief.currency),
        budget: brief.budget,
        budgetNotes: brief.budgetNotes,
      })}
      
      {brief.budgetNotes && 
      t('summaryItems.budgetNotes', {
        budgetNotes: brief.budgetNotes,
      })
      }
    </>
  )
}

export default Budget
