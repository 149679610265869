import { ReactNode } from 'react'
import { FormPath } from '../../../../../Router/Routes/formRoutes'
import EditLink from '../EditLink'
import './ListItem.less'

interface SummaryListItemProps {
  path: FormPath
  label: ReactNode
  children: ReactNode
  elementID?: string
  show?: boolean
  editable?: boolean
}

const SummaryListItem = ({
  path,
  label,
  children,
  elementID,
  show = true,
  editable = true,
}: SummaryListItemProps): JSX.Element | null => {
  if (!show) return null

  return (
    <div className='summary-entry'>
      <dt>{label}</dt>
      <dd>
        <span className='summary-value'>{children}</span>
        {editable ? <EditLink path={path} elementID={elementID} sectionLabel={label} /> : null}
      </dd>
    </div>
  )
}

export default SummaryListItem
