import { useMemo } from 'react'
import { gql, useQuery } from 'urql'
import { HOST_LISTINGS_MANAGEMENT_SERVICE } from '../../Constants'
import { PartialBrief } from '../../Types/Brief'
import captureMessage from '../../Utilities/Errors/captureMessage'
import isBizBrief from '../../Logic/Form/Sequences/Biz/isBizBrief'

export const SpaceByIdDocument = gql`
  query SpaceById($id: Int!) {
    spaceById(id: $id) {
      id
      venue {
        name
      }
    }
  }
`

export type SpaceByIdQuery = {
  __typename?: 'Query'
  spaceById: { __typename?: 'Space'; id: number; venue: { __typename?: 'Venue'; name: string } }
}

export const useSpaceByIdQuery = (
  brief: PartialBrief
): { spaceFetching: boolean; spaceData: SpaceByIdQuery } => {
  const [{ fetching: spaceFetching, error: spaceError, data: spaceData }] = useQuery({
    query: SpaceByIdDocument,
    pause: !brief.spaceId || isBizBrief(brief),
    variables: { id: Number(brief.spaceId) },
    context: useMemo(
      () => ({
        requestPolicy: 'cache-first',
        url: HOST_LISTINGS_MANAGEMENT_SERVICE,
      }),
      []
    ),
  })

  if (spaceError) {
    captureMessage(spaceError.message)
  }

  return {
    spaceFetching,
    spaceData,
  }
}
