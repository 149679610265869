import confetti from 'canvas-confetti'
import {
  ChangeEventHandler,
  Dispatch,
  FormEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react'
import isBizBrief from '../../../../../Logic/Form/Sequences/Biz/isBizBrief'
import { PartialBrief } from '../../../../../Types/Brief'
import { Collection } from '../../../../../Types/generated-types'
import { useEventTypesQuery } from './Fieldset.generated'
import throwGQLError from '../../../../../Utilities/Urql/Errors/throwGQLError'
import LoadingSpinner from '../../../../../Components/LoadingSpinner'
import { RadioButton } from '../../../../../Components/Form/Inputs'
import './Fieldset.less'
import ValidationMessage from '../../../../../Components/Form/Inputs/ValidationMessage/ValidationMessage'
import getValidationMessage from '../../../../../Components/Form/Inputs/Generic/Multi/Validation/getValidationMessage'
import useBriefContext from '../../../../../Hooks/Brief/useBriefContext'
import getAttributeOverrideByEventType from '../Logic/getAttributeOverrideByEventType'

export const eventTypeLabels: Record<string, string> = {
  'Meeting/Workshop': 'Meeting/workshop💡',
  Party: 'Party 🎉',
  'Private Dining': 'Private Dining 🍽',
  'Team Activity': 'Team Activity 🏅',
  Presentation: 'Presentation 📽',
  Wedding: 'Wedding 💖',
  Networking: 'Networking 🤝',
  Studio: 'Studio 📷',
  Conference: 'Conference 🎤',
  Training: 'Training 👩‍🏫',
  'Something Else': 'Something else 🤷‍♀️',
}

const getInputID = (eventTypeID: string) => `event-type-${eventTypeID}`

const triggerConfetti = async (eventTypeID: string) => {
  const input = document.getElementById(getInputID(eventTypeID))
  const { top, left } = input?.getBoundingClientRect() ?? {}
  const origin =
    top !== undefined && left !== undefined
      ? {
          x: (left + 30) / window.innerWidth,
          y: (top + 20) / window.innerHeight,
        }
      : { x: 0.5, y: 0.5 }

  await confetti({
    ticks: 110,
    origin,
    disableForReducedMotion: true,
  })
}

const filterTypesByBrief = (eventTypes: Collection[], brief: PartialBrief): Collection[] =>
  isBizBrief(brief)
    ? eventTypes.filter(({ name }) => name !== 'Wedding')
    : eventTypes.filter(({ name }) => name !== 'Training')

export interface EventTypeAttributes {
  layouts?: string // some event types enforce a particular layout option
  collection?: string | null
  collectionName?: string | null
}

interface EventTypeFieldsetProps {
  eventTypeAttrs: EventTypeAttributes
  setEventTypeAttrs: Dispatch<EventTypeAttributes>
  setFetchingInParent?: Dispatch<boolean>
  setEventTypesInParent?: Dispatch<Collection[]>
  onClick?: MouseEventHandler<HTMLInputElement>
}

const EventTypeFieldset = ({
  eventTypeAttrs,
  setEventTypeAttrs: _setEventTypeAttrs,
  setEventTypesInParent,
  setFetchingInParent,
  onClick,
}: EventTypeFieldsetProps) => {
  const { brief } = useBriefContext()
  const [{ fetching, error: fetchError, data }] = useEventTypesQuery({
    requestPolicy: 'cache-first',
  })
  const [invalidMessage, setInvalidMessage] = useState<string | null>(null)

  const eventTypeID = eventTypeAttrs.collection
  const { collections } = data ?? { collections: [] }
  const eventTypes = filterTypesByBrief(collections, brief)

  const setEventTypeAttrs = (attrs: EventTypeAttributes) => {
    const forcedLayout = getAttributeOverrideByEventType(attrs.collectionName, 'layouts')
    _setEventTypeAttrs({
      ...attrs,
      ...(forcedLayout ? { layouts: forcedLayout } : {}),
    })
  }

  useEffect(() => {
    if (eventTypes.length === 0) return

    const eventCollection = eventTypes.find(({ id }) => id === eventTypeID)

    if (eventCollection?.name === eventTypeAttrs.collectionName) return

    // for event types set via collection-id query param
    setEventTypeAttrs({ collectionName: eventCollection?.name })
  }, [eventTypeID, eventTypes])

  useEffect(() => {
    if (!setFetchingInParent) return

    setFetchingInParent(fetching)
  }, [fetching])

  useEffect(() => {
    if (!setEventTypesInParent) return

    setEventTypesInParent(eventTypes)
  }, [fetching])

  if (fetchError) throwGQLError(fetchError)

  const onInvalid: FormEventHandler<HTMLInputElement> = (event) => {
    setInvalidMessage(getValidationMessage(event.currentTarget, 'event type'))
    event.preventDefault()
  }
  const onChange: ChangeEventHandler<HTMLInputElement> = async ({ target }) => {
    const eventLabel = eventTypes.find(({ id }) => id === target.value)?.name
    setEventTypeAttrs({ collection: target.value, collectionName: eventLabel })
    setInvalidMessage(null)
    if (eventLabel === 'Party') await triggerConfetti(target.value)
  }

  return (
    <ValidationMessage id='invalid-event-type-error' invalidMessage={invalidMessage}>
      <fieldset
        aria-busy={fetching}
        aria-errormessage={invalidMessage ? 'invalid-event-type-error' : undefined}
        aria-invalid={!!invalidMessage}
        aria-describedby={'fieldset-loading'}>
        {fetching ? (
          <LoadingSpinner progressId='fieldset-loading' />
        ) : (
          <>
            <legend className='visually-hidden'>Select an event type:</legend>
            <ul role='list' className='event-types'>
              {eventTypes.map(({ id, name }) => (
                <li key={id}>
                  <RadioButton
                    id={getInputID(id)}
                    name='event-type'
                    value={id}
                    required
                    checked={id === eventTypeID}
                    onChange={onChange}
                    onClick={onClick}
                    onInvalid={onInvalid}
                    label={eventTypeLabels[name]}
                  />
                </li>
              ))}
            </ul>
          </>
        )}
      </fieldset>
    </ValidationMessage>
  )
}

export default EventTypeFieldset
