import { FormEventHandler, useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import './SearchBox.less'
import { ActiveLocale } from '../../../../../Types/Location'
import { useTranslation } from 'react-i18next'

export interface LocationSearchBoxProps {
  id?: string
  setLocation: (value: string) => void
  location: string
  searchableCountries?: Array<ActiveLocale> | ReadonlyArray<ActiveLocale> // ALL countries searchable if undefined
}

interface LoadPredictionsArgs {
  search: string
  autocompleteService: google.maps.places.AutocompleteService
  setPredictions: (predictions: { label: string; value: string }[]) => void
  selectedPrediction: { label: string; value: string } | null
  searchableCountries: LocationSearchBoxProps['searchableCountries']
}

const loadPredictions = ({
  search,
  autocompleteService,
  setPredictions,
  selectedPrediction,
  searchableCountries,
}: LoadPredictionsArgs): void => {
  const handlePredictions = (predictions: google.maps.places.AutocompletePrediction[] | null) => {
    if (predictions === null) return

    const newPredictions = predictions.map(({ description, place_id }) => ({
      label: description,
      value: place_id,
    }))
    setPredictions(selectedPrediction ? [selectedPrediction, ...newPredictions] : newPredictions)
  }

  autocompleteService.getPlacePredictions(
    {
      input: search,
      componentRestrictions: searchableCountries
        ? { country: searchableCountries.map((c) => c.toLowerCase()) }
        : undefined,
      types: ['geocode', 'establishment'],
    },
    handlePredictions
  )
}

const LocationSearchBox = ({
  location,
  setLocation,
  id,
  searchableCountries,
}: LocationSearchBoxProps): JSX.Element => {
  // spoof place id '1' avoids a GoogleAPI request for briefs where the location is already defined
  const defaultLocationOption = location !== '' ? { label: location, value: '1' } : null
  const [predictions, setPredictions] = useState<{ label: string; value: string }[]>(
    defaultLocationOption ? [defaultLocationOption] : []
  )
  const [searchStr, setSearchStr] = useState(location)
  const [selectedPrediction, setSelectedPrediction] = useState(defaultLocationOption)
  const [invalidMessage, setInvalidMessage] = useState<string | null>(null)
  useEffect(() => {
    // handle parent-initiated changes to location (eg. geocode fetches)
    setSelectedPrediction(defaultLocationOption)
  }, [location])
  const { t } = useTranslation('locationInput')

  const autocompleteService = new google.maps.places.AutocompleteService()
  const onSearch = (search: string) =>
    loadPredictions({
      search,
      setPredictions,
      autocompleteService,
      selectedPrediction,
      searchableCountries,
    })
  const onInvalid: FormEventHandler<HTMLInputElement> = (event) => {
    // Mui doesn't easily expose the underlying validity state
    setInvalidMessage('Please choose a location')
    event.preventDefault()
  }

  return (
    <Autocomplete
      id={id}
      fullWidth
      filterOptions={(x) => x}
      options={predictions}
      renderOption={(props, option) => (
        <li {...props} key={option.value}>
          {option.label}
        </li>
      )}
      className='location-search-box'
      autoComplete
      includeInputInList
      filterSelectedOptions
      size='small'
      noOptionsText={searchStr ? undefined : t('searchbox.placeholder')}
      isOptionEqualToValue={(option, value) => option.value === value?.value}
      value={selectedPrediction}
      onChange={(_event, newValue: { label: string; value: string } | null) => {
        setPredictions(newValue ? [newValue, ...predictions] : predictions)
        setLocation(newValue?.value ?? '')
        setSelectedPrediction(newValue ?? null)
      }}
      onInputChange={(_event, newInputValue) => {
        setSearchStr(newInputValue)
        onSearch(newInputValue)
        setInvalidMessage(null)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          value={searchStr}
          label='Search for a location'
          fullWidth
          required
          error={!!invalidMessage}
          helperText={invalidMessage}
          onInvalid={onInvalid}
          InputLabelProps={{ required: false }}
        />
      )}
    />
  )
}

export default LocationSearchBox
