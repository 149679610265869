import {
  ChangeEventHandler,
  Dispatch,
  FormEventHandler,
  MouseEventHandler,
  SetStateAction,
  useState,
} from 'react'
import { RadioButton } from '../../../../../Components/Form/Inputs'
import getValidationMessage from '../../../../../Components/Form/Inputs/Generic/Multi/Validation/getValidationMessage'
import ValidationMessage from '../../../../../Components/Form/Inputs/ValidationMessage/ValidationMessage'
import useBriefContext from '../../../../../Hooks/Brief/useBriefContext'
import getAttributeOverrideByEventType from '../../../EventType/Base/Logic/getAttributeOverrideByEventType'

const simpleLayouts = [
  { value: 'standing', label: 'Standing 🧍' },
  { value: 'seating', label: 'Seated 🪑' },
  { value: 'not_sure', label: 'Not sure 🤷‍♂️' },
]

interface LayoutFieldsetProps {
  layout?: string
  setLayout: Dispatch<SetStateAction<string | undefined>>
  submitOnClick: MouseEventHandler<HTMLInputElement>
  className?: string
}

const LayoutFieldset = ({
  layout,
  setLayout,
  className,
  submitOnClick,
}: LayoutFieldsetProps): JSX.Element | null => {
  const { brief } = useBriefContext()
  const [invalidMessage, setInvalidMessage] = useState<string | null>(null)

  const hasForcedLayout = getAttributeOverrideByEventType(brief.collectionName, 'layouts') // set in the event type step

  const onChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setLayout(target.value)
    setInvalidMessage(null)
  }
  const onInvalid: FormEventHandler<HTMLInputElement> = (event) => {
    setInvalidMessage(getValidationMessage(event.currentTarget))
    event.preventDefault()
  }

  if (hasForcedLayout && layout) return null

  return (
    <fieldset className={`layout-fieldset ${className}`}>
      <legend>And how would you like the space set up?</legend>
      <ValidationMessage id='layout-error' invalidMessage={invalidMessage}>
        <ul role='list' className={`simple-layouts ${className}`}>
          {simpleLayouts.map(({ label, value }) => (
            <li key={value}>
              <RadioButton
                id={`general-layout-${value}`}
                name='layout'
                required
                checked={layout === value}
                onChange={onChange}
                onInvalid={onInvalid}
                onClick={submitOnClick}
                aria-invalid={!!invalidMessage}
                aria-errormessage={invalidMessage ? 'layout-error' : undefined}
                label={label}
                value={value}
              />
            </li>
          ))}
        </ul>
      </ValidationMessage>
    </fieldset>
  )
}

export default LayoutFieldset
