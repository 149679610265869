import useBriefContext from '../../../Hooks/Brief/useBriefContext'
import isBizBrief from '../../../Logic/Form/Sequences/Biz/isBizBrief'
import InputView from '../InputView'
import BizEventNameInput from './Biz/EventNameInput'
import LFEventNameInput from './LF/EventNameInput'

const EventNameInput: InputView = (args): JSX.Element => {
  const { brief } = useBriefContext()
  if (isBizBrief(brief)) return <BizEventNameInput {...args} />

  return <LFEventNameInput {...args} />
}

export default EventNameInput
