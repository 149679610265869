import { ChangeEventHandler } from 'react'
import { TextArea } from '../../../../../Components/Form/Inputs'

interface CateringInputProps {
  cateringNeeds: string
  onChange: ChangeEventHandler<HTMLTextAreaElement>
}
const CateringInput = ({ cateringNeeds, onChange }: CateringInputProps): JSX.Element => (
  <section className='catering'>
    <h3>Do you have any catering requirements?</h3>
    <label htmlFor='catering-needs' className='visually-hidden'>
      Catering Requirement Notes (optional step)
    </label>
    <TextArea
      id='catering-needs'
      rows={5}
      value={cateringNeeds}
      onChange={onChange}
      placeholder={
        'If you have any food and drink requests or other requirements, please write them here.'
      }
    />
  </section>
)

export default CateringInput
