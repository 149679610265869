// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root .hb-button {
  padding: 7px 28px;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;
  will-change: color, transform;
  outline-offset: 3px;
  transition: background-color 0.2s ease-in-out, transform 0.1s ease-in-out;
}
app-root .hb-button.primary-button {
  outline-color: black;
  border: none;
  background-color: var(--primary);
  color: white;
}
app-root .hb-button.primary-button:active {
  transform: scale(0.95);
}
app-root .hb-button.primary-button:hover {
  background-color: #c52820;
}
app-root .hb-button.secondary-button {
  color: var(--border-grey);
  padding-top: 6px;
  padding-bottom: 6px;
  outline-offset: 4px;
  border: 1px solid var(--border-grey);
  text-decoration: none;
}
app-root .hb-button.secondary-button:active {
  transform: scale(0.95);
}
app-root .hb-button.secondary-button:visited {
  color: var(--border-grey);
}
app-root .hb-button.secondary-button:hover {
  color: var(--primary);
}
`, "",{"version":3,"sources":["webpack://./src/Styles/buttons.less"],"names":[],"mappings":"AAAA;EAEI,iBAAA;EACA,qBAAA;EACA,kBAAA;EACA,gBAAA;EACA,qBAAA;EACA,eAAA;EACA,kBAAA;EACA,6BAAA;EACA,mBAAA;EACA,yEAAA;AAAJ;AAEI;EACE,oBAAA;EACA,YAAA;EACA,gCAAA;EACA,YAAA;AAAN;AACM;EACE,sBAAA;AACR;AAEM;EACE,yBAAA;AAAR;AAGI;EACE,yBAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;EACA,oCAAA;EACA,qBAAA;AADN;AAGM;EACE,sBAAA;AADR;AAIM;EACE,yBAAA;AAFR;AAKM;EACE,qBAAA;AAHR","sourcesContent":["app-root {\n  .hb-button {\n    padding: 7px 28px;\n    display: inline-block;\n    text-align: center;\n    font-weight: 600;\n    text-decoration: none;\n    cursor: pointer;\n    border-radius: 4px;\n    will-change: color, transform;\n    outline-offset: 3px;\n    transition: background-color 0.2s ease-in-out, transform 0.1s ease-in-out;\n\n    &.primary-button {\n      outline-color: black;\n      border: none;\n      background-color: var(--primary);\n      color: white;\n      &:active {\n        transform: scale(0.95);\n      }\n\n      &:hover {\n        background-color: #c52820;\n      }\n    }\n    &.secondary-button {\n      color: var(--border-grey);\n      padding-top: 6px;\n      padding-bottom: 6px;\n      outline-offset: 4px;\n      border: 1px solid var(--border-grey);\n      text-decoration: none;\n\n      &:active {\n        transform: scale(0.95);\n      }\n\n      &:visited {\n        color: var(--border-grey);\n      }\n\n      &:hover {\n        color: var(--primary);\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
