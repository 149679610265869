import { ChangeEventHandler, FormEventHandler, ReactNode, useEffect, useState } from 'react'
import FormPage from '../../../../../Components/Form/Page/FormPage'
import { NewUserPasswordInput } from '../../../../../Components/Form/Inputs/'
import useBriefContext from '../../../../../Hooks/Brief/useBriefContext'
import useSubmitBrief from '../../../../../Hooks/Brief/useSubmitBrief'
import getAdjacentSteps from '../../../../../Logic/Form/Authorization/getAdjacentSteps'
import { Input, RadioInput } from '../../../../../Components/Form/Inputs'
import './RegistrationFormPage.less'
import LoadingSpinner from '../../../../../Components/LoadingSpinner'
import ValidationMessage from '../../../../../Components/Form/Inputs/ValidationMessage/ValidationMessage'
import getValidationMessage from '../../../../../Components/Form/Inputs/Generic/Multi/Validation/getValidationMessage'
import useRegisterUser from '../../../../../Hooks/Brief/useRegisterUser'
import NameInput from './Components'

interface RegistrationFormPageProps {
  path: string
  heading: string
  metaTitle: string
  requirePassword: boolean
  marketingCopy: ReactNode
}

const RegistrationForm = ({
  path,
  heading,
  metaTitle,
  marketingCopy,
  requirePassword,
}: RegistrationFormPageProps): JSX.Element => {
  const { brief, dispatchBrief, loading: authenticating } = useBriefContext()
  const { guest } = brief
  const { email } = guest ?? {}
  if (!guest || !email) throw new Error('Email undefined at registration step')

  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState(guest.firstName ?? '')
  const [lastName, setLastName] = useState(guest.lastName ?? '')
  const [contactNumber, setContactNumber] = useState(guest.mobileNumber ?? '')
  const [requestsExtraMarketingContent, setRequestsExtraMarketingContent] = useState(
    guest.requestsExtraMarketingContent
  )
  const [shouldRegister, setShouldRegister] = useState(false)
  const [shouldSubmitBrief, setShouldSubmitBrief] = useState(false)
  const [invalidRadioMessage, setInvalidRadioMessage] = useState<string | null>(null)
  const onRadioInvalid: FormEventHandler<HTMLInputElement> = (event) => {
    setInvalidRadioMessage(getValidationMessage(event.currentTarget))
    event.preventDefault()
  }

  const { registering, registered } = useRegisterUser({ skip: !shouldRegister })
  const { loading: submittingBrief, submitted } = useSubmitBrief({ skip: !shouldSubmitBrief })

  useEffect(() => {
    if (!registered) return

    setShouldSubmitBrief(true)
  }, [registered])

  // complex loading condition to paper over the gaps between registering and submission
  const loading =
    registering || authenticating || submittingBrief || (shouldSubmitBrief && !submitted)

  const briefGuestAttrs = {
    firstName,
    lastName,
    mobileNumber: contactNumber,
    requestsExtraMarketingContent,
    password,
  }
  const { isStepComplete, isFinalStep } = getAdjacentSteps(path, brief)

  const onRadioChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setInvalidRadioMessage(null)
    setRequestsExtraMarketingContent(target.value === 'yes')
  }

  const onContinue = () => {
    dispatchBrief({ updateAttributes: { guest: briefGuestAttrs } })
    if (isFinalStep) setShouldRegister(true)
  }

  return (
    <>
      {loading && (
        <LoadingSpinner
          fadeIn={registering}
          fullPage={true}
          accessibleText={registering ? 'Creating your account...' : 'Submitting your enquiry...'}
        />
      )}
      <FormPage
        path={path}
        heading={heading}
        metaTitle={metaTitle}
        onContinue={onContinue}
        canContinue={isStepComplete({ guest: briefGuestAttrs })}
        afterContinue={
          isFinalStep
            ? () => {
                null
              }
            : undefined
        }
        submitText={isFinalStep ? 'Submit' : undefined}
        hidden={loading}
        className='registration-page'>
        <fieldset className='name'>
          <label htmlFor='first-name'>
            First name
            <br />
            <NameInput
              id='first-name'
              autoComplete='given-name'
              fieldName='first name'
              name={firstName}
              setName={setFirstName}
            />
          </label>

          <label htmlFor='last-name'>
            Last name
            <br />
            <NameInput
              id='last-name'
              autoComplete='family-name'
              fieldName='last name'
              name={lastName}
              setName={setLastName}
            />
          </label>
        </fieldset>

        <label htmlFor='contact-number'>Contact number</label>
        <Input
          id='contact-number'
          type='tel'
          autoComplete='tel'
          required
          minLength={5}
          maxLength={20}
          fieldName='number'
          value={contactNumber}
          onChange={({ target }) => setContactNumber(target.value)}
        />

        {requirePassword && (
          <>
            <label htmlFor='password'>Password</label>
            <NewUserPasswordInput id='password' password={password} setPassword={setPassword} />
          </>
        )}
        <section className='marketing-section'>
          {marketingCopy}
          <fieldset
            className='marketing'
            aria-invalid={!!invalidRadioMessage}
            aria-errormessage={invalidRadioMessage ? 'comms-error' : undefined}>
            <legend className='visually-hidden'>Would you like to receive our newsletter?</legend>
            <ValidationMessage id='comms-error' invalidMessage={invalidRadioMessage}>
              <RadioInput
                id='comms-yes'
                name='comms-preference'
                value='yes'
                required /* 'required' applies to both options due to the shared name */
                checked={requestsExtraMarketingContent ?? false}
                onChange={onRadioChange}
                onInvalid={onRadioInvalid}
                label='Yes, please!'
              />
              <br />
              <RadioInput
                id='comms-no'
                name='comms-preference'
                value='no'
                checked={requestsExtraMarketingContent === false}
                onChange={onRadioChange}
                onInvalid={onRadioInvalid}
                label='No, thanks'
              />
            </ValidationMessage>
          </fieldset>
        </section>
      </FormPage>
    </>
  )
}

export default RegistrationForm
