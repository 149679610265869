import { nameToIndefiniteArticle } from '../../../Validation/Utilities/stringHelpers'

const getValueMissingMessage = (fieldName: string): string =>
  `Please choose ${nameToIndefiniteArticle(fieldName)}`

const getInvalidInputMessage = (fieldName: string): string => `Please choose a valid ${fieldName}`

const getValidationMessage = (
  input: EventTarget & HTMLInputElement,
  fieldName = 'option'
): string | null => {
  if (input.validity.valid) return null
  if (input.validity.valueMissing) return getValueMissingMessage(fieldName)
  if (input.validity.customError) return input.validationMessage

  return getInvalidInputMessage(fieldName)
}

export default getValidationMessage
