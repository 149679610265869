// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  margin: auto;
  background: white;
  border: none;
  padding: 0;
  display: block;
}
dialog:not([open]) {
  display: none;
}
dialog::backdrop {
  opacity: 0.8;
  background-color: black;
}
dialog + .backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
  opacity: 0.8;
}
._dialog_overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/Dialog/Dialog.less"],"names":[],"mappings":"AACA;EACE,eAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,uBAAA;EACA,0BAAA;EACA,kBAAA;EACA,wBAAA;EACA,2BAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,YAAA;EACA,UAAA;EACA,cAAA;AAAF;AACE;EACE,aAAA;AACJ;AACE;EACE,YAAA;EACA,uBAAA;AACJ;AAIA;EACE,eAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,uBAAA;EACA,YAAA;AAFF;AAKA;EACE,eAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;AAHF","sourcesContent":["// Polyfill styles\ndialog {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  width: -moz-fit-content;\n  width: -webkit-fit-content;\n  width: fit-content;\n  height: -moz-fit-content;\n  height: -webkit-fit-content;\n  height: fit-content;\n  margin: auto;\n  background: white;\n  border: none;\n  padding: 0;\n  display: block;\n  &:not([open]) {\n    display: none;\n  }\n  &::backdrop {\n    opacity: 0.8;\n    background-color: black;\n  }\n}\n\n\ndialog+.backdrop {\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  background-color: black;\n  opacity: 0.8;\n}\n\n._dialog_overlay {\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
