import { PartialBrief } from '../../../../../Types/Brief'
import BriefAttributeOrFallback from './BriefAttributeOrFallback'
import BRIEF_DEFAULTS_BY_EVENT_TYPE from './briefDefaultsByEventType'
import isEventType from './eventType'

const getDefaultAttributeByEventType = <T extends keyof PartialBrief>(
  eventType: PartialBrief['collectionName'],
  attribute: T
): BriefAttributeOrFallback<T, ''> => {
  if (!isEventType(eventType)) return ''
  const defaultAttrValue = BRIEF_DEFAULTS_BY_EVENT_TYPE[eventType][attribute]

  return defaultAttrValue ?? ''
}

export default getDefaultAttributeByEventType
