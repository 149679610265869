import { ReactNode } from 'react'
import './ValidationMessage.less'

interface ValidationMessageProps {
  id: string
  invalidMessage: string | null
  html5Error?: boolean
  className?: string
  children: ReactNode
}

const ValidationMessage = ({
  id,
  className,
  invalidMessage,
  html5Error,
  children,
}: ValidationMessageProps): JSX.Element => (
  <span
    className={`validation-wrapper ${className ?? ''} ${invalidMessage ? 'show-validity' : ''}`}>
    {children}
    {!html5Error && (
      <strong aria-live='polite' className='error-message' id={id}>
        {invalidMessage}
      </strong>
    )}
  </span>
)

export default ValidationMessage
