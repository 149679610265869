import { FacilitiesQuery } from '../../../FormSteps/Facilities/Base/Facilities/FacilitiesInput.generated'

interface FacilitiesProps {
  facilityIds?: number[] | null
  facilities?: FacilitiesQuery['collection']['features']
}

const getFacilityNames = ({ facilityIds, facilities }: FacilitiesProps): string[] | undefined =>
  facilities && facilityIds?.length
    ? facilityIds?.map(
        (selectedID) => facilities.find(({ id }) => selectedID === parseInt(id, 10))?.name ?? ''
      )
    : undefined

const getFacilitiesString = (facilities?: string[] | null): string =>
  facilities?.join(', ') || 'None'

export const Facilities = ({
  facilityIds,
  facilities: facilitiesReference,
}: FacilitiesProps): JSX.Element | null => {
  if (!facilitiesReference) return <>...</>

  return (
    <>{getFacilitiesString(getFacilityNames({ facilityIds, facilities: facilitiesReference }))}</>
  )
}

export default Facilities
