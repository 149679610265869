// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root .password-input-wrapper {
  width: fit-content;
  position: relative;
}
app-root .password-input-wrapper input {
  padding-right: calc(12px + 1em);
}
app-root .password-input-wrapper button.toggle {
  position: absolute;
  right: 0;
  height: auto;
  width: fit-content;
  line-height: 1em;
  padding: 6px;
  transition-property: color, transform, outline;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  border: none;
  color: inherit;
  background-color: transparent;
}
app-root .password-input-wrapper button.toggle .eye-icon {
  width: 1em;
  height: calc(1em + 12px);
  color: inherit;
}
app-root .password-input-wrapper button.toggle:hover {
  color: var(--primary);
}
app-root .password-input-wrapper button.toggle:active {
  transform: scale(0.75);
  color: var(--outline-grey);
}
`, "",{"version":3,"sources":["webpack://./src/Components/Form/Inputs/Password/PasswordInput.less"],"names":[],"mappings":"AAAA;EAEI,kBAAA;EACA,kBAAA;AAAJ;AAHA;EAKY,+BAAA;AACZ;AANA;EAOM,kBAAA;EACA,QAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;EAEA,8CAAA;EACA,yBAAA;EACA,oCAAA;EAEA,YAAA;EACA,cAAA;EACA,6BAAA;AAAN;AApBA;EAsBQ,UAAA;EACA,wBAAA;EACA,cAAA;AACR;AAEM;EAAU,qBAAA;AAChB;AAAM;EACE,sBAAA;EACA,0BAAA;AAER","sourcesContent":["app-root {\n  .password-input-wrapper {\n    width: fit-content;\n    position: relative;\n\n    input { padding-right: calc(12px + 1em);}\n    button.toggle {\n      position: absolute;\n      right: 0;\n      height: auto;\n      width: fit-content;\n      line-height: 1em;\n      padding: 6px;\n\n      transition-property: color, transform, outline;\n      transition-duration: 0.2s;\n      transition-timing-function: ease-out;\n\n      border: none;\n      color: inherit;\n      background-color: transparent;\n      .eye-icon {\n        width: 1em;\n        height: calc(1em + 12px);\n        color: inherit;\n      }\n\n      &:hover { color: var(--primary); }\n      &:active {\n        transform: scale(0.75);\n        color: var(--outline-grey);\n      }\n\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
