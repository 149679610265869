import {
  AriaAttributes,
  ComponentPropsWithoutRef,
  createElement,
  CSSProperties,
  ElementType,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import useMediaQuery from '../../../../Hooks/useMediaQuery'
import useStateTransition from '../../../../Hooks/useStateTransition'
import './Container.less'
import getCollapseStyles, { CollapsibleCSSProperties } from './Utilities/getCollapseStyles'
import getContainerClassName from './Utilities/getContainerClassName'

interface CollapsibilityProps {
  containerElement: keyof HTMLElementTagNameMap // container element string name
  collapse: boolean // triggers the 'collapse' fade-out animation
  collapsibleDimension: CollapsibleCSSProperties
  defaultSize?: number // optional one-dimensional size as fallback for when container ref is null
  defaultChildAnimation?: boolean // toggle the default slide-in animation for the child elements
  transitionDuration?: number // in ms - override if the collapse transition is overridden
  //
  // any margin on adjacent elements in the same plane as the collapse transition must be included
  // here to compute the negative margin on the target element. Adjacent margins stack in CSS.
  additionalMargin?: number | string
}

// Utility type to help build custom containers from this base container
export type CollapsibleContainerHTMLProps<T extends HTMLElement> = Omit<
  Partial<ComponentPropsWithoutRef<ElementType<T>>>,
  'children'
> & { children?: ReactNode } & AriaAttributes

// Combines specific HTMLElement props with custom collapsible container props
type CollapsibleContainerProps<T extends HTMLElement> = CollapsibleContainerHTMLProps<T> &
  CollapsibilityProps

function CollapsibleContainer<T extends HTMLElement>({
  collapse,
  collapsibleDimension,
  containerElement,
  defaultChildAnimation = true,
  defaultSize = 0,
  transitionDuration: _transitionDuration = 500,
  children,
  additionalMargin,
  style: customStyles,
  className: customClassName,
  ...props
}: CollapsibleContainerProps<T>): JSX.Element {
  const elementRef = useRef<HTMLElementTagNameMap[typeof containerElement]>(null)
  const [elementTargetSize, setElementTargetSize] = useState(defaultSize)
  const prefersReducedMotion = useMediaQuery('(prefers-reduced-motion: reduce)')

  const transitionDuration = prefersReducedMotion ? 0 : _transitionDuration
  const [{ state: collapsed }, setCollapsedAfterTransition] = useStateTransition(
    collapse,
    transitionDuration
  )

  const offsetProp =
    collapsibleDimension === 'marginLeft' || collapsibleDimension === 'marginRight'
      ? 'offsetWidth'
      : 'offsetHeight'
  const collapseStyles: CSSProperties = getCollapseStyles({
    elementSize: elementTargetSize,
    additionalMargin,
    collapsibleDimension,
  })
  const elementCurrrentSize = elementRef.current && elementRef.current[offsetProp]

  const style = { ...customStyles, ...(collapse ? collapseStyles : {}) }
  const hideChildren = collapse && collapsed
  const className = getContainerClassName({
    customClassName,
    collapse,
    hideChildren,
    defaultChildAnimation,
  })

  useEffect(() => {
    setCollapsedAfterTransition(collapse)
  }, [collapse])

  useEffect(() => {
    if (!collapse || !elementRef?.current) return

    setElementTargetSize(elementRef.current[offsetProp])
  }, [collapse, elementCurrrentSize])

  return createElement(
    containerElement,
    {
      ...props,
      style,
      className,
      ref: elementRef,
    },
    children
  )
}

export default CollapsibleContainer
