import useBriefContext from "../../../../Hooks/Brief/useBriefContext"
import BizLocationInput from '../Biz/LocationInput'
import LeadFeedLocationInput from '../LeadFeed/LocationInput'
import isBizBrief from '../../../../Logic/Form/Sequences/Biz/isBizBrief'
import InputView from "../../InputView"


const LocationInputSwitcher: InputView = ({path}) => {
  const { brief } = useBriefContext()

  if(isBizBrief(brief)) return <BizLocationInput path={path} />

  return <LeadFeedLocationInput path={path} />
}

export default LocationInputSwitcher
