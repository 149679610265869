// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary: #AF231C;
  --hover-primary: #c52820;
  --error-red: #F5222D;
  --faint-grey: #BFBFBF;
  --hover-grey: #3e3e3e;
  --border-grey: #595959;
  --background-grey: #FAFAFA;
  --checkbox-grey: #434343;
  --selection-grey: #262626;
  --text-black: #1F1F1F;
  --outline-grey: #6c6c6c;
  --outline-hover-grey: #dddddd;
  --header-border: #f5f5f5;
}
app-root * {
  outline-color: #3e3e3e;
}
body {
  background-color: #FAFAFA;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/rootColours.less"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,wBAAA;EACA,oBAAA;EACA,qBAAA;EACA,qBAAA;EACA,sBAAA;EACA,0BAAA;EACA,wBAAA;EACA,yBAAA;EACA,qBAAA;EACA,uBAAA;EACA,6BAAA;EACA,wBAAA;AADF;AAIA;EAEI,sBAAA;AAHJ;AAMA;EAAO,yBAAA;AAHP","sourcesContent":["@import './colours.less';\n\n:root {\n  --primary: @primary;\n  --hover-primary: @hover-primary;\n  --error-red: @error-red;\n  --faint-grey: @faint-grey;\n  --hover-grey: @hover-grey;\n  --border-grey: @border-grey;\n  --background-grey: @background-grey;\n  --checkbox-grey: @checkbox-grey;\n  --selection-grey: @selection-grey;\n  --text-black: @text-black;\n  --outline-grey: @outline-grey;\n  --outline-hover-grey: @outline-hover-grey;\n  --header-border: @header-border;\n}\n\napp-root {\n  * {\n    outline-color: @hover-grey;\n  }\n}\nbody { background-color: @background-grey;}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
