export const currencies = {
  AUD: '$',
  EUR: '€',
  GBP: '£',
  USD: '$',
} as const

export type Currency = keyof typeof currencies

export type CurrencySymbol = typeof currencies[Currency]

export const isCurrency = (currency?: string | null): currency is Currency =>
  !!currency && currency in currencies
