import { Guest, PartialBrief } from '../../../../Types/Brief'
import { LocationAttributes } from '../../../../Types/Location'
import { FormSequence } from '../formSequence'
import { passwordRules } from '../../../../Components/Form/Inputs/Password/SelfValidating/PasswordInput'
import { getGuestCountry } from '../../../Guest/getGuestCountry'
import isGuestAuthenticated from '../../../Guest/isGuestAuthenticated'
import isNewGuest from '../../../Guest/isNewGuest'

export const requiredLocationAttrs: Array<keyof LocationAttributes> = [
  'lat',
  'lon',
  'location',
  'currency',
  'radius',
]

export const requiredTimingAttrs: Array<keyof PartialBrief> = [
  'fromDate',
  'fromTime',
  'toDate',
  'toTime',
]

export const requiredGuestAttrs: Array<keyof Guest> = ['firstName', 'lastName', 'mobileNumber']

const isPasswordValid = (pw?: string): boolean => {
  if (pw === undefined) return false

  return passwordRules.every(({ isSatisfied }) => isSatisfied(pw))
}

const BIZ_FORM_SEQUENCE: FormSequence = [
  {
    path: 'business',
    isStepComplete: (brief) =>
      !!brief.guest?.email &&
      (brief.guest?.id !== undefined ||
        !!brief.guest?.unambiguousOrigin ||
        brief.guest?.country !== undefined),
    skipStep: isGuestAuthenticated,
  },
  {
    path: 'location',
    isStepComplete: (brief) => requiredLocationAttrs.every((attr) => !!brief[attr]),
  },
  {
    path: 'type',
    isStepComplete: (brief) => !!brief.collectionName && !!brief.collection,
  },
  {
    path: 'guests',
    isStepComplete: (brief) => brief.capacities !== undefined,
  },
  {
    path: 'style',
    isStepComplete: () => true,
  },
  {
    path: 'layout',
    isStepComplete: (brief) => !!brief.layouts,
  },
  {
    path: 'timings',
    isStepComplete: (brief) => requiredTimingAttrs.every((attr) => !!brief[attr]),
  },
  {
    path: 'flexible',
    isStepComplete: (brief) => brief.flexibleDates !== undefined,
  },
  {
    path: 'extras',
    isStepComplete: () => true,
  },
  {
    path: 'budget',
    isStepComplete: (brief) => !!brief.budget,
  },
  {
    path: 'name',
    isStepComplete: (brief) => !!brief.eventName,
  },
  {
    path: 'else',
    isStepComplete: () => true,
  },
  {
    path: 'emissions',
    isStepComplete: (brief) => !!brief.emissions,
    skipStep: (brief) => getGuestCountry(brief) !== 'GB',
  },
  {
    path: 'user',
    isStepComplete: ({ guest }) =>
      !!guest &&
      requiredGuestAttrs.every((attr) => !!guest[attr]) &&
      isPasswordValid(guest.password),
    skipStep: (brief) => !isNewGuest(brief.guest),
  },
  {
    path: 'returning-user',
    isStepComplete: () => true,
    skipStep: (brief) =>
      !brief.guest?.id ||
      (brief.guest?.authenticated && !brief.guest?.newlyAuthenticated) ||
      brief.guest?.newlyRegistered ||
      false,
  },
]

export default BIZ_FORM_SEQUENCE
