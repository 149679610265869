import { useGlobalAuth } from '@headbox-ui/auth-hook'
import { ReactNode, useEffect, useState } from 'react'
import useBriefContext from '../../../../Hooks/Brief/useBriefContext'
import './EmailInput.less'
import LoadingSpinner from '../../../../Components/LoadingSpinner'
import FormPage from '../../../../Components/Form/Page'
import CountryFields from './Fields/CountryFields'
import EmailField from './Fields/EmailField'
import useSubmitOnClick from '../../../../Hooks/Form/useSubmitOnClick'
import getAdjacentSteps from '../../../../Logic/Form/Authorization/getAdjacentSteps'
import { getGuestTriageCountry } from '../../../../Logic/Guest/getGuestCountry'
import { useNavigate } from 'react-router-dom'
import { Guest } from '../../../../Types/Brief'

interface EmailInputProps {
  path: string
  metaTitle: string
  heading: string
  className?: string
  header?: ReactNode
  emailCaption?: ReactNode
  emailHeading?: ReactNode
}

const EmailInput = ({
  path,
  heading,
  header,
  metaTitle,
  emailCaption,
  emailHeading,
  className = '',
}: EmailInputProps): JSX.Element => {
  const { brief, dispatchBrief } = useBriefContext()
  const { triageUser } = useGlobalAuth()
  const { submitOnClick, formRef } = useSubmitOnClick()
  const navigate = useNavigate()

  const [submissionLoading, setSubmissionLoading] = useState(false)
  const [email, setEmail] = useState(brief.guest?.email || '')
  const [showCountryStep, setShowCountryStep] = useState<boolean | null>(null)
  const [country, setCountry] = useState(getGuestTriageCountry(brief))
  const { isStepComplete } = getAdjacentSteps(path, brief)

  // manually navigate to next step after email update if triage country input not required
  useEffect(() => {
    if (showCountryStep !== false) return

    const { isStepComplete: isEmailStepComplete, nextPage } = getAdjacentSteps(path, brief)
    if (!isEmailStepComplete(brief)) return

    if (nextPage === undefined) throw new Error('Next page path unexpectedly missing in email step')

    navigate(`/${nextPage}`)
  }, [brief, showCountryStep])

  // Email Step FormPage options

  const fetchTriageResponseByEmail = async () => {
    setSubmissionLoading(true)
    const {
      userId,
      isBusinessUser,
      isDomainValid: unambiguousOrigin,
      hasExistingAccount,
    } = await triageUser(email, country)

    const requireCountryTriage =
      !unambiguousOrigin && !hasExistingAccount && isBusinessUser && country === undefined
    const guestAttributes: Guest = {
      id: userId,
      email,
      unambiguousOrigin,
      ...(!requireCountryTriage ? { isBusinessUser } : {}),
    }
    dispatchBrief({
      updateAttributes: { guest: guestAttributes },
    })

    setShowCountryStep(requireCountryTriage)
    setSubmissionLoading(!requireCountryTriage)
  }

  // Country Step FormPage options

  const fetchTriageResponseByEmailAndCountry = async () => {
    if (country == undefined) throw new Error('Country undefined in triage country form')
    if (email === undefined) throw new Error('Email undefined in triage country form')

    setSubmissionLoading(true)

    const { userId, isBusinessUser } = await triageUser(email, country)
    const guestAttributes: Guest = {
      id: userId,
      country,
      isBusinessUser,
    }

    dispatchBrief({
      updateAttributes: { guest: guestAttributes },
    })
  }

  const canContinue = showCountryStep
    ? isStepComplete({ guest: { email: brief.guest?.email, country } })
    : !!email
  const onContinue = showCountryStep
    ? fetchTriageResponseByEmailAndCountry
    : fetchTriageResponseByEmail
  // default onBack navigation will apply for email input
  const onBack = showCountryStep ? () => setShowCountryStep(null) : undefined
  // default afterContinue navigation will apply for country input
  const afterContinue = showCountryStep ? undefined : () => ({})

  return (
    <FormPage
      className={`triage-page ${submissionLoading ? 'loading' : ''} ${
        showCountryStep ? 'country-step' : 'email-step'
      } ${className}`}
      path={path}
      heading={heading}
      header={header}
      metaTitle={metaTitle}
      onContinue={onContinue}
      afterContinue={afterContinue}
      canContinue={canContinue}
      onBack={onBack}
      formRef={formRef}
      disableNav={submissionLoading}>
      <fieldset aria-busy={submissionLoading} id='triage-fieldset' className='input-section'>
        {submissionLoading ? (
          <LoadingSpinner progressId='triage-fieldset' fadeIn={true} />
        ) : showCountryStep ? (
          <CountryFields country={country} setCountry={setCountry} onCountryClick={submitOnClick} />
        ) : (
          <EmailField
            email={email}
            setEmail={setEmail}
            caption={emailCaption}
            heading={emailHeading}
          />
        )}
      </fieldset>
    </FormPage>
  )
}

export default EmailInput
