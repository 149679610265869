import ProcessOverview from '../../../ProcessOverview/LeadFeed'
import StatisticsCarousel from './Statistics/Carousel'
import './Aside.less'
import './AsideAnimation.less'

interface MessagingAsideProps {
  isDirectEnquiry: boolean
  triggerFadeOut?: boolean
}

const MessagingAside = ({ isDirectEnquiry, triggerFadeOut }: MessagingAsideProps): JSX.Element => (
  <aside className={`process-section ${triggerFadeOut ? 'fade-out' : ''}`}>
    <section className='process-wrapper'>
      <ProcessOverview isDirectEnquiry={isDirectEnquiry} />
      <StatisticsCarousel />
    </section>
  </aside>
)

export default MessagingAside
