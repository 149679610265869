import { DatepickerOverrides } from "baseui/datepicker"

const ChevronRight = () => (
  <svg
    style={{ width: '1rem', height: '1rem' }}
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <path d="M0.571813 1.01751L0.571812 2.02198C0.571812 2.09028 0.605295 2.15457 0.660205
      2.19474L5.90485 5.99966L0.660205 9.80457C0.605294 9.84474 0.571812 9.90903 0.571812
      9.97733L0.571812 10.9818C0.571812 11.0689 0.670919 11.1197 0.741901 11.0689L7.25217
      6.34653C7.48788 6.1751 7.48788 5.82421 7.25217 5.65412L0.741902 0.931797C0.67092 0.879566
      0.571813 0.930458 0.571813 1.01751Z" fill="#434343" />
  </svg>
)

const ChevronLeft = () => (
  <svg
    style={{width:'1rem',height:'1rem'}}
    viewBox="0 0 8 12"
    xmlns="http://www.w3.org/2000/svg"
    >
    <path d="m0.57181,1.01751l0,1.00447c0,0.0683 0.03349,0.13259
      0.08839,0.17276l5.24465,3.80492l-5.24465,3.80491c-0.05491,0.04017 -0.08839,0.10446
      -0.08839,0.17276l0,1.00447c0,0.0871 0.09911,0.1379 0.17009,0.0871l6.51027,-4.72237c0.23571,
      -0.17143 0.23571,-0.52232 0,-0.69241l-6.51027,-4.72232c-0.07098,-0.05223 -0.17009,-0.00134
      -0.17009,0.08571z" fill = "#434343" transform = "rotate(-180 4.00038 5.99991)" />
  </svg>
)

interface SharedStyleProps {
  $isFocused: boolean
  $error: boolean
}

const DATE_PICKER_OVERRIDES: DatepickerOverrides = {
  InputLabel: {
    style: {
      fontWeight: 400,
      fontFamily: 'Montserrat',
      textAlign: 'left',
      color: '#595959',
      marginBottom: '4px',
    },
  },
  InputWrapper: {
    style: {
      gap: '8px',// additional margin of 8px to make 16px
    },
  },

  CalendarHeader: {
    style: {
      borderTopRightRadius: '8px',
      borderTopLeftRadius: '8px',
    },
  },
  Root: {
    style: {
      borderTopRightRadius: '8px',
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
      borderLeftWidth: '1px',
      borderRightWidth: '1px',
      borderTopWidth: '1px',
      borderBottomWidth: '1px',
      borderTopStyle: 'solid',
      borderBottomStyle: 'solid',
      borderLeftStyle: 'solid',
      borderRightStyle: 'solid',
      borderLeftColor: '#D9D9D9',
      borderRightColor: '#D9D9D9',
      borderBottomColor: '#D9D9D9',
      borderTopColor: '#D9D9D9',
      boxShadow: 'none',

    },
  },
  CalendarContainer: {
    style: {
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',

    },
  },
  WeekdayHeader: {
    style: {
      fontWeight: 600,
      color: '#1F1F1F',
    },
  },
  MonthYearSelectButton: {
    style: {
      fontWeight: 600,
      color: '#1F1F1F',
    },
  },
  NextButton: {
    style: ({ $isHovered }) => ({
      height: '2.25rem',
      width: '2.25rem',
      paddingTop: '0.5rem',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      paddingBottom: '0.5rem',
      backgroundColor: $isHovered ? '#F0F0F0' : 'white',
    }),
  },
  PrevButton: {
    style: {
      height: '2.25rem',
      width: '2.25rem',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
    },
  },
  NextButtonIcon: {
    props: {
      overrides: {
        Svg: {
          component: ChevronRight,
        },
      },
    },
  },
  PrevButtonIcon: {
    props: {
      overrides: {
        Svg: {
          component: ChevronLeft,
        },
      },
    },
  },
  Popover: {
    props: {
      overrides: {
        Body: {
          style: {
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            boxShadow: 'none',
          },
        },
      },
    },
  },
  Input: {
    props: {
      overrides: {
        Root: {
          style: ({ $isFocused, $error }: SharedStyleProps) => ({
            color: '#262626',
            borderLeftWidth: '1px',
            borderRightWidth: '1px',
            borderTopWidth: '1px',
            borderBottomWidth: '1px',
            borderTopStyle: 'solid',
            borderBottomStyle: 'solid',
            borderLeftStyle: 'solid',
            borderRightStyle: 'solid',
            borderLeftColor: '#3e3e3e',
            borderRightColor: '#3e3e3e',
            borderBottomColor: '#3e3e3e',
            borderTopColor: '#3e3e3e',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
            ...(
              $isFocused
                ?
                {
                  outlineOffset: '-2px',
                  outline: '#3e3e3e solid 2px',
                  boxShadow: "0 0 0 3px rgba(126, 126, 126, 0.2)",
                }
                :
                undefined
            ),
            ...(
              $error
                ?
                {
                  borderLeftColor: '#F5222D',
                  borderRightColor: '#F5222D',
                  borderBottomColor: '#F5222D',
                  borderTopColor: '#F5222D',
                  outlineLeftColor: '#F5222D',
                  outlineRightColor: '#F5222D',
                  outlineTopColor: '#F5222D',
                  outlineBottomColor: '#F5222D',
                }
                :
                undefined
            ),
            ...(
              $error && $isFocused
                ?
                {
                  borderLeftColor: '#F5222D',
                  borderRightColor: '#F5222D',
                  borderBottomColor: '#F5222D',
                  borderTopColor: '#F5222D',
                  outlineLeftColor: '#F5222D',
                  outlineRightColor: '#F5222D',
                  outlineTopColor: '#F5222D',
                  outlineBottomColor: '#F5222D',
                  boxShadow: '0 0 0 3px rgb(232 132 132 / 28%)',
                }
                :
                undefined
            ),
          }),
        },
        Input: {
          style: {
            backgroundColor: 'white',
            fontWeight: 500,
            height: 'calc(1.5em + 18px)',
            paddingTop: '8px',
            paddingBottom: '8px',
            paddingLeft: '8px',
            paddingRight: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
    },
  },
}

export default DATE_PICKER_OVERRIDES
