import { ReactElement } from 'react'
import { Wrapper, Status } from '@googlemaps/react-wrapper'
import { GOOGLE_MAPS_API_KEY } from '../../../Constants'
import LocationInputSwitcher from './Switcher/LocationInputSwitcher'
import InputView from '../InputView'
import LoadingSpinner from '../../../Components/LoadingSpinner'
import './LocationInput.less'


const LocationInput: InputView = ({path}) => {
  const render = (status: Status): ReactElement => {
    switch (status) {
      case Status.LOADING:
        return <LoadingSpinner fadeIn={ false } fullPage={true}/>
      case Status.FAILURE:
        return <h3>ERROR</h3>;
      case Status.SUCCESS:
        return <LocationInputSwitcher path={path} />
    }
  }

  return <Wrapper
    apiKey={GOOGLE_MAPS_API_KEY}
    libraries={['geometry', 'drawing', 'places']}
    render={render} />
}

export default LocationInput