import { dateToMachineString } from './dateStrings'

interface GetDurationArgs {
  dates: [Date, Date]
  startTime?: string
  endTime?: string
}

const getDuration = ({ dates, startTime, endTime }: GetDurationArgs): number | undefined => {
  if (startTime === undefined || endTime === undefined) return undefined

  const startDateTime = new Date(`${dateToMachineString(dates[0])}T${startTime}`)
  const endDateTime = new Date(`${dateToMachineString(dates[1])}T${endTime}`)

  return endDateTime.valueOf() - startDateTime.valueOf()
}

const getPluralSuffix = (num: number): '' | 's' => (
  num === 1 ? '' : 's'
)

const durationToReadableString = (milliseconds: number) => {
  const hours = milliseconds / 1000 / 60 / 60
  const days = hours / 24
  const weeks = days / 7
  const durationUnitStrings = []

  const weekPortion = Math.floor(weeks)
  const dayPortion = Math.floor(days % 7)
  const hourPortion = Math.floor((hours % 24) * 2) / 2

  if (weekPortion >= 1) {
    durationUnitStrings.push(`${weekPortion} week${getPluralSuffix(weekPortion)}`)
  }
  if (dayPortion >= 1) {
    durationUnitStrings.push(`${dayPortion} day${getPluralSuffix(dayPortion)}`)
  }
  if (hourPortion >= 0.5) {
    durationUnitStrings.push(`${hourPortion} hour${getPluralSuffix(hourPortion)}`)
  }

  return durationUnitStrings.join(', ')
}

export const getReadableDuration = (props: GetDurationArgs): string | undefined => {
  const duration = getDuration(props)

  return duration !== undefined ? durationToReadableString(duration) : undefined
}