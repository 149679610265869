import { ChangeEventHandler, useEffect, SVGAttributes } from 'react'
import Select from '../../../../Components/Form/Inputs/Select'
import './TimePicker.less'
import {
  TimeOption,
  TimeOptionInput,
  timeStringToTimeOption,
  TIME_OPTIONS,
} from './TimeStamps/timeStamps'
import { TimeFormat, formatTimeOption } from '../Utilities/formatTimeOption'

const ClockIcon = ({ className }: SVGAttributes<SVGSVGElement>): JSX.Element => (
  <svg
    viewBox='0 0 16 16'
    preserveAspectRatio='xMidYMid meet'
    xmlns='http://www.w3.org/2000/svg'
    className={className}>
    <path
      d='m8.00754,0.06669c-4.38042,0 -7.93266,3.55727 -7.93266,7.94442c0,4.38715 3.55225,7.94442
      7.93266,7.94442c4.38042,0 7.93266,-3.55727 7.93266,-7.94442c0,-4.38715 -3.55225,-7.94442
      -7.93266,-7.94442zm0,14.54116c-3.63712,0 -6.58728,-2.95437 -6.58728,-6.59673c0,-3.64236
      2.95015,-6.59673 6.58728,-6.59673c3.63712,0 6.58728,2.95437 6.58728,6.59673c0,3.64236
      -2.95015,6.59673 -6.58728,6.59673z'
      fill='currentColor'
    />
    <path
      d='m11.91458,10.12591l-3.183,-2.3014l0,-5.5245c0,-0.0983 -0.08,-0.1786 -0.178,
      -0.1786l-1.074,0c-0.098,0 -0.178,0.0803 -0.178,0.1786l0,6.1473c0,0.058 0.026,0.1116
      0.073,0.1451l3.692,2.6919c0.081,0.0581 0.192,0.0402 0.25,-0.0379l0.639,-0.8705c0.058,-0.0826
      0.04,-0.1942 -0.041,-0.25z'
      fill='currentColor'
    />
  </svg>
)

interface IsAfterBaseTimeProps {
  baseTime: TimeOption
  selectedTime: TimeOption
  selectableRange: TimeOption[]
}
const isSelectedTimeBeforeBase = ({
  baseTime,
  selectedTime,
  selectableRange,
}: IsAfterBaseTimeProps): boolean => {
  const baseIndex = selectableRange.findIndex((ts) => ts === baseTime)

  const selectedIndex = selectableRange.findIndex((ts) => ts === selectedTime)

  return selectedIndex <= baseIndex
}

const getTimeRange = (start: TimeOption, end: TimeOption) => {
  const timeStamps: TimeOption[] = []
  const startIndex = TIME_OPTIONS.findIndex((ts) => start === ts)

  for (let i = 0; i < 48; i++) {
    const timeStampIndex = (startIndex + i) % 48
    const timeStamp = TIME_OPTIONS[timeStampIndex]
    timeStamps.push(timeStamp)

    if (timeStamp === end) return timeStamps
  }
  return timeStamps
}

interface TimePickerProps extends Partial<Omit<HTMLSelectElement, 'value'>> {
  value: TimeOptionInput
  setValue: (val: TimeOptionInput) => void
  format?: TimeFormat
  baseValue?: TimeOption
  rangeStart?: TimeOption
  rangeEnd?: TimeOption
  placeholder?: string
}

const TimePicker = ({
  id,
  value,
  setValue,
  required,
  baseValue,
  format = '24-hour',
  rangeStart = '00:00:00',
  rangeEnd = '23:30:00',
  placeholder = 'Select a time',
}: TimePickerProps) => {
  const onChange: ChangeEventHandler<HTMLSelectElement> = ({ target }) =>
    setValue(timeStringToTimeOption(target.value) ?? '')
  const timeStamps = getTimeRange(rangeStart, rangeEnd)

  const selectableTimes =
    baseValue === undefined ? timeStamps : timeStamps.slice(timeStamps.indexOf(baseValue) + 1)

  useEffect(() => {
    if (value === '') return

    const selectedTime = timeStringToTimeOption(value)

    if (selectedTime === undefined || baseValue === undefined) return

    if (
      isSelectedTimeBeforeBase({ baseTime: baseValue, selectedTime, selectableRange: timeStamps })
    ) {
      setValue(selectableTimes[0] ?? '')
    }
  }, [value, baseValue])

  return (
    <Select
      id={id}
      value={value}
      onChange={onChange}
      required={required}
      className={`time-picker-input ${value === '' && 'no-selection'}`}
      icon={<ClockIcon className='clock-icon' aria-hidden='true' />}>
      <option value='' className='placeholder' disabled>
        {placeholder}
      </option>
      {selectableTimes.map((timeStamp) => (
        <option key={timeStamp} value={timeStamp}>
          {formatTimeOption(timeStamp, format)}
        </option>
      ))}
    </Select>
  )
}

export default TimePicker
