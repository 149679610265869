import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useBriefContext from '../../../Hooks/Brief/useBriefContext'
import isBizBrief from '../../../Logic/Form/Sequences/Biz/isBizBrief'
import isDirectEnquiryBrief from '../../../Logic/Form/Sequences/DirectEnquiry/isDirectEnquiryBrief'
import { getLeadfeedSubmissionParams } from './getSubmissionParams/'
import { PartialBrief } from '../../../Types/Brief'
import throwGQLError from '../../../Utilities/Urql/Errors/throwGQLError'
import { useSubmitBriefMutation } from './useSubmitBrief.generated'
import getBriefUserAttributes from '../../../Utilities/User/getBriefUserAttributes'
import { useGlobalAuth } from '@headbox-ui/auth-hook'
import pushTagEvent from '../../../Utilities/GoogleTagManager/pushTagEvent'
import useSubmitBizBriefAndEmissionsDetails from './submitBizPlatformBrief'
import { useSpaceByIdQuery } from '../../useSpaceById'

export type BriefType = 'business' | 'enquiry' | 'leadfeed'

const getBriefTypeForSuccessPage = (brief: PartialBrief): BriefType => {
  if (isBizBrief(brief)) return 'business'

  if (isDirectEnquiryBrief(brief)) return 'enquiry'

  return 'leadfeed'
}
interface GetSuccessPagePathArgs {
  brief: PartialBrief
  businessEventUUID: string | undefined
  venueName: string | undefined
  messageLimit?: number
}

const getSuccessPagePath = ({
  brief,
  businessEventUUID,
  venueName,
  messageLimit,
}: GetSuccessPagePathArgs): string => {
  const type = getBriefTypeForSuccessPage(brief)

  const queryParams = new URLSearchParams({
    type,
    ...(venueName && { 'venue-name': venueName }),
    ...(businessEventUUID && { 'business-event-uuid': businessEventUUID }),
    ...(messageLimit && { 'message-limit': messageLimit.toString() }),
  })
  return `/success?${queryParams.toString()}`
}

interface UseSubmitBriefArgs {
  skip: boolean
}
interface UseSubmitBrief {
  loading: boolean
  submitted: boolean
}

const useSubmitBrief = ({ skip }: UseSubmitBriefArgs): UseSubmitBrief => {
  const { brief, dispatchBrief } = useBriefContext()
  const [{ error: lfError, fetching: submittingLF, data: lfData }, submitBrief] =
    useSubmitBriefMutation()
  const [
    { error: bizPlatformError, fetching: submittingBizPlatform, data: bizPlatformData },
    submitBizPlatformBrief,
  ] = useSubmitBizBriefAndEmissionsDetails()

  const { spaceFetching, spaceData } = useSpaceByIdQuery(brief)
  const [submitted, setSubmitted] = useState(false)

  const navigate = useNavigate()
  const { user } = useGlobalAuth()

  const submitting = !skip && (submittingLF || submittingBizPlatform || spaceFetching)

  useEffect(() => {
    if (skip || submitted) return
    if (isBizBrief(brief)) {
      submitBizPlatformBrief(brief)
    } else {
      submitBrief({ params: getLeadfeedSubmissionParams(brief) })
    }
    setSubmitted(true)
  }, [skip])

  useEffect(() => {
    if (submitting || (!lfData && !bizPlatformData)) return

    const submittedBrief = lfData?.brief
    const submittedBizPlatformBrief = bizPlatformData?.createEvent

    if (!submittedBrief && !submittedBizPlatformBrief) return // this scenario would trigger the 'no response' error below

    const id = (submittedBrief && parseInt(submittedBrief?.id, 10)) || submittedBizPlatformBrief?.ID
    if (id) pushTagEvent({ ...brief, id }, 'submitBrief')

    const successPath = getSuccessPagePath({
      brief,
      businessEventUUID: submittedBizPlatformBrief?.UUID,
      venueName: spaceData?.spaceById?.venue?.name,
      messageLimit: lfData?.brief?.messageLimit || undefined,
    })

    // 'reset' the brief but leave user attrs intact, in case the user hits the back button
    dispatchBrief({
      overwrite: {
        ...getBriefUserAttributes(user, { newlyRegistered: false }),
      },
    })
    navigate(successPath)
  }, [lfData, bizPlatformData])

  if (lfError) throwGQLError(lfError)
  if (bizPlatformError) throwGQLError(bizPlatformError)
  if (submitted && !submitting && !lfData?.brief?.id && !bizPlatformData?.createEvent?.ID) {
    throw new Error('No response returned for brief on submission')
  }

  return { loading: submitting, submitted }
}

export default useSubmitBrief
