import { useState, ChangeEventHandler } from 'react'
import FormPage from '../../../../Components/Form/Page/FormPage'
import useBriefContext from '../../../../Hooks/Brief/useBriefContext'
import throwGQLError from '../../../../Utilities/Urql/Errors/throwGQLError'
import InputView from '../../InputView'
import CateringInput from '../Base/Catering/CateringInput'
import FacilitiesFieldset from '../Base/Facilities/FacilitiesFieldset'
import { useFacilitiesQuery } from '../Base/Facilities/FacilitiesInput.generated'
import './FacilitiesInput.less'

const CateringAndFacilitiesInput: InputView = ({ path }) => {
  const { brief } = useBriefContext()
  const [{ fetching, error, data }] = useFacilitiesQuery({
    pause: brief.collection === undefined,
    requestPolicy: 'cache-first',
    variables: { id: brief.collection ?? '' },
  })

  const [facilityIDs, setFacilityIDs] = useState(brief.spaceFacilityIds ?? [])
  const [cateringNeeds, setCateringNeeds] = useState(brief.cateringNeeds ?? '')

  if (error) throwGQLError(error)

  const { features } = data?.collection ?? { features: [] }

  const onFacilityChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const intID = parseInt(target.value, 10)
    const modifiedIDs = target.checked
      ? [...facilityIDs, intID]
      : facilityIDs.filter((id) => id !== intID)
    setFacilityIDs(modifiedIDs)
  }

  const onTextAreaChange: ChangeEventHandler<HTMLTextAreaElement> = ({ target }) => {
    setCateringNeeds(target.value)
  }

  return (
    <FormPage
      metaTitle='Facilities & catering - HeadBox'
      heading='Need anything specific?'
      path={path}
      inputAttributes={{ spaceFacilityIds: facilityIDs, cateringNeeds }}
      className='facilities-page'>
      <FacilitiesFieldset
        facilities={features}
        fetching={fetching}
        selectedFacilityIDs={facilityIDs}
        onFacilitySelect={onFacilityChange}
      />
      <CateringInput cateringNeeds={cateringNeeds} onChange={onTextAreaChange} />
    </FormPage>
  )
}

export default CateringAndFacilitiesInput
