import { useEffect, useState } from 'react'
import FormPage from '../../../../Components/Form/Page/FormPage'
import useBriefContext from '../../../../Hooks/Brief/useBriefContext'
import getAdjacentSteps from '../../../../Logic/Form/Authorization/getAdjacentSteps'
import { LocationAttributes } from '../../../../Types/Location'
import BaseLocationInput from '../Base'
import InputView from '../../InputView'
import { SPACE_SEARCH_URL } from '../../../../Constants'
import { getDefaultLocationAttrs, LocationAttributesWithoutRadius } from '../Base/LocationInput'
import BizLoginBanner from '../../../../Components/BizLogin/BizLoginBanner'
import GeocodeErrorAlert from './GeocodeError'
import './LocationInput.less'
import './LocationInputAnimation.less'
import LoadingSpinner from '../../../../Components/LoadingSpinner'
import { getDefaultRadius } from '../../../../Utilities/Location'
import CombinedSlider from '../Base/CombinedSlider/CombinedSlider'

type SpaceSearchLocationAttrs = Omit<LocationAttributes, 'currency' | 'country' | 'leadFeedCity'>

const getSpaceSearchURL = (attrs: SpaceSearchLocationAttrs): URL => {
  const url = new URL(SPACE_SEARCH_URL)
  const searchEntries = Object.entries(attrs).map<[string, string]>(([prop, val]) => [
    `q[${prop}]`,
    `${val}`,
  ])
  const searchParams = new URLSearchParams([['utf8', '✓'], ...searchEntries])
    .toString()
    .replace(/%5B/g, '[')
    .replace(/%5D/g, ']')
    .replace(/%20/g, '+')

  return new URL(`${url.origin}${url.pathname}?${searchParams}`)
}

const LocationInput: InputView = ({ path }) => {
  const { brief, dispatchBrief, loading, geocodeError } = useBriefContext()
  const [locationAttributes, setLocationAttributes] = useState<
    LocationAttributesWithoutRadius | undefined
  >(getDefaultLocationAttrs(brief))
  const [pageClassName, setPageClassName] = useState('')
  const [redirecting, setRedirecting] = useState(false)
  const [radius, setRadius] = useState<LocationAttributes['radius']>(
    brief.radius ?? getDefaultRadius(brief)
  )
  useEffect(() => {
    if (loading || locationAttributes === undefined) return

    setPageClassName('delay-map-animations')
  }, [loading])

  useEffect(() => {
    if (!redirecting) return

    const { lat, lon, radius, location: address } = allLocationAttrs
    if (lat !== undefined && lon !== undefined && radius !== undefined && address !== undefined) {
      window.location.assign(getSpaceSearchURL({ lat, lon, radius, location: address }))
      return () => setRedirecting(false)
    }
    throw new Error(
      `Could not navigate to Space Search with location params:\n${JSON.stringify(
        allLocationAttrs
      )}`
    )
  }, [redirecting])

  const { isStepComplete } = getAdjacentSteps(path, brief)

  const allLocationAttrs = { radius, ...(locationAttributes && locationAttributes) }
  const isLeadFeedReady = locationAttributes?.leadFeedCity !== undefined
  const isSpaceSearchReady = isStepComplete(allLocationAttrs)
  const onContinue = () => dispatchBrief({ updateAttributes: allLocationAttrs })
  const redirectToSpaceSearch = () => {
    setRedirecting(true)
  }

  return (
    <FormPage
      path={path}
      metaTitle='Location - HeadBox'
      heading='What location works best?'
      alert={geocodeError && <GeocodeErrorAlert />}
      disableNav={redirecting}
      onContinue={onContinue}
      canContinue={isLeadFeedReady || isSpaceSearchReady}
      afterContinue={!isLeadFeedReady && isSpaceSearchReady ? redirectToSpaceSearch : undefined}
      className={`location-input leadfeed ${pageClassName}`}>
      {redirecting ? (
        <LoadingSpinner fadeIn={true} accessibleText='Redirecting you to venue search...' />
      ) : (
        <>
          <BizLoginBanner />
          <BaseLocationInput
            radius={radius}
            locationAttributes={locationAttributes}
            setLocationAttributes={setLocationAttributes}
            setRadius={setRadius}>
            <CombinedSlider
              country={locationAttributes?.country}
              radius={radius}
              setRadius={setRadius}
              show={!!locationAttributes?.location}
              title={'You will mainly hear from venues inside the circle you choose:'}
            />
          </BaseLocationInput>
        </>
      )}
    </FormPage>
  )
}

export default LocationInput
