import { UserResponseDto } from '@headbox-ui/auth-hook/dist/useGlobalAuth'
import { Guest, PartialBrief } from '../../Types/Brief'
import { BUSINESS_USER_ROLES } from '../../Constants'
import { getStoredBrief } from '../Storage/brief'

const actorToBriefGuest = (actor: UserResponseDto): Guest => ({
  firstName: actor.firstName,
  lastName: actor.lastName,
  email: actor.email,
  country: actor.country,
  company: actor.companyName,
  id: actor.id,
  isBusinessUser: BUSINESS_USER_ROLES.some((role) => actor.roles.includes(role)),
  authenticated: true,
})

const getStoredBriefGuestAttributes = (overrides?: Guest): PartialBrief => {
  const storedBrief = getStoredBrief()

  return {
    guest: {
      ...storedBrief.guest,
      authenticated: false,
      ...overrides,
    },
  }
}

const getBriefUserAttributes = (user?: UserResponseDto, userOverrides?: Guest): PartialBrief => {
  if (user) return { guest: { ...actorToBriefGuest(user), ...userOverrides } }

  return getStoredBriefGuestAttributes(userOverrides)
}

export default getBriefUserAttributes
