// AUTO-GENERATED CODE, DO NOT EDIT!
/* eslint-disable */

import * as Types from '../../../Types/generated-types'

import gql from 'graphql-tag'
import * as Urql from 'urql'
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export type LayoutsQueryVariables = Types.Exact<{
  id: Types.Scalars['String']
}>

export type LayoutsQuery = {
  __typename?: 'Query'
  collection: {
    __typename?: 'Collection'
    id: string
    name: string
    layouts?: Array<string> | null
  }
}

export const LayoutsDocument = gql`
  query Layouts($id: String!) {
    collection(id: $id) {
      id
      name
      layouts
    }
  }
`

export function useLayoutsQuery(options: Omit<Urql.UseQueryArgs<LayoutsQueryVariables>, 'query'>) {
  return Urql.useQuery<LayoutsQuery, LayoutsQueryVariables>({ query: LayoutsDocument, ...options })
}
