import { PartialBrief } from '../../Types/Brief'

export const getGuestCountry = ({ guest, country }: PartialBrief): string | undefined =>
  guest?.country || country || undefined

// Countries equal to '' currently have special significance in the biz user triaging process:
// This special case arises when a new user's email domain matches multiple companies in RedBox with
// different countries, but the user states they are not based in any of those countries.
//
// '' must currently be sent to Redbox as the user's country in this instance to ensure they are
// not automatically added to any of the matching companies.
export const getGuestTriageCountry = (brief: PartialBrief): string | undefined => {
  const guestCountry = brief.guest?.country

  if (guestCountry === '') return ''

  return getGuestCountry(brief)
}

export default getGuestCountry
