import {ChangeEventHandler, FormEventHandler, useState} from 'react';
import './IntentSection.less'
import { RadioInput } from '../../../../Components/Form/Inputs';
import { BookingIntent } from '../../../../Types/generated-types';
import ValidationMessage from '../../../../Components/Form/Inputs/ValidationMessage/ValidationMessage';
import getValidationMessage from '../../../../Components/Form/Inputs/Generic/Multi/Validation/getValidationMessage';

const intentLabels: {
  [key in BookingIntent]: string;
} = {
  "high": "I need to book a venue urgently",
  "medium": "I’d like to book a venue soon",
  "low": "I’m browsing and not ready to book yet",
} as const;
const intentOptions = Object.keys(intentLabels) as BookingIntent[];

interface IntentSectionProps {
  intent: BookingIntent|'';
  onIntentChange: ChangeEventHandler<HTMLInputElement>
}

export const IntentSection: (props: IntentSectionProps) => JSX.Element = ({intent, onIntentChange}) => {
  const [invalidMessage, setInvalidMessage] = useState<string | null>(null)

  const onInvalid: FormEventHandler<HTMLInputElement> = (event) => {
    setInvalidMessage(getValidationMessage(event.currentTarget))
    event.preventDefault()
  }

  const onIntentChangeWithClear = (e:React.ChangeEvent<HTMLInputElement>) => {
    setInvalidMessage(null)
    onIntentChange(e)
  }
  
  return (
    <section className='intent-section'>
      <header>
        <h3>How soon would you like to book a venue?</h3>
      </header>
      <ValidationMessage id='audience-error-message' invalidMessage={invalidMessage}>
        <ul role='list' className='intent-options'>
          {intentOptions.map((opt) => (
            <li key={opt}>
              <RadioInput
                name='event-intent'
                id={`${opt}-intent`}
                value={opt}
                label={intentLabels[opt]}
                checked={intent === opt}
                onChange={onIntentChangeWithClear}
                onInvalid={onInvalid}
                required
              />
            </li>
          ))}
        </ul>
      </ValidationMessage>
    </section>
  );
};
