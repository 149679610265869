// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root .google-map {
  will-change: height, opacity;
  transition: opacity 0.8s ease-in, height 0.8s ease-in-out;
  height: 0;
  opacity: 0;
  visibility: hidden;
}
app-root .google-map.show {
  visibility: visible;
  opacity: 1;
  height: 400px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/GoogleMap/GoogleMap.less"],"names":[],"mappings":"AAAA;EAEI,4BAAA;EACA,yDAAA;EAGA,SAAA;EACA,UAAA;EACA,kBAAA;AAFJ;AAII;EACE,mBAAA;EACA,UAAA;EACA,aAAA;AAFN","sourcesContent":["app-root {\n  .google-map {\n    will-change: height, opacity;\n    transition:\n      opacity 0.8s ease-in,\n      height 0.8s ease-in-out;\n    height: 0;\n    opacity: 0;\n    visibility: hidden;\n    \n    &.show {\n      visibility: visible;\n      opacity: 1;\n      height: 400px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
