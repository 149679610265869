import useBriefContext from '../../../Hooks/Brief/useBriefContext'
import isBizBrief from '../../../Logic/Form/Sequences/Biz/isBizBrief'
import InputView from '../InputView'
import BizGuestNumberInput from './Biz/GuestNumberInput'
import GuestNumberAndLayoutInput from './LF/GuestNumberAndLayoutInput'

const GuestNumberInput: InputView = (args): JSX.Element => {
  const { brief } = useBriefContext()
  if (isBizBrief(brief)) return <BizGuestNumberInput {...args} />

  return <GuestNumberAndLayoutInput {...args} />
}

export default GuestNumberInput
