import { ChangeEventHandler, useState } from 'react'
import AdditionalNotesSection from '../../../../Components/AdditionalNotes/AdditionalNotesSection'
import { Input } from '../../../../Components/Form/Inputs'

import FormPage from '../../../../Components/Form/Page/FormPage'
import useBriefContext from '../../../../Hooks/Brief/useBriefContext'
import InputView from '../../InputView'
import '../Base/EventNameInput.less'
import '../Base/EventNameInputAnimation.less'
import { IntentSection } from './IntentSection'
import { BookingIntent } from '../../../../Types/generated-types'


export const EventNameInput: InputView = ({ path }) => {
  const { brief } = useBriefContext()
  const [eventName, setEventName] = useState(brief.eventName ?? '')
  const [bookingIntent, setBookingIntent] = useState<BookingIntent|''>(brief.bookingIntent ?? '')
  const [additionalNotes, setAdditionalNotes] = useState(brief.additionalNotes ?? '')
  const onNameChange: ChangeEventHandler<HTMLInputElement> = ({ target }) =>
    setEventName(target.value)
  const onIntentChange: ChangeEventHandler<HTMLInputElement> = ({ target }) =>
    setBookingIntent(target.value as BookingIntent)

  return (
    <FormPage
      metaTitle={'Event name - HeadBox'}
      heading={'Give your event a name'}
      path={path}
      inputAttributes={{ eventName, additionalNotes, bookingIntent: bookingIntent == '' ? null : bookingIntent }}
      className='name-page'>
      <label htmlFor='event-name' className='visually-hidden'>
        Event Name
      </label>
      <Input
        id='event-name'
        name='eventName'
        maxLength={50}
        type='text'
        onChange={onNameChange}
        required
        placeholder={`${brief.collectionName}...`}
        value={eventName}
      />
      <IntentSection 
        intent={bookingIntent}
        onIntentChange={onIntentChange}
      />
      <AdditionalNotesSection
        additionalNotes={additionalNotes}
        setAdditionalNotes={setAdditionalNotes}
      />
    </FormPage>
  )
}

export default EventNameInput;

