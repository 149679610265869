import '../../Base/ProcessCopy.less'

interface ProcessProps {
  className?: string
}

const ProcessCopy = ({ className }: ProcessProps): JSX.Element => (
  <ol className={`process-overview ${className ? className : ''}`}>
    <li>
      <span className='list-text'>
        Tell us what you&#39;re planning - the more detail the better!
      </span>
    </li>
    <li>
      <span className='list-text'>We&#39;ll share your enquiry with suitable venues.</span>
    </li>
    <li>
      <span className='list-text'>Pick your favourite and get it booked.</span>
    </li>
  </ol>
)

export default ProcessCopy
