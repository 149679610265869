import useBriefContext from '../../../Hooks/Brief/useBriefContext'
import InputView from '../InputView'
import { ChangeEventHandler, useState } from 'react'
import FormPage from '../../../Components/Form/Page/FormPage'
import { Input } from '../../../Components/Form/Inputs'
import './BudgetInput.less'
import { getCurrencySymbol } from '../../../Utilities/Location'
import BudgetNotes from './BudgetNotes'
import isBizBrief from '../../../Logic/Form/Sequences/Biz/isBizBrief'

const MAXIMUM_BUDGET = 9000000

interface BudgetCaptionProps {
  budget?: number
  currencySymbol?: string
  numGuests: number
}
const BudgetCaption = ({ budget, currencySymbol, numGuests }: BudgetCaptionProps) => {
  if (budget === undefined) return <></>

  const budgetPerPerson = (budget / numGuests).toFixed(2)
  return (
    <p className='caption'>
      This works out as{' '}
      <data value={budgetPerPerson}>
        {currencySymbol}
        {budgetPerPerson}
      </data>{' '}
      per person.
    </p>
  )
}

const BudgetInput: InputView = ({ path }) => {
  const { brief } = useBriefContext()
  const [budget, setBudget] = useState<number | ''>(brief.budget ?? '')
  const [budgetNotes, setBudgetNotes] = useState<string | ''>(brief.budgetNotes ?? '')
  const currencySymbol = getCurrencySymbol(brief.currency)

  const onChangeBudget: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setBudget(target.value && parseInt(target.value, 10))
  }

  const allAttributes = {
    budget: budget === '' ? undefined : Math.min(budget, MAXIMUM_BUDGET),
    budgetNotes: budgetNotes,
  }

  return (
    <FormPage
      metaTitle='Event budget - HeadBox'
      heading="What's the budget for your event?"
      path={path}
      inputAttributes={allAttributes}
      className='budget-page'>
      <label htmlFor='budget-input' className='visually-hidden'>
        Event budget in {`${currencySymbol} `}
      </label>
      <span className='input-wrapper'>
        <em aria-hidden='true'>{currencySymbol}</em>
        <Input
          id='budget-input'
          type='number'
          value={budget}
          onChange={onChangeBudget}
          fieldName='budget'
          max={MAXIMUM_BUDGET}
          min={1}
          step={1}
          required
        />
      </span>

      <br/>

      <BudgetCaption
        budget={budget || undefined}
        currencySymbol={currencySymbol ?? undefined}
        numGuests={brief.capacities ?? 1}
      />

      {!isBizBrief(brief) && <BudgetNotes
        value={budgetNotes}
        onBudgetNotesChange={({ target }) => (setBudgetNotes(target.value))}
      />}
    </FormPage>
  )
}

export default BudgetInput
