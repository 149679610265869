// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root .intent-section {
  margin: 24px 0 48px;
}
app-root .intent-section header h3 {
  margin-bottom: 16px;
}
app-root .intent-section ul[role='list'].intent-options {
  width: 23em;
  display: inline-block;
}
app-root .intent-section ul[role='list'].intent-options li {
  text-align: left;
  padding: 3px;
  display: block;
}
app-root .intent-section ul[role='list'].intent-options li label {
  display: inline;
}
app-root .intent-section ul[role='list'].intent-options li + li {
  margin-top: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/Views/FormSteps/EventName/LF/IntentSection.less"],"names":[],"mappings":"AACA;EAEQ,mBAAA;AADR;AADA;EAIY,mBAAA;AAAZ;AAJA;EAOY,WAAA;EAEA,qBAAA;AADZ;AARA;EAWgB,gBAAA;EACA,YAAA;EACA,cAAA;AAAhB;AAbA;EAeoB,eAAA;AACpB;AAhBA;EAoBgB,eAAA;AADhB","sourcesContent":["\napp-root {\n    .intent-section{\n        margin: 24px 0 48px;\n        header h3{\n            margin-bottom: 16px;\n        }\n        ul[role='list'].intent-options{\n            width: 23em;\n\n            display: inline-block;\n            li{\n                text-align: left;\n                padding: 3px;\n                display: block;\n                label {\n                    display: inline;\n                }\n            }\n\n            li + li {\n                margin-top: 8px;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
