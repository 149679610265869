// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root section.anything-else-input header {
  margin-bottom: 16px;
}
app-root section.anything-else-input header p {
  margin-top: -16px;
}
@media (min-width: 900px) {
  app-root section.anything-else-input header {
    margin-bottom: 24px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/AdditionalNotes/AdditionalNotesSection.less"],"names":[],"mappings":"AAEA;EAKM,mBAAA;AALN;AAAA;EAGU,iBAAA;AAAV;AAGM;EAAA;IAA4B,mBAAA;EAChC;AACF","sourcesContent":["@import '../../Styles/mediaQueries.less';\n\napp-root {\n  section.anything-else-input {\n    header {\n      p { margin-top: -16px }\n\n      margin-bottom: 16px;\n      @media @landscapeTabletUp { margin-bottom: 24px; }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
