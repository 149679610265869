import { InputHTMLAttributes, ReactNode } from 'react'
import MultiInput from '../../Generic/Multi/MultiInput'
import './CheckboxInput.less'

export interface CheckboxInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  id: string
  label: ReactNode
}

const CheckboxInput = ({ label, ...props }: CheckboxInputProps) => (
  <MultiInput type={'checkbox'} label={label} {...props} />
)

export default CheckboxInput
