export interface OnRetryArgs {
  retryCount: number
  requestName: string
  error: unknown
}

interface RetryOptions {
  maxRetries?: number
  minRetryDelay?: number
  onRetry?: (args: OnRetryArgs) => void
  shouldRetry: (error: unknown) => boolean
}

type GenericRequest<RequestArgs extends unknown[], RequestResult> = (
  ...args: RequestArgs
) => Promise<RequestResult>

const retryFetch = <RequestArgs extends unknown[], RequestResult>(
  request: GenericRequest<RequestArgs, RequestResult>,
  options: RetryOptions
): ((...args: RequestArgs) => Promise<RequestResult>) => {
  const { maxRetries = 10, minRetryDelay = 2000, onRetry, shouldRetry } = options
  const retryRequest = (args: RequestArgs, retries: number): Promise<RequestResult> =>
    request(...args).catch((error: unknown) => {
      if (shouldRetry(error)) {
        const retryCount = retries + 1
        if (retryCount > maxRetries) throw error
        onRetry && onRetry({ error, retryCount, requestName: request.name })
        // progressively increase retry delay, with some randomisation ('thundering herd problem')
        const delay = retryCount * minRetryDelay + Math.random() * 2000
        const retryWithDelay = new Promise<RequestResult>((resolve) => {
          setTimeout(() => {
            resolve(retryRequest(args, retryCount))
          }, delay)
        })
        return retryWithDelay
      }
      throw error
    })
  return (...args) => retryRequest(args, 0)
}

export default retryFetch
