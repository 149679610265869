import useLocale from '../../../../Hooks/Locale/useLocale'
import { TimeOptionInput } from '../TimePicker/TimeStamps/timeStamps'
import { areSameDay, dateToMachineString, dateToReadableString } from '../Utilities/dateStrings'
import { getReadableDuration } from '../Utilities/duration'
import { formatTimeOption } from '../Utilities/formatTimeOption'
import { DateRange } from '../Utilities/getBriefDates'
import './Caption.less'

interface TimingsCaptionProps {
  dates: DateRange
  startTime: TimeOptionInput
  endTime: TimeOptionInput
  className?: string
}

const TimingsCaption = ({
  dates,
  startTime,
  endTime,
  className,
}: TimingsCaptionProps): JSX.Element => {
  const locale = useLocale()
  const timeFormat = locale === 'GB' ? '24-hour' : '12-hour'
  const [startDate, endDate] = dates
  if (startDate === undefined || endDate === undefined || startTime === '' || endTime === '') {
    return <p className={`time-caption ${className ?? ''}`} aria-live='polite'></p>
  }

  const formattedStartTime = startTime && formatTimeOption(startTime, timeFormat)
  const formattedEndTime = endTime && formatTimeOption(endTime, timeFormat)

  if (areSameDay(dates)) {
    return (
      <p className={`time-caption show ${className ?? ''}`} aria-live='polite'>
        Your event lasts from <time>{formattedStartTime}</time> to <time>{formattedEndTime}</time>{' '}
        on <time dateTime={dateToMachineString(startDate)}>{dateToReadableString(startDate)}</time>{' '}
        ({getReadableDuration({ dates: [startDate, endDate], startTime, endTime })}).
      </p>
    )
  }

  return (
    <p className={`time-caption show ${className ?? ''}`} aria-live='polite'>
      Your event starts on{' '}
      <time dateTime={`${dateToMachineString(startDate)}T${startTime}`}>
        {dateToReadableString(startDate)} at {formattedStartTime}
      </time>{' '}
      and ends on{' '}
      <time dateTime={`${dateToMachineString(endDate)}T${endTime}`}>
        {dateToReadableString(endDate)} at {formattedEndTime}
      </time>{' '}
      ({getReadableDuration({ dates: [startDate, endDate], startTime, endTime })}).
    </p>
  )
}

export default TimingsCaption
