// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root .summary-entry dd {
  display: inline;
}
app-root .summary-entry dd .summary-value {
  overflow-wrap: break-word;
}
`, "",{"version":3,"sources":["webpack://./src/Views/Summary/Base/SummaryList/Item/ListItem.less"],"names":[],"mappings":"AAAA;EAGM,eAAA;AADN;AAFA;EAIuB,yBAAA;AACvB","sourcesContent":["app-root {\n  .summary-entry {\n    dd {\n      display: inline;\n      .summary-value { overflow-wrap: break-word; }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
