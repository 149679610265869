import { useState } from 'react'
import FormPage from '../../../../Components/Form/Page/FormPage'
import useBriefContext from '../../../../Hooks/Brief/useBriefContext'
import useSubmitOnClick from '../../../../Hooks/Form/useSubmitOnClick'
import { PartialBrief } from '../../../../Types/Brief'
import InputView from '../../InputView'
import GuestPageHeading from '../Base/Heading/PageHeading'
import GuestNumberInput from '../Base/Input/NumberInput'
import LayoutFieldset from './LayoutFieldset/LayoutFieldset'
import './GuestNumberAndLayoutInput.less'

const GuestNumberAndLayoutInput: InputView = ({ path }): JSX.Element => {
  const { brief } = useBriefContext()
  const { formRef, submitOnClick } = useSubmitOnClick()
  const [numGuests, setNumGuests] = useState<number | ''>(brief.capacities ?? '')
  const [layout, setLayout] = useState(brief.layouts ?? undefined)

  const combinedAttributes: PartialBrief = {
    capacities: numGuests || undefined,
    layouts: layout,
  }

  return (
    <FormPage
      metaTitle='Event size - HeadBox'
      heading={<GuestPageHeading />}
      path={path}
      inputAttributes={combinedAttributes}
      className='guest-number-layout-page'
      formRef={formRef}>
      <GuestNumberInput numberOfGuests={numGuests} setNumberOfGuests={setNumGuests} />
      <LayoutFieldset
        layout={layout}
        setLayout={setLayout}
        submitOnClick={submitOnClick}
        className='lf-layouts'
      />
    </FormPage>
  )
}

export default GuestNumberAndLayoutInput
