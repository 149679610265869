import { withScope, captureException } from '@sentry/browser'
import { PartialBrief, PasswordlessGuest } from '../../Types/Brief'

interface BriefWithoutGuest extends PartialBrief {
  guest: undefined
}

const captureBriefBuilderException = (error: Error, brief: PartialBrief): void => {
  const guestlessBrief: BriefWithoutGuest = { ...brief, guest: undefined }
  const guest: PasswordlessGuest = { ...brief.guest, password: undefined }

  withScope((s) => {
    s.setContext('brief', guestlessBrief as unknown as Record<string, unknown>)
    s.setContext('guest', guest as unknown as Record<string, unknown>)
    captureException(error)
  })
}

export default captureBriefBuilderException
