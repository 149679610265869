import { Dispatch, ReactNode, SetStateAction } from 'react'
import { Input } from '../../../../../Components/Form/Inputs'

interface EmailFieldProps {
  email: string
  caption?: ReactNode
  heading?: ReactNode
  setEmail: Dispatch<SetStateAction<string>>
}

const EmailField = ({ email, setEmail, caption, heading = null }: EmailFieldProps): JSX.Element => (
  <>
    {heading}
    <label htmlFor='email' className='visually-hidden'>
      Email
    </label>
    <Input
      id='email'
      type='email'
      autoComplete='email'
      placeholder='Email'
      value={email}
      onChange={({ target }) => setEmail(target.value)}
      required
      fieldName='email address'
    />
    {caption ?? null}
  </>
)

export default EmailField
