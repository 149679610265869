import { ChangeEventHandler, useEffect, useState } from 'react'
import CollapsibleFieldset from '../../../../../Components/Collapsible/Fieldset'
import CollapsibleListItem from '../../../../../Components/Collapsible/ListItem'
import CheckboxInput from '../../../../../Components/Form/Inputs/Checkbox/Input/CheckboxInput'
import useMediaQuery from '../../../../../Hooks/useMediaQuery'
import { FacilitiesQuery } from '../../Base/Facilities/FacilitiesInput.generated'
import './EssentialFacilitiesFieldset.less'
import './EssentialFacilitiesFieldsetAnimations.less'

interface FacilitiesFieldsetProps {
  facilities?: FacilitiesQuery['collection']['features']
  optionalFacilityIds: number[]
  requiredFacilityIds: number[]
  onFacilitySelect: ChangeEventHandler<HTMLInputElement>
}

const EssentialFacilitiesFieldset = ({
  facilities,
  requiredFacilityIds,
  optionalFacilityIds,
  onFacilitySelect,
}: FacilitiesFieldsetProps): JSX.Element => {
  const [visibleFacilityIDs, setVisibleFacilityIDs] = useState<number[]>(optionalFacilityIds)
  const visibleFacilities = visibleFacilityIDs.map((facilityId) =>
    facilities?.find(({ id }) => facilityId == parseInt(id, 10))
  )
  const prefersReducedMotion = useMediaQuery('(prefers-reduced-motion: reduce)')

  const hideFieldset = !optionalFacilityIds.length

  useEffect(() => {
    const newIds = optionalFacilityIds.filter(
      (desirableID) => !visibleFacilityIDs.includes(desirableID)
    )
    // immediately display new 'desirable' facilities
    setVisibleFacilityIDs([...visibleFacilityIDs, ...newIds])

    // delay removal of no-longer desirable ids to allow for fade out
    const totalAnimationDuration = prefersReducedMotion ? 0 : 350
    const timeout = setTimeout(
      () => setVisibleFacilityIDs(optionalFacilityIds),
      totalAnimationDuration
    )
    return () => clearTimeout(timeout)
  }, [optionalFacilityIds])

  return (
    <CollapsibleFieldset
      aria-live='polite'
      collapse={hideFieldset}
      id='essential-facilities-fieldset'
      className='essential-facilities-section'>
      {facilities && (
        <>
          <legend>Are any of these essential?</legend>
          <ul role='list' className='essential-facilities'>
            {visibleFacilities.map((facility) => {
              if (!facility) return null

              const { id: facilityId, name: facilityName } = facility ?? {}

              return (
                <CollapsibleListItem
                  className='essential-facility-wrapper'
                  collapse={!optionalFacilityIds.includes(parseInt(facilityId, 10))}
                  defaultWidth={150}
                  key={facilityId}>
                  <CheckboxInput
                    label={facilityName}
                    checked={requiredFacilityIds.includes(parseInt(facilityId, 10))}
                    onChange={onFacilitySelect}
                    id={`essential-facility-input-${facilityId}`}
                    name='essential-facilities'
                    value={facilityId}
                    className='essential-facility'
                  />
                </CollapsibleListItem>
              )
            })}
          </ul>
        </>
      )}
    </CollapsibleFieldset>
  )
}

export default EssentialFacilitiesFieldset
