import { useState, ChangeEventHandler, useMemo } from 'react'
import FormPage from '../../../Components/Form/Page/FormPage'
import useBriefContext from '../../../Hooks/Brief/useBriefContext'
import InputView from '../InputView'
import gql from 'graphql-tag'
import { useQuery } from 'urql'
import throwGQLError from '../../../Utilities/Urql/Errors/throwGQLError'
import { HOST_LISTINGS_MANAGEMENT_SERVICE } from '../../../Constants'
import { CheckboxButton } from '../../../Components/Form/Inputs'
import LoadingSpinner from '../../../Components/LoadingSpinner'
import './StylesInput.less'

// Switch to codegen query once styles in GQL Gateway
export const StylesDocument = gql`
  query Styles {
    getAllStyles {
      id
      label
      emoji
    }
  }
`
export type StylesQuery = {
  __typename?: 'Query'
  getAllStyles: Array<{ __typename?: 'SpaceStyles'; id: string; label: string; emoji: string }>
}

const styleOrder = [
  'formal',
  'casual',
  'modern',
  'luxury',
  'traditional',
  'industrial',
  'social',
  'lively',
  'quiet',
  'professional',
  'blank_canvas',
  'simple',
  'fun',
  'quirky',
]

interface StylesFieldsetProps {
  fetching: boolean
  styles: StylesQuery['getAllStyles']
  selectedStyleIDs: string[]
  onStyleSelect: ChangeEventHandler<HTMLInputElement>
}

const StylesFieldset = ({
  styles,
  selectedStyleIDs,
  onStyleSelect,
  fetching,
}: StylesFieldsetProps): JSX.Element => (
  <fieldset aria-busy={fetching} aria-describedby={'styles-loading'}>
    {fetching ? (
      <LoadingSpinner progressId='styles-loading' />
    ) : (
      <>
        <legend className='visually-hidden'>Venue styles (optional step):</legend>
        <ul role='list' className='styles'>
          {styles.map(({ id: styleID, label, emoji }) => (
            <li key={styleID}>
              <CheckboxButton
                id={`style-input-${styleID}`}
                name={styleID}
                checked={selectedStyleIDs.includes(styleID)}
                onChange={onStyleSelect}
                label={`${label} ${emoji}`}
              />
            </li>
          ))}
        </ul>
      </>
    )}
  </fieldset>
)

const StylesInput: InputView = ({ path }): JSX.Element => {
  const { brief } = useBriefContext()
  const [styleIDs, setStyleIDs] = useState(brief.styleIds ?? [])

  const [{ fetching, error, data }] = useQuery<StylesQuery>({
    query: StylesDocument,
    context: useMemo(
      () => ({
        requestPolicy: 'cache-first',
        url: HOST_LISTINGS_MANAGEMENT_SERVICE,
      }),
      []
    ),
  })
  if (error) throwGQLError(error)
  const { getAllStyles } = data ?? { getAllStyles: [] }

  const sortedStyles = getAllStyles.sort(
    (a, b) => styleOrder.indexOf(a.id) - styleOrder.indexOf(b.id)
  )

  const onChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const modifiedIDs = target.checked
      ? [...styleIDs, target.name]
      : styleIDs.filter((id) => id !== target.name)
    setStyleIDs(modifiedIDs)
  }

  return (
    <FormPage
      metaTitle='Venue style - HeadBox'
      heading='Which venue styles are you looking for?'
      path={path}
      inputAttributes={{ styleIds: styleIDs }}
      className='styles-page'>
      <StylesFieldset
        fetching={fetching}
        styles={sortedStyles}
        onStyleSelect={onChange}
        selectedStyleIDs={styleIDs}
      />
    </FormPage>
  )
}

export default StylesInput
