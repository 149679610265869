import { GraphQLError } from 'graphql/error/GraphQLError'
import { CombinedError } from 'urql'

const networkErrorToString = (error: CombinedError['networkError']): string =>
  `Network Error: ${error?.name}\nMessage: ${error?.message || '[blank]'}\n${error?.stack}`

const graphQLErrorsToString = (errors: GraphQLError[]): string =>
  `\nGraphQL Errors: ${errors.map(
    ({ message, stack, name }, i) =>
      `\n  Error ${i + 1}: ${name}\n  Message: ${message || '[blank]'}\n  ${stack}`
  )}`

const combinedErrorToString = ({ graphQLErrors, networkError }: CombinedError): string =>
  (graphQLErrors.length > 0 ? graphQLErrorsToString(graphQLErrors) : '') +
  (networkError !== undefined ? networkErrorToString(networkError) : '')

export default combinedErrorToString
