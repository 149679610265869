const {
  services,
  googleMapsApiKey,
  googleMapsRestApiKey,
  refreshTokenCookieName,
  jwtCookieName,
  whitelistLocations,
  auCitiesLocations,
  resetPasswordURL,
  spaceSearchURL,
  sentryLogging,
  sustainabilityLink,
  businessYourEventsLink,
} = window.env

export * from './locales'

export const LOGGING = Boolean(sentryLogging)
// App logic
export const GOOGLE_MAPS_API_KEY = googleMapsApiKey
export const GOOGLE_MAPS_REST_API_KEY = googleMapsRestApiKey
export const WHITELIST_LOCATIONS = whitelistLocations
export const AU_CITIES_LOCATIONS = auCitiesLocations
export const SPACE_SEARCH_URL = spaceSearchURL
export const RESET_PASSWORD_URL = resetPasswordURL

export const AUTH_SERVICE = services?.auth
export const BIZ_SERVICE = services?.biz
export const HOST_SERVICE = services?.host
export const HOST_LISTINGS_MANAGEMENT_SERVICE = services?.hostListingsManagement
export const BIZ_PLATFORM_SERVICE = services?.bizPlatform
export const LOGIN_SERVICE = services?.login
export const HEADBOX_ROOT_SERVICE = services?.root
export const GUEST_EVENTS_LEAD_FEED = services?.guestEventsLeadFeed
export const ASSET_SERVICE = services?.assets

export const SUSTAINABILITY_LINK = sustainabilityLink
export const BUSINESS_YOUR_EVENTS_LINK = businessYourEventsLink

// GQL_GATEWAY is available at runtime and defines the URQL client endpoint.
// GRAPHQL_ENDPOINT is defined at build time in drone for use by Codegen. Both have the same value.
export const GQL_GATEWAY = services?.gqlGateway
export const REFRESH_TOKEN_COOKIE_NAME = refreshTokenCookieName
export const JWT_COOKIE_NAME = jwtCookieName

export const BIZ_GUEST_ROLES: string[] = ['administrator', 'manager', 'member']
export const HOST_ROLES: string[] = ['host', 'blackbox_host']

export const PASSWORD_RESET_TOKEN = 'reset_password_token'
export const PASSWORD_RESET_EMAIL = 'email'
export const BUSINESS_USER_ROLES = ['administrator', 'manager', 'member']

export const SENTRY_DSN =
  'https://b5bce29e32ad460dbf0cf6e041075480@o46740.ingest.sentry.io/4504255010963456'
