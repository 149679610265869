import { Guest, PartialBrief } from '../../Types/Brief'

export type NewGuest =
  | (Guest & { id: undefined })
  | (Guest & { id: string; newlyRegistered: true })
  | undefined

const isNewGuest = (guest: PartialBrief['guest']): guest is NewGuest => {
  if (guest === undefined) return true

  return !guest.id || guest.newlyRegistered || false
}

export default isNewGuest
