// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root fieldset.essential-facilities-section {
  margin-bottom: -16px;
}
app-root fieldset.essential-facilities-section legend {
  font-weight: 700;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
}
@media (min-width: 900px) {
  app-root fieldset.essential-facilities-section legend {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}
app-root fieldset.essential-facilities-section ul.essential-facilities {
  text-align: left;
  min-height: 2.5rem;
}
app-root fieldset.essential-facilities-section ul.essential-facilities li.essential-facility-wrapper {
  margin-right: 0;
  margin-left: 1rem;
  margin-bottom: 0.75rem;
}
app-root fieldset.essential-facilities-section ul.essential-facilities li.essential-facility-wrapper input.hb-checkbox {
  border-color: #595959;
}
app-root fieldset.essential-facilities-section ul.essential-facilities li.essential-facility-wrapper input.hb-checkbox + label.label-text {
  font-weight: 600;
  padding-left: 0.5em;
}
`, "",{"version":3,"sources":["webpack://./src/Views/FormSteps/Facilities/LF/EssentialFacilities/EssentialFacilitiesFieldset.less"],"names":[],"mappings":"AAGA;EAiCI,oBAAA;AAlCJ;AACA;EAGM,gBAAA;EACA,qBAAA;EACA,kBAAA;AADN;AAGM;EAAA;IACE,iBAAA;IACA,iBAAA;EAAN;AACF;AAVA;EAcM,gBAAA;EACA,kBAAA;AADN;AAdA;EAkBQ,eAAA;EACA,iBAAA;EACA,sBAAA;AADR;AAnBA;EAuBU,qBAAA;AADV;AAtBA;EA0BY,gBAAA;EACA,mBAAA;AADZ","sourcesContent":["@import '../../../../../Styles/mediaQueries.less';\n@import '../../../../../Styles/colours.less';\n\napp-root {\n  fieldset.essential-facilities-section {\n    legend {\n      font-weight: 700;\n      margin-bottom: 1.5rem;\n      font-size: 1.25rem;\n  \n      @media @landscapeTabletUp {\n        font-size: 1.5rem;\n        line-height: 1.25;\n      }\n    }\n\n    ul.essential-facilities {\n      text-align: left;\n      min-height: 2.5rem;\n\n      li.essential-facility-wrapper {\n        margin-right: 0;\n        margin-left: 1rem;\n        margin-bottom: 0.75rem;\n  \n        input.hb-checkbox {\n          border-color: @border-grey;\n  \n          + label.label-text {\n            font-weight: 600;\n            padding-left: 0.5em;\n          }\n        }\n      }\n    }\n\n    margin-bottom: -16px;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
