import { findLeadFeedCity } from '.'
import { LocationAttributes } from '../../Types/Location'
import { getLocationCoordsOverrides } from '../../Types/Location/getLocationOverrides'
import { getGeocodeCountry, patchAULocation } from './Address'
import fetchGeocoderLocations from './GoogleAPI/fetchGeocoderLocations'

export type GoogleLocationAttributes = Omit<LocationAttributes, 'radius' | 'currency'>

const fetchLocationByAddress = (address: string): Promise<GoogleLocationAttributes | undefined> =>
  fetchGeocoderLocations({ addressSearch: address }).then((results) => {
    const bestResult = results[0]
    if (bestResult == undefined) return undefined

    const {
      formatted_address,
      geometry: {
        location: { lat, lng },
      },
    } = bestResult
    const country = getGeocodeCountry(bestResult)
    const override = getLocationCoordsOverrides(formatted_address)
    const coords = { lat: override?.lat ?? lat, lon: override?.lng ?? lng }
    const leadFeedCity = findLeadFeedCity(coords)

    const address =
      country === 'AU'
        ? patchAULocation({ location: formatted_address, ...coords })
        : formatted_address
    return {
      ...(coords),
      location: address,
      country,
      leadFeedCity,
    }
  })

export default fetchLocationByAddress
