// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root textarea.hb-text-area {
  padding: 8px;
  border: 1px solid #595959;
  border-radius: 4px;
  outline: 0;
  transition-property: border-color, outline, box-shadow;
  transition-timing-function: ease;
  transition-duration: 0.3s;
}
app-root textarea.hb-text-area:hover {
  border-color: #3e3e3e;
  outline: 2px solid #dddddd;
}
app-root textarea.hb-text-area:focus {
  outline: #3e3e3e solid 2px;
  outline-offset: -2px;
  box-shadow: 0 0 0 3px rgba(126, 126, 126, 0.2);
}
`, "",{"version":3,"sources":["webpack://./src/Components/Form/Inputs/TextArea/TextArea.less"],"names":[],"mappings":"AAGA;EAEI,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,UAAA;EACA,sDAAA;EACA,gCAAA;EACA,yBAAA;AAHJ;AAKI;EACE,qBAAA;EACA,0BAAA;AAHN;AAMI;EACE,0BAAA;EACA,oBAAA;EACA,8CAAA;AAJN","sourcesContent":["@import '../../../../Styles/colours.less';\n\n\napp-root {\n  textarea.hb-text-area {\n    padding: 8px;\n    border: 1px solid @border-grey;\n    border-radius: 4px;\n    outline: 0;\n    transition-property: border-color, outline, box-shadow;\n    transition-timing-function: ease;\n    transition-duration: 0.3s;\n\n    &:hover {\n      border-color: @hover-grey;\n      outline: 2px solid @outline-hover-grey;\n    }\n\n    &:focus {\n      outline: @hover-grey solid 2px;\n      outline-offset: -2px;\n      box-shadow: 0 0 0 3px rgba(126, 126, 126, 0.2);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
