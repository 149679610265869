
      import API from "!../../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../../../../../node_modules/style-loader/dist/runtime/styleDomAPI.js";
      
      import setAttributes from "!../../../../../../node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../../../../../node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../../../../../node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../../../../../node_modules/css-loader/dist/cjs.js!../../../../../../node_modules/less-loader/dist/cjs.js??ruleSet[1].rules[2].use[2]!./Icons.less";
      
      

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;
options.insert = function insertWithDynamicNonce (style) {
                style.setAttribute('nonce', window.env.nonce)
                document.head.appendChild(style)
              };
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../../../../../node_modules/css-loader/dist/cjs.js!../../../../../../node_modules/less-loader/dist/cjs.js??ruleSet[1].rules[2].use[2]!./Icons.less";
       export default content && content.locals ? content.locals : undefined;
