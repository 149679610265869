import { ChangeEventHandler } from 'react'
import { CheckboxButton } from '../../../../../Components/Form/Inputs'
import LoadingSpinner from '../../../../../Components/LoadingSpinner'
import { FacilitiesQuery } from './FacilitiesInput.generated'

const facilityIcons = {
  'Air Conditioning': '❄️',
  'Audio Equipment': '🔊',
  'Breakout Spaces': '💥',
  'Close to Transport Links': '🚎',
  'Disabled Access': '♿️',
  'Natural Light': '☀️',
  'Outdoor Space': '🌻',
  Parking: '🚗',
  'TV/Projector': '🖥',
  'Whiteboards/Flipcharts': '🎨',
}

export type FacilityLabel = keyof typeof facilityIcons

export const isFacilityLabel = (label?: string | null): label is FacilityLabel =>
  label ? label in facilityIcons : false

const getFacilityIcon = (name: string): string => {
  if (isFacilityLabel(name)) return facilityIcons[name]

  return ''
}

interface FacilitiesFieldsetProps {
  fetching: boolean
  facilities?: FacilitiesQuery['collection']['features']
  selectedFacilityIDs: number[]
  onFacilitySelect: ChangeEventHandler<HTMLInputElement>
}

const FacilitiesFieldset = ({
  facilities,
  selectedFacilityIDs,
  onFacilitySelect,
  fetching,
}: FacilitiesFieldsetProps): JSX.Element => (
  <fieldset
    aria-busy={fetching}
    id='desirable-facilities-fieldset'
    className='desirable-facilities-section'
    aria-describedby={'facilities-loading'}>
    {fetching ? (
      <LoadingSpinner progressId='facilities-loading' />
    ) : (
      <>
        <legend className='visually-hidden'>Venue facilities (optional step):</legend>
        <ul role='list' className='facilities'>
          {facilities &&
            facilities.map(({ id: facilityID, name }) => (
              <li key={facilityID}>
                <CheckboxButton
                  id={`desirable-facility-input-${facilityID}`}
                  name='desirable-facilities'
                  value={facilityID}
                  checked={selectedFacilityIDs.includes(parseInt(facilityID, 10))}
                  onChange={onFacilitySelect}
                  label={`${name} ${getFacilityIcon(name)}`}
                />
              </li>
            ))}
        </ul>
      </>
    )}
  </fieldset>
)

export default FacilitiesFieldset
