import CollapsibleContainer, { CollapsibleContainerHTMLProps } from '../Base/Container/Container'

type CollapsibleFieldsetProps = CollapsibleContainerHTMLProps<HTMLFieldSetElement> & {
  collapse: boolean
  defaultHeight?: number
}

const CollapsibleFieldset = ({
  className,
  defaultHeight,
  ...props
}: CollapsibleFieldsetProps): JSX.Element => (
  <CollapsibleContainer<HTMLFieldSetElement>
    className={`animated-fieldset ${className}`}
    containerElement='fieldset'
    collapsibleDimension='marginBottom'
    defaultSize={defaultHeight}
    defaultChildAnimation
    {...props}
  />
)

export default CollapsibleFieldset
