import useBriefContext from '../../../Hooks/Brief/useBriefContext'
import isBizBrief from '../../../Logic/Form/Sequences/Biz/isBizBrief'
import InputView from '../InputView'
import BizDateTimeInput from './Biz/DateTimeInput'
import LFDateTimeInput from './LF/DateTimeInput'
import './DateTimeInput.less'

const DateTimeInput: InputView = (viewProps) => {
  const { brief } = useBriefContext()

  if (isBizBrief(brief)) return <BizDateTimeInput {...viewProps} />

  return <LFDateTimeInput {...viewProps} />
}

export default DateTimeInput
