// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root .hb-input.validation-wrapper {
  display: inline-block;
  font-size: 1rem;
  position: relative;
}
app-root .hb-input.validation-wrapper input {
  font-weight: 500;
  height: calc(1.5em + 18px);
  padding: 8px;
  border: 1px solid #595959;
  border-radius: 4px;
  outline: 0;
  will-change: border-color, outline, box-shadow;
  transition-property: border-color, outline, box-shadow;
  transition-timing-function: ease;
  transition-duration: 0.2s;
}
app-root .hb-input.validation-wrapper input:hover {
  border-color: #3e3e3e;
  box-shadow: 0 0 0 2px #dddddd;
}
app-root .hb-input.validation-wrapper input:focus {
  outline: #3e3e3e solid 2px;
  outline-offset: -2px;
  box-shadow: 0 0 0 3px rgba(126, 126, 126, 0.2);
}
`, "",{"version":3,"sources":["webpack://./src/Components/Form/Inputs/Generic/Input.less"],"names":[],"mappings":"AAEA;EAEI,qBAAA;EACA,eAAA;EACA,kBAAA;AAFJ;AAFA;EAOM,gBAAA;EACA,0BAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,UAAA;EACA,8CAAA;EACA,sDAAA;EACA,gCAAA;EACA,yBAAA;AAFN;AAIM;EACE,qBAAA;EACA,6BAAA;AAFR;AAIM;EACE,0BAAA;EACA,oBAAA;EACA,8CAAA;AAFR","sourcesContent":["@import \"../../../../Styles/colours.less\";\n\napp-root {\n  .hb-input.validation-wrapper {\n    display: inline-block;\n    font-size: 1rem;\n    position: relative;\n\n    input {\n      font-weight: 500;\n      height: calc(1.5em + 18px);\n      padding: 8px;\n      border: 1px solid @border-grey;\n      border-radius: 4px;\n      outline: 0;\n      will-change: border-color, outline, box-shadow;\n      transition-property: border-color, outline, box-shadow;\n      transition-timing-function: ease;\n      transition-duration: 0.2s;\n\n      &:hover {\n        border-color: @hover-grey;\n        box-shadow: 0 0 0 2px @outline-hover-grey;\n      }\n      &:focus {\n        outline: @hover-grey solid 2px;\n        outline-offset: -2px;\n        box-shadow: 0 0 0 3px rgba(126, 126, 126, 0.2);\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
