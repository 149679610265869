import {
  BIZ_EMAIL_PATH,
  FormPath,
  LF_EMAIL_PATH,
  SUMMARY_PATH,
} from '../../../Router/Routes/formRoutes'
import { PartialBrief } from '../../../Types/Brief'
import isGuestAuthenticated from '../../Guest/isGuestAuthenticated'
import getFormSequence from '../Sequences/GetFormSequence'

type PermittedPathTuple = [isPermittedPath: boolean, lastPermittedPath: FormPath]

const getPermittedPath = (targetPath: string, brief: PartialBrief): PermittedPathTuple => {
  const { sequence, name, skippedPaths } = getFormSequence(brief)
  const targetSkipped = skippedPaths.find((path) => path === targetPath) !== undefined

  if (targetPath === BIZ_EMAIL_PATH) {
    // this is the precise skip condition for the biz email step. `targetSkipped` would be false
    // for LFbriefs and thus cannot be relied upon in this case.
    if (isGuestAuthenticated(brief)) return [false, sequence[0].path]
    return [true, targetPath]
  }

  for (const { path: stepPath, isStepComplete } of sequence) {
    if (stepPath === targetPath) return [true, stepPath]
    if (!isStepComplete(brief)) return [false, stepPath]
    if (targetSkipped) return [false, stepPath]
    if (targetPath === LF_EMAIL_PATH && stepPath === SUMMARY_PATH) return [false, stepPath]
  }

  throw new Error(
    `Target path '${targetPath}' wasn't found in the ${name} form sequence during authorization`
  )
}

export default getPermittedPath
