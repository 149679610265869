import useBriefContext from '../../../Hooks/Brief/useBriefContext'
import isBizBrief from '../../../Logic/Form/Sequences/Biz/isBizBrief'
import InputView from '../InputView'
import BizEventTypeInput from './Biz/EventTypeInput'
import EventTypeAndAudienceInput from './LF/EventTypeInput'

const EventTypeInput: InputView = (args): JSX.Element => {
  const { brief } = useBriefContext()
  if (isBizBrief(brief)) return <BizEventTypeInput {...args} />

  return <EventTypeAndAudienceInput {...args} />
}

export default EventTypeInput
