import { PartialBrief } from '../../../../Types/Brief'
import { FacilitiesQuery } from '../../../FormSteps/Facilities/Base/Facilities/FacilitiesInput.generated'
import ListItem from '../../Base/SummaryList/Item'
import Facilities from '../ItemComponents/Facilities'

interface FacilitiesListItemProps {
  brief: PartialBrief
  facilities?: FacilitiesQuery['collection']['features']
}

const FacilitiesListItems = ({
  brief: {
    requiredSpaceFacilityIds,
    optionalSpaceFacilityIds: optionalAndRequiredSpaceFacilityIds,
    spaceFacilityIds: legacySpaceFacilityIds,
  },
  facilities,
}: FacilitiesListItemProps): JSX.Element => {
  const optionalSpaceFacilityIds = optionalAndRequiredSpaceFacilityIds?.filter(
    (selectedId) => !requiredSpaceFacilityIds?.includes(selectedId)
  )

  if (legacySpaceFacilityIds && !requiredSpaceFacilityIds && !optionalSpaceFacilityIds) {
    return (
      <ListItem path='extras' label='Facilities'>
        <Facilities facilityIds={legacySpaceFacilityIds} facilities={facilities} />
      </ListItem>
    )
  }

  // when no facilities of any kind selected, show simple, blank facility item
  if (!requiredSpaceFacilityIds?.length && !optionalSpaceFacilityIds?.length) {
    return (
      <ListItem path='extras' label='Facilities'>
        <Facilities facilities={facilities} />
      </ListItem>
    )
  }

  return (
    <>
      <ListItem
        path='extras'
        elementID='desirable-facilities-fieldset'
        label='Desirable facilities'>
        <Facilities facilityIds={optionalSpaceFacilityIds} facilities={facilities} />
      </ListItem>
      <ListItem
        path='extras'
        elementID='essential-facilities-fieldset'
        label='Essential facilities'>
        <Facilities facilityIds={requiredSpaceFacilityIds} facilities={facilities} />
      </ListItem>
    </>
  )
}

export default FacilitiesListItems
