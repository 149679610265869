import { Trans, useTranslation } from 'react-i18next'
import Page from '../../../Components/Page'
import { HEADBOX_ROOT_SERVICE } from '../../../Constants'
import { PartialBrief } from '../../../Types/Brief'
import getGuestEventsURL from '../getGuestEventsURL/getGuestEventsURL'
import { EnvelopeIconSVG, HeartIconSVG, TickIconSVG } from './SuccessPageIcons'
import useLocale from '../../../Hooks/Locale/useLocale'
import { ActiveLocale } from '../../../Types/Location'

const getHomepageURL = (locale: ActiveLocale): URL => {
  const homepageURL = new URL(HEADBOX_ROOT_SERVICE)
  if (['US', 'AU'].includes(locale)) homepageURL.pathname = locale.toLowerCase()

  return homepageURL
}

const SuccessLink = ({ secret }: { secret: string | null }): JSX.Element => {
  const { t } = useTranslation('successPage')
  const locale = useLocale()
  const homepageURL = getHomepageURL(locale)

  return secret ? (
    <a
      className='hb-button primary-button'
      href={getGuestEventsURL({ showSuccess: false }).toString()}>
      {t('page.gexp-link-text')}
    </a>
  ) : (
    <a className='home-page-link' href={homepageURL.toString()}>
      Return to homepage
    </a>
  )
}

const SuccessHeader = ({ guestEmail }: { guestEmail?: string }): JSX.Element => {
  const { t } = useTranslation('successPage')
  return (
    <header className='success-header'>
      <div className='success-header-content'>
        <h1>
          <Trans
            i18nKey={'successPage:header.h1'}
            values={{ srOnlyText: t('header.h1-sr-only-text') }}
            components={{ srOnly: <span className='visually-hidden' /> }}
          />
        </h1>
        <p>
          We’ve sent a confirmation to{' '}
          <span className={guestEmail ? 'email' : ''}>{guestEmail ?? 'your email address.'}</span>
        </p>
      </div>
    </header>
  )
}

interface LeadFeedSuccessPageProps {
  directEnquiry: boolean
  directEnquiryVenueName: string | null
  brief: PartialBrief
  messageLimit?: number
}

const LeadFeedSuccessPage = ({
  directEnquiry,
  directEnquiryVenueName,
  brief,
  messageLimit,
}: LeadFeedSuccessPageProps) => {
  const { t } = useTranslation('successPage')
  return (
    <Page
      metaTitle='All done! - HeadBox'
      className='success-page fade-in'
      header={<SuccessHeader guestEmail={brief?.guest?.email} />}>
      <div className='success-card'>
        <h2>What happens next?</h2>
        <section className='card-section'>
          <h3 className='card-section-header'>
            <span className='icon-wrap'>
              <TickIconSVG className='card-section-icon' aria-hidden='true' />
              Sit back and relax
            </span>
          </h3>
          <p className='card-text'>
            {directEnquiry &&
              !!directEnquiryVenueName &&
              t('page.step-1.paragraph.named-venue', 
              { venue: directEnquiryVenueName, interpolation: { escapeValue: false } })}
            {directEnquiry && !directEnquiryVenueName && t('page.step-1.paragraph.direct-enquiry')}
            {!directEnquiry && t('page.step-1.paragraph.standard')}
          </p>
        </section>
        <section className='card-section'>
          <h3 className='card-section-header'>
            <span className='icon-wrap'>
              <EnvelopeIconSVG className='card-section-icon' aria-hidden='true' />
              {messageLimit && <>Get messages from up to {messageLimit} venues</>}
              {!messageLimit && <>Get messages from venues</>}
            </span>
          </h3>
          <p className='card-text'>
            We’ll email you once messages start coming in from available venues.
          </p>
        </section>
        <section className='card-section'>
          <h3 className='card-section-header'>
            <span className='icon-wrap'>
              <HeartIconSVG className='card-section-icon' aria-hidden='true' />
              {t('page.step-3.heading')}
            </span>
          </h3>
          <p className='card-text'>
            Review your options, match with venues and they’ll be in touch directly to discuss
            things further.
          </p>
        </section>
        <SuccessLink secret={null} />
      </div>
    </Page>
  )
}

export default LeadFeedSuccessPage
