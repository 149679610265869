import { isRouteErrorResponse, useRouteError } from 'react-router-dom'
import UnknownError from './UnknownError'
import { NotFound } from './NotFound'
import { useEffect } from 'react'
import useBriefContext from '../../Hooks/Brief/useBriefContext'
import captureBriefBuilderException from '../../Utilities/Errors/captureBriefBuilderException'

const RouterError = (): JSX.Element => {
  const error = useRouteError()
  const { brief } = useBriefContext()

  useEffect(() => {
    if (!error) return

    if (isRouteErrorResponse(error)) {
      const routeError = new Error(
        `Router Error ${error.status}!\nMessage: ${error.statusText}\nURL: ${window.location.href}`
      )
      captureBriefBuilderException(routeError, brief)
      return
    }

    if (error instanceof Error) {
      captureBriefBuilderException(error, brief)
      return
    }

    const unknownError = new Error(`Unknown entity thrown: ${error}`)
    captureBriefBuilderException(unknownError, brief)
  }, [error])

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <NotFound />
    }
  }

  return <UnknownError />
}

export default RouterError
