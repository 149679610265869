import { captureException } from '@sentry/browser'
import { useState, useEffect } from 'react'
import { LocationAttributes, Coordinates } from '../../../../Types/Location'
import {
  fetchLocationByCoordinates,
  fetchLocationByAddress,
  getCurrencyByCountry,
  getDefaultRadius,
} from '../../../../Utilities/Location'
import { GeocodeResponseError } from '../../../../Utilities/Location/GoogleAPI/fetchGeocoderLocations'

interface UseLocationAttributesArgs extends Partial<Coordinates> {
  location?: string
}

type FetchLocationArgs = Coordinates | { location: string }

interface LocationAttributesState {
  fetchLoading: boolean
  geocodeError?: GeocodeResponseError
  locationAttrs?: LocationAttributes
}

const useLocationAttributes = ({
  lat,
  lon,
  location,
}: UseLocationAttributesArgs): LocationAttributesState => {
  const triggerFetchByCoordinates = lat !== undefined && lon !== undefined
  const triggerFetchByLocation = location !== undefined
  const triggerFetch = triggerFetchByCoordinates || triggerFetchByLocation
  const [fetchLoading, setFetchLoading] = useState(triggerFetch)
  const [geocodeError, setGeocodeError] = useState<GeocodeResponseError>()
  const [locationAttrs, setLocationAttrs] = useState<LocationAttributes>()

  const fetchLocation = async (args: FetchLocationArgs) => {
    setFetchLoading(true)
    try {
      const locationResult =
        'location' in args
          ? await fetchLocationByAddress(args.location)
          : await fetchLocationByCoordinates({ lat: args.lat, lon: args.lon })
      if (locationResult === undefined) return setLocationAttrs(undefined)

      setLocationAttrs({
        ...locationResult,
        radius: getDefaultRadius(locationResult),
        currency: getCurrencyByCountry(locationResult.country),
      })
    } catch (e) {
      if (e instanceof GeocodeResponseError) {
        setGeocodeError(e)
        console.error(e.message)
        captureException(e)
        return
      }

      throw e
    } finally {
      setFetchLoading(false)
    }
  }

  useEffect(() => {
    if (!triggerFetch) return

    setGeocodeError(undefined)

    if (triggerFetchByCoordinates) {
      fetchLocation({ lat, lon })
      return
    }
    if (triggerFetchByLocation) {
      fetchLocation({ location })
      return
    }
  }, [lat, lon, location])

  return {
    fetchLoading,
    geocodeError,
    locationAttrs: geocodeError === undefined ? locationAttrs : undefined,
  }
}

export default useLocationAttributes
