import { PartialBrief } from '../../../../../Types/Brief'
import isEventType, { EventType } from '../../../EventType/Base/Logic/eventType'

interface GuestCopyAttributes {
  guestHeadingPrefix: string
  guestLabel: string
}

const GUEST_COPY_BY_EVENT_TYPE: Record<EventType, GuestCopyAttributes> = {
  Party: { guestHeadingPrefix: 'Woohoo! 🕺💃', guestLabel: 'party people' },
  'Private Dining': {
    guestHeadingPrefix: 'Delicious! 🍽',
    guestLabel: 'diners',
  },
  'Meeting/Workshop': {
    guestHeadingPrefix: 'Splendid! 💼',
    guestLabel: 'attendees',
  },
  'Team Activity': {
    guestHeadingPrefix: 'Sounds like fun! 🎉',
    guestLabel: 'participants',
  },
  Presentation: {
    guestHeadingPrefix: 'Exciting! 🎤',
    guestLabel: 'attendees',
  },
  Wedding: {
    guestHeadingPrefix: 'Congratulations! 💐',
    guestLabel: 'guests',
  },
  Networking: {
    guestHeadingPrefix: 'Terrific! 🤝',
    guestLabel: 'guests',
  },
  Studio: { guestHeadingPrefix: 'Awesome! 📸 🎧', guestLabel: 'superstars' },
  Conference: {
    guestHeadingPrefix: 'Excellent! 🎤',
    guestLabel: 'attendees',
  },
  'Training': {
    guestHeadingPrefix: 'Splendid! 📒',
    guestLabel: 'attendees',
  },
  'Something Else': { guestHeadingPrefix: 'Marvelous! ✨', guestLabel: 'guests' },
}

function assertEventType(
  eventType: PartialBrief['collectionName']
): asserts eventType is EventType {
  if (!isEventType(eventType)) throw new Error(`Unrecognised event type ${eventType}`)
}

export const getGuestHeadingPrefix = (eventType: PartialBrief['collectionName']): string => {
  assertEventType(eventType)

  return GUEST_COPY_BY_EVENT_TYPE[eventType].guestHeadingPrefix
}

export const getGuestLabel = (eventType: PartialBrief['collectionName']): string => {
  assertEventType(eventType)

  return GUEST_COPY_BY_EVENT_TYPE[eventType].guestLabel
}
