import Cookies from 'universal-cookie';

export function retrieveAndClearContentPageHistory(): string[] {
  const cookies = new Cookies();
  try {
    const contentPageHistoryCookie = cookies.get('_contentPageHistory');
    cookies.remove('_contentPageHistory', { domain: '.headbox.com', path: '/' });
    if (!Array.isArray(contentPageHistoryCookie)) return [];
    return contentPageHistoryCookie;

  } catch (e) {
    return [];
  }
}
