// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root section.flexibility-details {
  text-align: left;
}
app-root section.flexibility-details .hb-text-area {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Views/FormSteps/DateTime/LF/FlexibilityNotes/FlexibilityNotes.less"],"names":[],"mappings":"AAAA;EAEI,gBAAA;AAAJ;AAFA;EAGoB,WAAA;AAEpB","sourcesContent":["app-root {\n  section.flexibility-details {\n    text-align: left;\n    .hb-text-area { width: 100%; }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
