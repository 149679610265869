import { SVGAttributes } from 'react'

type CustomSVG = (props: SVGAttributes<SVGSVGElement>) => JSX.Element

export const InfoIconSVG: CustomSVG = (props): JSX.Element => (
  <svg viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      // eslint-disable-next-line max-len
      d='M6 0C2.68661 0 0 2.68661 0 6C0 9.31339 2.68661 12 6 12C9.31339 12 12 9.31339 12 6C12 2.68661 9.31339 0 6 0ZM6.42857 8.89286C6.42857 8.95179 6.38036 9 6.32143 9H5.67857C5.61964 9 5.57143 8.95179 5.57143 8.89286V5.25C5.57143 5.19107 5.61964 5.14286 5.67857 5.14286H6.32143C6.38036 5.14286 6.42857 5.19107 6.42857 5.25V8.89286ZM6 4.28571C5.83178 4.28228 5.6716 4.21304 5.55384 4.09286C5.43609 3.97267 5.37013 3.81112 5.37013 3.64286C5.37013 3.4746 5.43609 3.31304 5.55384 3.19286C5.6716 3.07267 5.83178 3.00343 6 3C6.16822 3.00343 6.3284 3.07267 6.44616 3.19286C6.56391 3.31304 6.62987 3.4746 6.62987 3.64286C6.62987 3.81112 6.56391 3.97267 6.44616 4.09286C6.3284 4.21304 6.16822 4.28228 6 4.28571Z'
      fill='white'
    />
  </svg>
)

export const CloseIconSVG: CustomSVG = (props): JSX.Element => (
  <svg viewBox='0 0 9 8' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      // eslint-disable-next-line max-len
      d='M8.05707 8L6.48751 7.99287L4.12366 5.17479L1.76219 7.99049L0.190249 7.99762C0.085612 7.99762 0 7.91439 0 7.80737C0 7.76219 0.0166468 7.71938 0.0451842 7.68371L3.13912 3.99762L0.0451842 0.313912C0.0164478 0.279058 0.000503287 0.235419 0 0.190249C0 0.085612 0.085612 0 0.190249 0L1.76219 0.00713497L4.12366 2.82521L6.48514 0.00951251L8.0547 0.00237832C8.15933 0.00237832 8.24495 0.0856122 8.24495 0.192628C8.24495 0.237812 8.2283 0.280618 8.19976 0.31629L5.11058 4L8.20214 7.68609C8.23068 7.72176 8.24732 7.76457 8.24732 7.80975C8.24732 7.91439 8.16171 8 8.05707 8Z'
      fill='white'
    />
  </svg>
)
