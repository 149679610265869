import { useEffect } from 'react'

interface MetaTitleProps {
  title: string
}

// This component can be tested with an e2e test; snapshot/unit testing is not appropriate.
const MetaTitle: React.FC<MetaTitleProps> = ({ title }): JSX.Element | null => {
  useEffect(() => {
    document.title = title
  }, [title])

  return null
}

export default MetaTitle
