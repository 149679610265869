import { Link } from 'react-router-dom'
import ErrorBanner from '../../Components/ErrorBanner'
import MetaTitle from '../../Components/UI/MetaTitle'

export const NotFound = (): JSX.Element => {
  return (
    <main className='error-page'>
      <MetaTitle title='Page not found - HeadBox' />
      <ErrorBanner level='warning' title='Page Not Found'>
        Sorry, the page you&#39;re looking for doesn&#39;t seem to exist.{' '}
        <Link to='/'>Return to start</Link>
      </ErrorBanner>
    </main>
  )
}
