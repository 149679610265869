/* eslint-disable max-len */
import { SVGAttributes } from 'react'

export const TickIconSVG = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='33'
    height='32'
    viewBox='0 0 33 32'
    fill='none'
    {...props}>
    <path
      d='M16.5 32C25.3366 32 32.5 24.8366 32.5 16C32.5 7.16344 25.3366 0 16.5 0C7.66344 0 0.5 7.16344 0.5 16C0.5 24.8366 7.66344 32 16.5 32Z'
      fill='#AF231C'
    />
    <path
      d='M14.3593 24.17C14.0882 24.17 13.8208 24.1069 13.5784 23.9856C13.3359 23.8644 13.125 23.6883 12.9624 23.4714L8.67976 17.7608C8.54206 17.5774 8.44184 17.3686 8.38485 17.1464C8.32785 16.9242 8.31519 16.693 8.34758 16.4659C8.37997 16.2388 8.45679 16.0203 8.57364 15.8229C8.69049 15.6255 8.84508 15.4531 9.02859 15.3155C9.21209 15.1778 9.42092 15.0777 9.64312 15.0208C9.86533 14.9639 10.0966 14.9514 10.3236 14.9839C10.5507 15.0164 10.7691 15.0933 10.9665 15.2102C11.1638 15.3272 11.3361 15.4819 11.4737 15.6654L14.2756 19.4011L21.4718 8.60647C21.7288 8.22137 22.1282 7.9541 22.5822 7.86342C23.0362 7.77274 23.5077 7.86606 23.8929 8.12289C24.2781 8.37971 24.5456 8.779 24.6365 9.23297C24.7274 9.68695 24.6343 10.1585 24.3777 10.5438L15.8124 23.3924C15.6574 23.6247 15.449 23.8166 15.2046 23.9518C14.9603 24.087 14.687 24.1616 14.4079 24.1694C14.3917 24.1697 14.3755 24.17 14.3593 24.17Z'
      fill='#F2F2F2'
    />
  </svg>
)

export const EnvelopeIconSVG = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    {...props}>
    <path
      d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16345 24.8366 0 16 0C7.16344 0 0 7.16345 0 16C0 24.8366 7.16344 32 16 32Z'
      fill='#AF231C'
    />
    <path
      d='M23.7992 10.9209V21.079C23.7993 21.1743 23.7805 21.2687 23.744 21.3567C23.7076 21.4448 23.6541 21.5248 23.5868 21.5922C23.5194 21.6595 23.4394 21.713 23.3513 21.7494C23.2633 21.7859 23.1689 21.8046 23.0736 21.8046H8.9248C8.82951 21.8046 8.73515 21.7859 8.64711 21.7494C8.55906 21.713 8.47906 21.6595 8.41168 21.5922C8.3443 21.5248 8.29086 21.4448 8.2544 21.3567C8.21795 21.2687 8.1992 21.1743 8.19923 21.079V10.9209C8.19877 10.8379 8.21353 10.7555 8.2428 10.6778C8.28617 10.5539 8.36271 10.4442 8.4641 10.3607C8.56548 10.2772 8.68782 10.2232 8.8178 10.2044C8.85317 10.1986 8.88895 10.1956 8.9248 10.1953H23.0736C23.1095 10.1956 23.1453 10.1986 23.1806 10.2044C23.3106 10.2231 23.433 10.2772 23.5344 10.3607C23.6357 10.4442 23.7123 10.5539 23.7556 10.6778C23.7849 10.7555 23.7997 10.8379 23.7992 10.9209Z'
      fill='white'
    />
    <path
      d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16345 24.8366 0 16 0C7.16344 0 0 7.16345 0 16C0 24.8366 7.16344 32 16 32Z'
      fill='#AF231C'
    />
    <path
      d='M23.7992 10.9209V21.079C23.7993 21.1743 23.7805 21.2687 23.744 21.3567C23.7076 21.4448 23.6541 21.5248 23.5868 21.5922C23.5194 21.6595 23.4394 21.713 23.3513 21.7494C23.2633 21.7859 23.1689 21.8046 23.0736 21.8046H8.9248C8.82951 21.8046 8.73515 21.7859 8.64711 21.7494C8.55906 21.713 8.47906 21.6595 8.41168 21.5922C8.3443 21.5248 8.29086 21.4448 8.2544 21.3567C8.21795 21.2687 8.1992 21.1743 8.19923 21.079V10.9209C8.19877 10.8379 8.21353 10.7555 8.2428 10.6778C8.28617 10.5539 8.36271 10.4442 8.4641 10.3607C8.56548 10.2772 8.68782 10.2232 8.8178 10.2044C8.85317 10.1986 8.88895 10.1956 8.9248 10.1953H23.0736C23.1095 10.1956 23.1453 10.1986 23.1806 10.2044C23.3106 10.2231 23.433 10.2772 23.5344 10.3607C23.6357 10.4442 23.7123 10.5539 23.7556 10.6778C23.7849 10.7555 23.7997 10.8379 23.7992 10.9209Z'
      fill='white'
    />
    <path
      d='M23.757 10.7664L16.2164 17.4266C16.1543 17.4825 16.0785 17.5127 16.0006 17.5127C15.9226 17.5127 15.8468 17.4825 15.7847 17.4266L8.24414 10.7664C8.28751 10.6193 8.36405 10.4891 8.46543 10.3901C8.56681 10.291 8.68916 10.2269 8.81914 10.2046L16.0006 16.5462L23.182 10.2046C23.312 10.2268 23.4343 10.291 23.5357 10.3901C23.6371 10.4891 23.7136 10.6193 23.757 10.7664Z'
      fill='#AF231C'
      stroke='#AF231C'
    />
  </svg>
)

export const HeartIconSVG = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      {...props}>
      <g clipPath='url(#clip0_1126_18732)'>
        <path
          d='M15.6782 31.3875C24.247 31.3875 31.1934 24.4411 31.1934 15.8723C31.1934 7.30355 24.247 0.357178 15.6782 0.357178C7.10946 0.357178 0.163086 7.30355 0.163086 15.8723C0.163086 24.4411 7.10946 31.3875 15.6782 31.3875Z'
          fill='#AF231C'
        />
        <path
          d='M14.5833 10.6361C13.4586 9.93615 12.1011 9.71044 10.8093 10.0087C9.51757 10.3069 8.39741 11.1046 7.69524 12.2264C6.99306 13.3482 6.76641 14.7021 7.06514 15.9902C7.36388 17.2784 8.16354 18.3954 9.28819 19.0953L9.3088 19.1081L16.954 23.8409C17.423 24.1312 18.0383 23.9876 18.3295 23.5199L23.077 15.8942C23.772 14.7709 23.9921 13.4186 23.689 12.134C23.3859 10.8493 22.5843 9.73699 21.46 9.041C20.3357 8.345 18.9804 8.12208 17.6913 8.4211C16.4022 8.72012 15.2845 9.51668 14.5833 10.6361Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_1126_18732'>
          <rect width='32' height='32' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
