import useBriefContext from '../../../Hooks/Brief/useBriefContext'
import InputView from '../InputView'
import { Dispatch, useState, SetStateAction, FormEventHandler, useEffect } from 'react'
import { Input } from '../../../Components/Form/Inputs'
import FormPage from '../../../Components/Form/Page/FormPage'
import { PartialBrief } from '../../../Types/Brief'
import './EmissionsInput.less'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import { default as _Select, SelectChangeEvent } from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import {
  MaterialsEnum,
  CateringOptionsEnum,
  MenuTypeEnum,
} from '../../../Hooks/Brief/useSubmitBrief/submitBizPlatformBrief'
import getAdjacentSteps from '../../../Logic/Form/Authorization/getAdjacentSteps'
import useSubmitBrief from '../../../Hooks/Brief/useSubmitBrief'
import LoadingSpinner from '../../../Components/LoadingSpinner'

const eventTypeMessage = (brief: PartialBrief) => {
  const guests =
    brief.capacities && brief.capacities > 1
      ? `${brief.capacities} people`
      : `${brief.capacities} person`

  switch (brief.collectionName) {
    case 'Meeting/Workshop':
      return `You're having a meeting/workshop for ${guests} 💡`
    case 'Party':
      return `You're having a party for ${guests} 🎉`
    case 'Private Dining':
      return `You're having a private dinner for ${guests} 🍽`
    case 'Team Activity':
      return `You're running a team activity for ${guests} 🏅`
    case 'Presentation':
      return `You're having a presentation for ${guests} 📽`
    case 'Wedding':
      return `You're having a wedding with ${guests} 💖`
    case 'Networking':
      return `You're having a networking event for ${guests} 🤝`
    case 'Studio':
      return `You're booking a studio for ${guests} 📷`
    case 'Conference':
      return `You're having a conference for ${guests} 🎤`
    case 'Training':
      return `You're having a training session for ${guests} 👩‍🏫`
    case 'Something Else':
      return `Having an event for ${guests} 🤷‍♀️`
  }
}

const materialsOptionsDefaults = (collectionName: string | undefined | null): MaterialsEnum[] => {
  if (collectionName === 'Meeting/Workshop' || collectionName === 'Training') {
    return ['FLIP_CHART', 'NOTEPADS', 'PENS', 'SMALL_SIGNAGE']
  }

  if (collectionName === 'Party') {
    return ['SMALL_SIGNAGE']
  }

  if (collectionName === 'Conference') {
    return ['FLIP_CHART', 'NOTEPADS', 'PENS', 'SMALL_SIGNAGE', 'LARGE_SIGNAGE']
  }

  return []
}

const SELECT_STYLE = {
  fontWeight: 500,
  textAlign: 'left',
  height: 'calc(1.5em + 18px)',
  padding: '8px',
  outline: 0,
  willChange: 'border-color, outline, box-shadow',
  transitionProperty: 'border-color, outline, box-shadow',
  transitionTimingFunction: 'ease',
  transitionDuration: '0.2s',
  fieldset: {
    border: '1px solid #595959 ',
    borderRadius: '4px',
  },
  '&:hover fieldset': {
    borderColor: '#3e3e3e !important',
    boxShadow: '0 0 0 2px #dddddd',
  },
  '&.Mui-focused': {
    outline: 0,
    fieldset: {
      border: '2px solid #3e3e3e !important',
      outline: 0,
      outlineOffset: '-2px',
      boxShadow: '0 0 0 3px rgba(126, 126, 126, 0.2)',
    },
  },
  '&.error': {
    fieldset: {
      borderColor: '#F5222D',
    },
  },
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const MultipleSelect = ({
  id,
  options,
  selections,
  setSelections,
  disabled,
  required,
}: {
  id: string
  options: { name: string; value: string }[]
  selections: string[]
  setSelections: Dispatch<SetStateAction<any>>
  disabled?: boolean
  required?: boolean
}) => {
  const [invalid, setInvalid] = useState<boolean>(false)
  const onInvalid: FormEventHandler<HTMLInputElement> = () => {
    setInvalid(true)
  }
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event
    setSelections(typeof value === 'string' ? value.split(',') : value)
    setInvalid(false)
  }

  useEffect(() => {
    setInvalid(false)
  }, [disabled])

  return (
    <FormControl sx={{ width: { xs: '100%', sm: 255 }, fontSize: '1rem' }}>
      <_Select
        id={id}
        multiple
        value={selections}
        onChange={handleChange}
        onInvalid={onInvalid}
        className={invalid ? 'error' : ''}
        input={<OutlinedInput />}
        renderValue={(selected: string[]) =>
          options
            .filter((option) => selected.includes(option.value))
            .map((option) => option.name)
            .join(', ')
        }
        sx={SELECT_STYLE}
        MenuProps={MenuProps}
        required={!disabled && required}
        disabled={!!disabled}>
        {options.map(({ name, value }) => (
          <MenuItem key={value} value={value}>
            <Checkbox
              checked={selections.indexOf(value) > -1}
              sx={{
                '&.Mui-checked': {
                  color: '#AF231C',
                },
              }}
            />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </_Select>
    </FormControl>
  )
}

const Select = ({
  id,
  options,
  selection,
  setSelection,
  disabled,
  required,
}: {
  id: string
  options: { name: string; value: string }[]
  selection: string
  setSelection: Dispatch<SetStateAction<any>>
  disabled?: boolean
  required?: boolean
}) => {
  const [invalid, setInvalid] = useState<boolean>(false)
  const onInvalid: FormEventHandler<HTMLInputElement> = () => {
    setInvalid(true)
  }
  const handleChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event
    setSelection(value)
    setInvalid(false)
  }

  useEffect(() => {
    setInvalid(false)
  }, [disabled])

  return (
    <FormControl sx={{ width: { xs: '100%', sm: 255 }, fontSize: '1rem' }}>
      <_Select
        id={id}
        value={selection}
        onChange={handleChange}
        onInvalid={onInvalid}
        className={invalid ? 'error' : ''}
        input={<OutlinedInput />}
        sx={SELECT_STYLE}
        MenuProps={MenuProps}
        required={!disabled && required}
        disabled={!!disabled}>
        {options.map(({ name, value }) => (
          <MenuItem key={value} value={value}>
            {name}
          </MenuItem>
        ))}
      </_Select>
    </FormControl>
  )
}

const EmissionsInput: InputView = ({ path }) => {
  const { brief } = useBriefContext()
  const [shouldSubmitBrief, setShouldSubmitBrief] = useState(false)
  const { loading: submitting } = useSubmitBrief({ skip: !shouldSubmitBrief })

  const [materialsOptions, setMaterialsOptions] = useState<MaterialsEnum[]>(
    brief.emissions?.materials.options || materialsOptionsDefaults(brief.collectionName)
  )
  const [covers, setCovers] = useState<number>(
    brief.emissions?.catering?.covers || brief.capacities || 0
  )
  const [foodOptions, setFoodOptions] = useState<CateringOptionsEnum[]>(
    brief.emissions?.catering?.options || []
  )
  const [menuType, setMenuType] = useState<MenuTypeEnum | ''>(
    brief.emissions?.catering?.menuType || ''
  )
  const [accomodationGuests, setAccomodationGuests] = useState<number>(
    brief.emissions?.accomodation.guests || 0
  )
  const [accomodatioNights, setAccomodationNights] = useState<number>(
    brief.emissions?.accomodation.nights || 0
  )
  const { isFinalStep } = getAdjacentSteps(path, brief)

  return (
    <>
      {submitting && <LoadingSpinner fullPage={true} accessibleText='Submitting your enquiry...' />}
      <FormPage
        metaTitle='Carbon Emissions - HeadBox'
        heading='Can you confirm a few more details for us?'
        path={path}
        hidden={submitting}
        className='emissions-page'
        inputAttributes={{
          emissions: {
            materials: {
              options: materialsOptions,
            },
            catering: {
              covers,
              options: foodOptions,
              menuType: menuType || undefined,
            },
            accomodation: {
              guests: accomodationGuests,
              nights: accomodatioNights,
            },
          },
        }}
        afterContinue={
          isFinalStep // block default navigation with brief submission if final step of form
            ? () => setShouldSubmitBrief(true)
            : undefined
        }>
        <p className='emissions-page_subtitle'>
          Help us work out your event&apos;s carbon emissions.
        </p>
        <div className='emissions-page_wrapper'>
          <p className='caption'>{eventTypeMessage(brief)}</p>
          <section className='emissions-page_row'>
            <Input
              id='eating-count-input'
              type='number'
              value={covers}
              onChange={({ target }) => {
                setCovers(parseInt(target.value, 10))
              }}
              html5Error={true}
              max={brief.capacities}
              min={0}
              step={1}
              required
            />
            <span>people will be eating 🍴</span>
            <span>and they’ll be having</span>
            <MultipleSelect
              id='food-options'
              options={[
                {
                  name: 'Breakfast',
                  value: 'BREAKFAST',
                },
                {
                  name: 'Lunch',
                  value: 'LUNCH',
                },
                {
                  name: 'Dinner',
                  value: 'DINNER',
                },
                {
                  name: 'Tea/coffee',
                  value: 'TEA_AND_COFFEE',
                },

                {
                  name: 'Alcoholic drinks',
                  value: 'ALCOHOLIC_DRINKS',
                },
                {
                  name: 'Soft drinks',
                  value: 'SOFT_DRINKS',
                },
                {
                  name: 'Canapés',
                  value: 'CANAPES',
                },
              ]}
              selections={foodOptions}
              setSelections={setFoodOptions}
              disabled={covers < 1}
              required
            />
          </section>
          <section className='emissions-page_row'>
            <span>The menu will be</span>
            <Select
              id='menu-type'
              options={[
                {
                  name: 'Meat-based',
                  value: 'OMNIVEROUS',
                },
                {
                  name: 'Vegetarian',
                  value: 'VEGETARIAN',
                },
                {
                  name: 'Vegan',
                  value: 'VEGAN',
                },
              ]}
              selection={menuType || ''}
              setSelection={setMenuType}
              disabled={covers < 1}
              required
            />
          </section>
          <section className='emissions-page_row'>
            <span>For the event, you’ll be using</span>
            <MultipleSelect
              id='props-and-materials'
              options={[
                {
                  name: 'Large Signage',
                  value: 'LARGE_SIGNAGE',
                },
                {
                  name: 'Small Signage',
                  value: 'SMALL_SIGNAGE',
                },
                {
                  name: 'ID Badges',
                  value: 'ID_BADGES',
                },
                {
                  name: 'Pens',
                  value: 'PENS',
                },
                {
                  name: 'Notepads',
                  value: 'NOTEPADS',
                },
                {
                  name: 'Flip Chart',
                  value: 'FLIP_CHART',
                },
              ]}
              selections={materialsOptions}
              setSelections={setMaterialsOptions}
            />
          </section>
          <section className='emissions-page_row'>
            <span>You’ll also be booking accommodation for</span>
            <Input
              id='accomodation-count-input'
              type='number'
              value={accomodationGuests}
              onChange={({ target }) => {
                setAccomodationGuests(parseInt(target.value, 10))
              }}
              html5Error={true}
              max={brief.capacities}
              min={0}
              step={1}
              required
            />
            <span>people for</span>
            <Input
              id='accomodation-nights-count-input'
              type='number'
              value={accomodatioNights}
              onChange={({ target }) => {
                setAccomodationNights(parseInt(target.value, 10))
              }}
              html5Error={true}
              min={accomodationGuests ? 1 : 0}
              step={1}
              disabled={accomodationGuests < 1}
              required
            />
            <span>nights 😴</span>
          </section>
        </div>
      </FormPage>
    </>
  )
}

export default EmissionsInput
