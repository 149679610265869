import { WHITELIST_LOCATIONS } from '../../Constants'
import { Coordinates } from '../../Types/Location'
import { calculateSphericalDistance } from './'

const findLeadFeedCity = ({ lat, lon }: Coordinates): string | undefined => {
  const cityMatch = WHITELIST_LOCATIONS.find((location) => (
    calculateSphericalDistance(
      { lat, lon },
      { lat: location.lat, lon: location.lon }
    ) < location.radiusKM * 1000
  ))
  return cityMatch?.name
}

export default findLeadFeedCity
