import { RefObject, useEffect, useState } from 'react'

interface UseScrollToElementOptions {
  pause?: boolean
}

function useScrollToElement<T extends HTMLElement>(
  elementRef: RefObject<T>,
  { pause }: UseScrollToElementOptions
) {
  const startScroll = !pause
  const [scrollComplete, setScrollComplete] = useState(startScroll)

  useEffect(() => {
    if (scrollComplete || !startScroll) return

    elementRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    setScrollComplete(true)
  }, [startScroll])
}

export default useScrollToElement
