import { getGuestTriageCountry } from '../../../../Logic/Guest/getGuestCountry'
import { PartialBrief } from '../../../../Types/Brief'

interface CreateAccountUserDetails {
  // neither exported from nor correct in GlobalAuthHook (country param is missing entirely)
  email: string
  firstName: string
  lastName: string
  country: string
  contactNumber: string
  requestsExtraMarketingContent: boolean
}

const getUserDetails = (brief: PartialBrief): CreateAccountUserDetails => {
  const {
    email,
    firstName,
    lastName,
    mobileNumber: contactNumber,
    requestsExtraMarketingContent,
  } = brief.guest ?? {}
  const country = getGuestTriageCountry(brief)

  if (
    email === undefined ||
    firstName === undefined ||
    lastName === undefined ||
    country === undefined ||
    contactNumber == undefined
  )
    throw new Error('Brief guest missing required attributes for registration')

  return {
    email,
    firstName,
    lastName,
    contactNumber,
    country,
    requestsExtraMarketingContent: requestsExtraMarketingContent ?? false,
  }
}

export default getUserDetails
