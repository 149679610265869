import { useContext } from 'react'
import BriefContext from '../../../Contexts/Brief'
import { BriefState } from '../useBrief/useBrief'

const useBriefContext = (): BriefState => {
  const briefContext = useContext(BriefContext)

  if (briefContext === undefined) {
    throw new Error('useBriefContext() must be used within BriefProvider')
  }

  return briefContext
}

export default useBriefContext
