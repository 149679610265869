import { PartialBrief } from '../../../../../Types/Brief'
import { Audience } from '../../../../../Types/generated-types'
import { EventType } from './eventType'

// add attributes that must have a default specified below
type ExpectedDefaults = 'fromTime' | 'toTime'

type BriefDefaults = Omit<PartialBrief, ExpectedDefaults> &
  Required<Pick<PartialBrief, ExpectedDefaults>>

const BRIEF_DEFAULTS_BY_EVENT_TYPE: Record<EventType, BriefDefaults> = {
  Party: {
    fromTime: '18:00:00',
    toTime: '00:00:00',
  },
  'Private Dining': {
    fromTime: '18:00:00',
    toTime: '22:00:00',
  },
  'Meeting/Workshop': {
    audience: Audience['Professional'],
    fromTime: '10:00:00',
    toTime: '16:00:00',
  },
  'Team Activity': {
    audience: Audience['Professional'],
    fromTime: '14:00:00',
    toTime: '18:00:00',
  },
  Presentation: {
    audience: Audience['Professional'],
    fromTime: '11:00:00',
    toTime: '18:00:00',
  },
  Wedding: {
    fromTime: '15:00:00',
    toTime: '00:00:00',
  },
  Networking: {
    audience: Audience['Professional'],
    fromTime: '17:00:00',
    toTime: '21:00:00',
  },
  Studio: {
    fromTime: '10:00:00',
    toTime: '17:00:00',
  },
  Conference: {
    audience: Audience['Professional'],
    fromTime: '10:00:00',
    toTime: '18:00:00',
  },
  Training: {
    audience: Audience['Professional'],
    fromTime: '10:00:00',
    toTime: '16:00:00',
  },
  'Something Else': {
    fromTime: '15:00:00',
    toTime: '20:00:00',
  },
}

export default BRIEF_DEFAULTS_BY_EVENT_TYPE
