import { useCookies } from 'react-cookie'
import { createRoot } from 'react-dom/client'
import { globalAuthContext } from '@headbox-ui/auth-hook'
import { createFromUrqlClient } from '@headbox-ui/auth-hook/dist/graphQLAdapter'
import './Localisation/i18n'
import * as Sentry from '@sentry/browser'
import * as UrqlModule from 'urql'
import { retryExchange, RetryExchangeOptions } from '@urql/exchange-retry'
import FormRouter from './Router'
import {
  ASSET_SERVICE,
  LOGGING,
  SENTRY_DSN,
  REFRESH_TOKEN_COOKIE_NAME,
  JWT_COOKIE_NAME,
  HEADBOX_ROOT_SERVICE,
  GQL_GATEWAY,
  AUTH_SERVICE,
} from './Constants'
import { StrictMode, useEffect, useMemo, useState } from 'react'
import BriefProvider from './Contexts/Brief/BriefProvider'
import './Styles/modest-reset.less'
import './Styles/typography.less'
import './Styles/rootColours.less'
import ProgressBar from './Components/Form/ProgressBar'
import PseudoHeader from './Components/PseudoHeader/PseudoHeader'
import LeadFeedMessaging from './Components/Brand/Messaging/LeadFeed/'
import combinedErrorToString from './Utilities/Urql/Errors/errorParsing'
import { CombinedError, Operation } from 'urql'
import captureMessage from './Utilities/Errors/captureMessage'
import LocaleProvider from './Contexts/Locale/LocaleProvider'

const { createClient, cacheExchange, fetchExchange } = UrqlModule

__webpack_public_path__ = `${ASSET_SERVICE}/`

const container = document.querySelector('app-root')
if (container === null) throw new Error('No app-root element found')

const root = createRoot(container)

if (LOGGING) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: getSentryEnv(),
    release: window.SENTRY_RELEASE?.id,
  })
}

globalAuthContext.set({
  refreshTokenCookie: REFRESH_TOKEN_COOKIE_NAME,
  sessionTokenCookie: JWT_COOKIE_NAME,
  rootService: HEADBOX_ROOT_SERVICE,
})

const retryOptions: RetryExchangeOptions = {
  maxNumberAttempts: 10,
  maxDelayMs: 10000,
  retryIf: (err: CombinedError, op:Operation): boolean => !!(err && err.networkError) && op.kind == 'query',
  retryWith: (error: CombinedError, op: Operation) => {
    const sentryMessage = `URQL ${op.kind} retried!\nOperation Context:\n  URL: ${
      op.context.url
    }\n  Meta: ${JSON.stringify(op.context.meta)}\nTrigger: ${combinedErrorToString(error)}`

    captureMessage(sentryMessage)
    return op
  },
}

const App = (): JSX.Element => {
  const [cookies] = useCookies([REFRESH_TOKEN_COOKIE_NAME, JWT_COOKIE_NAME])
  const [hasSetGlobalAuth, setHasSetGlobalAuth] = useState(false)
  const authOptions = {
    url: AUTH_SERVICE,
    fetchOptions: () => ({ headers: { 'x-headbox-accesstoken': cookies[JWT_COOKIE_NAME] } }),
  }

  const client = useMemo(
    () =>
      createClient({
        url: GQL_GATEWAY,
        fetchOptions: () => ({ headers: { 'x-headbox-accesstoken': cookies[JWT_COOKIE_NAME] } }),
        exchanges: [cacheExchange, retryExchange(retryOptions), fetchExchange],
      }),
    [cookies, cookies[JWT_COOKIE_NAME]]
  )

  const authClient = useMemo(
    () => createClient({ ...authOptions, exchanges: [cacheExchange, fetchExchange] }),
    [cookies, cookies[JWT_COOKIE_NAME]]
  )

  useEffect(() => {
    globalAuthContext.set({
      client: createFromUrqlClient({ urqlModule: UrqlModule, client: authClient, ...authOptions }),
      refreshTokenCookie: REFRESH_TOKEN_COOKIE_NAME,
      sessionTokenCookie: JWT_COOKIE_NAME,
      rootService: HEADBOX_ROOT_SERVICE,
    })
    setHasSetGlobalAuth(true)
  }, [authClient])

  if (!hasSetGlobalAuth) return <></>

  return (
    <UrqlModule.Provider value={client}>
      <BriefProvider>
        <LocaleProvider>
          <PseudoHeader />
          <ProgressBar />
          <FormRouter />
          <LeadFeedMessaging />
        </LocaleProvider>
      </BriefProvider>
    </UrqlModule.Provider>
  )
}

root.render(
  <StrictMode>
    <App />
  </StrictMode>
)

function getSentryEnv(): string | undefined {
  if (
    window.location.href.includes('localhost') ||
    window.location.href.includes('dev.headbox.com')
  ) {
    return 'local-dev'
  }
  if (window.location.href.includes('staging')) {
    return 'staging'
  }
  return 'production'
}
