import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ActiveLocale, isActiveLocale } from '../../../Types/Location'
import useBriefContext from '../../../Hooks/Brief/useBriefContext'
import LocaleContext, { Language } from '../localeContext'
import { getGuestCountry } from '../../../Logic/Guest/getGuestCountry'

interface LanguageProviderProps {
  children: ReactNode
}

const LanguageByLocale = {
  GB: 'en-GB',
  US: 'en-US',
  AU: 'en-AU',
  IE: 'en',
} as const satisfies Record<ActiveLocale, Language>

const getDefaultLocale = (): ActiveLocale => {
  const navigatorLang = window.navigator.language
  if (navigatorLang === 'en-US') return 'US'

  if (navigatorLang === 'en-AU') return 'AU'

  return 'GB'
}

const LocaleProvider = ({ children }: LanguageProviderProps): JSX.Element => {
  const { brief } = useBriefContext()
  const { i18n } = useTranslation()
  const country = getGuestCountry(brief)
  const [locale, setLocale] = useState<ActiveLocale>(getDefaultLocale())

  useEffect(() => {
    if (!isActiveLocale(country)) return

    setLocale(country)
  }, [country])

  useEffect(() => {
    if (locale) {
      i18n.changeLanguage(LanguageByLocale[locale])
      return
    }

    i18n.changeLanguage(window.navigator.language)
  }, [locale])

  return <LocaleContext.Provider value={locale}>{children}</LocaleContext.Provider>
}

export default LocaleProvider
