import locationInput from './locationInput.json'
import brandContent from './brandContent.json'
import successPage from './successPage.json'
import summaryPage from './summaryPage.json'

const enUS = {
  brandContent,
  locationInput,
  successPage,
  summaryPage,
}

export default enUS
