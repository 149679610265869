import { Guest, PartialBrief, PasswordlessGuest } from '../../Types/Brief'
import captureMessage from '../Errors/captureMessage'

const STORAGE_KEY = 'hb-brief'

interface StoredGuest extends Omit<PasswordlessGuest, 'id'> {
  id?: string | number // legacy string IDs
}

interface StoredBrief extends Omit<PartialBrief, 'guest' | 'radius'> {
  userId?: number // legacy duplication of Guest['id']
  guest?: StoredGuest
  radius?: PartialBrief['radius'] | number // legacy numeric radii
}

const isObject = (obj: unknown): obj is object => typeof obj === 'object' && obj !== null

export const getStoredBrief = (): PartialBrief => {
  const storedStr = localStorage.getItem(STORAGE_KEY)
  if (!storedStr) return {}

  const storedBrief = JSON.parse(storedStr)

  if (!isObject(storedBrief)) {
    captureMessage(
      `Unrecognised entity ${storedBrief} found in local storage under key '${STORAGE_KEY}'`
    )
    return {}
  }

  const { radius: storedRadius, userId: legacyUserId } = storedBrief as StoredBrief

  return {
    ...storedBrief,
    guest: getBriefGuestFromStoredBrief(storedBrief),
    ...(legacyUserId && { userId: undefined }),
    radius:
      typeof storedRadius === 'number' ? { distance: storedRadius, unit: 'km' } : storedRadius,
  }
}

const getBriefGuestFromStoredBrief = (storedBrief: StoredBrief): PasswordlessGuest | undefined => {
  const { userId: legacyUserId, guest: storedGuest } = storedBrief
  const { id: userId } = storedGuest ?? {}

  const id = userId ? (typeof userId === 'string' ? parseInt(userId) : userId) : legacyUserId

  if (!storedGuest) return undefined

  return {
    ...storedGuest,
    id,
  }
}

const filterPassword = ({
  password: _password,
  ...guest
}: Guest | undefined = {}): PasswordlessGuest => (guest ? { ...guest } : {})

export const setStoredBrief = (brief: PartialBrief): void => {
  const briefToStore: StoredBrief = { ...brief, guest: filterPassword(brief.guest) }

  localStorage.setItem(STORAGE_KEY, JSON.stringify(briefToStore))
}

export const removeStoredBrief = (): void => {
  localStorage.removeItem(STORAGE_KEY)
}
