import { CSSProperties } from 'react'

export type CollapsibleCSSProperties =
  | 'margin'
  | 'marginLeft'
  | 'marginRight'
  | 'marginTop'
  | 'marginBottom'

interface GetCollapseStylesArgs {
  elementSize: number
  collapsibleDimension: CollapsibleCSSProperties
  additionalMargin?: string | number
}

const getCollapseStyles = ({
  elementSize,
  collapsibleDimension,
  additionalMargin,
}: GetCollapseStylesArgs): CSSProperties => {
  if (!additionalMargin) return { [collapsibleDimension]: -elementSize }

  if (typeof additionalMargin === 'string')
    return {
      [collapsibleDimension]: `calc(-${elementSize}px - ${additionalMargin})`,
    }

  return {
    [collapsibleDimension]: -elementSize - additionalMargin,
  }
}

export default getCollapseStyles
