import { useState, ChangeEventHandler } from 'react'
import FormPage from '../../../../Components/Form/Page/FormPage'
import useBriefContext from '../../../../Hooks/Brief/useBriefContext'
import throwGQLError from '../../../../Utilities/Urql/Errors/throwGQLError'
import InputView from '../../InputView'
import CateringInput from '../Base/Catering/CateringInput'
import FacilitiesFieldset, {
  FacilityLabel,
  isFacilityLabel,
} from '../Base/Facilities/FacilitiesFieldset'
import { useFacilitiesQuery } from '../Base/Facilities/FacilitiesInput.generated'
import EssentialFacilitiesFieldset from './EssentialFacilities'
import './FacilitiesInput.less'
import { Facility } from './facility'

const facilityOrder: FacilityLabel[] = [
  'Close to Transport Links',
  'Parking',
  'Disabled Access',
  'Outdoor Space',
  'Breakout Spaces',
  'Natural Light',
  'Air Conditioning',
  'TV/Projector',
  'Whiteboards/Flipcharts',
  'Audio Equipment',
]

const sortFacilities = (facilityA?: Facility | null, facilityB?: Facility | null): number => {
  const labelA = facilityA?.name
  const labelB = facilityB?.name
  if (!isFacilityLabel(labelA) || !isFacilityLabel(labelB)) return 0

  return facilityOrder.indexOf(labelA) - facilityOrder.indexOf(labelB)
}

const CateringAndFacilitiesInput: InputView = ({ path }) => {
  const { brief } = useBriefContext()
  const [{ fetching, error, data }] = useFacilitiesQuery({
    pause: brief.collection === undefined,
    requestPolicy: 'cache-first',
    variables: { id: brief.collection ?? '' },
  })

  const [optionalFacilityIds, setOptionalFacilityIds] = useState(
    brief.optionalSpaceFacilityIds ?? brief.spaceFacilityIds ?? []
  )
  const [requiredFacilityIds, setRequiredFacilityIds] = useState(
    brief.requiredSpaceFacilityIds ?? []
  )
  const [cateringNeeds, setCateringNeeds] = useState(brief.cateringNeeds ?? '')

  if (error) throwGQLError(error)

  const { features } = data?.collection ?? { features: [] }
  const sortedFacilities = features?.sort(sortFacilities)

  const onDesirableFacilityChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const intID = parseInt(target.value, 10)
    if (target.checked) return setOptionalFacilityIds([...optionalFacilityIds, intID])

    setOptionalFacilityIds(optionalFacilityIds.filter((id) => id !== intID))
    setRequiredFacilityIds(requiredFacilityIds.filter((id) => id !== intID))
  }

  const onEssentialFacilitiesChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const intID = parseInt(target.value, 10)
    if (target.checked) return setRequiredFacilityIds([...requiredFacilityIds, intID])

    setRequiredFacilityIds(requiredFacilityIds.filter((id) => id !== intID))
  }

  const onTextAreaChange: ChangeEventHandler<HTMLTextAreaElement> = ({ target }) => {
    setCateringNeeds(target.value)
  }

  return (
    <FormPage
      metaTitle='Facilities & catering - HeadBox'
      heading='Which facilities would you like?'
      path={path}
      inputAttributes={{
        optionalSpaceFacilityIds: optionalFacilityIds,
        requiredSpaceFacilityIds: requiredFacilityIds,
        cateringNeeds,
      }}
      className='facilities-page'>
      <FacilitiesFieldset
        facilities={sortedFacilities}
        fetching={fetching}
        selectedFacilityIDs={optionalFacilityIds}
        onFacilitySelect={onDesirableFacilityChange}
      />
      <EssentialFacilitiesFieldset
        facilities={sortedFacilities}
        optionalFacilityIds={optionalFacilityIds}
        requiredFacilityIds={requiredFacilityIds}
        onFacilitySelect={onEssentialFacilitiesChange}
      />
      <CateringInput cateringNeeds={cateringNeeds} onChange={onTextAreaChange} />
    </FormPage>
  )
}

export default CateringAndFacilitiesInput
