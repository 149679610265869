import { Dispatch, SetStateAction, createContext } from 'react'

export type PageLayout = 'lf' | 'biz'

export interface LayoutContextEntity {
  currentLayout: PageLayout
  setLayout: Dispatch<SetStateAction<PageLayout>>
}

const LayoutContext = createContext<LayoutContextEntity | undefined>(undefined)

export default LayoutContext
