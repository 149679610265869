import { Dispatch, SetStateAction } from 'react'
import { Radius } from '../../../../../Types/Location'
import Slider from '@mui/material/Slider'
import { Input } from '../../../../../Components/Form/Inputs'
import radiusUnits from '../../../../../Utilities/Location/Radius/radiusUnits'
import './CombinedSlider.less'
import { InputMaybe } from '../../../../../Types/generated-types'

export interface CombinedSliderProps {
  radius: Radius
  setRadius: Dispatch<SetStateAction<Radius>>
  show?: boolean
  country?: InputMaybe<string>
  title?: React.ReactNode
}

interface ValueLabelProps {
  value: number
  unit: Radius['unit']
}

const ValueLabel = ({ value, unit }: ValueLabelProps): JSX.Element => (
  <>
    {value} {radiusUnits[unit]}
  </>
)
interface RadiusIncrementProps {
  min: number
  max: number
  step: number
}
const getRadiusIncrementProps = (country?:InputMaybe<string>): RadiusIncrementProps => {
  if(country=='US'){
    return {
      min: 0.25,
      max: 7,
      step: 0.25,
    }
  }else if(country == 'AU'){
    return {
      min:  0.5,
      max: 25,
      step:  0.5,
    }
  }
  return {
    min: 0.5,
    max: 10,
    step: 0.5,
  }
}

const RadiusUnit = ({ unit }: { unit: Radius['unit'] }): JSX.Element => (
  <span aria-hidden={true}>{unit === 'km' ? 'km' : 'miles'}</span>
)

const getAriaValueText = ({ value, unit }: ValueLabelProps): string =>
  `${value} ${radiusUnits[unit]}`

const CombinedSlider = ({ show = true, radius, setRadius, title, country}: CombinedSliderProps) => {
  const { unit, distance } = radius
  const onSliderChange = (_event: Event, distanceValue: number | number[]) => {
    const distance = Array.isArray(distanceValue) ? distanceValue[0] : distanceValue
    setRadius({ distance, unit })
  }

  const onNumInputChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const distance = target.value === '' ? 0 : Number(target.value)
    setRadius({ distance, unit })
  }
  const isWideInput = Math.round(distance) !== radius.distance

  return (
    <div className={`combined-slider-wrapper ${show ? 'show' : ''}`}>
      {title && <span className="title"> {title}</span>}
      <div className="combined-slider">
        <Slider
          className='location-slider'
          {...getRadiusIncrementProps(country)}
          value={radius.distance}
          onChange={onSliderChange}
          valueLabelDisplay='auto'
          valueLabelFormat={(val) => <ValueLabel value={val} unit={unit} />}
          aria-hidden='true'
          sx={{ root: { color: '#AF231C' } }}
          tabIndex={-1}
        />
        <label htmlFor='radius-number-input' className='visually-hidden'>
          Radius Number Input
        </label>
        <Input
          id='radius-number-input'
          type='number'
          className={isWideInput ? 'increase-width' : ''}
          {...getRadiusIncrementProps(country)}
          value={radius.distance}
          aria-valuetext={getAriaValueText({ value: radius.distance, unit: unit })}
          onChange={onNumInputChange}
        />
        <RadiusUnit unit={unit} />
      </div>
    </div>
  )
}

export default CombinedSlider
