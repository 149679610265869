import { Dispatch, SetStateAction, useState, useEffect } from 'react'

interface TransitioningState<T> {
  state: T // the current state
  transitioning: boolean // whether a state transition is in progress
}

// A variation of useState that introduces a transition delay between state changes.
// Particularly useful for animations or other lagging UI changes.
//
function useStateTransition<T>(
  initialState: T,
  milliseconds: number // transition duration
): [
  transitioningState: TransitioningState<T>,
  setStateAfterTransition: Dispatch<SetStateAction<T>>
] {
  const [laggingState, setLaggingState] = useState(initialState)
  const [newState, setNewState] = useState(laggingState)

  useEffect(() => {
    if (laggingState === newState) return

    const timeout = setTimeout(() => setLaggingState(newState), milliseconds)

    return () => clearTimeout(timeout)
  }, [newState, milliseconds])

  return [{ state: laggingState, transitioning: laggingState !== newState }, setNewState]
}

export default useStateTransition
