import {
  ChangeEventHandler,
  Dispatch,
  FormEventHandler,
  MouseEventHandler,
  RefObject,
  useEffect,
  useState,
} from 'react'
import { RadioButton } from '../../../../../Components/Form/Inputs'
import getValidationMessage from '../../../../../Components/Form/Inputs/Generic/Multi/Validation/getValidationMessage'
import ValidationMessage from '../../../../../Components/Form/Inputs/ValidationMessage/ValidationMessage'
import { Audience } from '../../../../../Types/generated-types'
import './AudienceInput.less'

export type AudienceFieldValues = Audience | ''

interface AudienceInputProps {
  audience: string
  setAudience: Dispatch<AudienceFieldValues>
  fieldsetRef?: RefObject<HTMLFieldSetElement>
  hide?: boolean
  onClick?: MouseEventHandler<HTMLInputElement>
}

const audienceOptions = ['personal', 'professional']

const AudienceInput = ({
  audience,
  setAudience,
  hide,
  fieldsetRef,
  onClick,
}: AudienceInputProps) => {
  const [invalidMessage, setInvalidMessage] = useState<string | null>(null)

  useEffect(() => {
    if (!audience) return

    setInvalidMessage(null)
  }, [hide, audience])

  const onAudienceSelect: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    if (
      target.value !== Audience['Personal'] &&
      target.value !== Audience['Professional'] &&
      target.value !== ''
    ) {
      throw new Error(`Unrecognised Brief audience input value: ${target.value}`)
    }
    setAudience(target.value)
    setInvalidMessage(null)
  }
  const onInvalid: FormEventHandler<HTMLInputElement> = (event) => {
    setInvalidMessage(getValidationMessage(event.currentTarget))
    event.preventDefault()
  }
  return (
    <ValidationMessage id='audience-error-message' invalidMessage={invalidMessage}>
      <fieldset
        ref={fieldsetRef}
        aria-live='polite'
        className={`audience-options ${hide ? '' : ' show'}`}>
        {!hide && (
          <>
            <legend>
              <span aria-hidden='true'>What type of event is it?</span>
              <span className='visually-hidden'>What is the nature of the event?</span>
            </legend>
            <ul role='list' className='audience-options'>
              {audienceOptions.map((audienceOpt) => (
                <li key={audienceOpt}>
                  <RadioButton
                    name='event-audience'
                    id={`${audienceOpt}-audience`}
                    value={audienceOpt}
                    label={`${audienceOpt[0].toUpperCase()}${audienceOpt.slice(1)}`}
                    checked={audience === audienceOpt}
                    onChange={onAudienceSelect}
                    onClick={onClick}
                    onInvalid={onInvalid}
                    required
                  />
                </li>
              ))}
            </ul>
          </>
        )}
      </fieldset>
    </ValidationMessage>
  )
}

export default AudienceInput
