// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root .password-conditions {
  margin-top: -0.3em;
  text-align: left;
}
app-root .password-conditions .rule-indicator {
  display: block;
  font-size: 0.8em;
  will-change: color;
  transition: color 0.2s ease-in-out;
}
app-root .password-conditions .rule-indicator + .rule-indicator {
  margin-top: 0.2em;
}
app-root .password-conditions .rule-indicator svg {
  position: relative;
  top: 0.15em;
}
app-root .password-conditions .rule-indicator .rule-text {
  margin-left: 0.3em;
}
app-root .password-conditions .rule-indicator.neutral {
  color: var(--border-grey);
}
app-root .password-conditions .rule-indicator.valid {
  color: #1da8af;
}
app-root .password-conditions .rule-indicator.invalid {
  color: var(--primary);
}
`, "",{"version":3,"sources":["webpack://./src/Components/Form/Inputs/Password/SelfValidating/PasswordInput.less"],"names":[],"mappings":"AAAA;EAEI,kBAAA;EACA,gBAAA;AAAJ;AAHA;EAKM,cAAA;EACA,gBAAA;EAQA,kBAAA;EACA,kCAAA;AANN;AAFM;EAAsB,iBAAA;AAK5B;AAZA;EASQ,kBAAA;EACA,WAAA;AAMR;AAhBA;EAYmB,kBAAA;AAOnB;AAFM;EAAY,yBAAA;AAKlB;AAJM;EAAU,cAAA;AAOhB;AANM;EAAY,qBAAA;AASlB","sourcesContent":["app-root {\n  .password-conditions {\n    margin-top: -0.3em;\n    text-align: left;\n    .rule-indicator {\n      display: block;\n      font-size: 0.8em;\n      & + .rule-indicator { margin-top: 0.2em; }\n      svg {\n        position: relative;\n        top: 0.15em;\n      }\n      .rule-text { margin-left: 0.3em; }\n\n      will-change: color;\n      transition: color 0.2s ease-in-out;\n\n      &.neutral { color: var(--border-grey); }\n      &.valid { color: #1da8af; }\n      &.invalid { color: var(--primary); }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
