import { InputHTMLAttributes, ReactNode } from 'react'
import './MultiInput.less'

interface MultiInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  id: string
  type: 'radio' | 'checkbox'
  label: ReactNode
}

const MultiInput = ({ id, label, type, className, ...props }: MultiInputProps) => (
  <>
    <input className={`hb-${type} ${className ?? ''}`} id={id} type={type} {...props} />
    <label htmlFor={id} className={`label-text ${className ?? ''}`}>
      {label}
    </label>
  </>
)

export default MultiInput
