import { useState } from 'react'
import useBriefContext from '../../../../Hooks/Brief/useBriefContext'
import InputView from '../../InputView'
import FormPage from '../../../../Components/Form/Page/FormPage'
import { getBriefDates } from '../Utilities/getBriefDates'
import TimePicker from '../TimePicker'
import DatePicker from '../DatePicker'
import './DateTimeInput.less'
import TimingsCaption from '../TimingsCaption'
import { timeStringToTimeOption } from '../TimePicker/TimeStamps/timeStamps'

// fromDate and toDate are captured as ISO8601 strings. These will be converted on brief submission
// fromTime and toTime are captured as timestamp strings of 'HH:mm:ss' (where ss is always 00)

const DateTimeInput: InputView = ({ path }): JSX.Element => {
  const { brief } = useBriefContext()
  const [dates, setDates] = useState(getBriefDates(brief))
  const [startTime, setStartTime] = useState(brief.fromTime ?? '09:00:00')
  const [endTime, setEndTime] = useState(brief.toTime ?? '17:00:00')

  const allAttributes = {
    fromTime: startTime,
    toTime: endTime,
    fromDate: dates[0]?.toISOString(),
    toDate: dates[1]?.toISOString(),
  }

  const isSameDay = dates[0]?.getDate() === dates[1]?.getDate()

  return (
    <FormPage
      metaTitle='Dates & times - HeadBox'
      heading='What are your preferred timings?'
      path={path}
      inputAttributes={allAttributes}
      className='date-time-page'>
      <DatePicker dates={dates} setDates={setDates} allowDateRange />

      <div className='timings'>
        <label htmlFor='start-time-input'>
          Start time
          <TimePicker id='start-time-input' value={startTime} setValue={setStartTime} required />
        </label>

        <label htmlFor='end-time-input'>
          End time
          <TimePicker
            id='end-time-input'
            value={endTime}
            setValue={setEndTime}
            baseValue={isSameDay ? timeStringToTimeOption(startTime) : undefined}
            required
          />
        </label>
      </div>

      <TimingsCaption dates={dates} startTime={startTime} endTime={endTime} />
    </FormPage>
  )
}

export default DateTimeInput
