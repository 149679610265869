import { Dispatch, MouseEventHandler, SetStateAction } from 'react'
import { RadioInput } from '../../../../../Components/Form/Inputs'

interface CountryFieldsProps {
  country?: string
  setCountry: Dispatch<SetStateAction<string | undefined>>
  onCountryClick: MouseEventHandler<HTMLInputElement>
}

const countryOptions = [
  { label: 'Asia-Pacific', value: 'AU' },
  { label: 'UK', value: 'GB' },
  { label: 'Somewhere else', value: '' },
]

const CountryFields = ({
  country,
  setCountry,
  onCountryClick,
}: CountryFieldsProps): JSX.Element => (
  <>
    <legend>Where are you located?</legend>
    <ul role='list' className='country-options'>
      {countryOptions.map(({ label, value }) => (
        <li key={value}>
          <RadioInput
            id={`country-radio-${value}`}
            name='triage-country'
            value={value}
            required
            checked={value === country}
            onChange={({ target }) => setCountry(target.value)}
            onClick={onCountryClick}
            label={label}
          />
        </li>
      ))}
    </ul>
  </>
)

export default CountryFields
