import { useState } from 'react'
import FormPage from '../../../../Components/Form/Page/FormPage'
import useBriefContext from '../../../../Hooks/Brief/useBriefContext'
import { LocationAttributes } from '../../../../Types/Location'
import BaseLocationInput from '../Base'
import InputView from '../../InputView'
import { getDefaultLocationAttrs, LocationAttributesWithoutRadius } from '../Base/LocationInput'
import { getDefaultRadius } from '../../../../Utilities/Location'
import CombinedSlider from '../Base/CombinedSlider/CombinedSlider'

const LocationInput: InputView = ({ path }) => {
  const { brief } = useBriefContext()
  const [locationAttributes, setLocationAttributes] = useState<
    LocationAttributesWithoutRadius | undefined
  >(getDefaultLocationAttrs(brief))
  const [radius, setRadius] = useState<LocationAttributes['radius']>(
    brief.radius ?? getDefaultRadius(brief)
  )
  const allLocationAttrs = { radius, ...(locationAttributes && locationAttributes) }

  return (
    <FormPage
      metaTitle='Location - HeadBox'
      heading='What location works best?'
      path={path}
      inputAttributes={allLocationAttrs}
      className='location-input biz'>
      <BaseLocationInput
        radius={radius}
        locationAttributes={locationAttributes}
        setLocationAttributes={setLocationAttributes}
        setRadius={setRadius}
        limitSearchToActiveLocales={false}
      />
      <CombinedSlider
        radius={radius}
        setRadius={setRadius}
        show={!!locationAttributes?.location}
        country={locationAttributes?.country}
      />
    </FormPage>
  )
}

export default LocationInput
