import { nameToIndefiniteArticle, capitalise } from '../../Validation/Utilities/stringHelpers'

const getValueMissingMessage = (fieldName: string): string =>
  `Please select ${nameToIndefiniteArticle(fieldName)}`
const getPatternMismatchMessage = (fieldName: string): string =>
  `Your ${capitalise(fieldName)} selection doesn't match the expected format`
const getInvalidInputMessage = (fieldName: string): string => `Please select a valid ${fieldName}`

const getValidationMessage = (
  select: EventTarget & HTMLSelectElement,
  fieldName: string
): string | null => {
  if (select.validity.valid) return null
  if (select.validity.valueMissing) return getValueMissingMessage(fieldName)
  if (select.validity.customError) return select.validationMessage
  if (select.validity.patternMismatch) return getPatternMismatchMessage(fieldName)
  if (select.validity.badInput) return getInvalidInputMessage(fieldName)
  if (select.validity.typeMismatch) return getInvalidInputMessage(fieldName)

  return getInvalidInputMessage(fieldName)
}

export default getValidationMessage
