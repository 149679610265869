import { MouseEventHandler, useRef, useState } from 'react'
import FormPage from '../../../../Components/Form/Page/FormPage'
import useBriefContext from '../../../../Hooks/Brief/useBriefContext'
import InputView from '../../InputView'
import useSubmitOnClick from '../../../../Hooks/Form/useSubmitOnClick'
import EventTypeFieldset, { EventTypeAttributes } from '../Base/Fieldset'
import AudienceInput from './Audience'
import { AudienceFieldValues } from './Audience/AudienceInput'
import { PartialBrief } from '../../../../Types/Brief'
import { Collection } from '../../../../Types/generated-types'
import useScrollToElement from '../../../../Hooks/useScrollToElement'
import getAttributeOverrideByEventType from '../Base/Logic/getAttributeOverrideByEventType'
import getAttributeDefaultByEventType from '../Base/Logic/getAttributeDefaultByEventType'

const EventTypeInput: InputView = ({ path }): JSX.Element => {
  const { brief } = useBriefContext()
  const [fetchingEventTypes, setFetchingEventTypes] = useState(true)
  const [eventTypes, setEventTypes] = useState<Collection[]>([])
  const [eventTypeAttrs, _setEventTypeAttrs] = useState<EventTypeAttributes>({
    collection: brief.collection,
    collectionName: brief.collectionName,
  })
  const forcedAudience = getAttributeOverrideByEventType(eventTypeAttrs.collectionName, 'audience')
  const defaultAudience = getAttributeDefaultByEventType(eventTypeAttrs.collectionName, 'audience')
  const [audience, setAudience] = useState<AudienceFieldValues>(
    forcedAudience ?? brief.audience ?? defaultAudience
  )

  const setEventTypeAttrs = (attrs: EventTypeAttributes) => {
    const newForcedAudience = getAttributeOverrideByEventType(attrs.collectionName, 'audience')
    const newDefaultAudience = getAttributeDefaultByEventType(attrs.collectionName, 'audience')
    _setEventTypeAttrs(attrs)
    if (!newForcedAudience && brief.audience) return

    setAudience(newForcedAudience ?? newDefaultAudience)
  }

  const fieldsetRef = useRef<HTMLFieldSetElement>(null)
  const hideAudienceInput = fetchingEventTypes || !!forcedAudience || !eventTypeAttrs.collection

  useScrollToElement(fieldsetRef, { pause: hideAudienceInput })
  const { submitOnClick: defaultSubmitOnClick, formRef } = useSubmitOnClick()
  const submitOnEventTypeClick: MouseEventHandler<HTMLInputElement> = (e) => {
    const { currentTarget } = e
    const eventTypeLabel = eventTypes.find((e) => e.id === currentTarget.value)?.name
    if (!getAttributeOverrideByEventType(eventTypeLabel, 'audience')) return

    defaultSubmitOnClick(e)
  }

  const typeAndAudienceAttrs: PartialBrief = { ...eventTypeAttrs, audience: audience || undefined }

  return (
    <FormPage
      metaTitle='Event type - HeadBox'
      heading='What are you planning?'
      path={path}
      formRef={formRef}
      inputAttributes={typeAndAudienceAttrs}
      className='event-type-page'>
      <EventTypeFieldset
        eventTypeAttrs={eventTypeAttrs}
        setEventTypeAttrs={setEventTypeAttrs}
        setFetchingInParent={setFetchingEventTypes}
        setEventTypesInParent={setEventTypes}
        onClick={submitOnEventTypeClick}
      />
      <AudienceInput
        hide={hideAudienceInput}
        audience={audience}
        setAudience={setAudience}
        onClick={defaultSubmitOnClick}
        fieldsetRef={fieldsetRef}
      />
    </FormPage>
  )
}

export default EventTypeInput
