import useLocale from '../../../../Hooks/Locale'
import { PartialBrief } from '../../../../Types/Brief'
import { dateToReadableString, areSameDay } from '../../../FormSteps/DateTime/Utilities/dateStrings'
import { formatTimeOption } from '../../../FormSteps/DateTime/Utilities/formatTimeOption'
import { getBriefDates } from '../../../FormSteps/DateTime/Utilities/getBriefDates'

interface TimingsProps {
  brief: PartialBrief
}

export const Timings = ({ brief }: TimingsProps): JSX.Element => {
  const dates = getBriefDates(brief)
  const locale = useLocale()
  const timeFormat = locale === 'GB' ? '24-hour' : '12-hour'
  const [_startDate, _endDate] = dates
  const startDate = _startDate && dateToReadableString(_startDate)
  const endDate = _endDate && dateToReadableString(_endDate)
  const startTime = brief.fromTime && formatTimeOption(brief.fromTime, timeFormat)
  const endTime = brief.toTime && formatTimeOption(brief.toTime, timeFormat)

  return areSameDay(dates) ? (
    <>
      {startDate}, {startTime} - {endTime}
    </>
  ) : (
    <>
      {startDate} at {startTime} - {endDate} at {endTime}
    </>
  )
}

export default Timings
