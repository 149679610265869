// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root input[type='radio'].hb-radio {
  top: 0.15em;
}
app-root input[type='radio'].hb-radio:focus {
  outline-style: solid;
  outline-width: 2px;
  outline-offset: 2px;
}
app-root input[type='radio'].hb-radio:checked + label {
  font-weight: 600;
}
app-root input[type='radio'].hb-radio::before {
  box-shadow: inset 1em 1em var(--primary);
}
app-root input[type='radio'].hb-radio + label {
  font-weight: 500;
  color: var(--border-grey);
}
`, "",{"version":3,"sources":["webpack://./src/Components/Form/Inputs/Radio/Input/RadioInput.less"],"names":[],"mappings":"AAAA;EAEI,WAAA;AAAJ;AAEI;EACE,oBAAA;EACA,kBAAA;EACA,mBAAA;AAAN;AAEI;EACE,gBAAA;AAAN;AAII;EACE,wCAAA;AAFN;AAbA;EAmBM,gBAAA;EACA,yBAAA;AAHN","sourcesContent":["app-root {\n  input[type='radio'].hb-radio {\n    top: 0.15em;\n\n    &:focus {\n      outline-style: solid;\n      outline-width: 2px;\n      outline-offset: 2px;\n    }\n    &:checked + label {\n      font-weight: 600;\n    }\n\n\n    &::before {\n      box-shadow: inset 1em 1em var(--primary);\n    }\n\n    + label {\n      font-weight: 500;\n      color: var(--border-grey);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
