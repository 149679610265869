import { InputHTMLAttributes, ReactNode } from "react";
import './MultiButton.less'

interface MultiButtonProps extends Omit<InputHTMLAttributes<HTMLInputElement> , 'type'> {
  id: string
  type: 'radio' | 'checkbox'
  label: ReactNode
}

const MultiButton = ({id, label, type, ...props}: MultiButtonProps) => (
  <span className={`${type}-button wrapper`}>
    <input
      id={id}
      type={type}
      {...props}
      />
    <label htmlFor={id} className='button-contents'>
      {label}
    </label>
  </span>
)

export default MultiButton
