import { PartialBrief } from '../../../../../Types/Brief'
import { InputMaybe } from '../../../../../Types/generated-types'
import convertRadiusToKilometres from '../../../../../Utilities/Location/Radius/convertRadiusToKilometres'
import { dateToMachineString } from '../../../../../Views/FormSteps/DateTime/Utilities/dateStrings'
import { CreateEventInput } from '../../submitBizPlatformBrief'

const getIsoDateTime = ({
  date,
  time,
}: {
  date: InputMaybe<string> | undefined
  time: InputMaybe<string> | undefined
}): string => `${date}T${time}`

const getSubmissionParams = (brief: PartialBrief): CreateEventInput => {
  const {
    budget,
    capacities,
    currency,
    lat,
    lon,
    location,
    country,
    radius,
    guest: { id: userId, company } = {},
    fromDate,
    toDate,
    fromTime,
    toTime,
    ...optionalAttrs
  } = brief

  if (
    budget !== undefined &&
    capacities !== undefined &&
    lat !== undefined &&
    lon !== undefined &&
    !!radius &&
    location !== undefined &&
    currency !== undefined &&
    country != undefined &&
    fromDate != undefined &&
    toDate != undefined &&
    fromTime != undefined &&
    toTime != undefined &&
    userId !== undefined &&
    !!company
  ) {
    const zonelessStartDate = dateToMachineString(new Date(fromDate))
    const zonelessEndDate = dateToMachineString(new Date(toDate))
    const includeFlexibilityNote = optionalAttrs.flexibleDates

    return {
      budget,
      lat: lat.toString(),
      lon: lon.toString(),
      radius: convertRadiusToKilometres(radius).distance.toString(),
      flexibleDatesNote: includeFlexibilityNote ? optionalAttrs.flexibleDatesNote ?? '' : '',
      location,
      country,
      guestID: userId,
      eventTypeID: Number(brief.collection),
      attendees: capacities,
      layoutID: optionalAttrs.layouts?.toString() || '',
      isFlexibleWithDates: !!optionalAttrs.flexibleDates,
      fromDateTime: getIsoDateTime({ date: zonelessStartDate, time: fromTime }),
      toDateTime: getIsoDateTime({ date: zonelessEndDate, time: toTime }),
      spaceFacilityIDs: optionalAttrs.spaceFacilityIds || [],
      cateringNeeds: optionalAttrs.cateringNeeds?.toString(),
      currency,
      name: optionalAttrs.eventName || '',
      additionalNotes: optionalAttrs.additionalNotes ?? undefined,
      guestCompany: company,
      automatedInvitationsQualified: false,
      automatedInvitationsBypassed: false,
      styleIDs: optionalAttrs.styleIds || [],
    }
  }

  throw new Error('Biz Brief missing required attrs for submission')
}

export default getSubmissionParams
