import JourneyIcon from './JourneyIcon'
import { SUSTAINABILITY_LINK, BUSINESS_YOUR_EVENTS_LINK } from '../../../../../Constants'

type JourneysBannerProps = {
  eventUUID: string
}

const ANCHOR_ROOT: React.CSSProperties = {
  display: 'block',
  textDecoration: 'none',
  lineHeight: '36px',
  fontSize: '14px',
  fontWeight: 600,
}

const JourneysBanner = ({ eventUUID }: JourneysBannerProps): JSX.Element => {
  return (
    <article
      style={{
        background: '#FFFFFF',
        border: '1px solid #4F987B',
        borderRadius: '20px',
        marginTop: '24px',
        paddingTop: '18px',
        paddingLeft: '18px',
        paddingRight: '18px',
        textAlign: 'start',
        display: 'flex',
        boxShadow: '0px 0px 10px 5px #E6E6E6',
      }}>
      <div>
        <h2 style={{ fontSize: 'unset', fontWeight: 600, color: '#0E4433' }}>
          Need some help making your event as sustainable as possible?
        </h2>
        <p style={{ color: '#0E4433', fontWeight: 400 }}>
          Travel is the biggest source of carbon emissions for an in-person event. Add travel
          details for your guests to find the most sustainable venue options
        </p>
        <ul style={{ listStyle: 'none', marginTop: '18px', marginBottom: '18px' }}>
          <li style={{ display: 'inline-block', marginRight: '16px' }}>
            <a
              href={`${SUSTAINABILITY_LINK}/${eventUUID}/journeys`}
              style={{
                ...ANCHOR_ROOT,
                color: '#FFFFFF',
                background: '#187759',
                borderRadius: '4px',
                paddingLeft: '16px',
                paddingRight: '16px',
              }}>
              Add Travel Information
            </a>
          </li>
          <li
            style={{
              display: 'inline-block',
            }}>
            <a
              href={BUSINESS_YOUR_EVENTS_LINK}
              style={{
                ...ANCHOR_ROOT,
                color: '#187759',
              }}>
              Back To HeadBox
            </a>
          </li>
        </ul>
      </div>
      <div style={{ display: 'inline-flex' }}>
        <JourneyIcon />
      </div>
    </article>
  )
}

export default JourneysBanner
