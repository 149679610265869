import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { FormPath } from '../../../../../Router/Routes/formRoutes'

interface EditLinkProps {
  path: FormPath
  sectionLabel: ReactNode
  elementID?: string // optional HTML element ID portion for edit URL
}

const getEditPath = (path: FormPath, elementId?: string): string =>
  `/${path}?return-to-summary=true${elementId ? `#${elementId}` : ''}`

const EditLink = ({ path, elementID, sectionLabel }: EditLinkProps) => (
  <Link to={getEditPath(path, elementID)} className='edit-link'>
    Edit
    <span className='visually-hidden'> {sectionLabel}</span>
  </Link>
)

export default EditLink
