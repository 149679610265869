import { ReactNode, useEffect, useState } from 'react'
import LoadingSpinner from '../../Components/LoadingSpinner'
import useBrief from '../../Hooks/Brief/useBrief'
import { showBizLayout as shouldShowBizLayout } from '../../Logic/Form/Sequences/Biz/isBizBrief'
import BriefContext from './briefContext'
import './BriefProvider.less'
import LayoutContext, { PageLayout } from '../PageLayout/layoutContext'

interface BriefProviderProps {
  children: ReactNode
}

const BriefProvider = ({ children }: BriefProviderProps): JSX.Element => {
  const briefState = useBrief()
  const [briefStateLoaded, setBriefStateLoaded] = useState(false)
  const [currentLayout, setLayout] = useState<PageLayout>('biz')
  const pageLayoutContext = {
    currentLayout,
    setLayout,
  }

  useEffect(() => {
    if (briefState.loading || briefStateLoaded) return

    setLayout(shouldShowBizLayout(briefState.brief) ? 'biz' : 'lf')
    setBriefStateLoaded(true)
  }, [briefState.loading])

  // Only the initial brief load will trigger a loading state in this provider
  const hideChildren = briefState.loading && !briefStateLoaded

  return (
    <LayoutContext.Provider value={pageLayoutContext}>
      <BriefContext.Provider value={briefState}>
        {hideChildren && <LoadingSpinner fullPage={true} />}

        <div
          className={`brief-builder-provider
          ${currentLayout === 'biz' ? 'biz-flow' : 'lf-flow'}
          ${hideChildren ? 'hidden' : 'loaded'}`}>
          {children}
        </div>
      </BriefContext.Provider>
    </LayoutContext.Provider>
  )
}
export default BriefProvider
