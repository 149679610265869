import { Link } from 'react-router-dom'
import ErrorBanner from '../../Components/ErrorBanner'
import LinkButton from '../../Components/UI/LinkButton'
import MetaTitle from '../../Components/UI/MetaTitle'
import { BASE_NAME } from '../../Router/Router'
import { removeStoredBrief } from '../../Utilities/Storage/brief'
import './UnknownError.less'

const ResetLinkButton = (): JSX.Element => {
  const onClick = (): void => {
    removeStoredBrief()
    location.replace(BASE_NAME)
  }

  return <LinkButton onClick={onClick}>Reset the form</LinkButton>
}

const UnknownError = (): JSX.Element => (
  <main className='error-page'>
    <MetaTitle title='Unknown Error - HeadBox' />
    <ErrorBanner level='error' title='Unknown Error'>
      Sorry, something has gone wrong. Please try again or{' '}
      <a href='mailto:support@headbox.com'>contact support</a> if the problem persists.{' '}
      <menu role='list'>
        <li>
          <Link to='/'>Return to start</Link>
        </li>
        <li>—</li>
        <li>
          <ResetLinkButton />
        </li>
      </menu>
    </ErrorBanner>
  </main>
)

export default UnknownError
