interface QuoteProps {
  text?: string | null
}

export const Quote = ({ text }: QuoteProps): JSX.Element => {
  const trimmedText = text?.trim()

  return trimmedText ? <>&quot;{trimmedText}&quot;</> : <>None</>
}

export default Quote
