import { useEffect } from 'react'
import useBriefContext from '../../../Hooks/Brief/useBriefContext'
import isBizBrief from '../../../Logic/Form/Sequences/Biz/isBizBrief'
import throwGQLError from '../../../Utilities/Urql/Errors/throwGQLError'
import { useGlobalAuth } from '@headbox-ui/auth-hook'
import { AccountType, UserResponseDto } from '@headbox-ui/auth-hook/dist/useGlobalAuth'
import getUserDetails from './getUserDetails'
import getRegisteredUserAttributes from './getUserDetails/getRegisteredUserAttributes'

interface UseRegisterUserArgs {
  skip: boolean
  controlContext?: boolean
}
interface UseRegisterUser {
  registering: boolean
  registered: boolean
  user?: UserResponseDto
}

const useRegisterUser = ({ skip }: UseRegisterUserArgs): UseRegisterUser => {
  const { brief, dispatchBrief } = useBriefContext()
  const { register, user, loading: registering, error } = useGlobalAuth()

  const registered = !skip && !registering && user !== undefined

  useEffect(() => {
    if (skip || registered || registering) return

    // Block the Brief Provider from using its own loading state and refiring child CSS transitions

    const isBizUser = isBizBrief(brief)
    const password = brief.guest?.password
    const userDetails = getUserDetails(brief)
    register(
      isBizUser ? AccountType.BUSINESS : AccountType.STANDARD,
      userDetails,
      isBizUser ? password : undefined
    )
  }, [skip])

  useEffect(() => {
    if (error === undefined) return

    throwGQLError(error)
  }, [error])

  useEffect(() => {
    if (!user || skip) return

    dispatchBrief({
      updateAttributes: { guest: getRegisteredUserAttributes(user) },
    })
  }, [user, skip])

  return {
    registering,
    registered,
    user,
  }
}

export default useRegisterUser
