import { HEADBOX_ROOT_SERVICE } from '../../../../../Constants'
import InputView from '../../../../FormSteps/InputView'
import RegistrationFormPage from '../Base/RegistrationFormPage'

const RegistrationPage: InputView = ({ path }) => (
  <RegistrationFormPage
    path={path}
    requirePassword={true}
    heading='Create your account'
    metaTitle='New HeadBox Business user - HeadBox'
    marketingCopy={
      <>
        <h4>Stay in the loop</h4>
        <p>
          Get the newest and best venues, exclusive HeadBox Connect event invitations, product
          developments and industry news delivered straight to your inbox. You can unsubscribe at
          any time.
        </p>
        <p className='terms'>
          HeadBox takes the security and privacy of personal data seriously. All data will be
          handled in accordance with our data{' '}
          <a href={`${HEADBOX_ROOT_SERVICE}/policies/privacy`} target='_blank' rel="noreferrer">
            privacy policy
          </a>
          . We will not share your data with any third party organisations unless it is strictly
          required to deliver our service to you.
        </p>
      </>
    }
  />
)

export default RegistrationPage
