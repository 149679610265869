import { useContext } from 'react'
import LocaleContext from '../../Contexts/Locale/localeContext'
import { ActiveLocale } from '../../Types/Location'

const useLocale = (): ActiveLocale => {
  const currentLocale = useContext(LocaleContext)

  if (!currentLocale) throw new Error('Locale is undefined')

  return currentLocale
}

export default useLocale
