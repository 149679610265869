import _ErrorBanner from '../../../../../Components/ErrorBanner'

const ErrorBanner = (): JSX.Element => (
  <_ErrorBanner level='error'>
    Unfortunately we weren’t able to load the venue address, but please continue your enquiry
    regardless. We’ve recorded your chosen venue, and they will receive your details directly after
    submission.
  </_ErrorBanner>
)

export default ErrorBanner
