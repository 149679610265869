import { PartialBrief } from '../../../../../../Types/Brief'

// Derives the brief attributes to be modified in order to transform a biz brief into a LF brief
const getTransformationAttrsFromBizBrief = (bizBrief: PartialBrief): PartialBrief => {
  const { flexibleDates, spaceFacilityIds } = bizBrief

  return {
    flexibleDates: undefined,
    spaceFacilityIds: undefined,
    isFlexibleOnDate: flexibleDates,
    isFlexibleOnTime: flexibleDates,
    optionalSpaceFacilityIds: spaceFacilityIds,
  }
}

export default getTransformationAttrsFromBizBrief
