// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root .MuiAutocomplete-root.location-search-box * {
  font-family: Montserrat;
}
app-root .MuiAutocomplete-root.location-search-box .MuiOutlinedInput-root {
  font-weight: 500;
}
app-root .MuiAutocomplete-root.location-search-box .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 7px;
}
app-root .MuiAutocomplete-root.location-search-box .Mui-error {
  font-weight: 500;
}
app-root .MuiAutocomplete-root.location-search-box .Mui-focused.MuiFormLabel-root {
  background-color: var(--background-grey);
  color: var(--hover-grey);
}
app-root .MuiAutocomplete-root.location-search-box .Mui-focused .MuiOutlinedInput-notchedOutline {
  border: var(--hover-grey) solid 2px;
}
app-root .MuiAutocomplete-root.location-search-box .Mui-focused .MuiOutlinedInput-notchedOutline legend {
  visibility: unset;
  background-color: var(--background-grey);
}
.MuiAutocomplete-popper * {
  font-family: Montserrat;
}
`, "",{"version":3,"sources":["webpack://./src/Views/FormSteps/Location/Base/SearchBox/SearchBox.less"],"names":[],"mappings":"AAAA;EAEQ,uBAAA;AAAR;AAFA;EAIM,gBAAA;AACN;AAAM;EACE,gBAAA;EACA,mBAAA;EACA,iBAAA;AAER;AAVA;EAYiB,gBAAA;AACjB;AAEM;EACE,wCAAA;EACA,wBAAA;AAAR;AAjBA;EAsBQ,mCAAA;AAFR;AApBA;EAwBU,iBAAA;EACA,wCAAA;AADV;AAOA;EACE,uBAAA;AALF","sourcesContent":["app-root {\n  .MuiAutocomplete-root.location-search-box {\n    * { font-family: Montserrat; }\n    .MuiOutlinedInput-root {\n      font-weight: 500;\n      &.MuiInputBase-sizeSmall {\n        padding-top: 7px;\n        padding-bottom: 7px;\n        padding-left: 7px;\n      }\n    }\n\n    .Mui-error { font-weight: 500; }\n\n    .Mui-focused {\n      &.MuiFormLabel-root{\n        background-color: var(--background-grey);\n        color: var(--hover-grey);\n\n      }\n\n      .MuiOutlinedInput-notchedOutline {\n        border: var(--hover-grey) solid 2px;\n        legend {\n          visibility: unset;\n          background-color: var(--background-grey);\n        }\n      }\n    }\n  }\n}\n.MuiAutocomplete-popper * {\n  font-family: Montserrat;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
