import Alert, { AlertProps } from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import { ReactNode } from 'react'
import './ErrorBanner.less'

interface ErrorBannerProps {
  level: AlertProps['severity']
  children: ReactNode
  title?: ReactNode
}

const ErrorBanner = ({ children, level, title }: ErrorBannerProps): JSX.Element => (
  <Alert severity={level} className='error-banner'>
    {title && <AlertTitle>{title}</AlertTitle>}
    {children}
  </Alert>
)

export default ErrorBanner
