import GetFormSequence from '.'
import { BIZ_ONLY_PATHS, FormPath } from '../../../../Router/Routes/formRoutes'
import { PartialBrief } from '../../../../Types/Brief'
import isBizBrief from '../Biz/isBizBrief'
import BIZ_FORM_SEQUENCE from '../Biz/sequence'
import { FormSequence } from '../formSequence'
import LEAD_FEED_FORM_SEQUENCE from '../LeadFeed/sequence'

interface FilterSkippableSteps {
  filteredSequence: FormSequence
  filteredPaths: FormPath[]
}
const filterSkippableSteps = (
  sequence: FormSequence,
  brief: PartialBrief
): FilterSkippableSteps => {
  const filteredPaths: FormPath[] = []
  const filteredSequence = sequence.filter(({ skipStep, path }) => {
    if (!skipStep) return true

    const shouldSkip = skipStep(brief)
    if (shouldSkip) filteredPaths.push(path)

    return !shouldSkip
  })

  return {
    filteredSequence,
    filteredPaths,
  }
}

interface GetFormSequence {
  sequence: FormSequence
  name: string
  skippedPaths: FormPath[]
}

export const getBizFormSequence = (brief: PartialBrief): GetFormSequence => {
  const { filteredSequence, filteredPaths } = filterSkippableSteps(BIZ_FORM_SEQUENCE, brief)
  return {
    sequence: filteredSequence,
    skippedPaths: filteredPaths,
    name: 'biz',
  }
}

const getFormSequence = (brief: PartialBrief): GetFormSequence => {
  if (isBizBrief(brief)) {
    return getBizFormSequence(brief)
  }

  const { filteredSequence, filteredPaths } = filterSkippableSteps(LEAD_FEED_FORM_SEQUENCE, brief)
  return {
    sequence: filteredSequence,
    skippedPaths: [...filteredPaths, ...BIZ_ONLY_PATHS],
    name: 'lead feed',
  }
}

export default getFormSequence
