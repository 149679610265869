import { DateRange } from './getBriefDates'

// All Date operations assume the Date objects have the local timezone (the default Date behaviour)

const getOrdinalDay = (dayOfMonth: string): string => {
  const d = parseInt(dayOfMonth, 10)

  if (d > 3 && d < 21) return `${d}th`

  switch (d % 10) {
    case 1:
      return `${d}st`
    case 2:
      return `${d}nd`
    case 3:
      return `${d}rd`
    default:
      return `${d}th`
  }
}

const padDateNumber = (dateNum: number): string =>
  dateNum < 10 ? `0${dateNum}` : dateNum.toString()

export const dateToMachineString = (date: Date): string => {
  const month = date.getMonth() + 1
  const day = date.getDate()

  return `${date.getFullYear()}-${padDateNumber(month)}-${padDateNumber(day)}`
}

export const machineStringToDate = (simpleDate: string): Date => {
  const [_date, year, month, day] = simpleDate.match(/(\d{4})-(\d{2})-(\d{2})/) ?? []

  if (year == undefined || month == undefined || day == undefined)
    throw new Error(`Date match of form [year, month, day] returned ${[year, month, day]}`)
  // Args as follows: new Date(year, monthIndex, day, hours, minutes, seconds, milliseconds)
  return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10), 0, 0, 0, 0)
}

// returns the Date.prototype.toDateString() format but with ordinalised days of the month
export const dateToReadableString = (date: Date): string => {
  const dateMatch = /(\w{3} \w{3} )(\d+)( \d{4})/

  return date
    .toDateString()
    .replace(dateMatch, (_date, group1, group2, group3) => group1 + getOrdinalDay(group2) + group3)
}

export const areSameDay = (dates: DateRange): boolean => {
  const [startDate, endDate] = dates
  if (!startDate || !endDate) return false

  return dateToMachineString(startDate) === dateToMachineString(endDate)
}

export const getNextDayDate = (currentDate: Date): Date => {
  const newDate = new Date(currentDate)
  newDate.setDate(currentDate.getDate() + 1) // automatically handles days that exceed month total

  return newDate
}
