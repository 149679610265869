import { isCurrency } from '../../../../Constants/currencies'
import { PartialBrief } from '../../../../Types/Brief'

const permittedBriefQueryParams = [
  'spaceId',
  'hostId',
  'lat',
  'lon',
  'currency',
  'location',
  'collection',
] as const
type PermittedBriefQueryParams = typeof permittedBriefQueryParams[number]
export type BriefQueryAttributes = Pick<PartialBrief, PermittedBriefQueryParams>

const getPermittedBriefParams = (params: URLSearchParams): BriefQueryAttributes => {
  const lat = params.get('lat')
  const lon = params.get('lon')
  const location = params.get('location')
  const collection = params.get('collection-id')
  const spaceId = params.get('spaceId') // legacy param name from RedBox
  const hostId = params.get('hostId') // legacy param name from RedBox
  const currency = params.get('currency')
  return {
    ...(spaceId ? { spaceId } : {}),
    ...(hostId ? { hostId } : {}),
    ...(location ? { location } : {}),
    ...(lat ? { lat: parseFloat(lat) } : {}),
    ...(lon ? { lon: parseFloat(lon) } : {}),
    ...(isCurrency(currency) ? { currency } : {}),
    ...(collection ? { collection, collectionName: undefined } : {}),
  }
}

export default getPermittedBriefParams
