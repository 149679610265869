// AUTO-GENERATED CODE, DO NOT EDIT!
/* eslint-disable */

import * as Types from '../../../Types/generated-types'

import gql from 'graphql-tag'
import * as Urql from 'urql'
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export type SubmitBriefMutationVariables = Types.Exact<{
  params: Types.BriefAttributes
}>

export type SubmitBriefMutation = {
  __typename?: 'Mutation'
  brief?: { __typename?: 'Brief'; id: string; messageLimit?: number | null } | null
}

export const SubmitBriefDocument = gql`
  mutation SubmitBrief($params: BriefAttributes!) {
    brief(params: $params) {
      id
      messageLimit
    }
  }
`

export function useSubmitBriefMutation() {
  return Urql.useMutation<SubmitBriefMutation, SubmitBriefMutationVariables>(SubmitBriefDocument)
}
