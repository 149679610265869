import { useState, ChangeEventHandler, FormEventHandler } from 'react'
import { RadioButton, TextArea } from '../../../Components/Form/Inputs'
import getValidationMessage from '../../../Components/Form/Inputs/Generic/Multi/Validation/getValidationMessage'
import ValidationMessage from '../../../Components/Form/Inputs/ValidationMessage/ValidationMessage'
import FormPage from '../../../Components/Form/Page/FormPage'
import useBriefContext from '../../../Hooks/Brief/useBriefContext'
import useSubmitOnClick from '../../../Hooks/Form/useSubmitOnClick'
import InputView from '../InputView'
import './FlexibleTimingsInput.less'

const FlexibleTimingsInput: InputView = ({ path }): JSX.Element => {
  const { brief } = useBriefContext()
  const [flexibleDates, setFlexibleDates] = useState<boolean | ''>(brief.flexibleDates ?? '')
  const [flexibleDatesNote, setFlexibleDatesNote] = useState(brief.flexibleDatesNote ?? '')
  const [invalidMessage, setInvalidMessage] = useState<string | null>(null)
  const { submitOnClick, formRef } = useSubmitOnClick()

  const inputAttributes = {
    flexibleDates: flexibleDates === '' ? undefined : flexibleDates,
    flexibleDatesNote,
  }
  const onRadioChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setFlexibleDates(target.value === '1')
    setInvalidMessage(null)
  }
  const onRadioInvalid: FormEventHandler<HTMLInputElement> = (event) => {
    setInvalidMessage(getValidationMessage(event.currentTarget))
    event.preventDefault()
  }

  const onTextAreaChange: ChangeEventHandler<HTMLTextAreaElement> = ({ target }) => {
    setFlexibleDatesNote(target.value)
  }

  return (
    <FormPage
      metaTitle='Date flexibility - HeadBox'
      heading='Are you flexible on date or time?'
      path={path}
      formRef={formRef}
      inputAttributes={inputAttributes}
      className='date-flexibility-page'>
      <ValidationMessage id='flexibility-error' invalidMessage={invalidMessage}>
        <ul role='list' className='flexibility-options'>
          <li>
            <RadioButton
              id='flexible-input'
              value={1}
              name='date-flexibility'
              required
              onChange={onRadioChange}
              onInvalid={onRadioInvalid}
              aria-invalid={!!invalidMessage}
              aria-errormessage={invalidMessage ? 'flexibility-error' : undefined}
              checked={flexibleDates === true}
              label={
                <>
                  Yes<span aria-hidden='true'> 👍</span>
                  <span className='visually-hidden'>{', '}I am flexible on dates</span>
                </>
              }
            />
          </li>
          <li>
            <RadioButton
              id='not-flexible-input'
              value={0}
              name='date-flexibility'
              required
              onChange={onRadioChange}
              onInvalid={onRadioInvalid}
              onClick={submitOnClick}
              aria-invalid={!!invalidMessage}
              aria-errormessage={invalidMessage ? 'flexibility-error' : undefined}
              checked={flexibleDates === false}
              label={
                <>
                  No<span aria-hidden='true'> 👎</span>
                  <span className='visually-hidden'>{', '}I am not flexible on dates</span>
                </>
              }
            />
          </li>
        </ul>
      </ValidationMessage>
      {flexibleDates && (
        <section className='flexibility-details'>
          <h3>Tell us more</h3>
          <label htmlFor='flexible-dates-notes' className='visually-hidden'>
            Flexibility Notes
          </label>
          <TextArea
            id='flexible-dates-notes'
            rows={4}
            placeholder='Are you flexible on date, or time, or both? Let us know!'
            onChange={onTextAreaChange}
            value={flexibleDatesNote}
          />
        </section>
      )}
    </FormPage>
  )
}

export default FlexibleTimingsInput
