import Carousel from '../../../../../UI/Carousel/Carousel'
import { TickIconSVG } from './Icons'
import './Carousel.less'
import { Trans, useTranslation } from 'react-i18next'
import useLocale from '../../../../../../Hooks/Locale/'
import { ReactNode } from 'react'

interface StatisticsCarouselProps {
  currentIndex?: number
}

const CarouselStatistic = ({ children }: { children: ReactNode }): JSX.Element => (
  <p className='carousel-statistic'>
    <TickIconSVG aria-hidden='true' />
    {children}
  </p>
)

const StatisticsCarousel = ({ currentIndex }: StatisticsCarouselProps): JSX.Element => {
  const { t } = useTranslation('brandContent')
  const locale = useLocale()

  return (
    <Carousel
      currentIndex={currentIndex}
      className='statistics-carousel'
      aria-label='HeadBox by Numbers - Carousel'>
      <CarouselStatistic>
        <Trans
          i18nKey={'brandContent:statisticsCarousel.stat-default'}
          values={{ srOnlyText: t('statisticsCarousel.stat-default-sr-only') }}
          components={{ srOnly: <span className='visually-hidden' /> }}
        />
      </CarouselStatistic>

      {...locale === 'GB'
        ? [t('statisticsCarousel.stat-2'), t('statisticsCarousel.stat-3')].map((stat) => (
            <CarouselStatistic key={stat}>{stat}</CarouselStatistic>
          ))
        : []}
    </Carousel>
  )
}

export default StatisticsCarousel
