// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root section.statistics-carousel {
  margin-top: 16px;
  min-height: calc(2.8125rem + 16px);
  width: 100%;
  overflow: hidden;
}
app-root section.statistics-carousel footer {
  line-height: 1.5rem;
  margin-top: 16px;
}
app-root section.statistics-carousel .carousel-statistic {
  color: white;
  font-size: 0.875rem;
}
app-root section.statistics-carousel .carousel-statistic .tick-icon {
  width: 1em;
  height: auto;
  vertical-align: middle;
  margin-right: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Brand/Messaging/LeadFeed/Aside/Statistics/Carousel.less"],"names":[],"mappings":"AAAA;EAEI,gBAAA;EACA,kCAAA;EACA,WAAA;EACA,gBAAA;AAAJ;AALA;EAQM,mBAAA;EACA,gBAAA;AAAN;AATA;EAYM,YAAA;EACA,mBAAA;AAAN;AAbA;EAeQ,UAAA;EACA,YAAA;EACA,sBAAA;EACA,oBAAA;AACR","sourcesContent":["app-root {\n  section.statistics-carousel {\n    margin-top: 16px;\n    min-height: calc(2.8125rem + 16px);\n    width: 100%;\n    overflow: hidden;\n\n    footer {\n      line-height: 1.5rem;\n      margin-top: 16px;\n    }\n    .carousel-statistic {\n      color: white;\n      font-size: 0.875rem;\n      .tick-icon {\n        width: 1em;\n        height: auto;\n        vertical-align: middle;\n        margin-right: 0.5rem;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
