import isBizBrief from '../../../Logic/Form/Sequences/Biz/isBizBrief'
import { PartialBrief } from '../../../Types/Brief'
import { Radius } from '../../../Types/Location'
import { getLocationRadiusOverrides } from '../../../Types/Location/getLocationOverrides'

const getDefaultRadius = (brief: PartialBrief): Radius => {
  if (isBizBrief(brief)) return { distance: 5, unit: 'km' }
  const override = getLocationRadiusOverrides(brief.location)
  if(override) return override
  const {country} = brief
  
  if (country === 'US') return { distance: 1, unit: 'mi' }

  if (country === 'AU') return { distance: 4, unit: 'km' }

  return { distance: 2, unit: 'km' }
}

export default getDefaultRadius
