import ProcessCopy from './Copy/ProcessCopy'
import './ProcessOverview.less'

interface ProcessProps {
  className?: string
}

const ProcessOverview = ({ className }: ProcessProps): JSX.Element => (
  <ProcessCopy className={`left-aligned ${className}`} />
)

export default ProcessOverview
