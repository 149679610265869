// AUTO-GENERATED CODE, DO NOT EDIT!
/* eslint-disable */

import * as Types from '../../../../../Types/generated-types'

import gql from 'graphql-tag'
import * as Urql from 'urql'
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export type FacilitiesQueryVariables = Types.Exact<{
  id: Types.Scalars['String']
}>

export type FacilitiesQuery = {
  __typename?: 'Query'
  collection: {
    __typename?: 'Collection'
    id: string
    name: string
    features?: Array<{ __typename?: 'Feature'; id: string; name: string }> | null
  }
}

export const FacilitiesDocument = gql`
  query Facilities($id: String!) {
    collection(id: $id) {
      id
      name
      features {
        id
        name
      }
    }
  }
`

export function useFacilitiesQuery(
  options: Omit<Urql.UseQueryArgs<FacilitiesQueryVariables>, 'query'>
) {
  return Urql.useQuery<FacilitiesQuery, FacilitiesQueryVariables>({
    query: FacilitiesDocument,
    ...options,
  })
}
