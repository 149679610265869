import { useState, ChangeEventHandler, MouseEventHandler, FormEventHandler } from 'react'
import { RadioButton } from '../../../Components/Form/Inputs'
import getValidationMessage from '../../../Components/Form/Inputs/Generic/Multi/Validation/getValidationMessage'
import ValidationMessage from '../../../Components/Form/Inputs/ValidationMessage/ValidationMessage'
import FormPage from '../../../Components/Form/Page/FormPage'
import LoadingSpinner from '../../../Components/LoadingSpinner'
import useBriefContext from '../../../Hooks/Brief/useBriefContext'
import useSubmitOnClick from '../../../Hooks/Form/useSubmitOnClick'
import {
  BoardroomLayout,
  CabaretLayout,
  ClassroomLayout,
  NotSureLayout,
  StandingLayout,
  TheatreLayout,
  UShapedLayout,
} from '../../../Images/Layouts'
import throwGQLError from '../../../Utilities/Urql/Errors/throwGQLError'
import InputView from '../InputView'
import { useLayoutsQuery } from './LayoutInput.generated'
import './LayoutInput.less'

export const capitaliseLayout = (name: string) => {
  return name.charAt(0).toUpperCase() + name.slice(1)
}

const layoutIcons: Record<string, JSX.Element> = {
  'u-shaped': <UShapedLayout />,
  standing: <StandingLayout />,
  theatre: <TheatreLayout />,
  classroom: <ClassroomLayout />,
  boardroom: <BoardroomLayout />,
  cabaret: <CabaretLayout />,
  seating: <NotSureLayout />, // 'seating' is rendered as 'No preference'
}

interface SeatingLayoutsProps {
  layout?: string
  onInvalid: FormEventHandler<HTMLInputElement>
  setLayout: (layout: string) => void
  onLayoutClick: MouseEventHandler<HTMLInputElement>
}

const SeatingLayouts = ({
  layout,
  setLayout,
  onLayoutClick,
  onInvalid,
}: SeatingLayoutsProps): JSX.Element => {
  const { brief } = useBriefContext()

  const [{ fetching, error, data }] = useLayoutsQuery({
    pause: brief.collection === undefined,
    requestPolicy: 'cache-first',
    variables: { id: brief.collection ?? '' },
  })

  if (error) throwGQLError(error)

  const onChange: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }): void => {
    setLayout(value)
  }

  const { layouts } = data?.collection ?? { layouts: [] }

  // The 'collections' entity only returns a subset of relevant layouts for each event type
  const layoutsByEventType = [
    ...(layouts ?? []).filter((layout) => layout !== 'standing' && layout !== 'seating'),
    'seating',
  ]

  return (
    <fieldset
      aria-busy={fetching}
      aria-describedby='seating-layouts-loading'
      className='seating-layout-section'>
      {fetching ? (
        <LoadingSpinner progressId='seating-layouts-loading' />
      ) : (
        <>
          <legend>Please choose a seating layout</legend>
          <ul role='list' className='seating-layouts'>
            {layoutsByEventType.map((layoutName) => (
              <li key={layoutName}>
                <RadioButton
                  id={`seating-layout-${layoutName}`}
                  value={layoutName}
                  name='seating-layout'
                  checked={layout === layoutName}
                  onChange={onChange}
                  onClick={onLayoutClick}
                  onInvalid={onInvalid}
                  required
                  label={
                    <span className='seating-layout'>
                      <span className='text'>
                        {layoutName === 'seating'
                          ? 'No preference '
                          : `${capitaliseLayout(layoutName)} `}
                      </span>
                      {layoutIcons[layoutName]}
                    </span>
                  }
                />
              </li>
            ))}
          </ul>
        </>
      )}
    </fieldset>
  )
}

const LayoutInput: InputView = ({ path }): JSX.Element => {
  const { brief } = useBriefContext()
  const [layout, setLayout] = useState(brief.layouts ?? undefined)
  const { submitOnClick, formRef } = useSubmitOnClick()
  const [invalidMessage, setInvalidMessage] = useState<string | null>(null)

  const eventType = brief.collectionName
  const showGeneralLayouts = eventType !== 'Meeting/Workshop' && eventType !== 'Private Dining'
  const showSeatingOptions =
    !showGeneralLayouts || (layout !== undefined && layout !== 'standing' && layout !== 'not_sure')
  const onChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setLayout(target.value)
    setInvalidMessage(null)
  }
  const onInvalid: FormEventHandler<HTMLInputElement> = (event) => {
    setInvalidMessage(getValidationMessage(event.currentTarget, 'layout'))
    event.preventDefault()
  }

  const generalLayouts = [
    { value: 'not_sure', label: 'Not sure' },
    { value: 'standing', label: 'Standing' },
    { value: 'seating', label: 'Seating' },
  ]

  return (
    <FormPage
      path={path}
      metaTitle='Venue layout - HeadBox'
      heading='How would you like the space set up?'
      formRef={formRef}
      inputAttributes={{ layouts: layout }}
      className='layout-page'>
      <ValidationMessage id='general-layout-error' invalidMessage={invalidMessage}>
        <ul role='list' className='general-layouts'>
          {showGeneralLayouts &&
            generalLayouts.map(({ label, value }) => (
              <li key={value}>
                <RadioButton
                  id={`general-layout-${value}`}
                  name='layout'
                  required
                  checked={
                    (value === 'seating' &&
                      layout !== 'standing' &&
                      layout !== 'not_sure' &&
                      layout !== undefined) ||
                    layout === value
                  }
                  onChange={onChange}
                  onInvalid={onInvalid}
                  aria-invalid={!!invalidMessage}
                  aria-errormessage={invalidMessage ? 'general-layout-error' : undefined}
                  onClick={value === 'seating' ? undefined : submitOnClick}
                  label={label}
                  value={value}
                />
              </li>
            ))}
        </ul>
        {showSeatingOptions && (
          <SeatingLayouts
            onInvalid={onInvalid}
            layout={layout}
            setLayout={setLayout}
            onLayoutClick={submitOnClick}
          />
        )}
      </ValidationMessage>
    </FormPage>
  )
}

export default LayoutInput
