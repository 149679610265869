import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import './SuccessPage.less'
import '../../Styles/buttons.less'
import useBriefContext from '../../Hooks/Brief/useBriefContext'
import BusinessSuccessPage from './Business/SuccessPage'
import LeadFeedSuccessPage from './LeadFeed/LeadFeedSuccess'
import FallBackSuccessPage from './Business/FallbackSuccessPage'
import captureMessage from '../../Utilities/Errors/captureMessage'
import useLayout from '../../Hooks/Layout/Base/useLayout'

const SuccessPage = (): JSX.Element => {
  const { brief } = useBriefContext()
  const { setLayout } = useLayout()
  const [searchParams] = useSearchParams()
  const briefType = searchParams.get('type')
  const venueName = searchParams.get('venue-name')
  const businessEventUUID = searchParams.get('business-event-uuid')
  const messageLimitStr = searchParams.get('message-limit')
  const messageLimit = messageLimitStr ? parseInt(messageLimitStr, 10) : undefined
  const directEnquiry = briefType === 'enquiry'

  useEffect(() => {
    setLayout('biz') // hide LF messaging
  }, [])

  if (briefType === 'business') {
    if (businessEventUUID) return <BusinessSuccessPage businessEventUUID={businessEventUUID} />

    captureMessage('Missing event UUID for biz brief submission success page')
    return <FallBackSuccessPage />
  }

  return (
    <LeadFeedSuccessPage
      brief={brief}
      messageLimit={messageLimit}
      directEnquiry={directEnquiry}
      directEnquiryVenueName={venueName}
    />
  )
}

export default SuccessPage
