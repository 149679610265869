import JourneysBanner from './JourneysBanner'
import Page from '../../../../Components/Page'

type BusinessSuccessPageProps = {
  businessEventUUID: string
}

const BusinessSuccessPage = ({ businessEventUUID }: BusinessSuccessPageProps): JSX.Element => {
  return (
    <Page
      metaTitle='All done! - HeadBox'
      heading={<span style={{ color: '#1F1F1F' }}>Brilliant! Enquiry submitted.</span>}
      className='business-success-page fade-in'>
      <span style={{ fontWeight: 600, fontSize: '18px', lineHeight: '24px', color: '#434343' }}>
        💌&nbsp;
        {`Many thanks for submitting your event brief!
        A member of our Meetings and Events Team will be in touch shortly`}
        &nbsp;💌
      </span>
      <JourneysBanner eventUUID={businessEventUUID} /> 
    </Page>
  )
}

export default BusinessSuccessPage
