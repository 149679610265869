import { PartialBrief } from '../../../../../Types/Brief'
import BriefAttributeOrFallback from './BriefAttributeOrFallback'
import BRIEF_OVERRIDES_BY_EVENT_TYPE from './briefOverridesByEventType'
import isEventType from './eventType'

const getAttributeOverrideByEventType = <T extends keyof PartialBrief>(
  eventType: PartialBrief['collectionName'],
  attribute: T
): BriefAttributeOrFallback<T, undefined> => {
  if (!isEventType(eventType)) return undefined
  const overridenValue = BRIEF_OVERRIDES_BY_EVENT_TYPE[eventType][attribute]

  return overridenValue ?? undefined
}

export default getAttributeOverrideByEventType
