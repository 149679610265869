import { PartialBrief } from '../../../../Types/Brief'
import Layout from './Layout'

interface GuestsLayoutProps {
  brief: PartialBrief
}

export const GuestsLayout = ({ brief }: GuestsLayoutProps): JSX.Element => (
  <>
    {brief.capacities} guests
    <Layout brief={brief} />
  </>
)

export default GuestsLayout
