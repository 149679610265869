import { useGlobalAuth } from '@headbox-ui/auth-hook'
import { GraphQLError } from 'graphql'
import { ChangeEventHandler, useEffect, useRef, useState } from 'react'
import { CombinedError } from 'urql'
import { PasswordInput } from '../../../../../Components/Form/Inputs'
import FormPage from '../../../../../Components/Form/Page/FormPage'
import LoadingSpinner from '../../../../../Components/LoadingSpinner'
import { RESET_PASSWORD_URL } from '../../../../../Constants'
import useBriefContext from '../../../../../Hooks/Brief/useBriefContext'
import useSubmitBrief from '../../../../../Hooks/Brief/useSubmitBrief'
import getAdjacentSteps from '../../../../../Logic/Form/Authorization/getAdjacentSteps'
import throwGQLError from '../../../../../Utilities/Urql/Errors/throwGQLError'
import InputView from '../../../../FormSteps/InputView'
import './AuthenticationPage.less'

const includePasswordValidationError = ({ extensions }: GraphQLError): boolean =>
  'code' in extensions &&
  extensions.code === 'BAD_USER_INPUT' &&
  'argumentName' in extensions &&
  extensions.argumentName === 'password'

const AuthenticationPage: InputView = ({ path }) => {
  const [password, setPassword] = useState('')
  const [isInvalidPassword, setIsInvalidPassword] = useState(false)
  const [skipSubmit, setSkipSubmit] = useState<boolean>(true)
  const passwordRef = useRef<HTMLInputElement>(null)

  const { authenticated, login, loading: authenticating, error, user } = useGlobalAuth()
  const authError = error as CombinedError

  const { brief, dispatchBrief } = useBriefContext()
  const { loading: submittingBrief } = useSubmitBrief({ skip: skipSubmit })

  const { isFinalStep } = getAdjacentSteps(path, brief)

  useEffect(() => {
    if (!user || !skipSubmit) return

    dispatchBrief({
      updateAttributes: {
        guest: {
          company: user.companyName,
          country: user.country,
          newlyAuthenticated: true,
        },
      },
    })
    setSkipSubmit(false)
  }, [authenticated])

  useEffect(() => {
    if (!authError) return

    const containsValidationError = authError.graphQLErrors.some(includePasswordValidationError)

    if (containsValidationError) {
      setIsInvalidPassword(true)

      passwordRef.current?.setCustomValidity(
        'Sorry, that password was incorrect. Please try again.'
      )
      passwordRef.current?.reportValidity()
    } else {
      throwGQLError(authError)
    }
  }, [authError])

  const { guest } = brief
  const { email } = guest ?? {}

  if (!email) throw new Error('Email undefined at authentication step')

  const onContinue = async () => {
    await login({
      email,
      password: password,
    })
  }
  const onChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setPassword(target.value)
    passwordRef.current?.setCustomValidity('')

    if (isInvalidPassword) setIsInvalidPassword(false)
  }

  if (submittingBrief)
    return <LoadingSpinner fullPage={true} accessibleText='Submitting your enquiry...' />

  return (
    <FormPage
      path={path}
      metaTitle='Returning user - HeadBox'
      heading='Welcome Back'
      canContinue={!!password}
      onContinue={onContinue}
      afterContinue={
        isFinalStep
          ? () => {
              null
            }
          : undefined
      } // prevent navigation if final step
      submitText={'Submit'}
      className='biz-auth-page'>
      <strong className='email'>{brief.guest?.email}</strong>

      <label htmlFor='user-password'>Enter password</label>
      <PasswordInput
        id='user-password'
        autoComplete='current-password'
        value={password}
        onChange={onChange}
        disabled={authenticating}
        forwardRef={passwordRef}
        required
        aria-busy={authenticating}
      />
      <strong
        className={`loading-wrapper ${authenticating ? 'loading' : ''}`}
        aria-live='polite'
        tabIndex={authenticating ? 0 : -1}>
        {authenticating && <LoadingSpinner />}
      </strong>
      <a href={RESET_PASSWORD_URL} target='_blank' rel='noreferrer' className='reset-password'>
        Forgot password?
      </a>
    </FormPage>
  )
}

export default AuthenticationPage
