import isBizBrief from '../../../../Logic/Form/Sequences/Biz/isBizBrief'
import { PartialBrief } from '../../../../Types/Brief'
import pushTagEvent from '../../../../Utilities/GoogleTagManager/pushTagEvent'
import { removeStoredBrief, setStoredBrief } from '../../../../Utilities/Storage/brief'
import getTransformationAttrs from './getTransformationAttrs'

export interface BriefDispatch {
  updateAttributes?: PartialBrief
  overwrite?: PartialBrief // replaces the brief state - intended as a reset
}
interface HasGuestTypeChangedArgs {
  existingBrief: PartialBrief
  newBrief?: PartialBrief
}

const hasGuestTypeChanged = ({ existingBrief, newBrief }: HasGuestTypeChangedArgs): boolean => {
  if (newBrief?.guest?.isBusinessUser === undefined) return false // ignore guest type "resets"

  return isBizBrief(existingBrief) !== isBizBrief(newBrief)
}

interface ReduceUpdateAttributes {
  existingBrief: PartialBrief
  attributesToUpdate: PartialBrief
}

const reduceUpdateAttributes = ({
  existingBrief,
  attributesToUpdate,
}: ReduceUpdateAttributes): PartialBrief => {
  const { guest: newGuest, ...nonGuestAttrsToUpdate } = attributesToUpdate
  const { guest: existingGuest, ...nonGuestExistingAttrs } = existingBrief
  const newState = {
    ...nonGuestExistingAttrs,
    ...nonGuestAttrsToUpdate,
    guest: existingGuest || newGuest ? { ...existingGuest, ...newGuest } : undefined,
  }
  if (!hasGuestTypeChanged({ existingBrief, newBrief: newState })) return newState

  const briefTypeTransformationAttrs = getTransformationAttrs({ existingBrief, newBrief: newState })

  const newStateWithTypeTransformation = {
    ...newState,
    ...briefTypeTransformationAttrs,
  }

  // 'User Segmentation' Google Tag Manager event represents moments where the app decides
  // whether the user is a business or a leadfeed user.
  //
  pushTagEvent(newStateWithTypeTransformation, 'userSegmentation')

  return newStateWithTypeTransformation
}

const briefReducer = (briefState: PartialBrief, dispatchArgs: BriefDispatch): PartialBrief => {
  const { updateAttributes, overwrite } = dispatchArgs
  if (overwrite) {
    removeStoredBrief() // only subsequent state updates will be locally stored
    return overwrite
  }

  if (!updateAttributes) {
    throw new Error('Brief reducer must be called with attributes to update or a reset param')
  }

  const newBrief = reduceUpdateAttributes({
    existingBrief: briefState,
    attributesToUpdate: updateAttributes,
  })
  setStoredBrief(newBrief)

  return newBrief
}

export default briefReducer
