import { useEffect, useState } from 'react'
import useBriefContext from '../../../../Hooks/Brief/useBriefContext'
import InputView from '../../InputView'
import FormPage from '../../../../Components/Form/Page/FormPage'
import { getBriefDates } from '../Utilities/getBriefDates'
import TimePicker from '../TimePicker'
import DatePicker from '../DatePicker'
import TimingsCaption from '../TimingsCaption'
import CheckboxInput from '../../../../Components/Form/Inputs/Checkbox/Input'
import { TimeOption, TimeOptionInput } from '../TimePicker/TimeStamps/timeStamps'
import { getNextDayDate } from '../Utilities/dateStrings'
import FlexibilityNotes from './FlexibilityNotes'
import './DateTimeInput.less'
import './DateTimeInputAnimation.less'
import useLocale from '../../../../Hooks/Locale/useLocale'
import getDefaultAttributeByEventType from '../../EventType/Base/Logic/getAttributeDefaultByEventType'

// fromDate and toDate are captured as ISO8601 strings. These will be converted on brief submission
// fromTime and toTime are captured as timestamp strings of 'HH:mm:ss' (where ss is always 00)

const DateTimeInput: InputView = ({ path }): JSX.Element => {
  const { brief } = useBriefContext()
  const locale = useLocale()
  const timeFormat = locale === 'GB' ? '24-hour' : '12-hour'
  const [dates, setDates] = useState(getBriefDates(brief))
  const [startTime, setStartTime] = useState<TimeOptionInput>(
    brief.fromTime ?? getDefaultAttributeByEventType(brief.collectionName, 'fromTime')
  )
  const [endTime, setEndTime] = useState<TimeOptionInput>(
    brief.toTime ?? getDefaultAttributeByEventType(brief.collectionName, 'toTime')
  )
  const [isFlexibleOnDate, setIsFlexibleOnDate] = useState(brief.isFlexibleOnDate ?? false)
  const [isFlexibleOnTime, setIsFlexibleOnTime] = useState(brief.isFlexibleOnTime ?? false)
  const [flexibilityNotes, setFlexibilityNotes] = useState(brief.flexibleDatesNote ?? '')

  const endTimeUpperLimit: TimeOption = '04:30:00'
  const [startDate] = dates

  const timingsCaptionIsHidden = startDate === undefined || startTime === '' || endTime === ''
  const [delayNotes, setDelayNotes] = useState(timingsCaptionIsHidden)
  const delayClassName = (delayNotes && 'delay-animation') || ''

  useEffect(() => {
    if (timingsCaptionIsHidden && delayNotes) return

    if (timingsCaptionIsHidden) return setDelayNotes(true)

    const timeout = setTimeout(() => setDelayNotes(false), 2000)
    return () => clearTimeout(timeout)
  }, [timingsCaptionIsHidden])

  useEffect(() => {
    if (startDate === undefined) return setDates([startDate, startDate])

    if (endTime <= endTimeUpperLimit && endTime >= '00:00:00') {
      return setDates([startDate, getNextDayDate(startDate)])
    }

    setDates([startDate, startDate])
  }, [endTime, startDate])

  const allAttributes = {
    fromTime: startTime,
    toTime: endTime,
    fromDate: dates[0]?.toISOString(),
    toDate: dates[1]?.toISOString(),
    isFlexibleOnDate,
    isFlexibleOnTime,
    flexibleDatesNote: flexibilityNotes,
  }

  return (
    <FormPage
      metaTitle='Dates & times - HeadBox'
      heading='When is your event?'
      path={path}
      inputAttributes={allAttributes}
      className='date-time-page'>
      <DatePicker
        dates={dates}
        setDates={setDates}
        label={
          <label className='date-picker' aria-hidden='true'>
            Date
          </label>
        }
      />

      <CheckboxInput
        id='flexible-on-dates-input'
        checked={isFlexibleOnDate}
        onChange={({ target }) => setIsFlexibleOnDate(target.checked)}
        label='I’m flexible on date 🗓'
        className='flexibility'
      />

      <div className='timings'>
        <label className='time-picker' htmlFor='start-time-input'>
          Start time
          <TimePicker
            id='start-time-input'
            value={startTime}
            setValue={setStartTime}
            rangeStart={'05:00:00'}
            rangeEnd={'23:30:00'}
            format={timeFormat}
            required
          />
        </label>

        <label className='time-picker' htmlFor='end-time-input'>
          End time
          <TimePicker
            id='end-time-input'
            value={endTime}
            setValue={setEndTime}
            baseValue={startTime || undefined}
            rangeStart={'05:30:00'}
            rangeEnd={endTimeUpperLimit}
            format={timeFormat}
            required
          />
        </label>
      </div>

      <CheckboxInput
        id='flexible-on-time-input'
        checked={isFlexibleOnTime}
        onChange={({ target }) => setIsFlexibleOnTime(target.checked)}
        label='I’m flexible on time ⏱'
        className='flexibility'
      />

      <TimingsCaption
        dates={dates}
        startTime={startTime}
        endTime={endTime}
        className={delayClassName}
      />

      <FlexibilityNotes
        isFlexibleOnDate={isFlexibleOnDate}
        isFlexibleOnTime={isFlexibleOnTime}
        value={flexibilityNotes}
        hide={timingsCaptionIsHidden}
        className={delayClassName}
        onFlexibilityNotesChange={({ target }) => setFlexibilityNotes(target.value)}
      />
    </FormPage>
  )
}

export default DateTimeInput
