import { InputHTMLAttributes, ReactNode } from "react";
import MultiInput from "../../Generic/Multi/MultiInput";
import './RadioInput.less'

interface RadioInputProps extends Omit<InputHTMLAttributes<HTMLInputElement> , 'type'> {
  id: string
  label: ReactNode
}

const RadioInput = ( {label, ...props}: RadioInputProps) => (
  <MultiInput
    type={'radio'}
    label={label}
    {...props}
    />
)

export default RadioInput
