import InputView from '../../InputView'
import EmailInput from '../Base/EmailInput'
import './EmailInput.less'
import './EmailInputAnimation.less'

const LFEmailInput: InputView = ({ path }): JSX.Element => {
  return (
    <EmailInput
      path={path}
      metaTitle='Email address - HeadBox'
      heading="What's your email address?"
      emailCaption={<p className='email-caption'>Just checking if you&#39;ve been here before.</p>}
    />
  )
}

export default LFEmailInput
