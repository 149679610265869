import { PartialBrief } from '../../../../../../Types/Brief'

type GetDefaultFlexibilityNoteArgs = Pick<
  PartialBrief,
  'isFlexibleOnDate' | 'isFlexibleOnTime' | 'flexibleDatesNote'
>

// Attempt to preserve the information loss from having two flexibility options in LF briefs
const getDefaultDateFlexibilityNote = ({
  isFlexibleOnDate,
  isFlexibleOnTime,
  flexibleDatesNote,
}: GetDefaultFlexibilityNoteArgs): string | undefined => {
  if (flexibleDatesNote?.trim()) return flexibleDatesNote // always preserve non-blank notes

  if (isFlexibleOnDate && !isFlexibleOnTime) return 'Flexible on date only'

  if (isFlexibleOnTime && !isFlexibleOnDate) return 'Flexible on time only'

  return undefined
}

const superset = (set1?: number[] | null, set2?: number[] | null): number[] => {
  if (set1 && set2) return set1.filter((mergedValue) => !set2.includes(mergedValue)).concat(set2)

  if (set1) return set1

  return set2 ?? []
}

// Derives the brief attributes to be modified in order to transform a LF brief into a biz brief
const getTransformationAttrsFromLFBrief = (lfBrief: PartialBrief): PartialBrief => {
  const {
    isFlexibleOnDate,
    isFlexibleOnTime,
    flexibleDatesNote,
    requiredSpaceFacilityIds,
    optionalSpaceFacilityIds,
  } = lfBrief

  return {
    flexibleDates: isFlexibleOnDate || isFlexibleOnTime,
    flexibleDatesNote: getDefaultDateFlexibilityNote({
      isFlexibleOnDate,
      isFlexibleOnTime,
      flexibleDatesNote,
    }),
    spaceFacilityIds: superset(requiredSpaceFacilityIds, optionalSpaceFacilityIds),
    requiredSpaceFacilityIds: undefined,
    optionalSpaceFacilityIds: undefined,
    isFlexibleOnDate: undefined,
    isFlexibleOnTime: undefined,
  }
}

export default getTransformationAttrsFromLFBrief
