import {
  DetailedHTMLProps,
  FormEventHandler,
  FormHTMLAttributes,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useRef,
} from 'react'

type FormProps = DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>

export type BriefFormRef = {
  submit: () => void
} | null

const _Form: ForwardRefRenderFunction<BriefFormRef, FormProps> = ({ children, ...props }, ref) => {
  const submitButtonRef = useRef<HTMLInputElement>(null)
  // Simulating a submit button click is still the only widely supported way to submit a form
  // AND validate the nested inputs.
  useImperativeHandle(ref, () => ({
    submit: () => submitButtonRef?.current?.click(),
  }))

  // Replicate native HTML behaviour of focusing first invalid child input on submission attempts
  //
  const focusFirstInvalidChildInput: FormEventHandler<HTMLFormElement> = (event) => {
    const evTarget = event.target
    if (!(evTarget instanceof HTMLInputElement || evTarget instanceof HTMLSelectElement)) return

    if (
      // block re-focus if any invalid input already has focus
      (document.activeElement instanceof HTMLInputElement ||
        document.activeElement instanceof HTMLSelectElement) &&
      !document.activeElement.validity.valid
    )
      return

    evTarget.focus()
  }

  return (
    <form {...props} onInvalid={focusFirstInvalidChildInput}>
      <input
        type='submit'
        ref={submitButtonRef}
        className='visually-hidden'
        aria-hidden='true'
        tabIndex={-1}
        id={`${props.id}-submit-trigger`}
      />
      {children}
    </form>
  )
}

const Form = forwardRef(_Form)

export default Form
