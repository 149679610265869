import { useRef, useEffect, useState } from 'react';
import { Coordinates } from '../../Types/Location';
import './GoogleMap.less';
import { MAP_STYLES } from './GoogleMapStyle';

interface GoogleMapProps {
  center?: Coordinates
  radiusKM: number
  setMapInParent?: (map: google.maps.Map) => void
  show: boolean
}

 const setZoom = (radius: number) => {
  if (radius < 1.5) {
    return 14
  }
  if (radius < 2.5) {
    return 13
  }
  if (radius <= 4.5) {
    return 12
  }
  if (radius < 10.1) {
    return 11
  }
  return 10
}

const coordinatesToGoogleLatLng = ({lat, lon}: Coordinates): google.maps.LatLngLiteral => (
  {lat, lng: lon}
)

const GoogleMap = (
    {center: _center, radiusKM, setMapInParent, show }: GoogleMapProps
  ): JSX.Element => {
  const ref = useRef<HTMLElement>(null)
  const [map, _setMap] = useState<google.maps.Map | undefined>()
  const [marker, setMarker] = useState<google.maps.Marker | undefined>()
  const [circle, setCircle] = useState<google.maps.Circle | undefined>()
  const setMap = (map: google.maps.Map) => {
    _setMap(map)
    setMapInParent && setMapInParent(map)
  }
  const center = _center && coordinatesToGoogleLatLng(_center)
  const mapInitialised = !!map
  const markerInitialised = !!marker
  const circleInitialised = !!circle
  const centerDefined = center !== undefined

  useEffect(() => { // initialise map
    if(!ref.current) return

    setMap(new window.google.maps.Map(ref.current, {
      zoom: setZoom(radiusKM),
      center,
      styles: MAP_STYLES,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
    }))
  }, [])

  useEffect(() => { // initialise marker and circle
    if(!mapInitialised || !centerDefined) return

    setMarker(new window.google.maps.Marker({
      position: center,
      map,
    }))

    setCircle(new window.google.maps.Circle({
      center,
      radius: radiusKM * 1000,
      map,
      fillColor: '#AF231C',
      fillOpacity: 0.05,
      strokeColor:'#AF231C',
      strokeWeight: 2,
    }))
  }, [mapInitialised, centerDefined])

  useEffect(() => { // re-center map, marker and circle when coords change
    if(!center || !map) return
    
    map.setCenter(center)
    if(!marker) return

    marker.setPosition(center)
    if(!circle) return
    
    circle.setCenter(center)
  }, [center, circleInitialised, markerInitialised, mapInitialised])

  useEffect(() => { // change map zoom and circle size when radius changes
    if(!map) return
    
    map.setZoom(setZoom(radiusKM))
    if(!circle) return
    
    circle.setRadius(radiusKM * 1000)
  }, [radiusKM, mapInitialised, circleInitialised])

  return (
    // Element height must be set or the map won't be visible
    <figure
      ref={ref}
      id={'googleMap'}
      className={`google-map ${show ? 'show' : ''}`}
      />
  )
}

export default GoogleMap
