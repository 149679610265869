import { PartialBrief } from '../../../../../Types/Brief'
import { Audience } from '../../../../../Types/generated-types'
import { EventType } from './eventType'

const BRIEF_OVERRIDES_BY_EVENT_TYPE: Record<EventType, PartialBrief> = {
  Party: {},
  'Private Dining': {
    layouts: 'seating',
  },
  'Meeting/Workshop': {
    layouts: 'seating',
  },
  'Team Activity': {},
  Presentation: {},
  Wedding: {
    audience: Audience['Personal'],
  },
  Networking: {},
  Studio: {},
  Conference: {},
  Training: {},
  'Something Else': {},
}

export default BRIEF_OVERRIDES_BY_EVENT_TYPE
