import { PartialBrief } from '../../../../Types/Brief'
import { Audience } from '../../../../Types/generated-types'
import { eventTypeLabels } from '../../../FormSteps/EventType/Base/Fieldset'

interface EventTypeProps {
  brief: PartialBrief
}

export const EventType = ({ brief: { collectionName, audience } }: EventTypeProps): JSX.Element => {
  if (
    collectionName === 'Wedding' ||
    (collectionName === 'Networking' && audience === Audience['Professional']) ||
    (collectionName === 'Conference' && audience === Audience['Professional']) ||
    (collectionName === 'Meeting/Workshop' && audience === Audience['Professional']) ||
    (collectionName === 'Training' && audience === Audience['Professional']) ||
    (collectionName === 'Private Dining' && audience === Audience['Personal'])
  ) {
    return <>{eventTypeLabels[collectionName ?? '']}</>
  }

  if (collectionName === 'Something Else')
    return (
      <>
        {eventTypeLabels[collectionName]} (
        {audience === Audience['Personal'] ? 'private' : 'professional'} event)
      </>
    )

  if (collectionName === 'Studio')
    return (
      <>
        {eventTypeLabels[collectionName]} (
        {audience === Audience['Personal'] ? 'private' : 'professional'})
      </>
    )

  const lowerCaseLabel = eventTypeLabels[collectionName ?? ''].toLocaleLowerCase()

  if (!audience) return <>{eventTypeLabels[collectionName ?? '']}</>

  if (collectionName === 'Party')
    return (
      <>
        {audience === Audience['Professional'] ? 'Work' : 'Private'} {lowerCaseLabel}
      </>
    )

  if (collectionName === 'Private Dining')
    return <>{eventTypeLabels[collectionName]} (work event)</>

  if (collectionName === 'Presentation' && audience === Audience['Personal'])
    return <>Private {lowerCaseLabel}</>

  return (
    <>
      {`${audience[0].toUpperCase()}${audience.slice(1)}`} {lowerCaseLabel}
    </>
  )
}

export default EventType
