import { Dispatch, useEffect, useReducer, useState } from 'react'
import { PartialBrief } from '../../../Types/Brief'
import { useGlobalAuth } from '@headbox-ui/auth-hook'
import getBriefUserAttributes from '../../../Utilities/User/getBriefUserAttributes'
import { getStoredBrief } from '../../../Utilities/Storage/brief'
import useLocationAttributes from './Location/useLocationAttributes'
import getPermittedBriefParams, { BriefQueryAttributes } from './QueryParams'
import briefReducer, { BriefDispatch } from './Reducer'
import { GeocodeResponseError } from '../../../Utilities/Location/GoogleAPI/fetchGeocoderLocations'

export interface BriefState {
  brief: PartialBrief
  loading: boolean
  dispatchBrief: Dispatch<BriefDispatch>
  geocodeError?: GeocodeResponseError
}

const useBrief = (): BriefState => {
  const { user, loading: actorLoading } = useGlobalAuth()
  const [queryParams] = useState<URLSearchParams>(new URLSearchParams(window.location.search))
  const { lat, lon, location, ...queryAttrs }: BriefQueryAttributes =
    getPermittedBriefParams(queryParams)
  const { fetchLoading, locationAttrs, geocodeError } = useLocationAttributes({
    lat,
    lon,
    location,
  })
  const [loading, setLoading] = useState(actorLoading || fetchLoading)
  const briefUserAttrs = getBriefUserAttributes(user)
  const storedBriefAttrs = getStoredBrief()

  const [brief, dispatchBrief] = useReducer(briefReducer, {
    numonce: (Math.floor(Math.random() * 1000000000)),
    ...storedBriefAttrs,
    ...locationAttrs,
    ...queryAttrs,
    ...briefUserAttrs,
  })

  useEffect(() => {
    // Set the shared loading state manually to ensure fetched data is appended to the brief
    // state *before* brief dependent components are rendered
    if (actorLoading || fetchLoading) return setLoading(true)

    setLoading(false)
  }, [actorLoading, fetchLoading])

  useEffect(() => {
    if (actorLoading) return

    dispatchBrief({ updateAttributes: briefUserAttrs })
  }, [actorLoading])

  useEffect(() => {
    if (fetchLoading) return

    const { currency } = queryAttrs
    dispatchBrief({
      updateAttributes: {
        ...locationAttrs, // if the location fetch fails, all location coordinates will be undefined
        ...(currency ? { currency } : {}), // ensure any currency query-param takes precedent
      },
    })
  }, [fetchLoading])

  return {
    brief,
    // `loading` state variable lags behind `actorLoading` and `fetchLoading`
    loading: actorLoading || fetchLoading || loading,
    dispatchBrief,
    geocodeError,
  }
}

export default useBrief
