import { PartialBrief } from "../../../Types/Brief"
import getFormSequence from "../Sequences/GetFormSequence"

interface CompletionStats {
  totalSteps: number
  completedSteps: number
}

const getCompletionStats = (brief: PartialBrief): CompletionStats => {
  const { sequence } = getFormSequence(brief)
  const totalStepsCount = sequence.length

  const completedSteps = sequence.filter(({isStepComplete}) => isStepComplete(brief))

  return {
    totalSteps: totalStepsCount,
    completedSteps: completedSteps.length,
  }
}

export default getCompletionStats
