import { TextArea } from '../../../Components/Form/Inputs'
import { TextAreaProps } from '../../../Components/Form/Inputs/TextArea/TextArea'

interface FlexibiltyNotesProps {
  value: TextAreaProps['value']
  onBudgetNotesChange: TextAreaProps['onChange']
}

const BudgetNotes = ({
  value,
  onBudgetNotesChange,
}: FlexibiltyNotesProps): JSX.Element => {
  
  return (
    <section className='budget-notes'>
        <label htmlFor='budget-notes'>
            <h2>
                What should be included in this budget? <br/> Is your budget flexible?
            </h2>
        </label>

        <TextArea
          id='budget-notes'
          rows={5}
          placeholder={"This information will allow venues to provide you with more accurate pricing estimates."}
          value={value}
          onChange={onBudgetNotesChange}
        />

        </section>
      )}

export default BudgetNotes