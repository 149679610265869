import { useEffect, useMemo, useState } from 'react'
import { HOST_LISTINGS_MANAGEMENT_SERVICE } from '../../Constants'
import useBriefContext from '../../Hooks/Brief/useBriefContext'
import { StylesQuery, StylesDocument } from '../FormSteps/Styles/StylesInput'
import { useQuery } from 'urql'
import throwGQLError from '../../Utilities/Urql/Errors/throwGQLError'
import InputView from '../FormSteps/InputView'
import getAdjacentSteps from '../../Logic/Form/Authorization/getAdjacentSteps'
import useSubmitBrief from '../../Hooks/Brief/useSubmitBrief'
import { useFacilitiesQuery } from '../FormSteps/Facilities/Base/Facilities/FacilitiesInput.generated'
import FormPage from '../../Components/Form/Page/FormPage'
import './SummaryPage.less'
import LoadingSpinner from '../../Components/LoadingSpinner'
import useRegisterUser from '../../Hooks/Brief/useRegisterUser'
import BizSummaryList from './Biz/SummaryList'
import isBizBrief from '../../Logic/Form/Sequences/Biz/isBizBrief'
import LFSummaryList from './LF/SummaryList'

export const SummaryPage: InputView = ({ path }) => {
  const { brief } = useBriefContext()
  const isBizSummary = isBizBrief(brief)
  const [shouldRegisterUser, setShouldRegisterUser] = useState(false)
  const [shouldSubmitBrief, setShouldSubmitBrief] = useState(false)
  const { loading: submitting } = useSubmitBrief({ skip: !shouldSubmitBrief })
  const { registering, registered } = useRegisterUser({ skip: !shouldRegisterUser })

  useEffect(() => {
    if (!registered || !shouldRegisterUser || submitting) return

    setShouldSubmitBrief(true)
  }, [registered])
  const { isFinalStep } = getAdjacentSteps(path, brief)

  // don't switch off loading state once triggered if final step of form (submission will redirect)
  const showLoadingState = submitting || registering || (isFinalStep && registered)

  const onContinue = () => {
    if (isFinalStep) {
      if (!brief.guest?.id) return setShouldRegisterUser(true)

      setShouldSubmitBrief(true)
    }
  }

  // Query Data
  //
  const [{ fetching: fetchingStyles, error: stylesError, data: stylesData }] =
    useQuery<StylesQuery>({
      query: StylesDocument,
      context: useMemo(
        () => ({
          requestPolicy: 'cache-first',
          url: HOST_LISTINGS_MANAGEMENT_SERVICE,
        }),
        []
      ),
    })
  const [{ fetching: fetchingFeatures, error: facilityError, data: facilitiesData }] =
    useFacilitiesQuery({
      pause: brief.collection === undefined,
      requestPolicy: 'cache-first',
      variables: { id: brief.collection ?? '' },
    })

  const { getAllStyles: styles } = stylesData ?? { getAllStyles: [] }
  const { features: facilities } = facilitiesData?.collection ?? { features: [] }
  const error = stylesError ?? facilityError

  if (error) throwGQLError(error)

  const SummaryList = isBizSummary ? BizSummaryList : LFSummaryList

  return (
    <>
      {showLoadingState && (
        <LoadingSpinner
          fullPage={true}
          accessibleText={registering ? 'Creating your account...' : 'Submitting your enquiry...'}
        />
      )}
      <FormPage
        path={path}
        canContinue={true}
        onContinue={onContinue}
        afterContinue={
          isFinalStep
            ? () => {
                null
              }
            : undefined
        }
        metaTitle='Event summary - HeadBox'
        heading={
          isBizSummary ? "You're nearly there!" : 'One last check before we share your enquiry'
        }
        submitText={isFinalStep ? 'Submit' : undefined}
        hidden={showLoadingState}
        className={`summary-page ${isBizSummary ? 'biz' : 'lf'}`}>
        <SummaryList
          facilities={fetchingFeatures ? undefined : facilities}
          styles={fetchingStyles ? undefined : styles}
        />
      </FormPage>
    </>
  )
}

export default SummaryPage
