import CollapsibleContainer, { CollapsibleContainerHTMLProps } from '../Base/Container/Container'
import './ListItem.less'

type CollapsibleListItemProps = CollapsibleContainerHTMLProps<HTMLLIElement> & {
  collapse: boolean
  defaultWidth?: number
}

const CollapsibleListItem = ({
  collapse,
  className,
  defaultWidth,
  ...props
}: CollapsibleListItemProps): JSX.Element => (
  <CollapsibleContainer<HTMLLIElement>
    className={`animated-list-item ${className}`}
    defaultChildAnimation={false}
    transitionDuration={350}
    containerElement='li'
    collapsibleDimension='marginRight'
    additionalMargin='1rem'
    collapse={collapse}
    defaultSize={defaultWidth}
    {...props}
  />
)

export default CollapsibleListItem
