import { BASE_NAME, SUCCESS_PATH } from '../../../../Router/Router'
import { BIZ_EMAIL_PATH } from '../../../../Router/Routes/formRoutes'
import { PartialBrief } from '../../../../Types/Brief'
import isNewGuest from '../../../Guest/isNewGuest'

const isBizBrief = ({ guest, country }: PartialBrief): boolean => {
  // Exclude new biz users searching in US
  if (isNewGuest(guest)) return (country !== 'US' && guest?.isBusinessUser) || false

  return !!guest.isBusinessUser
}

export const showBizLayout = (brief: PartialBrief): boolean => {
  const PROCESS_HIDDEN_PATHS = [SUCCESS_PATH, BIZ_EMAIL_PATH]
  return (
    isBizBrief(brief) ||
    PROCESS_HIDDEN_PATHS.some((path) => window.location.pathname === `${BASE_NAME}/${path}`)
  )
}

export default isBizBrief
