// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root p.contextual-guest-input {
  color: var(--border-grey);
}
app-root p.contextual-guest-input .hb-input {
  margin: 0 0.5em;
}
app-root p.contextual-guest-input .hb-input .error-message {
  position: absolute;
  width: max-content;
  bottom: -1.75em;
  left: 50%;
  transform: translateY(-0.8em) translateX(-50%);
}
app-root p.contextual-guest-input .hb-input.show-validity .error-message {
  transform: translateY(0) translateX(-50%);
}
app-root p.contextual-guest-input .hb-input > input {
  width: 90px;
}
`, "",{"version":3,"sources":["webpack://./src/Views/FormSteps/GuestNumber/Base/Input/NumberInput.less"],"names":[],"mappings":"AAAA;EAEI,yBAAA;AAAJ;AAFA;EAKM,eAAA;AAAN;AALA;EAOQ,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,SAAA;EACA,8CAAA;AACR;AACM;EAAiC,yCAAA;AAEvC;AADM;EAAY,WAAA;AAIlB","sourcesContent":["app-root {\n  p.contextual-guest-input {\n    color: var(--border-grey);\n\n    .hb-input {\n      margin: 0 0.5em;\n      .error-message {\n        position: absolute;\n        width: max-content;\n        bottom: -1.75em;\n        left: 50%;\n        transform: translateY(-0.8em) translateX(-50%);\n      }\n      &.show-validity .error-message { transform: translateY(0) translateX(-50%) }\n      & > input { width: 90px }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
