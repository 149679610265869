import useBriefContext from '../../../../Hooks/Brief/useBriefContext'
import isBizBrief from '../../../../Logic/Form/Sequences/Biz/isBizBrief'
import InputView from '../../../FormSteps/InputView'
import BizRegistrationPage from './Biz/RegistrationPage'
import LFRegistrationPage from './LeadFeed/RegistrationPage'

const RegistrationPage: InputView = ({path}): JSX.Element => {
  const { brief } = useBriefContext()

  if (isBizBrief(brief)) return <BizRegistrationPage path={path} />

  return <LFRegistrationPage path={path} />
}

export default RegistrationPage
