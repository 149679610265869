import { InputHTMLAttributes, ReactNode } from "react";
import MultiButton from '../../Generic/Multi/MultiButton'

interface RadioButtonProps extends Omit<InputHTMLAttributes<HTMLInputElement> , 'type'> {
  id: string
  label: ReactNode
}

const RadioButton = (props: RadioButtonProps) => (
  <MultiButton type='radio' {...props} />
)

export default RadioButton
