import ProcessCopy from './Copy/ProcessCopy'
import './ProcessOverview.less'
import { StarRatingSVG, TrustPilotLogoSVG } from './TrustPilot/Icons'
import './ProcessOverview.less'
import './ProcessOverviewAnimation.less'

interface ProcessOverviewProps {
  isDirectEnquiry: boolean
}
const ProcessOverview = ({ isDirectEnquiry }: ProcessOverviewProps): JSX.Element | null => (
  <article className='process-explainer'>
    <header>
      <h3>How HeadBox works</h3>
    </header>
    <ProcessCopy isDirectEnquiry={isDirectEnquiry} />
    <footer>
      <a
        href='https://uk.trustpilot.com/review/headbox.com'
        title='Trustpilot reviews for HeadBox'
        target='blank'
        rel='noopener'>
        <span className='visually-hidden'>Customer reviews for HeadBox on Trustpilot</span>
        <TrustPilotLogoSVG />
        <StarRatingSVG />
      </a>
    </footer>
  </article>
)

export default ProcessOverview
