import { ReactNode, Key, useEffect, useState } from 'react'
import './CarouselItem.less'

interface CarouselItemProps {
  index: number
  visible: boolean
  children?: ReactNode
  key?: Key
}

const CarouselItem = ({
  index,
  visible: shouldBeVisible,
  children,
}: CarouselItemProps): JSX.Element | null => {
  const [isVisible, setIsVisible] = useState(shouldBeVisible)

  useEffect(() => {
    if (shouldBeVisible === isVisible) return

    const timer = setTimeout(() => {
      setIsVisible(!isVisible)
    }, 300)
    return () => clearTimeout(timer)
  }, [shouldBeVisible])

  if (isVisible)
    return (
      <article
        className={`carousel-item ${shouldBeVisible ? 'show' : 'hide'}`}
        role='group'
        aria-label={`Item ${index}`}
        aria-current={shouldBeVisible}>
        {children}
      </article>
    )

  return null
}

export default CarouselItem
