// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root * {
  font-family: Montserrat, Ariel, sans-serif;
}
app-root h1 {
  line-height: 2rem;
  margin-bottom: 1.5rem;
  color: #262626;
}
app-root h2 {
  font-size: 1.25rem;
}
app-root h2,
app-root h4,
app-root h5,
app-root h6,
app-root legend {
  color: #262626;
}
app-root h3 {
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  color: #262626;
}
app-root p,
app-root dt,
app-root dd,
app-root .hb-input > input {
  color: #1F1F1F;
}
app-root p,
app-root dt,
app-root dd {
  font-weight: 500;
}
app-root .hb-input > input {
  color: #1F1F1F;
}
@media (min-width: 900px) {
  app-root h1 {
    font-size: 2rem;
    line-height: 1.1875;
  }
  app-root h2 {
    font-size: 1.5rem;
  }
  app-root h3 {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Styles/typography.less"],"names":[],"mappings":"AAGA;EACM,0CAAA;AAFN;AACA;EAII,iBAAA;EACA,qBAAA;EACA,cAAA;AAFJ;AAJA;EAQO,kBAAA;AADP;AAPA;;;;;EAU2B,cAAA;AAI3B;AAdA;EAaI,mBAAA;EACA,qBAAA;EACA,cAAA;AAIJ;AAnBA;;;;EAiBiC,cAAA;AAQjC;AAzBA;;;EAkBc,gBAAA;AAYd;AA9BA;EAoBI,cAAA;AAaJ;AAVE;EAAA;IAEI,eAAA;IACA,mBAAA;EAYJ;EAfA;IAMI,iBAAA;EAYJ;EAlBA;IAUI,iBAAA;IACA,iBAAA;EAWJ;AACF","sourcesContent":["@import './mediaQueries.less';\n@import './colours.less';\n\napp-root {\n  * { font-family: Montserrat, Ariel, sans-serif; }\n\n  h1 {\n    line-height: 2rem;\n    margin-bottom: 1.5rem;\n    color: @selection-grey;\n  }\n  h2 { font-size: 1.25rem; }\n\n  h2, h4, h5, h6, legend { color: @selection-grey }\n\n  h3 {\n    line-height: 1.5rem;\n    margin-bottom: 1.5rem;\n    color: @selection-grey;\n  }\n  p, dt, dd, .hb-input > input { color: @text-black; }\n  p, dt, dd { font-weight: 500; }\n  .hb-input > input {\n    color: @text-black;\n  }\n\n  @media @landscapeTabletUp {\n    h1 {\n      font-size: 2rem;\n      line-height: 1.1875;\n    }\n    h2 {\n      font-size: 1.5rem;\n    }\n    \n    h3 {\n      font-size: 1.5rem;\n      line-height: 1.25;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
