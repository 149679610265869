import EventNameInput from '../../Views/FormSteps/EventName/EventNameInput'
import LocationInput from '../../Views/FormSteps/Location/LocationInput'
import EventTypeInput from '../../Views/FormSteps/EventType/EventTypeInput'
import GuestNumberInput from '../../Views/FormSteps/GuestNumber/GuestNumberInput'
import StylesInput from '../../Views/FormSteps/Styles/StylesInput'
import LayoutInput from '../../Views/FormSteps/Layout/LayoutInput'
import DateTimeInput from '../../Views/FormSteps/DateTime/DateTimeInput'
import FlexibleTimingsInput from '../../Views/FormSteps/FlexibleTimings/FlexibleTimingsInput'
import CateringAndFacilitiesInput from '../../Views/FormSteps/Facilities/FacilitiesInput'
import BudgetInput from '../../Views/FormSteps/Budget/BudgetInput'
import Emissions from '../../Views/FormSteps/Emissions/EmissionsInput'
import SummaryPage from '../../Views/Summary/SummaryPage'
import RegistrationPage from '../../Views/FormSteps/User/Registration/RegistrationPage'
import AuthenticationPage from '../../Views/FormSteps/User/Authentication/Biz/AuthenticationPage'
import LFEmailInput from '../../Views/FormSteps/Email/LeadFeed/EmailInput'
import BizEmailInput from '../../Views/FormSteps/Email/Biz/EmailInput'

export const BIZ_EMAIL_PATH = 'business'
export const LF_EMAIL_PATH = 'email'
export const SUMMARY_PATH = 'else'
export const TIMINGS_PATH = 'timings'

const FORM_ROUTES = {
  [BIZ_EMAIL_PATH]: BizEmailInput, // a direct, publicly accessible link for would-be biz users
  [LF_EMAIL_PATH]: LFEmailInput,
  name: EventNameInput,
  location: LocationInput,
  type: EventTypeInput,
  style: StylesInput,
  guests: GuestNumberInput,
  layout: LayoutInput,
  [TIMINGS_PATH]: DateTimeInput,
  flexible: FlexibleTimingsInput,
  extras: CateringAndFacilitiesInput,
  budget: BudgetInput,
  emissions: Emissions,
  [SUMMARY_PATH]: SummaryPage,
  user: RegistrationPage,
  'returning-user': AuthenticationPage,
} as const

// Biz form steps that were formerly part of the LF sequence - used to redirect LF users
export const BIZ_ONLY_PATHS: FormPath[] = ['flexible', 'layout']

export type FormPath = keyof typeof FORM_ROUTES

export default FORM_ROUTES
