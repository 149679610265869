import { ChangeEventHandler, HTMLAttributes, Key, ReactNode, useEffect, useState } from 'react'
import CarouselItem from './CarouselItem'
import './Carousel.less'

interface CarouselToggleProps {
  itemIndex: number
  currentItemIndex: number
  onChange: ChangeEventHandler<HTMLInputElement>
  key: Key
}

const CarouselToggle = ({
  itemIndex,
  currentItemIndex,
  onChange,
}: CarouselToggleProps): JSX.Element => (
  <>
    <input
      type='radio'
      id={`carousel-item-${itemIndex}`}
      className='carousel-toggle'
      value={itemIndex}
      checked={itemIndex === currentItemIndex}
      onChange={onChange}
      title={`Show item ${itemIndex}`}
    />
    <label className='visually-hidden'>Show item {itemIndex}</label>
  </>
)

interface CarouselProps extends HTMLAttributes<HTMLElement> {
  currentIndex?: number
  children: ReactNode
}

const Carousel = ({
  children,
  currentIndex: _currentIndex,
  className,
  ...htmlProps
}: CarouselProps): JSX.Element => {
  const [autoIndex, setAutoIndex] = useState(0)
  const [manualIndex, setManualIndex] = useState<number | undefined>(_currentIndex)
  const manyChildren = Array.isArray(children) && children.length > 1
  const numChildren = manyChildren ? children.length : 1
  const currentIndex = manualIndex ?? autoIndex

  useEffect(() => {
    // disable auto progression when manual index set (or single child)
    if (manualIndex !== undefined || !manyChildren) return

    const timer = setTimeout(() => {
      setAutoIndex((autoIndex + 1) % numChildren)
    }, 6000)

    return () => clearTimeout(timer)
  }, [autoIndex, manyChildren])

  return (
    <>
      <section className={`carousel ${className}`} role='group' {...htmlProps}>
        {manyChildren ? (
          children.map((child, i) => (
            <CarouselItem index={i + 1} visible={i === currentIndex} key={i}>
              {child}
            </CarouselItem>
          ))
        ) : (
          <CarouselItem index={1} visible={true}>
            {children}
          </CarouselItem>
        )}
        {manyChildren ? (
          <footer>
            <fieldset className='controls' tabIndex={-1}>
              {children.map((_, i) => (
                <CarouselToggle
                  itemIndex={i}
                  currentItemIndex={currentIndex}
                  key={i}
                  onChange={({ target }) => setManualIndex(parseInt(target.value, 10))}
                />
              ))}
            </fieldset>
          </footer>
        ) : null}
      </section>
    </>
  )
}

export default Carousel
