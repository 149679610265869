import { Dispatch, SetStateAction, ChangeEventHandler } from 'react'
import { TextArea } from '../Form/Inputs'
import './AdditionalNotesSection.less'

interface AdditionalNotesSectionProps {
  additionalNotes: string
  setAdditionalNotes: Dispatch<SetStateAction<string>>
}

const AdditionalNotesSection = ({
  additionalNotes,
  setAdditionalNotes,
}: AdditionalNotesSectionProps): JSX.Element => {
  const onNoteChange: ChangeEventHandler<HTMLTextAreaElement> = ({ target }) =>
    setAdditionalNotes(target.value)

  return (
    <section className='anything-else-input'>
      <header>
        <h3>Anything else? Add it here!</h3>
        <p aria-hidden='true'>A specific vibe, type of venue or any special requests?</p>
      </header>
      <label htmlFor='additional-notes' className='visually-hidden'>
        Additional Notes (optional step)
      </label>
      <TextArea
        id='additional-notes'
        rows={5}
        onChange={onNoteChange}
        value={additionalNotes}
        placeholder={
          "The more detail you provide, the more likely you'll hear from the perfect venue."
        }
      />
    </section>
  )
}

export default AdditionalNotesSection
