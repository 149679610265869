import Page from '../../Page'
import Nav from '../Nav'
import { FormEventHandler, MutableRefObject, ReactNode, useRef, useState } from 'react'
import { PartialBrief } from '../../../Types/Brief'
import { PageProps } from '../../Page/Page'
import useFormNavigationProps from './useFormNavigationProps'
import Form from '../Form'
import { BriefFormRef } from '../Form/Form'
import { type Forbid } from '../../../Types/Utilities'

interface BaseFormPageProps extends Omit<PageProps, 'footer'> {
  path: string
  header?: ReactNode
  submitText?: string
  formRef?: MutableRefObject<BriefFormRef>
  disableNav?: boolean
  onBack?: () => void // optional override of default navigation to previous page/summary page
}

// In most cases, the 'StandardFormProps' (with optional 'afterCotinue') are all you need to provide
// with the BaseFormPageProps
export interface StandardFormProps extends Forbid<CustomFormProps, 'onContinue' | 'canContinue'> {
  inputAttributes: PartialBrief // passed to dispatchBrief as 'updateAttributes'
}

// But if you need to override the default behaviour, please provide the following
export interface CustomFormProps {
  onContinue: () => void | Promise<void> // the callback to run on successful form submission
  canContinue: boolean // the state of whether the form is ready to be submitted

  afterContinue?: () => void // optional override of default navigation to next page/summary page
}

export type FormPageProps = BaseFormPageProps & (StandardFormProps | CustomFormProps)

const FormPage = (props: FormPageProps): JSX.Element => {
  const {
    metaTitle,
    heading,
    header,
    alert,
    children,
    formRef: _formRef,
    submitText,
    disableNav,
    onBack,
    ...formNavigationProps
  } = props
  const { canContinue, onContinue, afterContinue, previousPage, returnToSummary } =
    useFormNavigationProps(formNavigationProps)
  const [submissionError, setSubmissionError] = useState<unknown>()

  const defaultFormRef = useRef<BriefFormRef>(null)

  if (submissionError) throw submissionError

  const formRef = _formRef ?? defaultFormRef

  const triggerSubmit = () => formRef?.current?.submit()
  const formId = `brief-form-step-${formNavigationProps.path || 'start'}`
  const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault()
    event.stopPropagation()

    const areInputsValid = event.currentTarget.reportValidity()

    if (areInputsValid && canContinue) {
      try {
        await onContinue()
        afterContinue()
      } catch (e) {
        setSubmissionError(e)
      }
    }
  }

  const nav = (
    <Nav
      previousPage={previousPage}
      canContinue={canContinue}
      onContinue={triggerSubmit}
      returnToSummary={returnToSummary}
      submitText={returnToSummary ? 'Update' : submitText}
      disabled={disableNav}
      onBack={onBack}
    />
  )

  return (
    <Page
      metaTitle={metaTitle}
      heading={heading}
      alert={alert}
      footer={nav}
      className={props.className}
      hidden={props.hidden}>
      {header ?? null}
      <Form id={formId} onSubmit={onSubmit} ref={formRef}>
        {children}
      </Form>
    </Page>
  )
}

export default FormPage
