import enAU from './en-AU'
import enGB from './en-GB'
import enUS from './en-US'
import en from './en'

const resources = {
  en,
  'en-AU': enAU,
  'en-GB': enGB,
  'en-US': enUS,
} as const

export default resources
