/* eslint-disable max-len */
import successGif from '../../../Images/Success/end-screen.gif'
import { ASSET_SERVICE } from '../../../Constants'
import Page from '../../../Components/Page'

const FallBackSuccessPage = (): JSX.Element => (
  <Page
    heading='Brilliant! Enquiry submitted.'
    metaTitle='All done! - HeadBox'
    className='success-page fade-in'>
    <p className='line-with-heart'>
      💌&nbsp;Thank you for submitting your event brief. One of the HeadBox team will be in touch
      within 4 working hours to discuss your enquiry.&nbsp;💌
    </p>
    <img
      width='2500'
      height='1000'
      src={`${ASSET_SERVICE}${successGif}`}
      alt={'Image of paper plane taking off.'}
    />
    <p>You can then choose your favourite and request to book.</p>
  </Page>
)

export default FallBackSuccessPage
