import { UserResponseDto } from '@headbox-ui/auth-hook/dist/useGlobalAuth'
import { type Guest } from '../../../../Types/Brief'

interface RegisteredUserAttributes extends Guest {
  id: number // optional on Guest
  newlyRegistered: true
  country: string
  company?: string
}

const getRegisteredUserAttributes = ({
  id,
  companyName,
  country,
}: UserResponseDto): RegisteredUserAttributes => ({
  id,
  newlyRegistered: true,
  company: companyName,
  country,
})

export default getRegisteredUserAttributes
