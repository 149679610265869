import useBriefContext from '../../../../../Hooks/Brief/useBriefContext'
import { getGuestHeadingPrefix } from '../Utilities/getGuestCopyByEventType'

const PageHeading = (): JSX.Element => {
  const {
    brief: { collectionName },
  } = useBriefContext()
  const titlePrefix = getGuestHeadingPrefix(collectionName) ?? 'Great!'

  return <>{`${titlePrefix} Who's invited?`}</>
}

export default PageHeading
