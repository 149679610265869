import { GOOGLE_MAPS_REST_API_KEY } from '../../../Constants'
import { Coordinates, LocationAttributes } from '../../../Types/Location'
import axios, { AxiosError } from 'axios'
import retryFetch, { OnRetryArgs } from '../../REST/Retries/retryFetch'
import captureMessage from '../../Errors/captureMessage'

const GEOCODE_API_URL = 'https://maps.googleapis.com/maps/api/geocode/json'

export type GoogleLocationAttributes = Omit<LocationAttributes, 'radius' | 'currency'>
export interface FetchGeocoderLocationsArgs {
  coordinates?: Coordinates
  addressSearch?: string
}

interface GeocodeResponseErrorArgs {
  status: google.maps.GeocoderStatus
  error_message?: string
}

// The Geometry location is a google.maps.LatLng instance in the JS API but an object literal in the
// REST API
interface GeocoderJSONGeometry extends Omit<google.maps.GeocoderGeometry, 'location'> {
  location: google.maps.LatLngLiteral
}

export interface GeocoderJSONResult extends Omit<google.maps.GeocoderResult, 'geometry'> {
  geometry: GeocoderJSONGeometry
}

interface GeocoderJSONResponse extends Omit<google.maps.GeocoderResponse, 'results'> {
  results: GeocoderJSONResult[]
}

type GeocodeRestResponse = GeocoderJSONResponse & GeocodeResponseErrorArgs

export class GeocodeResponseError extends Error {
  constructor({ status, error_message }: GeocodeResponseErrorArgs) {
    super(`Google Geocode Response Error\nStatus: ${status}\nMessage: ${error_message}`)
    Object.setPrototypeOf(this, GeocodeResponseError.prototype) // required due to TS bug
    this.name = 'GeocodeResponseError'
  }
}
const fetchGeocoderLocations = async (
  args: FetchGeocoderLocationsArgs
): Promise<GeocoderJSONResult[]> => {
  const { coordinates, addressSearch } = args
  if ((coordinates === undefined) === (addressSearch === undefined)) {
    throw new Error('Please provide lat-lon coordinates OR a location search string!')
  }

  return await axios
    .get(GEOCODE_API_URL, {
      params: {
        key: GOOGLE_MAPS_REST_API_KEY,
        latlng: coordinates && `${coordinates.lat},${coordinates.lon}`,
        address: addressSearch,
      },
    })
    .then((response) => {
      const { results, status, error_message }: GeocodeRestResponse = response.data
      if (status !== 'OK') {
        throw new GeocodeResponseError({ status, error_message })
      }

      return results
    })
}

const onRetry = ({ retryCount }: OnRetryArgs) => {
  captureMessage(
    `Failed to fetch Google Geocoder location data.\nAutomatically retrying... (retry count: ${retryCount})`
  )
}
const shouldRetry = (error: unknown): boolean =>
  error instanceof AxiosError && error.code === 'ERR_NETWORK'

const retryFetchGeocoderLocations = retryFetch(fetchGeocoderLocations, {
  onRetry,
  shouldRetry,
})

export default retryFetchGeocoderLocations
