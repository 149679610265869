import { StylesQuery } from '../../FormSteps/Styles/StylesInput'
import { FacilitiesQuery } from '../../../Views/FormSteps/Facilities/Base/Facilities/FacilitiesInput.generated'
import ListItem from '../Base/SummaryList/Item/ListItem'
import BaseSummaryList from '../Base/SummaryList'
import useBriefContext from '../../../Hooks/Brief/useBriefContext'
import isDirectEnquiryBrief from '../../../Logic/Form/Sequences/DirectEnquiry/isDirectEnquiryBrief'
import EditLink from '../Base/SummaryList/EditLink'
import './SummaryList.less'
import TimingFlexibilityListItem from './ListItems/TimingFlexibility'
import Quote from '../Base/ItemComponents/Quote'
import GuestsLayout from './ItemComponents/GuestsLayout'
import Budget from '../Base/ItemComponents/Budget'
import Timings from '../Base/ItemComponents/Timings'
import EventType from './ItemComponents/EventType'
import FacilitiesListItems from './ListItems/Facilities'
import EventLocation from '../Base/ItemComponents/EventLocation'
import { useTranslation } from 'react-i18next'

interface LFSummaryListProps {
  facilities?: FacilitiesQuery['collection']['features']
  styles?: StylesQuery['getAllStyles']
}

const LFSummaryList = ({ facilities, styles }: LFSummaryListProps): JSX.Element => {
  const { brief } = useBriefContext()
  const { t } = useTranslation('summaryPage')
  const selectedStyles = styles
    ? brief.styleIds?.map((selectedID) => styles.find(({ id }) => id === selectedID)?.label)
    : undefined

  return (
    <BaseSummaryList
      className='lf-summary'
      heading={
        <>
          <h2>{brief.eventName}</h2>
          <EditLink path={'name'} sectionLabel='event name' />
          <p className='caption' aria-hidden='true'>
            {t('summarySubheading')}
          </p>
        </>
      }>
      <ListItem path='type' label='Event type'>
        <EventType brief={brief} />
      </ListItem>

      <ListItem path='location' label='Preferred location' editable={!isDirectEnquiryBrief(brief)}>
        <EventLocation brief={brief} />
      </ListItem>

      <ListItem path='guests' label='Attendees'>
        <GuestsLayout brief={brief} />
      </ListItem>

      <ListItem path='timings' label='Date & time'>
        <Timings brief={brief} />
      </ListItem>

      <TimingFlexibilityListItem brief={brief} />

      <ListItem path='budget' label='Budget'>
        <Budget brief={brief} />
      </ListItem>

      <ListItem path='style' label='Venue styles'>
        {selectedStyles ? selectedStyles.join(', ') || 'None' : '...'}
      </ListItem>

      <FacilitiesListItems brief={brief} facilities={facilities} />

      <ListItem path='extras' elementID='catering-needs' label='Catering requests'>
        <Quote text={brief.cateringNeeds} />
      </ListItem>

      <ListItem path='name' elementID='additional-notes' label='Additional notes'>
        <Quote text={brief.additionalNotes} />
      </ListItem>
    </BaseSummaryList>
  )
}

export default LFSummaryList
