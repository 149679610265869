import { useContext } from 'react'
import LayoutContext, { LayoutContextEntity } from '../../../Contexts/PageLayout/layoutContext'

const useLayout = (): LayoutContextEntity => {
  const layoutContext = useContext(LayoutContext)

  if (!layoutContext) {
    throw new Error('useLayout must be called within the <PageLayoutProvider> component')
  }

  return layoutContext
}

export default useLayout
