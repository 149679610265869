import { useEffect, useState } from 'react'
import { gql, useMutation } from 'urql'
import { BIZ_PLATFORM_SERVICE } from '../../../Constants'
import { PartialBrief } from '../../../Types/Brief'
import { getBizSubmissionParams } from './getSubmissionParams/'

export type CreateEventInput = {
  eventTypeID: number
  lat: string
  lon: string
  location: string
  country: string
  radius: string
  attendees: number
  layoutID: string
  isFlexibleWithDates: boolean
  flexibleDatesNote: string
  fromDateTime: string
  toDateTime: string
  spaceFacilityIDs: number[]
  cateringNeeds?: string
  currency: string
  budget: number
  name: string
  additionalNotes?: string
  guestID: number
  guestCompany: string
  automatedInvitationsQualified: boolean
  automatedInvitationsBypassed: boolean
  accountManagerId?: number
  styleIDs: string[]
}

type CreateEventMutation = {
  createEvent?: {
    ID: number
    UUID: string
  }
}

type CreateEventVariables = {
  input: CreateEventInput
}

const CreateEventDocument = gql`
  mutation createEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      ID
      UUID
    }
  }
`

const useSubmitBizBriefMutation = () =>
  useMutation<CreateEventMutation, CreateEventVariables>(CreateEventDocument)

export interface Emissions {
  materials: Materials
  catering: Catering
  accomodation: Accomodation
}

export interface Materials {
  options: MaterialsEnum[]
}

export interface Catering {
  covers: number
  menuType?: MenuTypeEnum
  options: CateringOptionsEnum[]
}

export interface Accomodation {
  guests: number
  nights: number
}

export type MaterialsEnum =
  | 'FLIP_CHART'
  | 'ID_BADGES'
  | 'LARGE_SIGNAGE'
  | 'NOTEPADS'
  | 'PENS'
  | 'SMALL_SIGNAGE'

export type MenuTypeEnum = 'OMNIVEROUS' | 'VEGAN' | 'VEGETARIAN'

export type CateringOptionsEnum =
  | 'BREAKFAST'
  | 'LUNCH'
  | 'DINNER'
  | 'TEA_AND_COFFEE'
  | 'ALCOHOLIC_DRINKS'
  | 'SOFT_DRINKS'
  | 'CANAPES'

type CreateEventEmissionsDetailsVariables = {
  input: CreateEventDetailsRecordInput
}

type CreateEventEmissionsDetailsRecord = {
  createEventDetailsRecord?: {
    id: number
  }
}

type CreateEventDetailsRecordInput = {
  eventId: number
  guests: number
  materials: {
    options: MaterialsEnum[]
  }
  catering: {
    covers: number
    menuType?: MenuTypeEnum
    options: CateringOptionsEnum[]
  }
  accomodation?: {
    guests: number
    nights: number
  }
}

const CreateEventEmissionsDetailsDocument = gql`
  mutation createEventDetailsRecord($input: CreateEventDetailsRecordInput!) {
    createEventDetailsRecord(input: $input) {
      id
    }
  }
`

const useCreateEventEmissionsDetailsRecord = () =>
  useMutation<CreateEventEmissionsDetailsRecord, CreateEventEmissionsDetailsVariables>(
    CreateEventEmissionsDetailsDocument
  )

const useSubmitBizBriefAndEmissionsDetails = (): [
  typeof combinedState,
  typeof combinedMutation
] => {
  const [submittedBriefState, submitBrief] = useSubmitBizBriefMutation()
  const [emissionsState, submitEmissions] = useCreateEventEmissionsDetailsRecord()
  const [briefToSubmit, setBriefToSubmit] = useState<PartialBrief>()

  useEffect(() => {
    const eventId = submittedBriefState.data?.createEvent?.ID

    if (eventId && briefToSubmit?.emissions) {
      submitEmissions(
        {
          input: {
            eventId,
            guests: briefToSubmit.capacities || 0,
            ...briefToSubmit.emissions,
          },
        },
        { url: BIZ_PLATFORM_SERVICE }
      )
    }
  }, [submittedBriefState.data, briefToSubmit])

  const combinedState = {
    ...submittedBriefState,
    error: submittedBriefState.error || emissionsState.error,
    fetching: submittedBriefState.fetching || emissionsState.fetching,
    data: submittedBriefState.data,
  }

  const combinedMutation = async (brief: PartialBrief): Promise<void> => {
    setBriefToSubmit(brief)
    submitBrief({ input: getBizSubmissionParams(brief) }, { url: BIZ_PLATFORM_SERVICE })
  }

  return [combinedState, combinedMutation]
}

export default useSubmitBizBriefAndEmissionsDetails
