import ProcessOverview from '../../../../Components/Brand/ProcessOverview/Biz'
import InputView from '../../InputView'
import EmailInput from '../Base/EmailInput'
import './EmailInput.less'
import './EmailInputAnimation.less'

const BizEmailInput: InputView = ({ path }): JSX.Element => {
  return (
    <EmailInput
      path={path}
      metaTitle='Plan your event - HeadBox'
      heading='Welcome to HeadBox&nbsp;👋'
      className='fade-in'
      header={
        <>
          <p className='header-caption'>Let the perfect venue find you.</p>
          <ProcessOverview />
        </>
      }
      emailHeading={<h2>First things first - what&#39;s your email address?</h2>}
    />
  )
}

export default BizEmailInput
