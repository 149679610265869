import isBizBrief from '../../../../../Logic/Form/Sequences/Biz/isBizBrief'
import { PartialBrief } from '../../../../../Types/Brief'
import getTransformationAttrsFromBizBrief from './GuestTypeTransformations/getTransformationAttrsFromBizBrief'
import getTransformationAttrsFromLFBrief from './GuestTypeTransformations/getTransformationAttrsFromLFBrief'

interface GetTransformationAttrsArgs {
  existingBrief: PartialBrief
  newBrief: PartialBrief
}

const getTransformationAttrs = ({
  existingBrief,
  newBrief,
}: GetTransformationAttrsArgs): PartialBrief => {
  const isExistingBizBrief = isBizBrief(existingBrief)
  const isNewBizBrief = isBizBrief(newBrief)
  if (isExistingBizBrief === isNewBizBrief) {
    // no effective type change - no transformation required
    return {}
  }

  if (isNewBizBrief) {
    return getTransformationAttrsFromLFBrief(newBrief)
  }

  return getTransformationAttrsFromBizBrief(newBrief)
}

export default getTransformationAttrs
