import { useState, useEffect } from 'react'

// A hook-based interface for the MediaQueryList entity. Tracks whether the provided query 'matches'
// i.e. is fulfilled
const useMediaQuery = (mediaQuery: string): boolean => {
  const [matches, setMatches] = useState(window.matchMedia(mediaQuery).matches)

  useEffect(() => {
    const queryChangeListener = (e: MediaQueryListEvent): void => setMatches(e.matches)
    // add and removeListener are deprecated but more widely supported
    window.matchMedia(mediaQuery).addListener(queryChangeListener)
    return () => window.matchMedia(mediaQuery).removeListener(queryChangeListener)
  }, [])

  return matches
}

export default useMediaQuery
