import { BaseProvider, LightTheme } from 'baseui'
import { DatePicker as BaseWebDatePicker, DatepickerProps } from 'baseui/datepicker'
import { Dispatch, ReactNode, useState } from 'react'
import { Client as Styletron } from 'styletron-engine-atomic'
import { Provider as StyletronProvider } from 'styletron-react'
import ValidationMessage from '../../../../Components/Form/Inputs/ValidationMessage/ValidationMessage'
import { DateRange } from '../Utilities/getBriefDates'
import './BaseWeb/DatePicker.less'
import DATE_PICKER_OVERRIDES from './BaseWeb/datePickerOverrides'
import useLocale from '../../../../Hooks/Locale/useLocale'

const engine = new Styletron()

interface DatePickerProps {
  dates: DateRange
  setDates: Dispatch<React.SetStateAction<DateRange>>
  allowDateRange?: boolean
  label?: ReactNode // BaseWeb refuse to play nicely with either method of properly linking labels
}

const DatePicker = ({
  dates,
  setDates,
  label,
  allowDateRange = false,
}: DatePickerProps): JSX.Element => {
  const [invalidMessage, setInvalidMessage] = useState<string | null>(null)
  const locale = useLocale()
  const textInputProps =
    locale === 'US'
      ? {
          formatString: 'MM/dd/yyyy',
          placeholder: 'MM/DD/YYYY',
          mask: '99/99/9999',
        }
      : {
          formatString: 'yyyy/MM/dd',
          placeholder: 'YYYY/MM/DD',
          mask: '9999/99/99',
        }

  const onDatesChange: DatepickerProps['onChange'] = ({ date }) => {
    const newDates = Array.isArray(date) ? date : [date]
    const [newStartDate, newEndDate] = newDates
    if (invalidMessage !== null) setInvalidMessage(null)

    setDates([newStartDate ?? undefined, newEndDate ?? undefined])
  }
  const onCalendarClose = (): void => {
    const [selectedStartDate, selectedEndDate] = dates
    if (selectedEndDate === undefined && selectedStartDate === undefined) {
      setInvalidMessage(
        allowDateRange ? 'Please enter valid dates.' : 'Please select a valid date.'
      )
    } else if (selectedEndDate === undefined) {
      // default end date to start date
      setDates([selectedStartDate, selectedStartDate])
    }
  }

  return (
    <div className='date-picker-wrapper'>
      {label}
      <StyletronProvider value={engine}>
        {/* these providers are base-ui dependencies */}
        <BaseProvider theme={LightTheme}>
          <ValidationMessage
            id='invalid-dates-error'
            className='datepicker-wrapper'
            invalidMessage={invalidMessage}>
            <BaseWebDatePicker
              value={dates}
              onChange={onDatesChange}
              onClose={onCalendarClose}
              minDate={new Date()}
              required
              {...textInputProps}
              range={allowDateRange}
              error={!!invalidMessage}
              separateRangeInputs
              aria-invalid={!!invalidMessage}
              aria-errormessage={invalidMessage ? 'invalid-dates-error' : undefined}
              aria-label={allowDateRange ? 'Date or dates' : 'Event Date'}
              overrides={DATE_PICKER_OVERRIDES}
            />
          </ValidationMessage>
        </BaseProvider>
      </StyletronProvider>
    </div>
  )
}

export default DatePicker
