import CircularProgress from '@mui/material/CircularProgress'
import { ReactNode } from 'react'
import './Spinner.less'

interface SpinnerProps {
  progressId?: string
  fadeIn?: boolean
  fullPage?: boolean
  className?: string
  accessibleText?: ReactNode
}

interface SpinnerWrapperProps {
  fadeIn: boolean
  fullPage: boolean
  children: ReactNode
  className?: string
}

const SpinnerWrapper = ({
  fullPage,
  className: _className,
  fadeIn,
  children,
}: SpinnerWrapperProps): JSX.Element => {
  const className = `indeterminate-progress ${fadeIn ? 'fade' : ''} ${fullPage ? 'full' : ''} ${
    _className ? _className : ''
  }`

  if (fullPage) {
    return <main className={className}>{children}</main>
  }

  return <strong className={className}>{children}</strong>
}

const Spinner = ({
  progressId,
  className,
  fadeIn = true,
  fullPage = false,
  accessibleText = 'loading...',
}: SpinnerProps): JSX.Element => (
  <SpinnerWrapper fadeIn={fadeIn} fullPage={fullPage} className={className}>
    {progressId ? null : (
      <span aria-live='polite' className='visually-hidden'>
        {accessibleText}
      </span>
    )}
    <CircularProgress aria-hidden={progressId ? 'false' : 'true'} id={progressId} />
  </SpinnerWrapper>
)

export default Spinner
