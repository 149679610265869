import { ChangeEventHandler, Key, MutableRefObject, useState } from 'react'
import TogglePasswordInput from '../PasswordInput'
import { RuleInvalidIcon, RuleNeutralIcon, RuleValidIcon } from './RuleStatusIcons'
import './PasswordInput.less'

interface PasswordRule {
  isSatisfied: (input: string) => boolean
  rule: string
}

export const passwordRules: PasswordRule[] = [
  {
    isSatisfied: (password) => password.length > 9,
    rule: 'At least 10 characters',
  },
  {
    isSatisfied: (password) => /\d/.test(password),
    rule: 'At least 1 number',
  },
  {
    isSatisfied: (password) => /[A-Z]/.test(password),
    rule: 'At least 1 upper case character',
  },
]

interface RuleIndicatorProps {
  text: string
  ruleSatisfied?: boolean
  key: Key
}

const RuleIcons = {
  valid: RuleValidIcon,
  invalid: RuleInvalidIcon,
  neutral: RuleNeutralIcon,
}

const RuleIndicator = ({ text, ruleSatisfied }: RuleIndicatorProps): JSX.Element => {
  const status = ruleSatisfied !== undefined ? (ruleSatisfied ? 'valid' : 'invalid') : 'neutral'
  const Icon = RuleIcons[status]

  return (
    <li className={`rule-indicator ${status}`}>
      <Icon />
      &nbsp;
      <span className='rule-text'>{text}</span>
    </li>
  )
}

interface PasswordInputProps {
  id?: string
  password: string
  setPassword: (input: string) => void
  ref?: MutableRefObject<HTMLInputElement | null> // can use this to call reportValidity() directly
}

const PasswordInput: React.FC<PasswordInputProps> = ({ id, ref, password, setPassword }) => {
  const [shouldValidate, setShouldValidate] = useState<boolean | undefined>(!!password)
  const validate = (pw: string): boolean =>
    passwordRules.every(({ isSatisfied }) => isSatisfied(pw))
  const onChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const customValidity = validate(target.value) ? '' : 'Password requirements not met'
    setPassword(target.value)
    target.setCustomValidity(customValidity) // form submit or input.reportValidity() also required
    setShouldValidate(true)
  }

  return (
    <>
      <TogglePasswordInput
        id={id}
        value={password}
        autoComplete='new-password'
        onChange={onChange}
        required
        forwardRef={ref}
      />
      <ul className='password-conditions' role='list' aria-live='polite'>
        {passwordRules.map(({ isSatisfied, rule }) => (
          <RuleIndicator
            ruleSatisfied={shouldValidate ? isSatisfied(password) : undefined}
            text={rule}
            key={rule}
          />
        ))}
      </ul>
    </>
  )
}

export default PasswordInput
