import { MouseEventHandler, useEffect, useRef, useState } from 'react'
import ProcessOverview from '../../../ProcessOverview/LeadFeed'
import './Modal.less'
import './ModalAnimation.less'
import { CloseIconSVG, InfoIconSVG } from './Icons'
import Dialog from '../../../../UI/Dialog/Dialog'
import useStateTransition from '../../../../../Hooks/useStateTransition'

interface MessageModalProps {
  isDirectEnquiry: boolean
  triggerFadeOut?: boolean
}

const MessagingModal = ({
  isDirectEnquiry,
  triggerFadeOut,
}: MessageModalProps): JSX.Element | null => {
  const openButtonRef = useRef<HTMLButtonElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [{ state: hideButton, transitioning: buttonTransitioning }, setHideButton] =
    useStateTransition(triggerFadeOut, 400)
  const buttonFadingOut = !hideButton && buttonTransitioning

  useEffect(() => {
    const onWindowScroll: EventListenerOrEventListenerObject = () => {
      if (window.scrollY > 16) return setHideButton(true)

      setHideButton(false)
    }
    window.addEventListener('scroll', onWindowScroll)

    return () => window.removeEventListener('scroll', onWindowScroll)
  }, [])

  const handleClose: MouseEventHandler<HTMLButtonElement> = () => {
    setIsOpen(false)
    openButtonRef.current?.focus()
  }
  const handleOpen: MouseEventHandler<HTMLButtonElement> = () => setIsOpen(true)

  return (
    <>
      <Dialog isModal={true} isOpen={isOpen && !triggerFadeOut} className='messaging-dialog'>
        <ProcessOverview isDirectEnquiry={isDirectEnquiry} />
        <button className='hb-button modal-close' onClick={handleClose} type='button'>
          Close
          <CloseIconSVG className='close-icon' />
        </button>
      </Dialog>
      <button
        className={`hb-button message-modal-open${isOpen ? ' modal-is-open' : ''}${
          buttonFadingOut ? ' hide' : hideButton ? ' hidden' : ' show'
        }`}
        disabled={triggerFadeOut}
        tabIndex={buttonFadingOut || hideButton ? -1 : 0}
        onClick={handleOpen}
        type='button'
        ref={openButtonRef}>
        How it works
        <InfoIconSVG />
      </button>
    </>
  )
}

export default MessagingModal
