import { HEADBOX_ROOT_SERVICE } from '../../Constants'
import './PseudoHeader.less'
import './PseudoHeaderAnimation.less'

const HeadBoxLogo = (): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 2340 450'
    width='200'
    aria-hidden='true'
    className='logo'>
    <path
      fill='currentColor'
      d='M2002.3 82.2h-279.6c-6.7 0-12.2 5.2-12.2 11.9v278.5c.2 6.6 5.5
      11.9 12.1 11.9h63.4v-46.9h-27.2V129.2h207.3v208.3h-112.7l-68.2 89.3 25.3 20.5c5.3 4.2 13
      3.2 17.1-2.1l47.8-60.8h126.7c6.6 0 12-5.3 12.2-11.9v-278c.1-6.7-5.3-12.3-12-12.3M2341.2
      82h-41.5s-9.8.1-16.6 3.6c-4.5 2.3-13.2 13.1-13.2 13.1l-76.1 99.8-89.1-116.6h-55.1l115.9
      153.5-114.7 148.4h45.2s6.3-.2 11.7-3.1c5.2-2.8 9.3-7.5 9.3-7.5l76.8-100.5 83.6
      111.2h57.9L2222 235.4 2341.2 82zM1607.5 321.9c-13.2 12.5-24.3 16.2-40.1
      16.7l-3.6.1h-118.6v-135c16.7 0 116-.1 116.7-.1 18.8 0 33.2 5.9 45.2 18.5 12.6 13.2 23.1
      27.7 23.1 48.4.1 19.9-9.6 38.8-22.7 51.4M1545.2 46s23.9 1 37 14.2c10.6 10.7 18.3 24.6
      18.3 42 0 16.7-6.2 28.5-16.9 39.1-16.1 16.1-38.3 15.8-38.3 15.8l-100.1 1.3V47.3c15.4-.2
      100.1-1.3 100-1.3m69.6 126.1c22.8-21.1 30.3-42.2 31.4-69.4
      1.1-27.3-7.6-50.4-24.9-68.5-15.5-16.3-31-30.7-71.5-31.7l-104.7-.1h-34.4-.5c-.1
      0-5.9-.4-9.6 3.3-3.5 3.6-3.3 8.9-3.3 8.9v368.3h167.6c.5 0 31.8-2.1 46.4-9.8 19.6-10.4
      60.4-34.8 64.1-101.5 1.7-31-13.7-59-33.3-79.7-8.1-8.8-17.8-14.4-27.3-19.8M256.2 5.9c-3.5
      3.7-3.3 9.3-3.3 9.3v144.3h-205V2.3H12S7.6 2 3.8 5.6C.3 8.8 0 14.2 0 14.2v370h36.7s4.8-.2
      7.5-3.1c4-4.3 3.8-7.4 3.8-7.4v-168h205v178.5h36.8s4.4-.1 7.7-3.8c3.1-3.3 3.2-8.3
      3.2-8.3V2.4h-34.9s-5.9-.5-9.6 3.5M1193.3 345.3c-61.7 0-111.8-50-111.8-111.8s50-111.8
      111.8-111.8 111.8 50 111.8 111.8-50 111.8-111.8 111.8M1306.1 5.9c-3.5 3.7-3.3 9.3-3.3
      9.3v105.7c-28.3-27.5-66.9-44.5-109.4-44.5-86.8 0-157.2 70.4-157.2 157.2s70.4 157.2
      157.2 157.2c42.6 0 81.1-17 109.4-44.5v38h36.8s4.4-.1 7.7-3.8c2.3-2.5 3-6 3.1-7.5
      0-.3.1-.5.1-.5V2.4h-34.9c.1 0-5.8-.5-9.5 3.5M387.9 202.1c13.7-44.8 55.4-77.4
      104.6-77.4s90.9 32.6 104.6 77.4H387.9zM492.5 77.7c-86.4 0-156.5 70.1-156.5 156.5s70.1
      156.5 156.5 156.5c62.4 0 116.2-36.5 141.3-89.4L602.7 285s-4-2.5-8.1-1.3c-1.3.4-5.3
      1.5-6.9 4.3-18.8 33.2-54.4 55.6-95.2 55.6-55.7
      0-101.6-41.6-108.5-95.3l264.3-.5c.4-4.5.6-9 .6-13.6 0-11-1.1-21.7-3.3-32C631 131 568
      77.7 492.5 77.7M842.5 346.4c-61.7 0-111.8-50-111.8-111.8s50-111.8 111.8-111.8c61.7 0
      111.8 50 111.8 111.8s-50.1 111.8-111.8 111.8M955.2 90.9c-3.5 3.7-3.3 9.3-3.3
      9.3V122C923.6 94.5 885 77.5 842.5 77.5c-86.8 0-157.1 70.4-157.1 157.2s70.4 157.2 157.1
      157.2c42.6 0 81.1-17 109.4-44.5v36.8H988.8s4.1-.1 7.5-3.4c3.1-2.9 3.4-7.2
      3.4-7.2V87.3h-34.9c0 .1-5.9-.3-9.6 3.6'></path>
  </svg>
)

const PseudoHeader = (): JSX.Element => (
  <header className='pseudo-header' style={{ height: 64 }}>
    <a href={HEADBOX_ROOT_SERVICE} title='HeadBox'>
      <span className='visually-hidden'>Home</span>
      <HeadBoxLogo />
    </a>
  </header>
)

export default PseudoHeader
