import isDirectEnquiryBrief from '../../../../Logic/Form/Sequences/DirectEnquiry/isDirectEnquiryBrief'
import MessagingAside from './Aside'
import MessagingModal from './Modal'
import useLayout from '../../../../Hooks/Layout/Base/useLayout'
import useBriefContext from '../../../../Hooks/Brief/useBriefContext'
import useStateTransition from '../../../../Hooks/useStateTransition'
import { useEffect } from 'react'

const LeadFeedMessaging = (): JSX.Element | null => {
  const { brief } = useBriefContext()
  const isDirectEnquiry = isDirectEnquiryBrief(brief)
  const { currentLayout } = useLayout()
  const showBizLayout = currentLayout === 'biz'
  const [{ state: hideMessaging }, setHideMessaging] = useStateTransition(showBizLayout, 1000)

  useEffect(() => {
    if (showBizLayout === hideMessaging) return

    setHideMessaging(showBizLayout)
  }, [showBizLayout])

  if (hideMessaging) return null

  return (
    <>
      <MessagingAside isDirectEnquiry={isDirectEnquiry} triggerFadeOut={showBizLayout} />
      <MessagingModal isDirectEnquiry={isDirectEnquiry} triggerFadeOut={showBizLayout} />
    </>
  )
}

export default LeadFeedMessaging
