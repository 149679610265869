import { AU_CITIES_LOCATIONS } from '../../../Constants'
import { LocationAttributes } from '../../../Types/Location'
import { GeocoderJSONResult } from '../GoogleAPI/fetchGeocoderLocations'
import { calculateSphericalDistance } from '../index'

type PatchAULocationArgs = Pick<LocationAttributes, 'lat' | 'lon' | 'location'>

export const patchAULocation = ({ location, lat, lon }: PatchAULocationArgs): string => {
  const matchedAUCity = AU_CITIES_LOCATIONS.find(
    (location) =>
      calculateSphericalDistance({ lat, lon }, { lat: location.lat, lon: location.lon }) <
      location.radiusKM * 1000
  )
  return matchedAUCity ? rebuildAddress(location, matchedAUCity.name) : location
}

const rebuildAddress = (address: string, city: string): string => {
  const addressArray = address.split(', ')
  addressArray.splice(-1, 0, city)
  return addressArray.join(', ')
}

interface ReleventGeocodeParams {
  formatted_address: string
  country?: string
}
export const getGeocodeCountry = (result: GeocoderJSONResult): string | undefined =>
  result.address_components.find((component) => component.types.includes('country'))?.short_name

// This seems to favour less specific address results, where present; it includes some (redundant?)
// checks for props which should always be present.
export const optimiseAddressResult = (results: GeocoderJSONResult[]): ReleventGeocodeParams => {
  const newResults = results.filter((result) => {
    return (
      result.types.indexOf('plus_code') === -1 &&
      !!result.formatted_address &&
      !!getGeocodeCountry(result) &&
      result.geometry.location_type.toString() === 'APPROXIMATE' &&
      !(
        result.types.indexOf('route') > -1 &&
        result.address_components.some((comp) => comp.long_name.toLowerCase().includes('unnamed'))
      )
    )
  })
  const bestResult = newResults.length ? newResults[0] : results[0]
  return { formatted_address: bestResult.formatted_address, country: getGeocodeCountry(bestResult) }
}
