import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import resources from '../../locales'

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // see: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: false, // set to true to enable info-logging to console
    resources,
  })

export default i18n
