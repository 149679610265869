import { ReactNode } from 'react'
import { useSearchParams } from 'react-router-dom'
import MetaTitle from '../UI/MetaTitle'
import './Page.less'
import './PageAnimation.less'

export interface PageProps {
  metaTitle: string
  header?: ReactNode // Consider including an <h1> for accessibility unless heading also used
  heading?: ReactNode // Heading is always injected as a h1 if present
  children: ReactNode
  alert?: ReactNode
  footer?: ReactNode
  className?: string
  hidden?: boolean
}

const Page = ({
  children,
  metaTitle,
  header,
  heading,
  alert,
  footer,
  className,
  hidden = false,
}: PageProps): JSX.Element => {
  const [params] = useSearchParams()
  const accessibleMetaTitle = metaTitle.replace(' - HeadBox', '')
  const reverseTransition =
    params.get('reverse-transition') === 'true' || params.get('return-to-summary') === 'true'

  return (
    <main className={hidden ? 'hidden' : ''}>
      <MetaTitle title={metaTitle} />
      <section
        className={`page-content page-transition ${className ? className : ''} ${
          reverseTransition ? 'reverse-transitions' : 'slide-in'
        }`}>
        {alert}
        {header}
        {heading && (
          <h1>
            <span className='visually-hidden'>{accessibleMetaTitle} - </span>
            {heading}
          </h1>
        )}
        {children}
      </section>
      {footer}
    </main>
  )
}

export default Page
