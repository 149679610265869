import { nameToIndefiniteArticle, capitalise } from '../../Validation/Utilities/stringHelpers'

export interface MessageOverrides {
  patternMismatch?: string
}
const getValueMissingMessage = (fieldName: string): string =>
  `Please enter ${nameToIndefiniteArticle(fieldName)}`
const getRangeUnderflowMessage = (fieldName: string, min: string): string =>
  `${capitalise(fieldName)} must be at least ${min}`
const getRangeOverflowMessage = (fieldName: string, max: string): string =>
  `${capitalise(fieldName)} must be ${max} or less`
const getPatternMismatchMessage = (fieldName: string): string =>
  `${capitalise(fieldName)} doesn't match the expected format`
const getMinLengthMessage = (fieldName: string, minLength: number): string =>
  `${capitalise(fieldName)} must be ${minLength} characters or longer`
const getMaxLengthMessage = (fieldName: string, maxLength: number): string =>
  `${capitalise(fieldName)} must be ${maxLength} characters or shorter`
const getStepMismatchMessage = (fieldName: string, step: string) =>
  step === '1'
    ? `${capitalise(fieldName)} must be a whole number`
    : `${capitalise(fieldName)} must be a multiple of ${step}`
const getInvalidInputMessage = (fieldName: string): string => `Please enter a valid ${fieldName}`

const getValidationMessage = (
  input: EventTarget & HTMLInputElement,
  fieldName: string,
  overrides?: MessageOverrides
): string | null => {
  if (input.validity.valid) return null
  if (input.validity.valueMissing) return getValueMissingMessage(fieldName)
  if (input.validity.rangeUnderflow) return getRangeUnderflowMessage(fieldName, input.min)
  if (input.validity.rangeOverflow) return getRangeOverflowMessage(fieldName, input.max)
  if (input.validity.tooLong) return getMaxLengthMessage(fieldName, input.maxLength)
  if (input.validity.tooShort) return getMinLengthMessage(fieldName, input.minLength)
  if (input.validity.customError) return input.validationMessage
  if (input.validity.patternMismatch)
    return overrides?.patternMismatch ?? getPatternMismatchMessage(fieldName)
  if (input.validity.stepMismatch) return getStepMismatchMessage(fieldName, input.step)
  if (input.validity.badInput) return getInvalidInputMessage(fieldName)
  if (input.validity.typeMismatch) return getInvalidInputMessage(fieldName)

  return getInvalidInputMessage(fieldName)
}

export default getValidationMessage
