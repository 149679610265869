// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root .biz-auth-page form > * {
  display: block;
}
app-root .biz-auth-page form .password-input-wrapper {
  margin: 0 auto;
  width: min-content;
}
app-root .biz-auth-page form strong.email {
  margin-bottom: 16px;
  color: var(--border-grey);
}
app-root .biz-auth-page form label {
  color: var(--border-grey);
}
app-root .biz-auth-page form strong.loading-wrapper {
  will-change: height;
  height: 0;
  transition: 0.25s ease-in-out height;
}
app-root .biz-auth-page form strong.loading-wrapper .indeterminate-progress {
  padding: 0.25em;
}
app-root .biz-auth-page form strong.loading-wrapper.loading {
  height: calc(48px + 0.25em);
  margin-bottom: 8px;
}
app-root .biz-auth-page form a.reset-password {
  display: inline-block;
  text-decoration: none;
  font-weight: 500;
  color: #338ac4;
}
`, "",{"version":3,"sources":["webpack://./src/Views/FormSteps/User/Authentication/Biz/AuthenticationPage.less"],"names":[],"mappings":"AAAA;EAIQ,cAAA;AAFR;AAFA;EAOQ,cAAA;EACA,kBAAA;AAFR;AANA;EAWQ,mBAAA;EACA,yBAAA;AAFR;AAVA;EAcc,yBAAA;AADd;AAbA;EAiBQ,mBAAA;EACA,SAAA;EACA,oCAAA;AADR;AAlBA;EAsBU,eAAA;AADV;AAIQ;EACE,2BAAA;EACA,kBAAA;AAFV;AAzBA;EA+BQ,qBAAA;EACA,qBAAA;EACA,gBAAA;EACA,cAAA;AAHR","sourcesContent":["app-root {\n  .biz-auth-page {\n    form {\n      > * {\n        display: block;\n      }\n      .password-input-wrapper {\n        margin: 0 auto;\n        width: min-content;\n      }\n      strong.email {\n        margin-bottom: 16px;\n        color: var(--border-grey);\n      }\n      label { color: var(--border-grey);}\n\n      strong.loading-wrapper {\n        will-change: height;\n        height: 0;\n        transition: 0.25s ease-in-out height;\n\n        .indeterminate-progress {\n          padding: 0.25em\n        }\n\n        &.loading {\n          height: calc(48px + 0.25em);\n          margin-bottom: 8px;\n        }\n      }\n      a.reset-password {\n        display: inline-block;\n        text-decoration: none;\n        font-weight: 500;\n        color: #338ac4;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
