// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root svg.star-rating {
  enable-background: new 0 0 512 96;
}
app-root svg.star-rating .st0 {
  fill: #00B67A;
}
app-root svg.star-rating .st1 {
  fill: #DCDCE6;
}
app-root svg.star-rating .st2 {
  fill: #FFFFFF;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Brand/ProcessOverview/LeadFeed/TrustPilot/Icons.less"],"names":[],"mappings":"AAAA;EAEI,iCAAA;AAAJ;AAFA;EAIU,aAAA;AACV;AALA;EAKU,aAAA;AAGV;AARA;EAMU,aAAA;AAKV","sourcesContent":["app-root {\n  svg.star-rating {\n    enable-background: new 0 0 512 96;\n\n    .st0{ fill:#00B67A; }\n    .st1{ fill: #DCDCE6; }\n    .st2{ fill: #FFFFFF; }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
