// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root article.process-explainer {
  padding: 24px 0;
  background: white;
  border: 1px solid #BFBFBF;
  border-radius: 8px;
  text-align: center;
}
app-root article.process-explainer header {
  padding: 0 16px;
}
app-root article.process-explainer h3 {
  font-size: 1.5rem;
  color: #1F1F1F;
}
app-root article.process-explainer > * {
  padding-left: 32px;
  padding-right: 32px;
}
app-root article.process-explainer ol.process-overview .list-text {
  display: block;
  margin: 0.25rem auto 0 auto;
}
app-root article.process-explainer ol.process-overview li {
  margin-bottom: 16px;
}
app-root article.process-explainer ol.process-overview li:last-of-type {
  margin-bottom: 24px;
}
app-root article.process-explainer footer {
  border-top: 1px solid #BFBFBF;
  padding: 24px 16px 0 16px;
}
app-root article.process-explainer footer a {
  display: inline-block;
  width: 100%;
  border-radius: 8px;
  padding: 2px 1rem 0 1rem;
  outline-offset: 2px;
  outline-color: #a8a8a8;
}
app-root article.process-explainer footer a svg {
  min-width: 90px;
  width: 45%;
  max-width: 120px;
}
app-root article.process-explainer footer a svg:first-of-type {
  margin-right: 10%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Brand/ProcessOverview/LeadFeed/ProcessOverview.less"],"names":[],"mappings":"AAEA;EAEI,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,kBAAA;EACA,kBAAA;AAFJ;AAJA;EAOa,eAAA;AAAb;AAPA;EASM,iBAAA;EACA,cAAA;AACN;AAXA;EAcM,kBAAA;EACA,mBAAA;AAAN;AAfA;EAmBQ,cAAA;EACA,2BAAA;AADR;AAnBA;EAwBQ,mBAAA;AAFR;AAGQ;EAAiB,mBAAA;AAAzB;AAzBA;EA8BM,6BAAA;EACA,yBAAA;AAFN;AA7BA;EAkCQ,qBAAA;EACA,WAAA;EACA,kBAAA;EACA,wBAAA;EACA,mBAAA;EACA,sBAAA;AAFR;AArCA;EA2CU,eAAA;EACA,UAAA;EACA,gBAAA;AAHV;AAAU;EAAkB,iBAAA;AAG5B","sourcesContent":["@import '../../../../Styles/colours.less';\n\napp-root {\n  article.process-explainer {\n    padding: 24px 0;\n    background: white;\n    border: 1px solid @faint-grey;\n    border-radius: 8px;\n    text-align: center;\n    header { padding: 0 16px; }\n    h3 {\n      font-size: 1.5rem;\n      color: @text-black;\n    }\n\n    > * {\n      padding-left: 32px;\n      padding-right: 32px;\n    }\n    ol.process-overview {\n      .list-text {\n        display: block;\n        margin: 0.25rem auto 0 auto;\n      }\n\n      li {\n        margin-bottom: 16px;\n        &:last-of-type { margin-bottom: 24px;}\n      }\n    }\n    \n    footer {\n      border-top: 1px solid @faint-grey;\n      padding: 24px 16px 0 16px;\n\n      a {\n        display: inline-block;\n        width: 100%;\n        border-radius: 8px;\n        padding: 2px 1rem 0 1rem;\n        outline-offset: 2px;\n        outline-color: @faint-outline-grey;\n\n        svg {\n          &:first-of-type { margin-right: 10%; }\n          min-width: 90px;\n          width: 45%;\n          max-width: 120px;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
