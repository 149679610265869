import { MouseEventHandler, MutableRefObject, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { BriefFormRef } from '../../Components/Form/Form/Form'

interface UseSubmitOnClick {
  submitOnClick: MouseEventHandler<HTMLInputElement>
  formRef: MutableRefObject<BriefFormRef>
}
const useSubmitOnClick = (): UseSubmitOnClick => {
  const formRef = useRef<BriefFormRef>(null)
  const [willSubmit, setWillSubmit] = useState(false)
  const [searchParams] = useSearchParams()
  const editMode = searchParams.get('return-to-summary') === 'true'
  const submit = () => {
    const formSubmit = formRef?.current?.submit
    if (formSubmit === undefined) throw new Error('Submit ref not defined for onClick submission')

    formSubmit()
    setWillSubmit(false)
  }

  useEffect(() => {
    if (!willSubmit || editMode) return // auto-progression disabled when editing via summary

    const submitTimer = setTimeout(submit, 450)

    return () => clearTimeout(submitTimer)
  }, [willSubmit, submit])

  const submitOnClick: UseSubmitOnClick['submitOnClick'] = (event) => {
    if (event.clientX === 0 && event.clientY === 0) return

    setWillSubmit(true)
  }

  return {
    submitOnClick,
    formRef,
  }
}

export default useSubmitOnClick
