import { Currency } from '../../../Constants/currencies'
import { ActiveLocale, isActiveLocale } from '../../../Types/Location'

const currenciesByCountry: Record<ActiveLocale, Currency> = {
  GB: 'GBP',
  US: 'USD',
  AU: 'AUD',
  IE: 'EUR',
}

const getCurrencyByCountry = (country?: string | null): Currency => {
  if (isActiveLocale(country)) return currenciesByCountry[country]

  return 'EUR'
}

export default getCurrencyByCountry
