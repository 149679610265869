import { ACTIVE_LOCALES } from '../../Constants'
import { Currency } from '../../Constants/currencies'
import { Brief } from '../generated-types'

export type ActiveLocale = typeof ACTIVE_LOCALES[number]

export const isActiveLocale = (country?: string | null): country is ActiveLocale =>
  !!country && ACTIVE_LOCALES.includes(country as ActiveLocale)

export interface Coordinates {
  lat: number
  lon: number
}

export interface Radius {
  distance: number
  unit: 'mi' | 'km'
}

export type LocationAttributes = Pick<
  Brief,
  'lat' | 'lon' | 'country' | 'leadFeedCity' | 'location'
> & {
  currency: Currency
  radius: Radius
}
