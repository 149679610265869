import { PartialBrief } from '../../../../Types/Brief'

interface EventLocationProps {
  brief: PartialBrief
}

const EventLocation = ({ brief }: EventLocationProps): JSX.Element => {
  if (!brief.radius) return <>{brief.location}</>

  const { distance, unit } = brief.radius

  return <>{`${brief.location} + ${distance} ${unit}`}</>
}

export default EventLocation
