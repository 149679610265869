import useBriefContext from '../../../../../../Hooks/Brief/useBriefContext'
import { getGuestLabel } from '../../Utilities/getGuestCopyByEventType'

const GuestLabel = (): JSX.Element => {
  const {
    brief: { collectionName },
  } = useBriefContext()
  const guestLabel = getGuestLabel(collectionName) ?? 'guests'

  return <span aria-hidden={true}>{guestLabel}</span>
}

export default GuestLabel
