// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `dialog[open]::backdrop,
dialog[open] + .backdrop {
  animation: opacity-fade 0.6s ease-in-out normal backwards;
}
@keyframes opacity-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/Dialog/DialogAnimation.less"],"names":[],"mappings":"AAGI;;EACE,yDAAA;AADN;AAKE;EACE;IACE,UAAA;EAHJ;EAKC;IACG,YAAA;EAHJ;AACF","sourcesContent":["// Polyfill styles\ndialog {\n  &[open] {\n    &::backdrop, & + .backdrop {\n      animation: opacity-fade 0.6s ease-in-out normal backwards;\n    }\n  }\n\n  @keyframes opacity-fade {\n    0% {\n      opacity: 0;\n    }\n   100% {\n      opacity: 0.8;\n    }\n    \n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
