import { TimeOption } from '../TimePicker/TimeStamps/timeStamps'

export type TimeFormat = '24-hour' | '12-hour'

export const formatTimeOption = (time: TimeOption, timeFormat: TimeFormat): string => {
  // remove trailing `:[seconds]`
  if (timeFormat === '24-hour') return time.slice(0, 5)

  const [hour, minute, _second] = time.split(':')
  const hours = parseInt(hour)

  if (hours === 0) {
    return `12:${minute} AM`
  }

  if (hours === 12) {
    return `12:${minute} PM`
  }

  if (hours > 12) {
    const twelfthHour = hours % 12
    return `${twelfthHour}:${minute} PM`
  }

  return `${hours}:${minute} AM`
}
