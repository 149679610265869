import { Radius } from '../../../Types/Location'

interface RadiusKM extends Radius {
  unit: 'km'
}

const isRadiusInKM = (radius: Radius): radius is RadiusKM => radius.unit === 'km'

const convertRadiusToKilometres = (radius: Radius): RadiusKM => {
  if (isRadiusInKM(radius)) return radius

  return {
    distance: radius.distance * 1.6, // conversion of miles to km
    unit: 'km',
  }
}

export default convertRadiusToKilometres
