// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root > .brief-builder-provider {
  height: 100%;
}
app-root > .brief-builder-provider.hidden {
  display: none;
  visibility: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/Contexts/Brief/BriefProvider.less"],"names":[],"mappings":"AACE;EACE,YAAA;AAAJ;AACI;EACE,aAAA;EACA,kBAAA;AACN","sourcesContent":["app-root {\n  &> .brief-builder-provider {\n    height: 100%;\n    &.hidden {\n      display: none;\n      visibility: hidden;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
