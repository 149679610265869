import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import GoogleMap from '../../../../Components/GoogleMap/GoogleMap'
import LocationSearchBox, { LocationSearchBoxProps } from './SearchBox/SearchBox'
import { LocationAttributes } from '../../../../Types/Location'
import { fetchLocationByPlaceId, getCurrencyByCountry } from '../../../../Utilities/Location'
import { PartialBrief } from '../../../../Types/Brief'
import { GoogleLocationAttributes } from '../../../../Utilities/Location/fetchLocationByCoordinates'
import useBriefContext from '../../../../Hooks/Brief/useBriefContext'
import { ACTIVE_LOCALES } from '../../../../Constants'
import { CombinedSliderProps } from './CombinedSlider/CombinedSlider'
import { getDefaultRadius, convertRadiusToKilometres } from '../../../../Utilities/Location'

export const getDefaultLocationAttrs = (
  brief: PartialBrief
): LocationAttributesWithoutRadius | undefined => {
  const { lat, lon, country, leadFeedCity, location, currency } = brief
  if (lat !== undefined && lon !== undefined && location !== undefined) {
    return {
      lat,
      lon,
      country,
      leadFeedCity,
      location,
      currency: currency ?? getCurrencyByCountry(country),
    }
  }
  return undefined
}

export type LocationAttributesWithoutRadius = Omit<LocationAttributes, 'radius'>

interface LocationInputProps extends Pick<CombinedSliderProps, 'radius' | 'setRadius'> {
  locationAttributes?: LocationAttributesWithoutRadius
  setLocationAttributes: Dispatch<SetStateAction<LocationAttributesWithoutRadius | undefined>>
  limitSearchToActiveLocales?: boolean,
  children?: React.ReactNode
}

const LocationInput: (props: LocationInputProps) => JSX.Element = ({
  radius,
  setLocationAttributes: _setLocationAttributes,
  setRadius,
  locationAttributes,
  limitSearchToActiveLocales = true,
  children,
}) => {
  const { loading, brief } = useBriefContext()
  const [map, setMap] = useState<google.maps.Map>()
  const [placeId, setPlaceId] = useState<string>()
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService>()
  const center = locationAttributes && {
    lat: locationAttributes.lat,
    lon: locationAttributes.lon,
  }
  const setLocationAttributes = (googleLocation?: GoogleLocationAttributes) => {
    const currency = getCurrencyByCountry(googleLocation?.country)
    const newLocationAttrs = googleLocation && {
      ...googleLocation,
      currency,
    }

    _setLocationAttributes(newLocationAttrs)
  }

  // Update place service in state once Google map loads
  useEffect(() => {
    map && setPlacesService(new google.maps.places.PlacesService(map))
  }, [map])

  // Trigger location attribute lookup after place selection
  useEffect(() => {
    if (!placeId || !placesService) return

    const fetchAndSetLocation = async () => {
      const fetchedLocationAttrs = await fetchLocationByPlaceId({ placeId, placesService })
      setLocationAttributes(fetchedLocationAttrs)
    }

    fetchAndSetLocation()
  }, [placeId])

  // Update location attributes in state once brief-state loading completes
  useEffect(() => {
    if (loading) return

    setLocationAttributes(getDefaultLocationAttrs(brief))
  }, [loading])

  // Update default radius when country changes
  useEffect(() => {
    if (!locationAttributes?.country) return
    const radius = getDefaultRadius({ ...brief, ...locationAttributes })
    setRadius(radius)
  }, [locationAttributes?.country, locationAttributes?.location])

  const handleLocationSelect: LocationSearchBoxProps['setLocation'] = (value) => {
    setPlaceId(value)
  }

  return (
    <>
      <LocationSearchBox
        id='location-search'
        location={locationAttributes?.location ?? ''}
        setLocation={handleLocationSelect}
        searchableCountries={limitSearchToActiveLocales ? ACTIVE_LOCALES : undefined}
      />
      {children}
      <GoogleMap
        radiusKM={convertRadiusToKilometres(radius).distance}
        center={center && { lat: center.lat, lon: center.lon }}
        setMapInParent={setMap}
        show={!!locationAttributes?.location}
      />
    </>
  )
}

export default LocationInput

