import {
  ChangeEventHandler,
  Dispatch,
  FocusEventHandler,
  KeyboardEventHandler,
  SetStateAction,
} from 'react'
import Input, { InputProps } from '../../../../../../Components/Form/Inputs/Generic/Input'
import { capitalise } from '../../../../../../Components/Form/Inputs/Validation/Utilities/stringHelpers'

interface NameInputProps
  extends Omit<
    InputProps,
    | 'value'
    | 'type'
    | 'required'
    | 'minLength'
    | 'maxLength'
    | 'onBlur'
    | 'forwardedRef'
    | 'ref'
    | 'pattern'
    | 'patternMismatch'
  > {
  name: string
  setName: Dispatch<SetStateAction<string>>
}

const NameInput = ({
  name,
  setName,
  fieldName,
  onChange: _onChange,
  ...props
}: NameInputProps): JSX.Element => {
  const capitalisedFieldName = fieldName ? capitalise(fieldName) : 'Name'
  const trimOnBlur: FocusEventHandler<HTMLInputElement> = ({ target }) =>
    setName(target.value.trim())
  const trimOnEnter: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key !== 'Enter') return

    setName(e.currentTarget.value.trim())
  }
  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    _onChange && _onChange(event)
    setName(event.target.value)
  }

  return (
    <Input
      type='text'
      required
      minLength={2}
      maxLength={35}
      pattern={"[\\p{L}'\\-\\. ]+"}
      errorMessageOverrides={{
        patternMismatch: `${capitalisedFieldName} must not contain numbers or special punctuation`,
      }}
      title={`${capitalisedFieldName} may contain any letter from any language, apostrophes, commas and spaces'`}
      fieldName={fieldName}
      onBlur={trimOnBlur}
      onKeyDown={trimOnEnter}
      value={name}
      onChange={onChange}
      {...props}
    />
  )
}

export default NameInput
