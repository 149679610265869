import { ReactNode } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { FormPath } from '../../../Router/Routes/formRoutes'
import './Nav.less'

interface FormNavProps {
  onContinue: () => void
  onBack?: () => void
  submitText?: string
  previousPage?: FormPath
  canContinue: boolean
  returnToSummary?: boolean
  disabled?: boolean
}

type BackLinkProps = Pick<FormNavProps, 'previousPage' | 'onBack' | 'returnToSummary' | 'disabled'>

interface LinkProps {
  href?: string
  onClick?: () => void
  children: ReactNode
}

const Link = ({ href, children, onClick }: LinkProps): JSX.Element => {
  const className = 'hb-button secondary-button'
  if (href !== undefined)
    return (
      <ReactRouterLink className={className} to={href}>
        {children}
      </ReactRouterLink>
    )

  if (onClick !== undefined)
    return (
      <button className={className} type='button' onClick={onClick}>
        {children}
      </button>
    )

  return <a className={className}>{children}</a>
}

const BackLink = ({
  previousPage,
  returnToSummary,
  disabled,
  onBack,
}: BackLinkProps): JSX.Element | null => {
  if (returnToSummary) {
    return (
      <Link href={disabled ? undefined : '/else'}>
        Cancel
        <span className='visually-hidden'> and return to summary</span>
      </Link>
    )
  }

  if (onBack !== undefined) return <Link onClick={onBack}>Back</Link>

  if (previousPage !== undefined) {
    return (
      <Link href={disabled ? undefined : `/${previousPage}?reverse-transition=true`}>Back</Link>
    )
  }

  return null
}

const FormNav = ({
  canContinue,
  onContinue: _onContinue,
  onBack,
  previousPage,
  submitText = 'Continue',
  returnToSummary,
  disabled,
}: FormNavProps): JSX.Element => {
  const submitNode = returnToSummary ? (
    <>
      Update<span className='visually-hidden'> and return to summary</span>
    </>
  ) : (
    submitText
  )

  const onContinue = () => {
    if (!canContinue) window.scrollTo({ top: 0 })
    _onContinue()
  }

  return (
    <nav className='form-nav'>
      <menu className='button-wrapper' role={'list'}>
        <li>
          <button
            type='button'
            className={`hb-button primary-button ${canContinue ? 'permitted' : ''}`}
            onClick={onContinue}
            disabled={disabled}>
            {submitNode}
          </button>
        </li>
        <li>
          <BackLink
            previousPage={previousPage}
            returnToSummary={returnToSummary ?? false}
            disabled={disabled}
            onBack={onBack}
          />
        </li>
      </menu>
    </nav>
  )
}

export default FormNav
