import { PartialBrief } from '../../../../Types/Brief'
import { capitaliseLayout } from '../../../FormSteps/Layout/LayoutInput'

interface LayoutProps {
  brief: PartialBrief
}

export const Layout = ({ brief: { layouts } }: LayoutProps): JSX.Element | null => {
  if (!layouts) return null

  return <>{capitaliseLayout(layouts.replace(/_+/g, ' '))}</>
}

export default Layout
