import { PartialBrief, LeadfeedBriefInput } from '../../../../../Types/Brief'
import convertRadiusToKilometres from '../../../../../Utilities/Location/Radius/convertRadiusToKilometres'
import { dateToMachineString } from '../../../../../Views/FormSteps/DateTime/Utilities/dateStrings'
import { retrieveAndClearContentPageHistory } from '../retrieveAndClearContentPageHistory'

const getSubmissionParams = (brief: PartialBrief): LeadfeedBriefInput => {
  const {
    numonce,
    budget,
    capacities,
    lat,
    lon,
    location,
    radius,
    guest: { id: userId } = {},
    fromDate,
    toDate,
    ...optionalAttrs
  } = brief

  if (
    budget === undefined ||
    capacities === undefined ||
    lat === undefined ||
    lon === undefined ||
    location === undefined ||
    radius === undefined ||
    fromDate === undefined ||
    fromDate === null ||
    toDate === undefined ||
    toDate === null ||
    userId === undefined
  ) {
    throw new Error('Brief missing required attrs for submission') 
  } 

  const zonelessStartDate = dateToMachineString(new Date(fromDate))
  const zonelessEndDate = dateToMachineString(new Date(toDate))
  const includeFlexibilityNote =
    optionalAttrs.isFlexibleOnDate ||
    optionalAttrs.isFlexibleOnTime ||
    optionalAttrs.flexibleDates
  const optionalSpaceFacilityIds = optionalAttrs.optionalSpaceFacilityIds?.filter(
    (optionalId) => !optionalAttrs.requiredSpaceFacilityIds?.includes(optionalId)
  )

  return {
    ...optionalAttrs,
    enquiryContentPageSources: retrieveAndClearContentPageHistory(),
    numonce: ((numonce === null || numonce === undefined  ) ? (Math.floor(Math.random() * 1000000000)) : numonce),
    budget,
    capacities,
    lat: lat.toString(),
    lon: lon.toString(),
    radius: convertRadiusToKilometres(radius).distance,
    flexibleDatesNote: includeFlexibilityNote ? optionalAttrs.flexibleDatesNote : undefined,
    optionalSpaceFacilityIds,
    location,
    fromDate: zonelessStartDate,
    toDate: zonelessEndDate,
    userId,
  }
  
}

export default getSubmissionParams
