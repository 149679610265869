// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-page menu {
  list-style: 'none';
  margin: 0;
  margin-top: 4px;
  padding: 0;
}
.error-page menu li {
  display: inline-block;
}
.error-page menu li:nth-of-type(2) {
  margin: 0 0.75rem;
}
`, "",{"version":3,"sources":["webpack://./src/Views/Errors/UnknownError.less"],"names":[],"mappings":"AAAA;EAEI,kBAAA;EACA,SAAA;EACA,eAAA;EACA,UAAA;AAAJ;AALA;EAQM,qBAAA;AAAN;AACM;EACE,iBAAA;AACR","sourcesContent":[".error-page {\n  menu {\n    list-style: 'none';\n    margin: 0;\n    margin-top: 4px;\n    padding: 0;\n\n    li {\n      display: inline-block;\n      &:nth-of-type(2) {\n        margin: 0 0.75rem;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
