// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root .error-banner {
  text-align: left;
  margin-bottom: 16px;
}
app-root .error-banner .MuiAlertTitle-root {
  font-family: inherit;
}
@media (min-width: 900px) {
  app-root .error-banner {
    margin-top: -24px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/ErrorBanner/ErrorBanner.less"],"names":[],"mappings":"AAEA;EAEI,gBAAA;EACA,mBAAA;AAFJ;AADA;EAKM,oBAAA;AADN;AAII;EAAA;IAA4B,iBAAA;EAA9B;AACF","sourcesContent":["@import '../../Styles/mediaQueries.less';\n\napp-root {\n  .error-banner {\n    text-align: left;\n    margin-bottom: 16px;\n    .MuiAlertTitle-root {\n      font-family: inherit;\n    }\n\n    @media @landscapeTabletUp { margin-top: -24px; }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
