import { useState } from 'react'
import FormPage from '../../../../Components/Form/Page/FormPage'
import useBriefContext from '../../../../Hooks/Brief/useBriefContext'
import InputView from '../../InputView'
import useSubmitOnClick from '../../../../Hooks/Form/useSubmitOnClick'
import EventTypeFieldset, { EventTypeAttributes } from '../Base/Fieldset'

const EventTypeInput: InputView = ({ path }): JSX.Element => {
  const { brief } = useBriefContext()
  const [eventTypeAttrs, setEventTypeAttrs] = useState<EventTypeAttributes>({
    collection: brief.collection,
    collectionName: brief.collectionName,
  })
  const { submitOnClick, formRef } = useSubmitOnClick()

  return (
    <FormPage
      metaTitle='Event type - HeadBox'
      heading='What are you planning?'
      path={path}
      formRef={formRef}
      inputAttributes={eventTypeAttrs}
      className='event-type-page'>
      <EventTypeFieldset
        eventTypeAttrs={eventTypeAttrs}
        setEventTypeAttrs={setEventTypeAttrs}
        onClick={submitOnClick}
      />
    </FormPage>
  )
}

export default EventTypeInput
