import InputView from '../../../../FormSteps/InputView'
import RegistrationFormPage from '../Base/RegistrationFormPage'

const RegistrationPage: InputView = ({ path }) => (
  <RegistrationFormPage
    path={path}
    requirePassword={false}
    heading='Almost there...'
    metaTitle='Contact details - HeadBox'
    marketingCopy={
      <>
        <h4>Stay in the know</h4>
        <p>
          Get the newest and best venues delivered straight to your inbox, once a week. We think
          this will be the start of a beautiful friendship.
        </p>
      </>
    }
  />
)

export default RegistrationPage
