export const MAP_STYLES: google.maps.MapTypeStyle[] = [
  {
    elementType: 'geometry.fill',
    featureType: 'all',
    stylers: [
      {
        weight: 2.0,
      },
    ],
  },
  {
    elementType: 'geometry.stroke',
    featureType: 'all',
    stylers: [
      {
        color: '#9c9c9c',
      },
    ],
  },
  {
    "featureType": "road",
    "elementType": "all",
    "stylers": [
      {
        "saturation": "-25",
      },
      {
        "lightness": "55",
      },
      {
        "gamma": "0.8",
      },
    ],
  },
  {
    elementType: 'labels.text',
    featureType: 'all',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'all',
    featureType: 'landscape',
    stylers: [
      {
        color: '#f2f2f2',
      },
    ],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'landscape',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'landscape.man_made',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'all',
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },

  {
    elementType: 'geometry.fill',
    featureType: 'road',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    featureType: 'road',
    stylers: [
      {
        color: '#7b7b7b',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    featureType: 'road',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    elementType: 'all',
    featureType: 'road.highway',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    featureType: 'road.arterial',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'all',
    featureType: 'transit',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'water',
    stylers: [
      {
        color: '#9c9fa6',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    featureType: 'water',
    stylers: [
      {
        color: '#070707',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    featureType: 'water',
    stylers: [
      {
        color: '#ffffff',
        weight: 2.0,
      },
    ],
  },
]