import { ForwardedRef, forwardRef, TextareaHTMLAttributes } from 'react'
import './TextArea.less'

export interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  forwardedRef?: ForwardedRef<HTMLTextAreaElement | null>
}

const _TextArea = ({ className, forwardedRef, ...props }: TextAreaProps): JSX.Element => (
  <textarea className={`hb-text-area ${className}`} {...props} ref={forwardedRef} />
)

const TextArea = forwardRef<HTMLTextAreaElement | null, TextAreaProps>(function CustomTextArea(
  props,
  ref
) {
  return <_TextArea forwardedRef={ref} {...props} />
})

export default TextArea
