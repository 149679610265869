// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root input[type='checkbox'].hb-checkbox {
  border-radius: 3px;
}
app-root input[type='checkbox'].hb-checkbox:focus {
  outline-style: solid;
  outline-width: 2px;
  outline-offset: 2px;
}
app-root input[type='checkbox'].hb-checkbox::before {
  border-radius: 3px;
  background-color: #595959;
}
app-root input[type='checkbox'].hb-checkbox + label {
  font-weight: 500;
  color: #595959;
  will-change: transform, color;
  transition: transform 0.15s ease-out, color 0.15s ease-out;
  transform-origin: left;
}
app-root input[type='checkbox'].hb-checkbox + label:hover {
  color: #262626;
}
app-root input[type='checkbox'].hb-checkbox + label:active {
  transform: scale(0.99);
}
app-root input[type='checkbox'].hb-checkbox:active,
app-root input[type='checkbox'].hb-checkbox:active + label {
  transform: scale(0.99);
}
`, "",{"version":3,"sources":["webpack://./src/Components/Form/Inputs/Checkbox/Input/CheckboxInput.less"],"names":[],"mappings":"AAEA;EAEI,kBAAA;AAFJ;AAII;EACE,oBAAA;EACA,kBAAA;EACA,mBAAA;AAFN;AAKI;EACE,kBAAA;EACA,yBAAA;AAHN;AATA;EAgBM,gBAAA;EACA,cAAA;EAEA,6BAAA;EACA,0DAAA;EACA,sBAAA;AALN;AAOM;EAAU,cAAA;AAJhB;AAKM;EACE,sBAAA;AAHR;AAMI;;EAA6B,sBAAA;AAFjC","sourcesContent":["@import '../../../../../Styles/colours.less';\n\napp-root {\n  input[type='checkbox'].hb-checkbox {\n    border-radius: 3px;\n\n    &:focus {\n      outline-style: solid;\n      outline-width: 2px;\n      outline-offset: 2px;\n    }\n\n    &::before {\n      border-radius: 3px;\n      background-color: @border-grey;\n    }\n\n    + label {\n      font-weight: 500;\n      color: @border-grey;\n\n      will-change: transform, color;\n      transition: transform 0.15s ease-out, color 0.15s ease-out;\n      transform-origin: left;\n      \n      &:hover { color: @selection-grey; }\n      &:active {\n        transform: scale(0.99);\n      }\n    }\n    &:active, &:active + label { transform: scale(0.99); }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
