import { findLeadFeedCity } from '.'
import { getLocationCoordsOverrides } from '../../Types/Location/getLocationOverrides'
import { patchAULocation } from './Address'
import { GoogleLocationAttributes } from './fetchLocationByCoordinates'

interface FetchLocationByPlaceId {
  placeId: string
  placesService: google.maps.places.PlacesService
}

const fetchLocationByPlaceId = async (
    { placeId, placesService }: FetchLocationByPlaceId
): Promise<GoogleLocationAttributes | undefined> => (
  new Promise((resolve) => {
    placesService.getDetails({
      placeId, fields: ['geometry', 'address_components', 'formatted_address'],
    }, (result) => {
      const { lat, lng } = result?.geometry?.location ?? {}
      const { address_components, formatted_address } = result ?? {}
      
      if (!lat || !lng || !address_components || !formatted_address) return resolve(undefined)
      

      const country = address_components.find(({types}) =>
        types.includes('country')
      )?.short_name

      const address = country === 'AU' ? patchAULocation({ location: formatted_address, lat: lat(),
                                                           lon: lng() })
                                       : formatted_address;
      const override = getLocationCoordsOverrides(address)

      const coords = { lat: override?.lat ?? lat(), lon: override?.lng ?? lng()}
      const leadFeedCity = findLeadFeedCity(coords)

      resolve({
        country,
        lat: coords.lat,
        leadFeedCity,
        location: address,
        lon: coords.lon,
      })
    })
  })
)

export default fetchLocationByPlaceId

