import { useTranslation } from 'react-i18next'
import '../../Base/ProcessCopy.less'

interface ProcessProps {
  isDirectEnquiry: boolean
  className?: string
}

const ProcessCopy = ({ isDirectEnquiry, className }: ProcessProps): JSX.Element => {
  const { t } = useTranslation('brandContent')

  return (
    <ol className={`process-overview ${className ? className : ''}`}>
      <li>
        <span className='list-text'>{t('processOverview.step-1')}</span>
      </li>
      <li>
        <span className='list-text'>
          {t(isDirectEnquiry ? 'processOverview.step-2-direct-enquiry' : 'processOverview.step-2')}
        </span>
      </li>
      <li>
        <span className='list-text'>{t('processOverview.step-3')}</span>
      </li>
    </ol>
  )
}

export default ProcessCopy
