import { SVGAttributes } from 'react'

export const TickIconSVG = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    className='tick-icon'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <circle cx='8' cy='8' r='8' fill='white' />
    <path
      d='M4 9L6.5 11.5L12 5'
      stroke='#AF231C'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
