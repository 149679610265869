import BRIEF_DEFAULTS_BY_EVENT_TYPE from './briefDefaultsByEventType'

const EVENT_TYPES = [
  'Party',
  'Private Dining',
  'Meeting/Workshop',
  'Team Activity',
  'Presentation',
  'Wedding',
  'Networking',
  'Studio',
  'Conference',
  'Training',
  'Something Else',
] as const

export type EventType = (typeof EVENT_TYPES)[number]

const isEventType = (eventType?: string | null): eventType is EventType =>
  !!eventType && eventType in BRIEF_DEFAULTS_BY_EVENT_TYPE

export default isEventType
