import { Coordinates } from "../../Types/Location"

const EARTH_RADIUS = 6378137

// ArcTangent Haversine; result In metres
const calculateSphericalDistance = (
  pointA: Coordinates, pointB: Coordinates, radius: number = EARTH_RADIUS
): number => {
  const { lat: latA, lon: lonA } = pointA
  const { lat: latB, lon: lonB } = pointB

  // Both in Radians
  const angularLatDiff = (latB - latA) * (Math.PI / 180)
  const angularLoniff = (lonB - lonA) * (Math.PI / 180)

  // Square of half the chord length between the points.
  const halfChordSquare = Math.pow(Math.sin(angularLatDiff / 2), 2) +
    Math.cos(latA * (Math.PI / 180)) * Math.cos(latB * (Math.PI / 180)) *
    Math.pow(Math.sin(angularLoniff / 2), 2)

  const angularDistanceRadians = 2 * Math.atan2(
    Math.sqrt(halfChordSquare),
    Math.sqrt(1 - halfChordSquare)
  )

  return angularDistanceRadians * radius
}

export default calculateSphericalDistance
