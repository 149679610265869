// AUTO-GENERATED CODE, DO NOT EDIT!
//
// These types are generated from the schema
// base types and should not be used
//
/* eslint-disable */

export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  JSON: any
}

export enum Audience {
  Personal = 'personal',
  Professional = 'professional',
}

export enum BookingIntent {
  High = 'high',
  Low = 'low',
  Medium = 'medium',
}

/** Type of a normal brief. */
export type Brief = {
  __typename?: 'Brief'
  additionalNotes?: Maybe<Scalars['String']>
  /** Is this a personal or professional event? */
  audience?: Maybe<Audience>
  /** How likely is the guest to book? */
  bookingIntent?: Maybe<BookingIntent>
  budget: Scalars['Float']
  budgetNotes?: Maybe<Scalars['String']>
  capacities: Scalars['Int']
  /** Archived */
  catering?: Maybe<Array<Scalars['String']>>
  cateringNeeds?: Maybe<Scalars['String']>
  collection: Scalars['JSON']
  collectionName: Scalars['String']
  country?: Maybe<Scalars['String']>
  createdAt: Scalars['String']
  currency: Scalars['String']
  /** Deal ID on Hubspot */
  dealId?: Maybe<Scalars['String']>
  /** Event From (Full DateTime) */
  eventDateFrom: Scalars['String']
  /** Event To (Full DateTime) */
  eventDateTo: Scalars['String']
  eventName: Scalars['String']
  /**
   * List of facility ids as strings
   * @deprecated Features renamed to SpaceFacilities and split by whether they are required; use optional/required variants instead. GATEWAY_EXCLUDED
   */
  features?: Maybe<Array<Scalars['String']>>
  /**
   * List of facility names
   * @deprecated Features renamed to SpaceFacilities and split by whether they are required; use optional/required variants instead. GATEWAY_EXCLUDED
   */
  featuresList?: Maybe<Array<Scalars['String']>>
  /** @deprecated Superceded by isFlexibleOnDate and isFlexibleOnTime in Lead Feed */
  flexibleDates: Scalars['Boolean']
  /** Additional notes on flexibility. Used with legacy flexible_dates and new flexible_on_date/time fields */
  flexibleDatesNote?: Maybe<Scalars['String']>
  fromDate: Scalars['String']
  fromTime: Scalars['String']
  guest: BriefGuest
  /** Guest (User) ID */
  guestId: Scalars['ID']
  /** Host (User) ID */
  hostId?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  /** Date-specific flexibility field for Lead Feed */
  isFlexibleOnDate?: Maybe<Scalars['Boolean']>
  /** Time-specific flexibility field for Lead Feed */
  isFlexibleOnTime?: Maybe<Scalars['Boolean']>
  lat: Scalars['Float']
  layout: Scalars['String']
  leadFeedCity?: Maybe<Scalars['String']>
  location: Scalars['String']
  lon: Scalars['Float']
  /** The maximum number of undeclined messages this brief will be able to receive. The value comes from e-tenders */
  messageLimit?: Maybe<Scalars['Int']>
  numonce?: Maybe<Scalars['Int']>
  /** The space facilities considered desirable by the guest */
  optionalSpaceFacilities: Array<SpaceFacility>
  public: Scalars['Boolean']
  radius: Scalars['Float']
  /** The space facilities considered essential by the guest */
  requiredSpaceFacilities: Array<SpaceFacility>
  /**
   * List of all space facilities
   * @deprecated Lead Feed now distuinguishes between optional and required facilities
   */
  spaceFacilities: Array<SpaceFacility>
  spaceId?: Maybe<Scalars['ID']>
  status: Scalars['String']
  styleIds: Array<Scalars['String']>
  tier: Scalars['String']
  toDate: Scalars['String']
  toTime: Scalars['String']
  /** Guest (User) ID */
  userId: Scalars['ID']
  virtual: Scalars['Boolean']
}

/**
 * Attributes for creating or updating a brief.
 *     Also means what we get from BriefBuidler FrontEnd.
 */
export type BriefAttributes = {
  additionalNotes?: InputMaybe<Scalars['String']>
  /** Specify whether this is a personal or professional event */
  audience?: InputMaybe<Audience>
  /** An indication of how likely a guest is to book */
  bookingIntent?: InputMaybe<BookingIntent>
  budget: Scalars['Int']
  budgetNotes?: InputMaybe<Scalars['String']>
  bypassAutomation?: InputMaybe<Scalars['Boolean']>
  capacities: Scalars['Int']
  catering?: InputMaybe<Array<Scalars['String']>>
  cateringNeeds?: InputMaybe<Scalars['String']>
  collection?: InputMaybe<Scalars['String']>
  collectionName?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  currency?: InputMaybe<Scalars['String']>
  /** A list of content pages visted prior to submitting brief */
  enquiryContentPageSources?: InputMaybe<Array<Scalars['String']>>
  eventName?: InputMaybe<Scalars['String']>
  /** Deprecated: use requiredSpaceFacilityIds and/or optionalSpaceFacilityIds instead. */
  facilities?: InputMaybe<Array<Scalars['String']>>
  /** Deprecated: this parameter is completely ignored. */
  facilitiesList?: InputMaybe<Array<Scalars['String']>>
  /** Deprecated: superceded by isFlexibleOnDate/Time fields for Lead Feed */
  flexibleDates?: InputMaybe<Scalars['Boolean']>
  flexibleDatesNote?: InputMaybe<Scalars['String']>
  fromDate?: InputMaybe<Scalars['String']>
  fromTime?: InputMaybe<Scalars['String']>
  hostId?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  isFlexibleOnDate?: InputMaybe<Scalars['Boolean']>
  isFlexibleOnTime?: InputMaybe<Scalars['Boolean']>
  lat: Scalars['String']
  layouts?: InputMaybe<Scalars['String']>
  leadFeedCity?: InputMaybe<Scalars['String']>
  location: Scalars['String']
  lon: Scalars['String']
  numonce?: InputMaybe<Scalars['Int']>
  /** IDs for the facilities considered 'desirable' by the user */
  optionalSpaceFacilityIds?: InputMaybe<Array<Scalars['Int']>>
  public?: InputMaybe<Scalars['Boolean']>
  radius?: InputMaybe<Scalars['Float']>
  /** IDs for the facilities considered 'essential' by the user */
  requiredSpaceFacilityIds?: InputMaybe<Array<Scalars['Int']>>
  /** Deprecated: use requiredSpaceFacilityIds and/or optionalSpaceFacilityIds instead. */
  spaceFacilityIds?: InputMaybe<Array<Scalars['Int']>>
  spaceId?: InputMaybe<Scalars['String']>
  styleIds?: InputMaybe<Array<Scalars['String']>>
  tier?: InputMaybe<Scalars['String']>
  toDate?: InputMaybe<Scalars['String']>
  toTime?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['Int']>
  virtual?: InputMaybe<Scalars['Boolean']>
}

/** Type of a guest user who created the brief. */
export type BriefGuest = {
  __typename?: 'BriefGuest'
  company: Scalars['String']
  country?: Maybe<Scalars['String']>
  email: Scalars['String']
  firstName: Scalars['String']
  id: Scalars['ID']
  isBusinessUser: Scalars['Boolean']
  lastName: Scalars['String']
  mobileNumber?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
}

export type BriefIsAutomatableResponse = {
  __typename?: 'BriefIsAutomatableResponse'
  /** Whether the brief is automatable. */
  isAutomatable: Scalars['Boolean']
}

/** Type of collection / event_type. */
export type Collection = {
  __typename?: 'Collection'
  features?: Maybe<Array<Feature>>
  id: Scalars['ID']
  layouts?: Maybe<Array<Scalars['String']>>
  name: Scalars['String']
}

/** Type of features of collection. */
export type Feature = {
  __typename?: 'Feature'
  id: Scalars['ID']
  name: Scalars['String']
  position: Scalars['Int']
}

export type LeadsPage = {
  __typename?: 'LeadsPage'
  /** Infomation of this current page. */
  pages: Page
  /** Rows of AM leads. */
  rows: Array<Brief>
  /** Total number of pages. */
  totalPages: Scalars['Int']
  /** Totla number of AM leads. */
  totalRows: Scalars['Int']
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Create a new brief by BriefAttributes. */
  brief?: Maybe<Brief>
  /** Claim a brief by ID. */
  claimBrief?: Maybe<Brief>
  /** Disqualify a brief by ID. */
  disqualifyBrief?: Maybe<Brief>
  /** Release a brief by ID. */
  releaseBrief?: Maybe<Brief>
  /** Resend the brief confirmatio to guest email by ID. */
  resendBriefConfirmation?: Maybe<Brief>
  /** Resend the claimed brief infomation to Hubspot again by ID. */
  resendBriefToHubspot?: Maybe<Brief>
}

export type MutationBriefArgs = {
  params: BriefAttributes
}

export type MutationClaimBriefArgs = {
  id: Scalars['Int']
}

export type MutationDisqualifyBriefArgs = {
  id: Scalars['Int']
}

export type MutationReleaseBriefArgs = {
  id: Scalars['Int']
}

export type MutationResendBriefConfirmationArgs = {
  id: Scalars['Int']
}

export type MutationResendBriefToHubspotArgs = {
  id: Scalars['Int']
}

export type Page = {
  __typename?: 'Page'
  hasNextPage: Scalars['Boolean']
  number: Scalars['Int']
  size: Scalars['Int']
}

export type Query = {
  __typename?: 'Query'
  /** Query for AM Leads list table.  */
  amLeadsPage: LeadsPage
  /** Query for archived leads list table.  */
  archivedLeadsPage: LeadsPage
  /** Query for Brief by ID.  */
  brief: Brief
  /** Query for whether a Brief qualifies for automation.  */
  briefIsAutomatable: BriefIsAutomatableResponse
  collection: Collection
  collections: Array<Collection>
  /** Query for Leads with status.  */
  leads: Array<Brief>
  spaces: SpaceSearch
}

export type QueryAmLeadsPageArgs = {
  filter: RegionsFilter
  number: Scalars['Int']
  size: Scalars['Int']
}

export type QueryArchivedLeadsPageArgs = {
  number: Scalars['Int']
  size: Scalars['Int']
}

export type QueryBriefArgs = {
  id: Scalars['ID']
}

export type QueryBriefIsAutomatableArgs = {
  briefParams: BriefAttributes
}

export type QueryCollectionArgs = {
  id: Scalars['String']
}

export type QueryLeadsArgs = {
  status?: InputMaybe<Scalars['String']>
}

export type QuerySpacesArgs = {
  page?: InputMaybe<Scalars['Int']>
  params: SearchParamsInput
}

export type RegionsFilter = {
  regions?: InputMaybe<Array<Scalars['String']>>
}

export type SearchParamsInput = {
  additionalNotes?: InputMaybe<Scalars['String']>
  budget: Scalars['Int']
  capacities: Scalars['Int']
  catering?: InputMaybe<Array<Scalars['String']>>
  collection?: InputMaybe<Scalars['String']>
  collectionName?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  currency?: InputMaybe<Scalars['String']>
  eventName?: InputMaybe<Scalars['String']>
  facilities?: InputMaybe<Array<Scalars['String']>>
  flexibleDates?: InputMaybe<Scalars['Boolean']>
  fromDate?: InputMaybe<Scalars['String']>
  fromTime?: InputMaybe<Scalars['String']>
  hostId?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  lat: Scalars['String']
  layouts?: InputMaybe<Scalars['String']>
  location: Scalars['String']
  lon: Scalars['String']
  public?: InputMaybe<Scalars['Boolean']>
  radius?: InputMaybe<Scalars['Int']>
  spaceId?: InputMaybe<Scalars['String']>
  toDate?: InputMaybe<Scalars['String']>
  toTime?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['Int']>
}

export type Space = {
  __typename?: 'Space'
  coordinates: SpaceCoordinates
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  location?: Maybe<Scalars['String']>
  map?: Maybe<Array<SpaceMap>>
  spaceName?: Maybe<Scalars['String']>
  spacePhotos: Array<SpacePhoto>
  venueName?: Maybe<Scalars['String']>
}

export type SpaceCoordinates = {
  __typename?: 'SpaceCoordinates'
  lat: Scalars['Float']
  lng: Scalars['Float']
}

/** Consistent, business-defined properties of spaces that users can request for their events, such as 'natural light', 'parking facilities', etc. */
export type SpaceFacility = {
  __typename?: 'SpaceFacility'
  id: Scalars['Int']
  name: Scalars['String']
}

export type SpaceMap = {
  __typename?: 'SpaceMap'
  coordinates: SpaceCoordinates
  id: Scalars['ID']
  location?: Maybe<Scalars['String']>
  onPage: Scalars['Boolean']
}

export type SpacePhoto = {
  __typename?: 'SpacePhoto'
  detail: Scalars['String']
  id: Scalars['ID']
  listings: Scalars['String']
  position: Scalars['Int']
  squareThumb: Scalars['String']
  thumb: Scalars['String']
  url: Scalars['String']
}

export type SpaceSearch = {
  __typename?: 'SpaceSearch'
  listView: Array<Space>
  mapView: Array<SpaceMap>
}
