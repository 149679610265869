// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.link-button {
  padding: 0;
  margin: 0;
  border: 0;
  color: #0000EE;
  text-decoration: underline;
  background: none;
}
button.link-button:hover {
  cursor: pointer;
}
button.link-button:active {
  color: #FF0000;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/LinkButton/LinkButton.less"],"names":[],"mappings":"AAMA;EACE,UAAA;EACA,SAAA;EACA,SAAA;EACA,cAAA;EAGA,0BAAA;EACA,gBAAA;AAPF;AAIE;EAAU,eAAA;AADZ;AAEE;EAAW,cAAA;AACb","sourcesContent":["@import '../../../Styles/colours.less';\n\n// these colours are the webkit anchor defaults\n@link-blue: #0000EE;\n@link-active: #FF0000;\n\nbutton.link-button {\n  padding: 0;\n  margin: 0;\n  border: 0;\n  color: @link-blue;\n  &:hover { cursor: pointer }\n  &:active { color: @link-active }\n  text-decoration: underline;\n  background: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
