import { useState } from 'react'
import FormPage from '../../../../Components/Form/Page/FormPage'
import useBriefContext from '../../../../Hooks/Brief/useBriefContext'
import InputView from '../../InputView'
import GuestPageHeading from '../Base/Heading/PageHeading'
import GuestNumberInput from '../Base/Input/NumberInput'

const BizGuestNumberInput: InputView = ({ path }): JSX.Element => {
  const { brief } = useBriefContext()
  const [numGuests, setNumGuests] = useState<number | ''>(brief.capacities ?? '')

  const guestNumAttributes = { capacities: numGuests || undefined }

  return (
    <FormPage
      metaTitle='Event size - HeadBox'
      heading={<GuestPageHeading />}
      path={path}
      inputAttributes={guestNumAttributes}
      className='guest-number-page'>
      <GuestNumberInput numberOfGuests={numGuests} setNumberOfGuests={setNumGuests} />
    </FormPage>
  )
}

export default BizGuestNumberInput
