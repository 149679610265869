// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root .biz-summary {
  background-color: white;
  will-change: background-color;
  animation: 1.5s ease-out normal backwards fade-background;
  padding: 16px;
  text-align: left;
}
app-root .biz-summary .summary-header {
  margin-bottom: 12px;
}
app-root .biz-summary .summary-entry + .summary-entry {
  margin-top: 0.5em;
}
app-root .biz-summary .summary-entry .edit-link {
  font-size: 0.8rem;
}
app-root .biz-summary .summary-entry dt {
  display: inline;
  font-weight: 600;
  margin-right: 0.5em;
}
app-root .biz-summary .summary-entry dt::after {
  content: ':';
}
@keyframes fade-background {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: white;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Views/Summary/Biz/SummaryList.less"],"names":[],"mappings":"AAAA;EAEI,uBAAA;EACA,6BAAA;EACA,yDAAA;EACA,aAAA;EACA,gBAAA;AAAJ;AANA;EAQsB,mBAAA;AACtB;AATA;EAYQ,iBAAA;AAAR;AAZA;EAcmB,iBAAA;AACnB;AAfA;EAgBQ,eAAA;EAEA,gBAAA;EACA,mBAAA;AACR;AAAQ;EACE,YAAA;AAEV;AAGI;EACE;IACE,6BAAA;EADN;EAII;IACE,uBAAA;EAFN;AACF","sourcesContent":["app-root {\n  .biz-summary {\n    background-color: white;\n    will-change: background-color;\n    animation: 1.5s ease-out normal backwards fade-background;\n    padding: 16px;\n    text-align: left;\n\n    .summary-header { margin-bottom: 12px; }\n\n    .summary-entry {\n      + .summary-entry {\n        margin-top: 0.5em;\n      }\n      .edit-link { font-size: 0.8rem; }\n      dt {\n        display: inline;\n        margin-right: 0.5em;\n        font-weight: 600;\n        margin-right: 0.5em;\n        &::after {\n          content: ':';\n        }\n      }\n    }\n\n    @keyframes fade-background {\n      0% {\n        background-color: transparent;\n      }\n\n      100% {\n        background-color: white;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
