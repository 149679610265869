import { PartialBrief } from '../../../../Types/Brief'
import ListItem from '../../Base/SummaryList/Item'

interface FlexibilityListItemProps {
  brief: PartialBrief
}

type GetFlexibilityMessageProps = Pick<PartialBrief, 'isFlexibleOnDate' | 'isFlexibleOnTime'>

const getFlexibilityMessage = ({
  isFlexibleOnDate,
  isFlexibleOnTime,
}: GetFlexibilityMessageProps): string => {
  if (isFlexibleOnDate && isFlexibleOnTime) {
    return 'Flexible on date & time'
  } else if (isFlexibleOnDate) {
    return 'Flexible on date'
  } else if (isFlexibleOnTime) {
    return 'Flexible on time'
  } else {
    return 'None'
  }
}

const TimingFlexibilityListItem = ({
  brief: { isFlexibleOnDate, isFlexibleOnTime, flexibleDatesNote },
}: FlexibilityListItemProps): JSX.Element => {
  const trimmedNote = flexibleDatesNote?.trim()

  return (
    <ListItem path='timings' elementID='flexible-on-dates-input' label='Flexibility'>
      {getFlexibilityMessage({ isFlexibleOnDate, isFlexibleOnTime })}
      {(!!isFlexibleOnDate || !!isFlexibleOnTime) && !!trimmedNote && ` — "${trimmedNote}"`}
    </ListItem>
  )
}

export default TimingFlexibilityListItem
