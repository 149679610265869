import { Dispatch, ReactElement, useEffect, useState } from 'react'
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  useNavigate,
  ScrollRestoration,
  useLocation,
  Navigate,
} from 'react-router-dom'
import useBriefContext from '../Hooks/Brief/useBriefContext'
import getPermittedPath from '../Logic/Form/Authorization'
import { showBizLayout as shouldShowBizLayout } from '../Logic/Form/Sequences/Biz/isBizBrief'
import RouterBoundaryError from '../Views/Errors/RouterError'
import LocationInput from '../Views/FormSteps/Location/LocationInput'
import SuccessPage from '../Views/Success/SuccessPage'
import FORM_ROUTES from './Routes'
import { TIMINGS_PATH } from './Routes/formRoutes'
import useLayout from '../Hooks/Layout/Base/useLayout'

export const BASE_NAME = '/plan-my-event'
export const SUCCESS_PATH = 'success'

const FormRouter = (): JSX.Element => {
  const [firstInputRendered, setFirstInputRendered] = useState(false)
  const formRouter = createBrowserRouter(
    createRoutesFromElements(
      <Route path={'/'} errorElement={<RouterBoundaryError />}>
        {Object.entries(FORM_ROUTES).map(([path, InputPage]) => (
          <Route
            key={path}
            path={path}
            element={
              <InputOrRedirect
                path={path}
                firstInputRendered={firstInputRendered}
                setFirstInputRendered={setFirstInputRendered}>
                <InputPage path={path} />
              </InputOrRedirect>
            }
          />
        ))}
        {/* the root path should default to the first step of the LF sequence (location) */}
        <Route
          key={'lf-root'}
          path={''}
          element={
            <InputOrRedirect
              path={'location'}
              firstInputRendered={firstInputRendered}
              setFirstInputRendered={setFirstInputRendered}>
              <LocationInput path={'location'} />
            </InputOrRedirect>
          }
        />
        <Route path={SUCCESS_PATH} element={<SuccessPage />} />

        {/* Legacy Routes */}
        <Route path='dates' element={<Navigate to={`/${TIMINGS_PATH}`} replace />} />
      </Route>
    ),
    { basename: BASE_NAME }
  )

  return <RouterProvider router={formRouter} />
}

interface InputOrRedirectProps {
  path: string
  children: ReactElement
  firstInputRendered: boolean
  setFirstInputRendered: Dispatch<React.SetStateAction<boolean>>
}

const InputOrRedirect = ({
  path,
  children: input,
  firstInputRendered,
  setFirstInputRendered,
}: InputOrRedirectProps): JSX.Element => {
  const { brief, loading } = useBriefContext()
  const { setLayout } = useLayout()
  const navigate = useNavigate()
  const location = useLocation()
  const [isPermittedPath, lastPermittedPath] = getPermittedPath(path, brief)

  useEffect(() => {
    // determine the app layout
    if (isPermittedPath) setLayout(shouldShowBizLayout(brief) ? 'biz' : 'lf')

    if (!firstInputRendered) setFirstInputRendered(true)
  }, [location])

  useEffect(() => {
    // handle authorisation and redirection per path

    if (loading) return

    if (!isPermittedPath) {
      navigate(`/${lastPermittedPath}`, { replace: true })
    }
  }, [loading, isPermittedPath])

  return (
    <>
      {(isPermittedPath && input) || null}
      {(!firstInputRendered && <ScrollRestoration />) || null}
    </>
  )
}

export default FormRouter
