import { BIZ_EMAIL_PATH, FormPath } from '../../../Router/Routes/formRoutes'
import { PartialBrief } from '../../../Types/Brief'
import { SequenceStep } from '../Sequences'
import getFormSequence from '../Sequences/GetFormSequence'
import { getBizFormSequence } from '../Sequences/GetFormSequence/getFormSequence'

interface AdjacentSteps {
  previousPage?: FormPath
  currentPage: FormPath
  nextPage?: FormPath
  isStepComplete: SequenceStep['isStepComplete']
  isFinalStep: boolean
}

const getAdjacentSteps = (currentPage: string, brief: PartialBrief): AdjacentSteps => {
  const { sequence, name } =
    currentPage === BIZ_EMAIL_PATH ? getBizFormSequence(brief) : getFormSequence(brief)

  for (let i = 0; i < sequence.length; i++) {
    const { path, isStepComplete } = sequence[i]
    if (currentPage === path) {
      const { path: previousPage } = sequence[i - 1] ?? {}
      const { path: nextPage } = sequence[i + 1] ?? {}
      return {
        previousPage,
        currentPage: path,
        nextPage,
        isStepComplete,
        isFinalStep: nextPage === undefined,
      }
    }
  }

  throw new Error(`Path /${currentPage} wasn't found in the ${name} form sequence`)
}

export default getAdjacentSteps
