// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root .time-picker-input.no-selection select {
  color: #595959;
}
app-root .time-picker-input select {
  width: 100%;
}
app-root .time-picker-input select:focus ~ .icon-wrapper .clock-icon {
  color: #3e3e3e;
}
app-root .time-picker-input .clock-icon {
  color: #595959;
}
`, "",{"version":3,"sources":["webpack://./src/Views/FormSteps/DateTime/TimePicker/TimePicker.less"],"names":[],"mappings":"AAII;EAAwB,cAAA;AAF5B;AAAA;EAIM,WAAA;AADN;AAEM;EAAsC,cAAA;AAC5C;AANA;EAUM,cAAA;AADN","sourcesContent":["@import '../../../../Styles/colours.less';\n\napp-root {\n  .time-picker-input {\n    &.no-selection select { color: @border-grey; }\n    select {\n      width: 100%;\n      &:focus ~ .icon-wrapper .clock-icon { color: @hover-grey; }\n    }\n\n\n    .clock-icon {\n      color: @border-grey;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
