// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-root .date-time-page form {
  min-height: 500px;
  height: 100%;
}
app-root .date-time-page form div[data-baseweb='popover'] {
  margin: 8px 0;
  transform: translateX(-50%);
  left: 50%;
  z-index: 4;
}
app-root .date-time-page label {
  font-weight: 400;
  color: #595959;
}
app-root .date-time-page .timings {
  text-align: left;
}
app-root .date-time-page .timings label {
  width: calc(50% - 8px);
}
app-root .date-time-page .timings label + label {
  margin-left: 16px;
}
app-root .date-time-page .timings .time-picker-input {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Views/FormSteps/DateTime/DateTimeInput.less"],"names":[],"mappings":"AAEA;EAGM,iBAAA;EACA,YAAA;AAHN;AADA;EAOQ,aAAA;EACA,2BAAA;EACA,SAAA;EACA,UAAA;AAHR;AAPA;EAcM,gBAAA;EACA,cAAA;AAJN;AAXA;EAkBM,gBAAA;AAJN;AAdA;EAqBQ,sBAAA;AAJR;AAKQ;EAAY,iBAAA;AAFpB;AApBA;EAyB2B,WAAA;AAF3B","sourcesContent":["@import '../../../Styles/colours.less';\n\napp-root {\n  .date-time-page {\n    form {\n      min-height: 500px;\n      height: 100%;\n    \n      div[data-baseweb='popover'] {\n        margin: 8px 0;\n        transform: translateX(-50%);\n        left: 50%;\n        z-index: 4;\n      }\n    }\n    label {\n      font-weight: 400;\n      color: @border-grey;\n    }\n    .timings {\n      text-align: left;\n\n      label {\n        width: calc(50% - 8px);\n        & + label { margin-left: 16px;}\n      }\n\n      .time-picker-input { width: 100%; }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
