import {
  ChangeEventHandler,
  FormEventHandler,
  ForwardedRef,
  forwardRef,
  ReactNode,
  SelectHTMLAttributes,
  useId,
  useState,
} from 'react'
import ValidationMessage from '../ValidationMessage/ValidationMessage'
import getValidationMessage from './Validation/getValidationMessage'
import './Select.less'

interface CustomProps {
  icon?: ReactNode
  fieldName?: string // the name that will be used in validation error messages
  forwardedRef?: ForwardedRef<HTMLSelectElement | null>
}

type SelectProps = SelectHTMLAttributes<HTMLSelectElement> & CustomProps

const _Select = ({
  onChange: _onChange,
  onInvalid: _onInvalid,
  className,
  children,
  forwardedRef,
  icon,
  fieldName = 'option',
  ...selectProps
}: SelectProps): JSX.Element => {
  const [invalidMessage, setInvalidMessage] = useState<string | null>(null)
  const invalidMessageId = useId()
  const isInvalid = !!invalidMessage

  const onInvalid: FormEventHandler<HTMLSelectElement> = (event) => {
    _onInvalid && _onInvalid(event)
    const { currentTarget } = event
    setInvalidMessage(getValidationMessage(currentTarget, fieldName))
    event.preventDefault()
  }
  const onChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    _onChange && _onChange(event)
    setInvalidMessage(null)
  }
  return (
    <ValidationMessage
      className={`hb-select ${icon ? 'with-icon' : ''} ${className ?? ''}`}
      id={invalidMessageId}
      invalidMessage={invalidMessage}>
      <select
        onInvalid={onInvalid}
        onChange={onChange}
        ref={forwardedRef}
        aria-invalid={isInvalid}
        aria-errormessage={isInvalid ? invalidMessageId : undefined}
        {...selectProps}>
        {children}
      </select>
      {icon && <span className='icon-wrapper'>{icon}</span>}
    </ValidationMessage>
  )
}

const Select = forwardRef<HTMLSelectElement | null, SelectProps>(function CustomSelect(props, ref) {
  return <_Select forwardedRef={ref} {...props} />
})

export default Select
