import { createContext } from 'react'
import { ActiveLocale } from '../../Types/Location'
import resources from '../../../locales'

export type Language = keyof typeof resources

export type CurrentLocale = ActiveLocale | undefined

const LocaleContext = createContext<CurrentLocale>(undefined)

export default LocaleContext
