import { PartialBrief } from "../../Types/Brief"

const pushTagEvent = (brief: PartialBrief, eventName: string): void => {
  if (!window.dataLayer) return

  window.dataLayer.push({
    brief: {
      budget: brief.budget,
      currency: brief.currency,
      id: brief.id,
      location: brief.location,
      virtual: brief.virtual,
      isBizBrief: brief?.guest?.isBusinessUser,
    },
    event: eventName,
  })
}

export default pushTagEvent
